import React, { Component, useEffect, useState, useRef } from 'react';
import './InvoiceBill.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
// import image from "../../../assets/images/mjt logo.cpy.png";
// import qrcode from "../../../assets/images/mjt qrcode.png"
import bgawfiya from "../../../assets/images/awfiyaqr.png"
import Loader from "../../loader/loader"



function InvoiceBill() {
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState("")
    const [othercharges, setOthercharges] = React.useState("")
    const [customer, setCustomer] = React.useState("")
    const [building, setBuilding] = React.useState("")

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [customername, setCustomername] = useState('')
    const [area, setArea] = useState('')
    const [remarks, setRemarks] = useState('')
    const [ordertype, setOrdertype] = useState('')
    const [street, setStreet] = useState('')

    const [zone, setZone] = useState('')
    const [mobile, setMobile] = useState('')
    const [lpo, setLpono] = useState('')
    const [tableRow, setTableRow] = useState([])
    const [reference, setReference] = useState('')
    const [salesman, setSalesman] = useState('')

    const [totalamount, setTotalamount] = useState('')
    const [salesOrderDetail,setSalesOrderDetail]= useState([]);
    const [totalprice, setPrice] = useState(0)
    const [totaltax, setTax] = useState('')

    const [roundoff, setRoundoff] = useState('')
    const [subtotal, setSubtotal] = useState('')

    const [variationamount, setVariationamount] = useState('')
    const [deliverycharge, setDeliverycharge] = useState('')
    const [replacereturn, setReplacereturn] = useState('')

    const [replacebefore, setReplacebefore] = useState('')
    const [isreplacedone, setisreplacedone] = useState('')


    const [refcode, setRefcode] = useState('')

    const [taxrate, setTaxrate] = useState('')
    const [loader, setLoader] = React.useState(false);

    const [subrate, setSubrate] = useState('')



    // qr code 


    // const [qrcode,setQrcode] = useState(
    //     {
    //         date:[],
    //         name:[],
    //         mobilenumber:[],
    //         area:[],
    //         building:[]

    //     }
    // )
    // const [dataLength, setDataLength] = useState('')


    const itemsPerPage = 15;
    // // const length=  dataLength;

    // const totalpages = Math.ceil(dataLength / itemsPerPage)

    // console.log(totalpages, 'total232323')
    // console.log(dataLength, 'dlen')

    // const pages = pageIndex

    // const pageStart = pageIndex * itemsPerPage;
    // const pageEnd = pageStart + itemsPerPage;
    // const pageData = tableRow.slice(pageStart,pageEnd)




    // number to words conversion


    // const NumberToWordsConverter=()=>{
    const [number, setNumber] = useState([]);
    // const [words, setWords] = useState([]);




    const numberToWords = (num) => {
        console.log(num, "number1")
        const units = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Fourty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        const getWords = (num) => {
            if (num === 10) return 'ten'
            if (num < 10) return units[num];
            else if (num < 20) return teens[num - 10];
            else return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
        }

        const inWords = (num) => {
            // if (num < 100) return getWords(num);
            // else if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred ' + getWords(num % 100);
            // else return inWords(Math.floor(num / 1000)) + ' Thousand ' + inWords(num % 1000);
            // -------------------------------------------------------------------------------------------------------
            // if (num < 100) return getWords(num);
            // else if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred ' + getWords(num % 100);
            // else if (num < 1000000) return inWords(Math.floor(num / 1000)) + ' Thousand ' + inWords(num % 1000);
            // else return inWords(Math.floor(num / 1000000)) + ' Million ' + inWords(num % 1000000);

            // --------------------------------------------------------------------------------------------------------
            const hasDecimal = num % 1 !== 0;
            if (hasDecimal) {
                const decimalPart = num.toString().split('.')[1];
                const decimalWords = decimalPart.split('').map(digit => units[parseInt(digit)]).join(' ');
                return inWords(Math.floor(num)) + ' Point ' + decimalWords;
                // return inWords(Math.floor(num)) + ' Point ' + decimalWords +' ' + ' Halala ';

            }


            if (num < 100) return getWords(num);

            else if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred ' + getWords(num % 100);

            else if (num < 100000) return inWords(Math.floor(num / 1000)) + ' Thousand ' + inWords(num % 1000);

            else if (num < 10000000) return inWords(Math.floor(num / 100000)) + ' Lakh ' + inWords(num % 100000);

            else return inWords(Math.floor(num / 10000000)) + ' Crore ' + inWords(num % 10000000);

            // else return inWords(Math.floor(num / 1000000000)) + ' Million ' + inWords(num % 1000000000);



        };

        return inWords(num);
    };




    // }
    const words = numberToWords(number)
    console.log(number, "number 23")
    console.log("word 11", words)
    console.log(numberToWords(2547845), "hhhhhai")

    console.log("id billNo", id)
    useEffect(() => {

        setLoader(true)

        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)

        var data = {
            "companyid": company,
            "salesorderid": id,
            "type": userdata.user_type
        }

        fetch(global.url + "viewSalesOrderDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })


            .then((response) => response.json())
            .then((responseJson) => {
                // setDataLength(responseJson.data.sales_details.length);

                setLoader(false)
                console.log("print details", responseJson)
                setCustomername(responseJson.data.customer_name)
                setBuilding(responseJson.data.customer_building)
                setArea(responseJson.data.customer_area)
                setRemarks(responseJson.data.customer_remarks)
                setDate(responseJson.data.sales_date)
                setOrdertype(responseJson.data.customer_whatsapp)
                setStreet(responseJson.data.customer_street)
                setZone(responseJson.data.sales_zone)
                setMobile(responseJson.data.customer_mobile)
                setLpono(responseJson.data.sales_invoice)
                setTableRow(responseJson.data.sales_details)
                setReference(responseJson.data.sales_reference)
                setSalesman(responseJson.data.sales_salesman)
                setOthercharges(responseJson.data.sales_other_charges)
                setRoundoff(responseJson.data.sales_roundoff)
                setSubtotal(responseJson.data.sales_subtotal)
                setTotalamount(responseJson.data.sales_total)
                setTax(responseJson.data.salesorder_tax)
                setPrice(responseJson.data.salesorder_price)
                // setRefcode(responseJson.data.sales_ref_no)
                setRefcode(responseJson.data.sales_details.code)
                setTaxrate(responseJson.data.salesorder_tax)
                setNumber(responseJson.data.sales_total)
                setSubrate(responseJson.data.sales_calprice)
                setSalesOrderDetail(responseJson.data.sales_details)
                console.log(salesOrderDetail,'sdd')
                if (responseJson.data.replacereturn == 1) {

                    setReplacereturn(responseJson.data.replacereturn)
                    setVariationamount(responseJson.data.variation_amount)
                    setDeliverycharge(responseJson.data.delivery_charge)

                    var data = responseJson.data.variation_amount + responseJson.data.delivery_charge
                    setTotalamount(data)
                    console.log(data,'her124');
                }

                if (responseJson.data.salesorder_replacebefore == 1) {

                    setReplacebefore(responseJson.data.salesorder_replacebefore)
                    setDeliverycharge(responseJson.data.salesorder_delivery_charge)
                    setVariationamount(responseJson.data.salesorder_variation_amount)
                    var data = responseJson.data.salesorder_variation_amount + responseJson.data.salesorder_delivery_charge
                    setTotalamount(data)
                    console.log(data,'her123');
                }

                // if (salesOrderDetail.salesorder_isreplacedone == 1) {


                //     if(responseJson.data.sales_details[0].salesorder_isreplacedone == 1){  
                //     console.log('this function')
                //     setisreplacedone(responseJson.data.salesorder_isreplacedone)
                //     setDeliverycharge(responseJson.data.salesorder_delivery_charge)
                //     setVariationamount(responseJson.data.salesorder_variation_amount)
                //     // var data = responseJson.data.salesorder_variation_amount + responseJson.data.salesorder_delivery_charge    <---- first code part
                //     // setTotalamount(data)
                //     setTotalamount(responseJson.data.replaced_amount_2)
                //     setNumber(responseJson.data.replaced_amount_2)
                // }

                // if (salesOrderDetail.salesorder_isreplacedone == 1) {
                    if(responseJson.data.sales_details[0].salesorder_isreplacedone == 1){  
                        console.log('this function jhkjh')
                        setisreplacedone(responseJson.data.salesorder_isreplacedone)
                        setDeliverycharge(responseJson.data.salesorder_delivery_charge)
                        setVariationamount(responseJson.data.salesorder_variation_amount)
                        // var data = responseJson.data.salesorder_variation_amount + responseJson.data.salesorder_delivery_charge    <---- first code part
                        // setTotalamount(data)
                        // setTotalamount(responseJson.data.replaced_amount_2)
                        setTotalamount(responseJson.data.sales_total - responseJson.data.cancelled_products_2)
                        setNumber(responseJson.data.sales_total - responseJson.data.cancelled_products_2)
                    }
    
              else{
                console.log('else function')
              }
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    // useEffect(() => {
    //     var totalAmount = 0;
    //     for (var i = 0; i < tableRow.length; i++) {
    //         totalAmount += tableRow[i].sd_total_amount
    //      }
    //      setTotalamount(totalAmount+10)
    // }, [tableRow]);




    const pageFunction = () => {
        const printablePages = [];
        const totalPages = Math.ceil(tableRow.length / itemsPerPage);

        for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
            const pageStart = pageIndex * itemsPerPage;
            const pageEnd = pageStart + itemsPerPage;
            const pageData = tableRow.slice(pageStart, pageEnd);

            printablePages.push(
                <div
                    className='printdivorg'
                >
                    <Container fluid >
                        <Row >
                            <Col xs={12}
                                // style={{ marginTop: 20 }}
                                className='col1'
                            >

                                <div className="invoice-box printing">
                                    <div className="invoice-inside" style={{
                                        marginTop: '0px',
                                        border: '1px solid #000', borderRadius: 'inherit'
                                    }}>
                                        {/* <div className="invoice-inside" style={{ marginTop: '-22px'}}> */}

                                        {/* <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset' }}>Dekanet Beirut</p>
                                <p style={{ textAlign: 'center', fontSize: 13, marginBottom: 'unset' }}>Al Dafna - Doha</p>
                                <p style={{ textAlign: 'center', fontSize: 13 }}>Tel: 9444647200</p>
                                <p style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>Salesorder Invoice</p> */}

                                        <Row style={{ marginBottom: '15px' }}>
                                            <Col lg={5} md={5} xs={5}>
                                                <p style={{ fontSize: 15, fontWeight: 600, marginBottom: 'unset', color: '#222274' }}>{global.proname}</p>
                                                <p style={{ fontSize: 11, fontWeight: 'bold', marginBottom: 'unset' }}>{global.cr}</p>
                                                <p style={{ fontSize: 11, fontWeight: 'bold', marginBottom: 'unset' }}>{global.vat}</p>
                                                <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>{global.loc}</p>
                                                <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>{global.email}</p>
                                                <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}> {global.web}</p>

                                            </Col>

                                            <Col lg={2} md={2} xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                {/* <img src={image} style={{ width: '80px', height: '80px' }} alt='no image' /> */}
                                                <div className='center-logo'></div>

                                            </Col>

                                            <Col lg={5} md={5} xs={5}>

                                                <p style={{ textAlign: 'right', fontSize: 15, fontWeight: 600, marginBottom: 'unset', color: '#222274' }}>{global.arbicname}</p>
                                                <p style={{ textAlign: 'right', fontSize: 11, marginBottom: 'unset', fontWeight: 'bold' }}>{global.arbiccr}</p>

                                                <p style={{ textAlign: 'right', fontSize: 11, fontWeight: 'bold' }}>{global.arbicvat}</p>
                                                <p style={{ textAlign: 'right', fontSize: 10, fontWeight: 'bold', marginBottom: 'unset', marginTop: '-15px' }}>{global.arbicloc}</p>


                                                {/* <p style={{ textAlign: 'center', fontSize: 7, fontWeight: 'bold', marginBottom: 'unset' }}>الدمام ,الملكة العربية السعودية</p> */}
                                                {/* <p style={{ textAlign: 'center', fontSize: 13 }}>Email:Dammam.mjt@outlook.com</p> */}


                                            </Col>


                                        </Row>
                                        {/* <hr/> */}

                                        <div  >

                                            {/* <br /> */}

                                            <Row
                                                style={{ border: '1px solid #000000', borderLeft: 'none', borderRight: 'none', marginLeft: '0px', marginRight: '0px', borderBottom: 'none' }}

                                            >



                                                <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 9, marginBottom: 'unset' }}>CUSTOMER DETAILS {mobile}</p>

                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>رقم الفاتورة</p>
                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Invoice No: {lpo}</p>


                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Date: {date}</p>

                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم العميل</p>
                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Customer: {customername}</p>

                                                    {/* <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p> */}

                                                    {/* <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p> */}

                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>رقم ضريبة القيمة المضافة</p>


                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>VAT NO:311419001500003</p>

                                                </Col>



                                                <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>


                                                    {/* <p style={{ fontWeight: 'bold', fontSize: 9, marginBottom: 'unset' }}>SHIP TO</p>

                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>رقم الجوال</p>
                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Mob No: {mobile}</p>

                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>منطقة</p>
                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Area: {area}</p>


                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                                    <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Building: {building}</p> */}


                                                </Col>



                                                <Col className='bgsize' xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderLeft: '1px solid rgb(0,0,0)' }}>
                                                    {/* <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize:7}}>QR CODE</p> */}


                                                    {/* <center> <img src={bgmjt} style={{ width: '80px', height: '80px', marginTop: '30px', marginLeft: '-12px' }} alt='no image' /></center> */}

                                                    <center><img src={bgawfiya} style={{ width: '80px', height: '80px', marginTop: '30px', marginLeft: '-12px' }} alt='no image' /></center>
                                                </Col>

                                            </Row>
                                        </div>

                                        {/* <Row>

                                <Col>
                                <p style={{fontSize:10}}>CUSTOMER DETAILS</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عميل</p>
                                <p style={{ fontSize: 12, fontWeight: 500 }}>Customer: {customername}</p>

                                <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p>
                                
                                </Col>


                                
                              </Row> */}
                                        {/* <Row>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عميل</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Customer: {customername}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Mob No: {mobile}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Building: {building}</p>
                                    </Col>

                                    <Col xs={6}>

                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Street: {street}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Zone: {zone}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Area: {area}</p>
                                    </Col> */}

                                        {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Remarks: {remarks}</p>
                                </Col> */}
                                        {/* <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Reference: {reference}</p>
                                    </Col>

                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Date: {date}</p>
                                    </Col> */}


                                        {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col> */}
                                        {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Other Reference(s): Cash</p>
                                </Col> */}
                                        {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col> */}
                                        {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col> */}
                                        {/* </Row>     */}
                                        {/* <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div> */}

                                        {/* </Row> */}
                                        <div key={pageIndex} className='table-container' >




                                            <Table bordered className='invoicetable' style={{ marginBottom: '0px' }} >
                                                <thead>
                                                    <tr style={{ border: '1px solid #000000', borderTop: 'none', borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                                        <th style={{ backgroundColor: '#9E2567' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>عدد</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>SLNo.</p>
                                                        </th>

                                                        <th className='item2' style={{ backgroundColor: '#9E2567', width: '87px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>رمز الصنف</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Item Code</p>
                                                        </th>
                                                        <th className='name3' style={{ backgroundColor: '#9E2567', width: '350px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', color: 'white', textAlign: 'center' }}>اسم العنصر</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Item Name</p>
                                                        </th>
                                                        {/* <th style={{width: '20%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الرمز الشريطي</p>
                                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Barcode</p>
                                                </th> */}
                                                        {/* <th style={{width: '35%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Product</p></th> */}
                                                        <th style={{ backgroundColor: '#9E2567' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>كمية</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Qty</p></th>

                                                        {/* <th style={{ width: '10%' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عدد</p>
                                                    <p style={{ fontSize: 12, fontWeight: 'bold' }}>Unit</p>
                                                </th>
                                                     */}
                                                        <th className='web' style={{ backgroundColor: '#9E2567', maxWidth: '84px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>ثمن</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Price</p></th>
                                                        <th style={{ backgroundColor: '#9E2567' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>ضريبة%</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Tax %</p></th>
                                                        <th className='total4' style={{ backgroundColor: '#9E2567', width: '80px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>مجموع</p>
                                                            <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Total</p></th>
                                                    </tr>
                                                </thead>
                                                {
                                                    replacereturn == 1 ?
                                                        <tbody className='custom-tbody' >
                                                            {
                                                                pageData.map((item, index) => {
                                                                    console.log(item, "item code")
                                                                    return (
                                                                        item.sod_iscancel == 1 ?
                                                                            null :
                                                                            <>
                                                                                <tr>

                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{index + 1}</td>
                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.code}</td>
                                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.productname}</td>

                                                                                    {/* <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td> */}
                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td>
                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.calculated_price}</td>
                                                                                    {/* <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td> */}

                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.taxrate}%</td>
                                                                                    {/* <td>{item.calculated_totalprice}</td> */}
                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.sd_total_amount}</td>
                                                                                    {/* <td>{item.calculated_totalprice}</td> */}
                                                                                </tr>
                                                                                {/* <tr>
                                                                            <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                {item.productname}
                                                                            </td>
                                                                        </tr> */}
                                                                            </>
                                                                    )

                                                                })
                                                            }


                                                            <tr className='header' style={{ border: '1px solid #000000' }}>

                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                {/* <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td> */}
                                                            </tr>


                                                            {/* ----------------------------------
                                                    <tr>

                                                        <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Extra Delivery Charge</p></th>
                                                        <td>QAR {deliverycharge}</td>
                                                    </tr>
                                                    <tr>

                                                        <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Variationamount</p></th>
                                                        <td>QAR {variationamount}</td>
                                                    </tr>
                                                    <tr>

                                                        <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total Amount</p></th>
                                                        <td>QAR {totalamount}</td>
                                                    </tr>

                                                    ----------------------------------------------- */}



                                                            <tfoot style={{ height: '100px' }}>
                                                                <tr style={{ border: '1px solid #000' }}>

                                                                    <td colSpan={5}>
                                                                        <p className='set12' style={{ fontSize: 16 }}>Total In Words</p>

                                                                        {words && <p className='set12' style={{ fontSize: 13, fontWeight: 'bold', fontFamily: "'Kanit', sans-serif" }}> Saudi Riyal {words} Only</p>}

                                                                    </td>
                                                                    <td className='ppp'>
                                                                        {/* <p></p> */}
                                                                        <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Sub Total</p>
                                                                        <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Tax%</p>
                                                                        <p className='ppp' style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }}>Total</p>
                                                                        <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>Balance due</p>

                                                                    </td>
                                                                    <td>
                                                                        {/* <p></p> */}
                                                                        <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> {totalprice}</p>
                                                                        <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}>{taxrate} </p>
                                                                        <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }} >SAR {totalamount}</p>
                                                                        <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>SAR {totalamount}</p>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </tbody>
                                                        :
                                                        replacebefore == 1 ?
                                                            <tbody>
                                                                {
                                                                    pageData.map((item, index) => {
                                                                        return (
                                                                            item.sod_iscancel == 1 ?
                                                                                null :
                                                                                <>
                                                                                    <tr>

                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{index + 1}</td>
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.code}</td>
                                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.productname}</td>

                                                                                        {/* <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td> */}
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td>
                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                        {/* <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td> */}
                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.calculated_price}</td>

                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.taxrate}%</td>
                                                                                        {/* <td>{item.calculated_totalprice}</td> */}
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.sd_total_amount}</td>
                                                                                    </tr>
                                                                                    {/* <tr>
                                                                                <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td>
                                                                            </tr> */}
                                                                                </>
                                                                        )

                                                                    })
                                                                }

                                                                <tr className='header' style={{ border: '1px solid #000000' }}>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    {/* <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td> */}
                                                                </tr>


                                                                {/* ---------------------------
                                                        <tr>

                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Extra Delivery Charge</p></th>
                                                            <td>QAR {deliverycharge}</td>
                                                        </tr>
                                                        <tr>

                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold', }}>Variationamount</p></th>
                                                            <td>QAR {variationamount}</td>
                                                        </tr>
                                                        <tr>

                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', float: 'right' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total Amount</p></th>
                                                            <td>QAR {totalamount}</td>
                                                        </tr>

                                                        ----------------------------------- */}


                                                                <tfoot style={{ height: '100px' }}>
                                                                    <tr style={{ border: '1px solid #000' }}>

                                                                        <td colSpan={5}>
                                                                            <p style={{ fontSize: 16 }}>Total In Words</p>

                                                                            {words && <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: "'Kanit', sans-serif" }}> Saudi Riyal {words} Only</p>}

                                                                        </td>
                                                                        <td className='ppp'>
                                                                            {/* <p></p> */}
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Sub Total</p>
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Tax%</p>
                                                                            <p className='ppp' style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }}>Total</p>
                                                                            <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>Balance due</p>

                                                                        </td>
                                                                        <td>
                                                                            {/* <p></p> */}
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> {totalprice}</p>
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}>{taxrate} </p>
                                                                            <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }} >SAR {totalamount}</p>
                                                                            <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>SAR {totalamount}</p>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </tbody> :
                                                            <tbody className='custom-tbody'>
                                                                {
                                                                    pageData.map((item, index) => {
                                                                        console.log(item, "item code 1")
                                                                        return (
                                                                            item.sod_iscancel == 1 ?
                                                                                null :
                                                                                <>
                                                                                    <tr className='rowbody' >



                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{index + 1}</td>
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.code}</td>
                                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.productname}</td>

                                                                                        {/* <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td> */}
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td>
                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                        {/* <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td> */}
                                                                                    <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.calculated_price}</td>

                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.taxrate}%</td>
                                                                                        {/* <td>{item.calculated_totalprice}</td> */}
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.sd_total_amount}</td>


                                                                                    </tr>

                                                                                </>

                                                                        )

                                                                    })
                                                                }
                                                                <tr style={{ border: '1px solid #000000' }}>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                </tr>



                                                                {/* <tr>
                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total </p></th>
                                                            <td>QAR {totalamount}</td>
                                                        </tr>  */}
                                                                {/* <tr>
                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}> مجموع الضريبة </p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total Tax</p></th>
                                                            <td>QAR {totaltax}</td>
                                                        </tr>
                                                        <tr> */}
                                                                {/* <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Sub Total</p></th>
                                                            <td>QAR {totalprice}</td>
                                                        </tr> */}

                                                                {/* <tr>
    
                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Other Charges</p></th>
                                                            <td>QAR {othercharges}</td>
                                                        </tr> */}

                                                                {/* <tr>
                                                                
                                                                <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>نهاية الجولة</p>
                                                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Round off</p></th>
                                                                <td>QAR {roundoff}</td>
                                                            </tr> */}




                                                            </tbody>


                                                }
                                                <tfoot style={{ height: '82px' }}>
                                                    <tr style={{ border: '1px solid #000' }}>

                                                        <td colSpan={3}>
                                                            <p style={{ fontSize: 16, marginBottom: '0px' }}>Total In Words</p>

                                                            {words && <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: "'Kanit', sans-serif", marginBottom: '0px' }}> Saudi Riyal {words} Only</p>}

                                                            <p style={{ fontSize: 9, fontFamily: 'sans-serif', marginBottom: '0px' }}> Thanks for your business</p>

                                                        </td>
                                                        <td className='ppp' colSpan={2}>
                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Sub Total</p>
                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Tax%</p>
                                                            <p className='ppp' style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }}>Total</p>
                                                            <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>Balance due</p>

                                                        </td>
                                                        <td colSpan={2}>

                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> {totalprice}</p>
                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}>{taxrate} </p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }} >SAR {totalamount}</p>
                                                            <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>SAR {totalamount}</p>
                                                        </td>
                                                    </tr>



                                                    {/* ----------------------------------------------------------------- */}
                                                    {/* <tr style={{ border: '1px solid #000', height: '200px' }}>

                                                <td colSpan={3}>
                                                    
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>1) It is understood and agreed that bt signing this document by the customer or any of their representatives,the above mentioned goods are recieved in good condition and as per the terms and conditions mentioned on our website/contract</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>2) Title and ownership is goods purchased,for which the prices are set out this invoice,including full legal and benificial ownwership,shall not under any circumstances pass to the buyer until the seller has recieved the full payment.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>3) Sales person or Client endorsement of corrections/deductions on invoice is not valid.Deduction relating to price difference, FOC etc. are accept only against official credit notes.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>4) Discrepancies if any,should be notified within 7 days</p>



                                                </td>

                                                <td colSpan={2}>

                                                <Col lg={4} md={4} xs={4} style={{ borderBottom: 'none', borderTop: 'none' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'center', backgroundColor: '#4dabf5', color: 'white' }}>CUSTOMER RECEIPT</p>

                                                     <Col >
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم المستلم</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Receiver's Name:</p></Col>

                                                    <Col>
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>ضريبة%</p>
                                                        <p style={{ fontWeight: 'b0ld', fontSize: 10 }}>Sign & Stamp:</p></Col>

                                                    <Col>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Date:</p></Col>

                                                        </Col>


                                                </td>
                                                <td colSpan={2}></td>


                                            </tr> */}


                                                    {/* ----------------------------------------- */}
                                                </tfoot>


                                                {/* <thead style={{height:'200px'}}>

                                               <tr style={{border:'1px solid #000'}}>

                                                <td colSpan={3}></td>

                                                <td colSpan={2}></td>
                                                <td colSpan={2}></td>


                                               </tr>

                                             

                                        </thead> */}




                                                {/* <tfoot>


                                            <tr style={{ border: '1px solid #000000' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                

                                                <td style={{ textAlign: 'end' }}>
                                                    <Row>
                                                        <p style={{ fontWeight: 'bold' }}>Total</p>
                                                        <p></p>
                                                        <p></p>
                                                        <p className='balancedue' style={{ fontWeight: 'bold', marginTop: '-20px' }}>Balance Due </p>
                                                    </Row>

                                                </td>

                                                <td>
                                                    <p style={{ borderTop: 'revert', textAlign: 'center', fontWeight: 'bold' }}> SAR{totalamount}</p>
                                                    <p>hhh</p>

                                                    <p style={{ borderTop: 'revert', textAlign: 'center', fontWeight: 'bold' }}> SAR{totalamount}</p>

                                                </td>
                                            </tr>


                                        </tfoot> */}

                                            </Table>


                                            <div className='ftr' >

                                                {/* <br /> */}

                                                <Row
                                                    style={{ border: '1px solid #000000', borderLeft: 'none', borderRight: 'none', marginLeft: '0px', marginRight: '0px', borderTop: 'none' }}

                                                >



                                                    <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none' }}>

                                                        <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>1) It is understood and agreed that bt signing this document by the customer or any of their representatives,the above mentioned goods are recieved in good condition and as per the terms and conditions mentioned on our website/contract.</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>2) Title and ownership is goods purchased,for which the prices are set out this invoice,including full legal and benificial ownership,shall not under any circumstances pass to the buyer until the seller has recieved the full payment.</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>3) Sales person or Client endorsement of corrections/deductions on invoice is not valid.Deduction relating to price difference, FOC etc. are accept only against official credit notes.</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px' }}>4) Discrepancies if any,should be notified within 7 days.</p>





                                                    </Col>



                                                    <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>


                                                        <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'center', backgroundColor: '#9E2567', color: 'white' }}>CUSTOMER RECEIPT</p>

                                                        <Col >
                                                            <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم المستلم</p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: 'unset' }}>Receiver's Name:-</p></Col>

                                                        <Col>
                                                            <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>ضريبة%</p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: 'unset' }}>Sign & Stamp:-</p></Col>

                                                        <Col>

                                                            <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: 'unset' }}>Date:-</p></Col>


                                                    </Col>



                                                    <Col className='bgsize' xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderLeft: '1px solid rgb(0,0,0)' }}>
                                                        <p className='set12' style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12, }}>AWFIYA DIGITAL PRINT</p>




                                                    </Col>

                                                </Row>
                                            </div>

                                            {/* <Table>
                                        
                                    </Table> */}
                                            {/* <Table>
                                        <tfoot >

                                            <Row style={{ border: '1px solid #000000', width: '195mm' }}


                                            // style={{border:'2px solid #C00000F9'}}
                                            >



                                                <Col lg={4} md={4} xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderRight: '1px solid #000', borderLeft: 'revert' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>1) It is understood and agreed that bt signing this document by the customer or any of their representatives,the above mentioned goods are recieved in good condition and as per the terms and conditions mentioned on our website/contract</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>2) Title and ownership is goods purchased,for which the prices are set out this invoice,including full legal and benificial ownwership,shall not under any circumstances pass to the buyer until the seller has recieved the full payment.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>3) Sales person or Client endorsement of corrections/deductions on invoice is not valid.Deduction relating to price difference, FOC etc. are accept only against official credit notes.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>4) Discrepancies if any,should be notified within 7 days</p>


                                                    ---------------------
                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عميل</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Customer: {customername}</p>

                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p>
                                                    ---------------------
                                                </Col>



                                                <Col lg={4} md={4} xs={4} style={{ borderBottom: 'none', borderTop: 'none' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'center', backgroundColor: '#4dabf5', color: 'white' }}>CUSTOMER RECEIPT</p>


                                                    <Col >
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم المستلم</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Receiver's Name:</p></Col>

                                                    <Col>
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>ضريبة%</p>
                                                        <p style={{ fontWeight: 'b0ld', fontSize: 10 }}>Sign & Stamp:</p></Col>

                                                    <Col>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Date:</p></Col>





                                                    -----------
                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Mob No: {mobile}</p>

                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Area: {area}</p>
                                                    ---------------



                                                </Col>



                                                <Col lg={4} md={4} xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: '1px solid #000', height: '306px', borderRight: '1px  ' }}>
                                                    <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12, }}>MOKHAFAT JOUDHA <br />TRADING EST</p>
                                                    --------------
                                                    <center> <img src={qrcode} style={{ width: '60px', height: '60px' }} alt='no image' /></center>
                                                    -----------------
                                                </Col>

                                            </Row>


                                        </tfoot>
                                    </Table> */}

                                        </div>



                                        {/* -------------------------------

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={require("../../../assets/images/qrcode.png").default} style={{ width: 50, height: 50 }} />
                                </div>
                                <div style={{ marginTop: 30 }} className="invoicebill">
                                    <ul className='billliststyle'>
                                        <li>Sales are non refundable</li>
                                        <li>Marchandise can only be exchanged upon presentation of a valid reciept within 7 days of the date of sale</li>
                                        <li>Used, Cleaned, altered or damaged marchandise will not be accepted for exchange</li>
                                        <li>Marchandise for exchange must inclued or iginal packing and labels</li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: 30 }} className="invoicebill">

                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Visit Again!</p>
                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Dekanet Beirut</p>
                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>9444647200</p>
                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Al Dafna - Doha</p>
                                </div>

                                -------------------------------- */}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            );
        }

        return printablePages;
    }

    const print = () => {
        window.print()
    }
    console.log(words, "words", number, "number")

    return (

        <div>
            {loader ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <div>
                    <div
                        className='printdivorg'
                    >
                        {pageFunction()}
                        <Button className='printbutton1' onClick={print} variant="primary">Print</Button>

                    </div>

                    <div className='printdiv'>
                        <Container fluid >
                            <Row >
                                <Col xs={12}
                                    // style={{ marginTop: 20 }}
                                    className='col1'
                                >



                                    <div className="invoice-box printing">
                                        <div className="invoice-inside" style={{
                                            marginTop: '10px',
                                            border: '1px solid #000', borderRadius: 'inherit'
                                        }}>
                                            {/* <div className="invoice-inside" style={{ marginTop: '-22px'}}> */}

                                            {/* <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset' }}>Dekanet Beirut</p>
                                <p style={{ textAlign: 'center', fontSize: 13, marginBottom: 'unset' }}>Al Dafna - Doha</p>
                                <p style={{ textAlign: 'center', fontSize: 13 }}>Tel: 9444647200</p>
                                <p style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>Salesorder Invoice</p> */}

                                            <Row style={{ marginBottom: '15px' }}>
                                                <Col lg={5} md={5} xs={5}>
                                                    <p style={{ fontSize: 15, fontWeight: 600, marginBottom: 'unset', color: '#222274' }}>{global.proname}</p>
                                                    <p style={{ fontSize: 11, fontWeight: 'bold', marginBottom: 'unset' }}>{global.cr}</p>
                                                    <p style={{ fontSize: 11, fontWeight: 'bold', marginBottom: 'unset' }}>{global.vat}</p>
                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>{global.loc}</p>
                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>{global.email}</p>
                                                    <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}> {global.web}</p>


                                                </Col>

                                                <Col lg={2} md={2} xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                    {/* <img src={image} style={{ width: '80px', height: '80px' }} alt='no image' /> */}
                                                    <div className='center-logo'></div>

                                                </Col>

                                                <Col lg={5} md={5} xs={5}>

                                                    <p style={{ textAlign: 'right', fontSize: 15, fontWeight: 600, marginBottom: 'unset', color: '#222274' }}>         {global.arbicname}</p>
                                                    <p style={{ textAlign: 'right', fontSize: 11, marginBottom: 'unset', fontWeight: 'bold' }}>{global.arbiccr}</p>

                                                    <p style={{ textAlign: 'right', fontSize: 11, fontWeight: 'bold' }}>{global.arbicvat}</p>
                                                    <p style={{ textAlign: 'right', fontSize: 10, fontWeight: 'bold', marginBottom: 'unset', marginTop: '-15px' }}>{global.loc}</p>

                                                    {/* <p style={{ textAlign: 'center', fontSize: 7, fontWeight: 'bold', marginBottom: 'unset' }}>الدمام ,الملكة العربية السعودية</p> */}
                                                    {/* <p style={{ textAlign: 'center', fontSize: 13 }}>Email:Dammam.mjt@outlook.com</p> */}


                                                </Col>


                                            </Row>
                                            {/* <hr/> */}

                                            <div  >
                                                {/* <h3 style={{textAlign:'end',fontSize:9}}>Sales Invoice</h3> */}

                                                {/* <br /> */}

                                                <Row
                                                    style={{ border: '1px solid #000000', borderLeft: 'none', borderRight: 'none', marginLeft: '0px', marginRight: '0px', borderBottom: 'none' }}

                                                >



                                                    <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none' }}>
                                                        <p style={{ fontWeight: 'bold', fontSize: 9, marginBottom: 'unset' }}>CUSTOMER DETAILS</p>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>رقم الفاتورة</p>
                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Invoice No: {lpo}</p>


                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Date: {date}</p>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم العميل</p>
                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Customer: {customername}</p>

                                                        {/* <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p> */}

                                                        {/* <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p> */}

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>رقم ضريبة القيمة المضافة</p>


                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>VAT NO:311419001500003</p>

                                                    </Col>



                                                    <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>
                                                        {/* <p style={{ fontWeight: 'bold', fontSize: 9, marginBottom: 'unset' }}>SHIP TO</p>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>رقم الجوال</p>
                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Mob No: {mobile}</p>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>منطقة</p>
                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Area: {area}</p>


                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                                        <p style={{ fontSize: 10, fontWeight: 500, marginBottom: 'unset' }}>Building: {building}</p> */}


                                                    </Col>



                                                    <Col className='bgsize' xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderLeft: '1px solid rgb(0,0,0)' }}>
                                                        {/* <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize:7 }}>QR CODE</p> */}


                                                        {/* <center> <img src={bgmjt} style={{ width: '80px', height: '80px', marginTop: '30px', marginLeft: '-12px' }} alt='no image' /></center> */}
                                                        <center><img src={bgawfiya} style={{ width: '80px', height: '80px', marginTop: '30px', marginLeft: '-12px' }} alt='no image' /></center>

                                                    </Col>

                                                </Row>
                                            </div>

                                            {/* <Row>

                                <Col>
                                <p style={{fontSize:10}}>CUSTOMER DETAILS</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عميل</p>
                                <p style={{ fontSize: 12, fontWeight: 500 }}>Customer: {customername}</p>

                                <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p>
                                
                                </Col>


                                
                              </Row> */}
                                            {/* <Row>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عميل</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Customer: {customername}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Mob No: {mobile}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Building: {building}</p>
                                    </Col>

                                    <Col xs={6}>

                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Street: {street}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Zone: {zone}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Area: {area}</p>
                                    </Col> */}

                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Remarks: {remarks}</p>
                                </Col> */}
                                            {/* <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Reference: {reference}</p>
                                    </Col>

                                    <Col xs={6}>
                                        <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                        <p style={{ fontSize: 12, fontWeight: 500 }}>Date: {date}</p>
                                    </Col> */}


                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col> */}
                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Other Reference(s): Cash</p>
                                </Col> */}
                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col> */}
                                            {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col> */}
                                            {/* </Row>     */}
                                            {/* <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div> */}

                                            {/* </Row> */}
                                            <div className='table-container' >




                                                <Table bordered className='invoicetable' style={{ marginBottom: '0px' }} >
                                                    <thead>
                                                        <tr style={{ border: '1px solid #000000', borderTop: 'none', borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                                            <th style={{ backgroundColor: '#9E2567' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>عدد</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>SLNo.</p>
                                                            </th>

                                                            <th className='item2' style={{ backgroundColor: '#9E2567', width: '87px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>رمز الصنف</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Item Code</p>
                                                            </th>
                                                            <th className='name3' style={{ backgroundColor: '#9E2567', width: '340px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', color: 'white', textAlign: 'center' }}>اسم العنصر</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Item Name</p>
                                                            </th>
                                                            {/* <th style={{width: '20%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الرمز الشريطي</p>
                                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Barcode</p>
                                                </th> */}
                                                            {/* <th style={{width: '35%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Product</p></th> */}
                                                            <th style={{ backgroundColor: '#9E2567' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>كمية</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Qty</p></th>

                                                            {/* <th style={{ width: '10%' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عدد</p>
                                                    <p style={{ fontSize: 12, fontWeight: 'bold' }}>Unit</p>
                                                </th>
                                                     */}
                                                            <th className='web' style={{ backgroundColor: '#9E2567', maxWidth: '84px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center', width: '90px' }}>ثمن</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Price</p></th>
                                                            <th style={{ backgroundColor: '#9E2567' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center' }}>ضريبة%</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Tax %</p></th>
                                                            <th className='total4' style={{ backgroundColor: '#9E2567', maxWidth: '95px' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', color: 'white', textAlign: 'center', width: '90px' }}>مجموع</p>
                                                                <p style={{ fontSize: 8, fontWeight: 'bold', color: 'white', marginBottom: 'unset', textAlign: 'center' }}>Total</p></th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        replacereturn == 1 ?
                                                            <tbody className='custom-tbody' >
                                                                {
                                                                    tableRow.map((item, index) => {
                                                                        console.log(item, "item code")
                                                                        return (
                                                                            item.sod_iscancel == 1 ?
                                                                                null :
                                                                                <>

                                                                                    <tr className='rowbody' >



                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{index + 1}</td>
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.code}</td>
                                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.productname}</td>

                                                                                        {/* <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td> */}
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td>
                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td>
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.taxrate}%</td>
                                                                                        {/* <td>{item.calculated_totalprice}</td> */}
                                                                                        <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.sd_total_amount}</td>


                                                                                    </tr>
                                                                                    {/* <tr> */}
                                                                                    {/* <td style={{ textAlign: 'center' }}>{index + 1}</td> */}
                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.code}</td> */}
                                                                                    {/* <td>{item.productname}</td> */}
                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td> */}
                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))).toFixed(2)}</td> */}

                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.taxrate}%</td> */}
                                                                                    {/* <td style={{ textAlign: 'center' }}>{item.sd_total_amount} </td> */}
                                                                                    {/* <td>{item.calculated_totalprice}</td> */}
                                                                                    {/* </tr> */}
                                                                                    {/* <tr>
                                                                            <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                {item.productname}
                                                                            </td>
                                                                        </tr> */}
                                                                                </>
                                                                        )

                                                                    })
                                                                }




                                                                <tr className='header' style={{ border: '1px solid #000000' }}>

                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    {/* <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td> */}
                                                                </tr>


                                                                {/* ----------------------------------
                                                    <tr>

                                                        <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Extra Delivery Charge</p></th>
                                                        <td>QAR {deliverycharge}</td>
                                                    </tr>
                                                    <tr>

                                                        <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Variationamount</p></th>
                                                        <td>QAR {variationamount}</td>
                                                    </tr>
                                                    <tr>

                                                        <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total Amount</p></th>
                                                        <td>QAR {totalamount}</td>
                                                    </tr>

                                                    ----------------------------------------------- */}



                                                                <tfoot style={{ height: '82px' }}>
                                                                    <tr style={{ border: '1px solid #000' }}>

                                                                        <td colSpan={4}>
                                                                            <p style={{ fontSize: 16, marginBottom: '0px' }}>Total In Words</p>

                                                                            {words && <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: "'Kanit', sans-serif", marginBottom: '0px' }}> Saudi Riyal {words} Only</p>}

                                                                            <p style={{ fontSize: 9, fontFamily: 'sans-serif', marginBottom: '0px' }}> Thanks for your business</p>

                                                                        </td>
                                                                        <td className='ppp' colSpan={2}>
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Sub Total</p>
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Tax%</p>
                                                                            <p className='ppp' style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }}>Total</p>
                                                                            <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>Balance due</p>

                                                                        </td>
                                                                        <td >

                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> {totalprice}</p>
                                                                            <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}>{taxrate} </p>
                                                                            <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }} >SAR {totalamount}</p>
                                                                            <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>SAR {totalamount}</p>
                                                                        </td>
                                                                    </tr>

                                                                </tfoot>
                                                            </tbody>
                                                            :
                                                            replacebefore == 1 ?
                                                                <tbody>
                                                                    {
                                                                        tableRow.map((item, index) => {
                                                                            return (
                                                                                item.sod_iscancel == 1 ?
                                                                                    null :
                                                                                    <>


                                                                                        <tr className='rowbody' >



                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{index + 1}</td>
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.code}</td>
                                                                                            <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.productname}</td>

                                                                                            {/* <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td> */}
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td>
                                                                                            {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td>
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.taxrate}%</td>
                                                                                            {/* <td>{item.calculated_totalprice}</td> */}
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.sd_total_amount}</td>

                                                                                        </tr>
                                                                                        {/* <tr > */}
                                                                                        {/* <td style={{ textAlign: 'center' }}>{index + 1}</td> */}
                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.code}</td> */}
                                                                                        {/* <td>{item.productname}</td> */}

                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td> */}

                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td> */}
                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.taxrate}%</td> */}
                                                                                        {/* <td>{item.calculated_totalprice}</td> */}
                                                                                        {/* <td style={{ textAlign: 'center' }}>{item.sd_total_amount}</td> */}
                                                                                        {/* </tr> */}
                                                                                        {/* <tr>
                                                                                <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td>
                                                                            </tr> */}
                                                                                    </>
                                                                            )

                                                                        })
                                                                    }

                                                                    <tr className='header' style={{ border: '1px solid #000000' }}>

                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        {/* <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td> */}
                                                                    </tr>


                                                                    {/* ---------------------------
                                                        <tr>

                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Extra Delivery Charge</p></th>
                                                            <td>QAR {deliverycharge}</td>
                                                        </tr>
                                                        <tr>

                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold', }}>Variationamount</p></th>
                                                            <td>QAR {variationamount}</td>
                                                        </tr>
                                                        <tr>

                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', float: 'right' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total Amount</p></th>
                                                            <td>QAR {totalamount}</td>
                                                        </tr>

                                                        ----------------------------------- */}


                                                                    <tfoot style={{ height: '82px' }}>
                                                                        <tr style={{ border: '1px solid #000' }}>

                                                                            <td colSpan={4}>
                                                                                <p style={{ fontSize: 16, marginBottom: '0px' }}>Total In Words</p>

                                                                                {words && <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: "'Kanit', sans-serif", marginBottom: '0px' }}> Saudi Riyal {words} Only</p>}

                                                                                <p style={{ fontSize: 9, fontFamily: 'sans-serif', marginBottom: '0px' }}> Thanks for your business</p>

                                                                            </td>
                                                                            <td className='ppp' colSpan={2}>
                                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Sub Total</p>
                                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Tax%</p>
                                                                                <p className='ppp' style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }}>Total</p>
                                                                                <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>Balance due</p>

                                                                            </td>
                                                                            <td >

                                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> {totalprice}</p>
                                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}>{taxrate} </p>
                                                                                <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }} >SAR {totalamount}</p>
                                                                                <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>SAR {totalamount}</p>
                                                                            </td>
                                                                        </tr>

                                                                    </tfoot>
                                                                </tbody> :
                                                                <tbody className='custom-tbody'>
                                                                    {
                                                                        tableRow.map((item, index) => {
                                                                            console.log(item, "item code 1")
                                                                            return (
                                                                                item.sod_iscancel == 1 ?
                                                                                    null :
                                                                                    <>
                                                                                        <tr className='rowbody' >



                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{index + 1}</td>
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.code}</td>
                                                                                            <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.productname}</td>

                                                                                            {/* <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                    {item.productname}
                                                                                </td> */}
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))}</td>
                                                                                            {/* <td style={{ textAlign: 'center' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <br />({item.sd_baseqty}x{item.pb_displayconvertunit})</td> */}


                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))) : (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty)))}</td>
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.taxrate}%</td>
                                                                                            {/* <td>{item.calculated_totalprice}</td> */}
                                                                                            <td style={{ textAlign: 'center', marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}>{item.sd_total_amount}</td>


                                                                                        </tr>

                                                                                    </>

                                                                            )

                                                                        })
                                                                    }
                                                                    <tr style={{ border: '1px solid #000000' }}>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                        <td style={{ marginBottom: 'unset', paddingBottom: '0px', paddingTop: '0px', borderBottom: '0px' }}></td>
                                                                    </tr>



                                                                    {/* <tr>
                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total </p></th>
                                                            <td>QAR {totalamount}</td>
                                                        </tr>  */}
                                                                    {/* <tr>
                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}> مجموع الضريبة </p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Total Tax</p></th>
                                                            <td>QAR {totaltax}</td>
                                                        </tr>
                                                        <tr> */}
                                                                    {/* <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Sub Total</p></th>
                                                            <td>QAR {totalprice}</td>
                                                        </tr> */}

                                                                    {/* <tr>
    
                                                            <th colSpan={3} style={{ textAlign: 'end' }}><p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>المجموع الفرعي</p>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>Other Charges</p></th>
                                                            <td>QAR {othercharges}</td>
                                                        </tr> */}

                                                                    {/* <tr>
                                                                
                                                                <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>نهاية الجولة</p>
                                                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Round off</p></th>
                                                                <td>QAR {roundoff}</td>
                                                            </tr> */}




                                                                </tbody>


                                                    }
                                                    <tfoot style={{ height: '82px' }}>
                                                        <tr style={{ border: '1px solid #000' }}>

                                                            <td colSpan={4}>
                                                                <p style={{ fontSize: 16, marginBottom: '0px' }}>Total In Words</p>

                                                                {words && <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: "'Kanit', sans-serif", marginBottom: '0px' }}> Saudi Riyal {words} Only</p>}

                                                                <p style={{ fontSize: 9, fontFamily: 'sans-serif', marginBottom: '0px' }}> Thanks for your business</p>

                                                            </td>
                                                            <td className='ppp' colSpan={2}>
                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Sub Total</p>
                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> Tax%</p>
                                                                <p className='ppp' style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }}>Total</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>Balance due</p>

                                                            </td>
                                                            <td >

                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}> {totalprice}</p>
                                                                <p style={{ fontSize: 10, textAlign: 'end', fontWeight: 'bold', marginBottom: 'unset' }}>{taxrate} </p>
                                                                <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'end', marginBottom: 'unset' }} >SAR {totalamount}</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'end', marginBottom: 'unset', fontSize: 13 }}>SAR {totalamount}</p>
                                                            </td>
                                                        </tr>



                                                        {/* ----------------------------------------------------------------- */}
                                                        {/* <tr style={{ border: '1px solid #000', height: '200px' }}>

                                                <td colSpan={3}>
                                                    
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>1) It is understood and agreed that bt signing this document by the customer or any of their representatives,the above mentioned goods are recieved in good condition and as per the terms and conditions mentioned on our website/contract</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>2) Title and ownership is goods purchased,for which the prices are set out this invoice,including full legal and benificial ownwership,shall not under any circumstances pass to the buyer until the seller has recieved the full payment.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>3) Sales person or Client endorsement of corrections/deductions on invoice is not valid.Deduction relating to price difference, FOC etc. are accept only against official credit notes.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>4) Discrepancies if any,should be notified within 7 days</p>



                                                </td>

                                                <td colSpan={2}>

                                                <Col lg={4} md={4} xs={4} style={{ borderBottom: 'none', borderTop: 'none' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'center', backgroundColor: '#4dabf5', color: 'white' }}>CUSTOMER RECEIPT</p>

                                                     <Col >
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم المستلم</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Receiver's Name:</p></Col>

                                                    <Col>
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>ضريبة%</p>
                                                        <p style={{ fontWeight: 'b0ld', fontSize: 10 }}>Sign & Stamp:</p></Col>

                                                    <Col>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Date:</p></Col>

                                                        </Col>


                                                </td>
                                                <td colSpan={2}></td>


                                            </tr> */}


                                                        {/* ----------------------------------------- */}
                                                    </tfoot>


                                                    {/* <thead style={{height:'200px'}}>

                                               <tr style={{border:'1px solid #000'}}>

                                                <td colSpan={3}></td>

                                                <td colSpan={2}></td>
                                                <td colSpan={2}></td>


                                               </tr>

                                             

                                        </thead> */}




                                                    {/* <tfoot>


                                            <tr style={{ border: '1px solid #000000' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                

                                                <td style={{ textAlign: 'end' }}>
                                                    <Row>
                                                        <p style={{ fontWeight: 'bold' }}>Total</p>
                                                        <p></p>
                                                        <p></p>
                                                        <p className='balancedue' style={{ fontWeight: 'bold', marginTop: '-20px' }}>Balance Due </p>
                                                    </Row>

                                                </td>

                                                <td>
                                                    <p style={{ borderTop: 'revert', textAlign: 'center', fontWeight: 'bold' }}> SAR{totalamount}</p>
                                                    <p>hhh</p>

                                                    <p style={{ borderTop: 'revert', textAlign: 'center', fontWeight: 'bold' }}> SAR{totalamount}</p>

                                                </td>
                                            </tr>


                                        </tfoot> */}

                                                </Table>


                                                <div className='ftr' >

                                                    {/* <br /> */}

                                                    <Row
                                                        style={{ border: '1px solid #000000', borderLeft: 'none', borderRight: 'none', marginLeft: '0px', marginRight: '0px', borderTop: 'none' }}

                                                    >



                                                        <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none' }}>

                                                            <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>1) It is understood and agreed that bt signing this document by the customer or any of their representatives,the above mentioned goods are recieved in good condition and as per the terms and conditions mentioned on our website/contract</p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>2) Title and ownership is goods purchased,for which the prices are set out this invoice,including full legal and benificial ownership,shall not under any circumstances pass to the buyer until the seller has recieved the full payment.</p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>3) Sales person or Client endorsement of corrections/deductions on invoice is not valid.Deduction relating to price difference, FOC etc. are accept only against official credit notes.</p>
                                                            <p style={{ fontWeight: 'bold', fontSize: 7, marginBottom: '0px', textAlign: 'justify', marginLeft: '-10px', marginRight: '-12px' }}>4) Discrepancies if any,should be notified within 7 days</p>





                                                        </Col>



                                                        <Col xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>


                                                            <p style={{ fontWeight: 'bold', fontSize: 10, textAlign: 'center', backgroundColor: '#9E2567', color: 'white' }}>CUSTOMER RECEIPT</p>

                                                            <Col >
                                                                <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم المستلم</p>
                                                                <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: 'unset' }}>Receiver's Name:</p></Col>

                                                            <Col>
                                                                <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>ضريبة</p>
                                                                <p style={{ fontWeight: 'b0ld', fontSize: 10, marginBottom: 'unset' }}>Sign & Stamp:</p></Col>

                                                            <Col>

                                                                <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                                <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: 'unset' }}>Date:</p></Col>


                                                        </Col>



                                                        <Col className='bgsize' xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: 'none', borderLeft: '1px solid rgb(0,0,0)' }}>
                                                            <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12, }}>AWFIYA DIGITAL PRINT</p>




                                                        </Col>

                                                    </Row>
                                                </div>

                                                {/* <Table>
                                        
                                    </Table> */}
                                                {/* <Table>
                                        <tfoot >

                                            <Row style={{ border: '1px solid #000000', width: '195mm' }}


                                            // style={{border:'2px solid #C00000F9'}}
                                            >



                                                <Col lg={4} md={4} xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderRight: '1px solid #000', borderLeft: 'revert' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>1) It is understood and agreed that bt signing this document by the customer or any of their representatives,the above mentioned goods are recieved in good condition and as per the terms and conditions mentioned on our website/contract</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>2) Title and ownership is goods purchased,for which the prices are set out this invoice,including full legal and benificial ownwership,shall not under any circumstances pass to the buyer until the seller has recieved the full payment.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>3) Sales person or Client endorsement of corrections/deductions on invoice is not valid.Deduction relating to price difference, FOC etc. are accept only against official credit notes.</p>
                                                    <p style={{ fontWeight: 'bold', fontSize: 10, marginBottom: '5px' }}>4) Discrepancies if any,should be notified within 7 days</p>


                                                    ---------------------
                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>عميل</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Customer: {customername}</p>

                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>رقم القسيمة</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Voucher No: {lpo}</p>
                                                    ---------------------
                                                </Col>



                                                <Col lg={4} md={4} xs={4} style={{ borderBottom: 'none', borderTop: 'none' }}>
                                                    <p style={{ fontWeight: 'bold', fontSize: 11, textAlign: 'center', backgroundColor: '#4dabf5', color: 'white' }}>CUSTOMER RECEIPT</p>


                                                    <Col >
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>اسم المستلم</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Receiver's Name:</p></Col>

                                                    <Col>
                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>ضريبة%</p>
                                                        <p style={{ fontWeight: 'b0ld', fontSize: 10 }}>Sign & Stamp:</p></Col>

                                                    <Col>

                                                        <p style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 'unset' }}>تاريخ</p>
                                                        <p style={{ fontWeight: 'bold', fontSize: 10 }}>Date:</p></Col>





                                                    -----------
                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Mob No: {mobile}</p>

                                                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 'unset' }}>بناءب</p>
                                                    <p style={{ fontSize: 12, fontWeight: 500 }}>Area: {area}</p>
                                                    ---------------



                                                </Col>



                                                <Col lg={4} md={4} xs={4} style={{ border: '1px solid #000000', borderBottom: 'none', borderTop: 'none', borderLeft: '1px solid #000', height: '306px', borderRight: '1px  ' }}>
                                                    <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12, }}>MOKHAFAT JOUDHA <br />TRADING EST</p>
                                                    --------------
                                                    <center> <img src={qrcode} style={{ width: '60px', height: '60px' }} alt='no image' /></center>
                                                    -----------------
                                                </Col>

                                            </Row>


                                        </tfoot>
                                    </Table> */}

                                            </div>



                                            {/* -------------------------------

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={require("../../../assets/images/qrcode.png").default} style={{ width: 50, height: 50 }} />
                                </div>
                                <div style={{ marginTop: 30 }} className="invoicebill">
                                    <ul className='billliststyle'>
                                        <li>Sales are non refundable</li>
                                        <li>Marchandise can only be exchanged upon presentation of a valid reciept within 7 days of the date of sale</li>
                                        <li>Used, Cleaned, altered or damaged marchandise will not be accepted for exchange</li>
                                        <li>Marchandise for exchange must inclued or iginal packing and labels</li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: 30 }} className="invoicebill">

                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Visit Again!</p>
                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Dekanet Beirut</p>
                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>9444647200</p>
                                    <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Al Dafna - Doha</p>
                                </div>

                                -------------------------------- */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Button className='printbutton' onClick={print} variant="primary">Print</Button>
                        </Container>
                    </div>
                </div>




            )}

        </div>

    );
}


export default InvoiceBill;
import React, { Component, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal, Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Button from '@mui/material/Button';
import Loader from '../loader/loader';


const columns = [
  { id: 'name', label: 'Country', minWidth: 170, span: false },
  { id: 'code', label: 'State', minWidth: 170, span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  {
    country: 'india',
    state: 'kerala'
  }
];

function State(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

  const [modal, setModal] = useState(false);
  const [stateList, setStatelist] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [stateName, setStateName] = React.useState("");
  const [stateId, setStateId] = React.useState("");
  const [countryList, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState("")

  useEffect(() => {
    getData()
    getCountry()
  }, [])


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const countryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)

    setLoader(true)
    fetch(global.url + "viewAllState", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("respo", responseJson)
        if (responseJson.status == "false") {
          setStatelist(responseJson.data)
          setLoader(false)
        }
        else {
          setLoader(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onAdd = () => {
    setModal(true)
    setCountry("")
    setStateName("")
    setStateId("")
  }

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("respo country", responseJson)
        if (responseJson.status == "false") {
          setCountryList(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onSave = () => {
    var data = {
      "country_id": country,
      "state_name": stateName,
      "state_id": stateId,
      "state_branchid": userbranchid,
      "state_companyid": usercompanyid,
      "user_id": userId
    }
    fetch(global.url + "updateState", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responsejson", responseJson);
        if (responseJson.status == "false") {
          getData()
          setModal(false)
          alert(responseJson.data)
        }
        if (responseJson.status == "true") {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const deleteClick = (id) => {
    var data = {
      'state_id': id,
      "user_id": userId
    }
    fetch(global.url + "deleteState", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        alert("Deleted")
        getData()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editClick = (
    countryid,
    statename,
    stateid
  ) => {
    setModal(true)
    setCountry(countryid)
    setStateName(statename)
    setStateId(stateid)
  }

  return (
    <div className="main-content">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
          <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>State</p>
          <Button variant="contained" onClick={onAdd}>Add</Button>
        </div>
        <TableContainer sx={{ maxHeight: 440 }}>
          {loader ? <Loader /> :
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                      colSpan={column.span == true ? 2 : 1}
                    >
                      {column.label}

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stateList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow tabIndex={-1} key={row.code}>
                        <TableCell >
                          {row.country_name}
                        </TableCell>
                        <TableCell >
                          {row.state_name}
                        </TableCell>
                        <TableCell >
                          <a href="javascript:void(0)" onClick={() => editClick(
                            row.country_id_fk,
                            row.state_name,
                            row.state_id
                          )}>
                            <FaIcons.FaEdit fontSize={20} color="blue" />
                          </a>
                        </TableCell>
                        <TableCell >
                          <a href="javascript:void(0)" onClick={() => deleteClick(row.state_id)}>
                            <RiIcons.RiDeleteBin7Fill color="red" fontSize={20} />
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={stateList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Modal
        size="lg"
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            State Add
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {/* <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Country *</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={country}
                                label="Age"
                                onChange={countryChange}
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {countryList.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl> */}



              <FormControl sx={{ mt: 4, width: '100%' }}>
                <Autocomplete
                  id="country-select"
                  options={countryList}
                  getOptionLabel={(option) => option.country_name}
                  // value={props.selectedCountry}
                  onChange={(event, newValue) => {
                    countryChange({
                      target: {
                        value: newValue ? newValue.country_id : '',
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </FormControl>

            </Col>
            <Col xs={12} >

              <FormControl sx={{ mt: 4, width: '100%' }}>
                <TextField autoComplete="off" onChange={(e) => setStateName(e.target.value)} value={stateName} id="outlined-basic" label="State*" variant="outlined" size="small" />
              </FormControl>
            </Col>
            <Col xs={12} style={{ marginTop: 30 }}>
              <Button onClick={onSave} style={{ float: 'right' }} variant="contained">Submit</Button>
              <Button onClick={() => setModal(false)} style={{ marginRight: 40, float: 'right' }} variant="contained" color="error">Cancel</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default State;

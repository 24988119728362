
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import { MultiSelect } from "react-multi-select-component";
import Input from '@mui/material/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";




function Defaultprice(props) {
   
  return (
        <div>
            <Row>

                <Col sm={3}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Color</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.dproductcolor}
                                label="Age"
                                onChange={e => props.dproductcolorChange(e)}
                                size="small"
                                
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {props.colorlist.map((item, index) => (
                                <MenuItem value={item.color_id}>{item.color_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                </Col>

                <Col sm={3}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Size</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.dproductsize}
                                    label="Age"
                                    onChange={e => props.dproductsizechange(e)}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.sizelist.map((item, index) => (
                                    <MenuItem value={item.size_id}>{item.size_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                </Col>

                <Col sm={3}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField 
                            name="purchaseprice"
                            value={props.dcost} onChange={(e) => props.setDcost(e.target.value)}
                            autoComplete="off" placeholder="Cost" type="number"  size="small"/>
                        </FormControl>
                </Col>

                <Col sm={3}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField 
                            name="purchaseprice"
                            value={props.dproductpurchaseprice} onChange={(e) => props.setDpurchaseprice(e.target.value)}
                            autoComplete="off" placeholder="Purchase price" type="number"  size="small"/>
                        </FormControl>
                </Col>

                <Col sm={3}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField 
                            name="purchaseprice"
                            value={props.dsalesrate} onChange={(e) => props.setDsalesrate(e.target.value)}
                            autoComplete="off" placeholder="Sales price" type="number"  size="small"/>
                        </FormControl>
                </Col>

                {/* <Col sm={3}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Opening Unit</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.dunit}
                                label="Age"
                                onChange={props.dunitchange}
                                size="small"
                                
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {props.unitList.map((item, index) => (
                                <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                </Col>

                <Col sm={3}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField 
                            name="purchaseprice"
                            value={props.dopeningqty} onChange={(e) => props.setDopeningqty(e.target.value)}
                            autoComplete="off" placeholder="Opening QTY" type="number"  size="small"/>
                    </FormControl>
                </Col> */}
                <Col sm={3} style={{marginTop: 30}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Expiry Date"
                                    value={props.dexpirydate}
                                    onChange={(newValue) => {
                                    props.setDexpirydate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                        </LocalizationProvider>
                </Col> 
                <Col sm={3} style={{marginTop: 30}}>
                    <div>
                        <label>Image</label>
                        <input type="file" multiple onChange={(e) => props.dfileSelectedHandler(e)}  style={{marginTop: 10}}/>
                    </div>
                </Col>
                <Col sm={3} style={{marginTop: 50}}>
                    <Button style={{float: 'right'}} variant="contained">Submit</Button>
                </Col>             
            </Row>
        </div>
  );
}

export default Defaultprice;

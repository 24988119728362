
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const columns = [
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Mode', minWidth: 50,span: false },
    { id: '2', label: 'Invoice', minWidth: 50,span: false },
    { id: '3', label: 'Shift', minWidth: 50,span: false },
    { id: '4', label: 'Amount', minWidth: 50,span: false },
    { id: '5', label: 'Disc', minWidth: 50,span: false },
    { id: '7', label: 'Sale Total', minWidth: 50,span: false },
    { id: '8', label: 'Delivery charge', minWidth: 50,span: false },
    { id: '8', label: 'Grand Total', minWidth: 50,span: false },
    { id: '8', label: 'Cash', minWidth: 50,span: false },
    { id: '8', label: 'Card', minWidth: 50,span: false },
    { id: '8', label: 'Cost', minWidth: 50,span: false },
    { id: '8', label: 'Profit', minWidth: 50,span: false },
    { id: '8', label: 'Profit %', minWidth: 50,span: false },
  ];
  
function SalesSummaryReport(props) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [input, setInput] = useState(0);
    const [filt, setFilt] = useState(false)
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);

    const [totalAmount, setTotalamount] = useState("")
    const [totalDisc, setTotaldisc] = useState("")
    const [totalSales, setTotalsales] = useState("")
    const [totalDeli, setTotaldeli] = useState("")
    const [totalGrand, setTotalgrand] = useState("")
    const [totalCash, setTotalcash] = useState("")
    const [totalCard, setTotalcard] = useState("")
    const [totalCost, setTotalcost] = useState("")
    const [totalProfit, setTotalprofit] = useState("")
    const [totalProfitPer, setTotalprofitper] = useState("")

  useEffect(() => {
      getData()
  }, [input])

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company,
        "fromdate": dateFrom.toISOString().split('T')[0],
        "todate": dateTo.toISOString().split('T')[0],
        "excelexport" : true,
      }
      console.log("response12 data", data)
    fetch(global.url + "salesWiseSummary", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log("response12", responseJson)
      setLoader(false)
      if(responseJson.error == false){
        setSource(responseJson.data)
        setTotalamount(responseJson.total.grandamount)
        setTotaldisc(responseJson.total.granddiscount)
        setTotalsales(responseJson.total.grandsalestotal)
        setTotaldeli(responseJson.total.granddeliverycharge)
        setTotalgrand(responseJson.total.grandtotal)
        setTotalcash(responseJson.total.grandcashtotal)
        setTotalcard(responseJson.total.grandcardtotal)
        setTotalcost(responseJson.total.grandcosttotal)
        setTotalprofit(responseJson.total.grandprofittotal)
        setTotalprofitper(responseJson.total.grandprofitperctotal)
      }
    })
  }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Summary Report</p>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                      <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                        setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={8} style={{marginTop: 25}}>
                          <div style={{display:'flex', justifyContent: 'space-between'}}>
                              <Button className='btn1' variant="primary" onClick={() => getData()}>Filter</Button>
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="table-to-xls"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="Download as XLS"/>
                          </div>
                        </Col>
                </Row>
            </div>

            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
            {loader ? <Loader /> :
            
              <Table stickyHeader aria-label="sticky table" id="table-to-xls">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <>
                            <TableRow  tabIndex={-1} >
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.date}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                               
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.totalinvoice}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.amount)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.discount)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.salestotal)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.deliverycharge)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.total)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.cash)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.card)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.cost)?.toFixed(2)}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.profit)?.toFixed(2)}
                                </TableCell> 
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {parseFloat(row.profitpercent).toFixed(2)}
                                </TableCell> 
                            </TableRow>
                       {row.detail.map((row, index) =>(
                        <TableRow  tabIndex={-1} >
                                <TableCell >
                                    
                                </TableCell>
                                <TableCell >
                                    {row.mode}
                                </TableCell>
                                <TableCell >
                                    {row.totalinvoice}
                                </TableCell>
                                <TableCell >
                                    {row.POS}
                                </TableCell>
                                <TableCell >
                                    {row.amount}
                                </TableCell>
                                <TableCell >
                                    {row.discount}
                                </TableCell>
                                <TableCell >
                                    {row.salestotal}
                                </TableCell>
                                <TableCell >
                                    {row.deliverycharge}
                                </TableCell>
                                <TableCell >
                                    {row.total}
                                </TableCell>
                                <TableCell >
                                    {row.cash}
                                </TableCell>
                                <TableCell >
                                    {row.card}
                                </TableCell>
                                <TableCell >
                                    {row.cost.toFixed(2)}
                                </TableCell>
                                <TableCell >
                                    {row.profit}
                                </TableCell> 
                                <TableCell >
                                    {row.profitpercent}
                                </TableCell> 
                            </TableRow>
                       ))} 
                        </>
                      );
                    })}
                    <TableRow  tabIndex={-1} >
                              <TableCell style={{fontWeight: 'bold'}}>
                                  Total
                              </TableCell>
                              <TableCell >
                              
                              </TableCell>
                              <TableCell >
                                  
                              </TableCell>
                              <TableCell >
                                
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalAmount}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalDisc}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalSales}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalDeli}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalGrand}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalCash}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {totalCard}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {totalCost}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                               {totalProfit}
                              </TableCell> 
                              <TableCell style={{fontWeight: 'bold'}}>
                                 {totalProfitPer}
                              </TableCell> 
                        </TableRow>
                </TableBody>
              </Table>
              }
            </TableContainer>

            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper> 
          {/* {filt == true ? null :
          <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
          } */}
      </div>
    )
}
export default SalesSummaryReport;
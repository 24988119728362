import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as GoIcons from "react-icons/go";
import * as SiIcons from "react-icons/si";
import * as HiIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";
import * as CgIcons from "react-icons/cg";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";


export const SidebarData = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <MdIcons.MdHome />,
	},
	{
		title: "Settings",
		path: "",
		icon: <MdIcons.MdImportantDevices />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,
		subNav: [
			// {
			// 	title: "Common",
			// 	path: "",
			// 	icon: <IoIcons.IoIosPaper />,
			// 	iconClosed: <AiIcons.AiOutlinePlus />,
			// 	iconOpened: <AiIcons.AiOutlineMinus />,
			// 	subSubNav: [
			// 		{
			// 			title: "Branch",
			// 			path: "/master/Branch",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Counter",
			// 			path: "/master/Counter",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 	]
			// },
			{
				title: "Products",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "SKU",
						path: "/Products/Products",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Price level",
						path: "/Products/PriceLevel",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Department",
						path: "/Products/Department",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Section",
						path: "/Products/Section",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Category",
						path: "/Products/Category",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Subcategory",
						path: "/Products/SubCategory",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Segment",
						path: "/Products/Segment",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Subsegment",
						path: "/Products/Subsegment",
						icon: <IoIcons.IoIosPaper />,
					},


					// {
					// 	title: "Display Location",
					// 	path: "/Products/locationdisplay",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					{
						title: "Unit",
						path: "/Products/Unit",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Taxmaster",
					// 	path: "/Products/Tax",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 		title: "Tax Setttings",
					// 		path: "",
					// 		icon: <IoIcons.IoIosPaper />,
					// 	},
					// {
					// 	title: "HSN",
					// 	path: "/Products/Hsn",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Color",
						path: "/Products/color",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Size",
						path: "/Products/size",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Combo Products",
					// 	path: "/Products/comboproducts",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					
				]
			},
			{
				title: "Ledgers",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Sub Group",
						path: "/Accounts/Group",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Ledger",
						path: "/Accounts/Account",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Customer",
						path: "/Accounts/Customer",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Supplier",
						path: "/Accounts/Supplier",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Designation",
					// 	path: "/Accounts/Designation",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Employee",
					// 	path: "/Accounts/Employee",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Bank",
						path: "/Accounts/Bank",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Bank Master",
						path: "/Accounts/BankMaster",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Default Ledger",
						path: "/settings/commonsettings",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Representative",
					// 	path: "/Accounts/Representative",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Promoter",
					// 	path: "/Accounts/Promoter",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
			{
				title: "User",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Manage User",
						path: "/User/UserManagement",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "User log",
					// 	path: "/User/userlog",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "User role",
						path: "/User/userrole",
						icon: <IoIcons.IoIosPaper />,
					},

					{
						title: "State",
						path: "/master/state",
						icon: <IoIcons.IoIosPaper />,

						status: false,
					},
					{
						title: "District",
						path: "/master/district",
						icon: <IoIcons.IoIosPaper />,

						status: false,
					},

				]
			},
			// {
			// 	title: "Others",
			// 	path: "",
			// 	icon: <IoIcons.IoIosPaper />,
			// 	iconClosed: <AiIcons.AiOutlinePlus />,
			// 	iconOpened: <AiIcons.AiOutlineMinus />,
			// 	subSubNav: [
			// {
			// 	title: "Bill Settings",
			// 	path: "/transaction/BillSettings",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// {
			// 	title: "Tax Setttings",
			// 	path: "",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// {
			// 	title: "Common Settings",
			// 	path: "/settings/commonsettings",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// {
			// 	title: "Online Order",
			// 	path: "/master/Ordertype",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// 	]
			// },
		],
	},
	{
		title: "Transactions",
		path: "",
		icon: <AiIcons.AiOutlineTransaction />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,

		subNav: [
			{
				title: "Vouchers",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Payment",
						path: "/transaction/paymentvoucher/add",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Salary Payment",
					// 	path: "/transaction/Salary/add",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Receipt",
						path: "/transaction/receiptvoucher/add",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Contra",
					// 	path: "/transaction/Contra/add",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Journal",
					// 	path: "/transaction/journalvoucher/add",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Opening PDC",
					// 	path: "/transaction/PDC",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
			{
				title: "Purchase",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Purchase Request",
						path: "/transaction/Purchaserequest",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "LPO",
						path: "/transaction/Purchaseorder/add",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "GRN",
					// 	path: "/Creditors/GRN",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Purchase Approval",
					// 	path: "/Creditors/PurchaseApproval",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Purchase",
						path: "/transaction/Purchase/add",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Purchase Return",
						path: "/transaction/purchasesinglereturn/add",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Add To Stock",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Purchase Return",
					// 	path: "/Creditors/PurchasesReturn",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Debit Note",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
			{
				title: "Sales",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					// {
					// 	title: "Quotation",
					// 	path: "/Sales/Quatation",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					{
						title: "Sales Order",
						path: "/transaction/Salesorder/add",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Approval",
						path: "/Sales/SalesApproval",
						icon: <IoIcons.IoIosPaper />,
					},

					// {
					// 	title: "Sales Order Replace List",
					// 	path: "/Sales/Salesorderreplacelist",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Display Warehouse",
					// 	path: "/Sales/displaywarehouse",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					{
						title: "Sales",
						path: "/transaction/Sales/add",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Return",
						path: "/transaction/salessinglereturn",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Delivery Note",
					// 	path: "/Sales/DeliveryNote/add",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Sales Replacement",
					// 	path: "/transaction/Salesreplace",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Sales return",
					// 	path: "/Sales/DeliveryReturn",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Credit Note",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "POS",
					// 	path: "/Sales/pos",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Service Quotation",
					// 	path: "/transaction/Quotation/add",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

				]
			},
			{
				title: "Stock Entries",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Stock Adjustment",
						path: "/StockEntries/StockAdjustment",
						icon: <IoIcons.IoIosPaper />,
					},

					// {
					// 	title: "Transfer In",
					// 	path: "/StockEntries/TransferIn",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Transfer Out",
					// 	path: "/StockEntries/TransferOut",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Stock Receipt",
					// 	path: "/StockEntries/StockReceipt",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Godown Return",
					// 	path: "/StockEntries/GodownTransfer",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Godown Receipt",
					// 	path: "/StockEntries/GodownReceipt",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Packing",
					// 	path: "/StockEntries/Packing",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Unpacking",
					// 	path: "/StockEntries/Unpacking",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Wastege",
						path: "/StockEntries/DamageEntry",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Item To Item",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Batch to Batch",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
		],
	},

	// {
	// 	title: "User",
	// 	path: "",
	// 	icon: <BiIcons.BiUser/>,
	// 	iconClosed: <RiIcons.RiArrowDownSFill />,
	// 	iconOpened: <RiIcons.RiArrowUpSFill />,

	// 	subNav: [
	// 		// {
	// 		// 	title: "User Attendance",
	// 		// 	path: "/User/UserAttendance",
	// 		// 	icon: <IoIcons.IoIosPaper />,

	// 		// },
	// 		{
	// 			title: "User Management",
	// 			path: "/User/UserManagement",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		// {
	// 		// 	title: "Attendance",
	// 		// 	path: "/User/Attendance",
	// 		// 	icon: <IoIcons.IoIosPaper />,
	// 		// },
	// 		{
	// 			title: "User log",
	// 			path: "/User/userlog",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "User role",
	// 			path: "/User/userrole",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 	],
	// },
	// {
	// 	title: "Ecommerce",
	// 	path: "",
	// 	icon: <AiIcons.AiOutlineShoppingCart/>,
	// 	iconClosed: <RiIcons.RiArrowDownSFill />,
	// 	iconOpened: <RiIcons.RiArrowUpSFill />,

	// 	subNav: [
	// 		{
	// 			title: "Banner/Slider",
	// 			path: "/ecommerce/banner",
	// 			icon: <IoIcons.IoIosPaper />,

	// 		},
	// 		// {
	// 		// 	title: "Delivery Order",
	// 		// 	path: "/ecommerce/delivery",
	// 		// 	icon: <IoIcons.IoIosPaper />,

	// 		// },
	// 	],
	// },

	// {
	// 	title: "Utilities",
	// 	path: "",
	// 	icon: <MdIcons.MdImportantDevices/>,
	// 	iconClosed: <RiIcons.RiArrowDownSFill />,
	// 	iconOpened: <RiIcons.RiArrowUpSFill />,

	// 	subNav: [
	// 		{
	// 			title: "Product Import",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,

	// 		},
	// 		{
	// 			title: "Unit Settings",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "Apply Unit",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "Ledger Import",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "Customer Import",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "Supplier Import",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "Barcode Print",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 	],
	// },

	// {
	// 	title: "Settings",
	// 	path: "",
	// 	icon: <AiIcons.AiFillSetting/>,
	// 	iconClosed: <RiIcons.RiArrowDownSFill />,
	// 	iconOpened: <RiIcons.RiArrowUpSFill />,

	// 	subNav: [
	// 		// {
	// 		// 	title: "Priniting Template",
	// 		// 	path: "",
	// 		// 	icon: <IoIcons.IoIosPaper />,
	// 		// },
	// 		{
	// 			title: "Bill Settings",
	// 			path: "/transaction/BillSettings",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		// {
	// 		// 	title: "System Settings",
	// 		// 	path: "",
	// 		// 	icon: <IoIcons.IoIosPaper />,
	// 		// },
	// 		{
	// 			title: "Tax Setttings",
	// 			path: "",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		// {
	// 		// 	title: "Coupons",
	// 		// 	path: "",
	// 		// 	icon: <IoIcons.IoIosPaper />,
	// 		// },
	// 		// {
	// 		// 	title: "Promotions",
	// 		// 	path: "",
	// 		// 	icon: <IoIcons.IoIosPaper />,
	// 		// },
	// 		{
	// 			title: "Common Settings",
	// 			path: "/settings/commonsettings",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 		{
	// 			title: "Order Type",
	// 			path: "/master/Ordertype",
	// 			icon: <IoIcons.IoIosPaper />,
	// 		},
	// 	],
	// },

	// {
	// 	title: "Inventory",
	// 	path: "/inventory",
	// 	icon: <HiIcons.HiOutlineDocumentReport/>,
	// 	iconClosed: <RiIcons.RiArrowDownSFill />,
	// 	iconOpened: <RiIcons.RiArrowUpSFill />,
	// },

	{
		title: "Report",
		path: "",
		icon: <HiIcons.HiOutlineDocumentReport />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,

		subNav: [
			{
				title: "Stock",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Current Stock",
						path: "/StockReport/CurrentStockReport",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "History Stock",
					// 	path: "/StockEntries/Historystock",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Stock Value",
					// 	path: "/stockvalue",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Detailed Stock",
						path: "/detailedstock",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Display Warehouse",
					// 	path: "/digitalwarehousereport",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Inventory",
						path: "/inventory",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Stock Summary",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
			{
				title: "Tax",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [

					// {
					// 	title: "Purchase Return Tax Report",
					// 	path: "/Reports/Taxreport/purchasereturntaxreport",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Purchase Tax Report",
						path: "/Reports/Taxreport/purschasetaxreport",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Tax Report",
						path: "/Reports/Taxreport/salestaxreport",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Sales Return Tax Report",
					// 	path: "/Reports/Taxreport/salesreturntaxreport",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

				]
			}
			,

			{
				title: "Sales",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [

					// {
					// 	title: "Sales Order Report(Online)",
					// 	path: "/Reports/Salesorderreport",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Userwise Sales Order",
					// 	path: "/Reports/userwisesalesorder",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "POS Report",
						path: "/Reports/PosReport",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Report",
						path: "/Reports/SalesReport",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Productwise Report",
						path: "/Reports/SalesProductWiseReport",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Return Report",
						path: "/Reports/salesreturn",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Sales Summary",
						path: "/Reports/SalesSummary",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "Order Delivery",
					// 	path: "/Reports/orderdelivery",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Delivery Executive",
					// 	path: "/Reports/deliveryexecutive",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Cancel Order Report",
					// 	path: "/Reports/cancelorder",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Cancel Product Report",
					// 	path: "/Reports/cancelproduct",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Quotation List",
					// 	path: "/transaction/Quotationlist",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
			{
				title: "Purchase",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					{
						title: "Purchase Order Report",
						path: "/Reports/Purchaseorderreport",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Purchase Report",
						path: "/Reports/PurchaseReport",
						icon: <IoIcons.IoIosPaper />,
					},

					{
						title: "Purchase Return Report",
						path: "/Reports/purchasereturn",
						icon: <IoIcons.IoIosPaper />,
					},
				]
			},
			// {
			// 	title: "Contributions",
			// 	path: "",
			// 	icon: <IoIcons.IoIosPaper />,
			// 	iconClosed: <AiIcons.AiOutlinePlus />,
			// 	iconOpened: <AiIcons.AiOutlineMinus />,
			// 	subSubNav: [
			// 		{
			// 			title: "Billwise Sales Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Sale Itemwise Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Sales Return Billwise Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Sales Return Itemwise Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Purchase Billwise Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Purchase Itemwise Profit",
			// 			path: "/master/district",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Purchase Return Billwise Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Purchase Return Itemwise Profit",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 	]
			// },
			{
				title: "Vouchers",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					// {
					// 	title: "Journal",
					// 	path: "/Reports/JournalReport",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

					// {
					// 	title: "Contra",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Payment",
						path: "/Reports/PaymentReport",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Receipt",
						path: "/Reports/ReceiptReport",
						icon: <IoIcons.IoIosPaper />,
					},
					// {
					// 	title: "PDC",
					// 	path: "",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
				]
			},
			// {
			// 	title: "HR",
			// 	path: "",
			// 	icon: <IoIcons.IoIosPaper />,
			// 	iconClosed: <AiIcons.AiOutlinePlus />,
			// 	iconOpened: <AiIcons.AiOutlineMinus />,
			// 	subSubNav: [
			// 		{
			// 			title: "Salary",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},
			// 		{
			// 			title: "Attendance",
			// 			path: "",
			// 			icon: <IoIcons.IoIosPaper />,
			// 		},

			// 	]
			// },
			{
				title: "Accounts",
				path: "",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
				subSubNav: [
					// {
					// 	title: "Cash Book",
					// 	path: "/Reports/cashbook",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Bank Book",
					// 	path: "/Reports/bankbook",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Ledger",
					// 	path: "/Reports/ledgersummary",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					{
						title: "Ledger Detail",
						path: "/Reports/ledgerdetails",
						icon: <IoIcons.IoIosPaper />,
					},
					{
						title: "Daybook",
						path: "/Reports/daybookdetails",
						icon: <IoIcons.IoIosPaper />,
					},

					// {
					// 	title: "Profit & Loss",
					// 	path: "/Reports/profitandloss",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Trial Balance",
					// 	path: "/Reports/trialbalance",
					// 	icon: <IoIcons.IoIosPaper />,
					// },
					// {
					// 	title: "Balance Sheet",
					// 	path: "/Reports/balancesheet",
					// 	icon: <IoIcons.IoIosPaper />,
					// },

				]
			},
			{
				title: "User Logs",
				path: "/Reports/userlogs",
				icon: <IoIcons.IoIosPaper />,
				iconClosed: <AiIcons.AiOutlinePlus />,
				iconOpened: <AiIcons.AiOutlineMinus />,
			}
		],
	},
	{
		title: "Advanced",
		path: "",
		icon: <AiIcons.AiFillSetting />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,

		subNav: [
			{
				title: "Utilities",
				path: "/Utilities",
				icon: <IoIcons.IoIosPaper />,
			},
		],
	},
	{
		title: "Developer Mode",
		path: "",
		icon: <AiIcons.AiFillSetting />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,

		subNav: [
			{
				title: "Branch",
				path: "/master/Branch",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Counter",
				path: "/master/Counter",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Display Location",
				path: "/Products/locationdisplay",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Combo Products",
				path: "/Products/comboproducts",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "User log",
				path: "/User/userlog",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Bill Settings",
				path: "/transaction/BillSettings",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Online Order",
				path: "/master/Ordertype",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Contra",
				path: "/transaction/Contra/add",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Journal",
				path: "/transaction/journalvoucher/add",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Journal Report",
				path: "/Reports/JournalReport",
				icon: <IoIcons.IoIosPaper />,
			},
			// {
			// 	title: "Sales Order",
			// 	path: "/transaction/Salesorder/add",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// {
			// 	title: "Sales Approval",
			// 	path: "/Sales/SalesApproval",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// {
			// 	title: "Sales",
			// 	path: "/transaction/Sales/add",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			// {
			// 	title: "Sales Return",
			// 	path: "/transaction/salessinglereturn",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			{
				title: "Delivery Note",
				path: "/Sales/DeliveryNote/add",
				icon: <IoIcons.IoIosPaper />,
			},
			// {
			// 	title: "Service Quotation",
			// 	path: "/transaction/Quotation/add",
			// 	icon: <IoIcons.IoIosPaper />,
			// },
			{
				title: "Transfer In",
				path: "/StockEntries/TransferIn",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Transfer Out",
				path: "/StockEntries/TransferOut",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Sales Order Report(Online)",
				path: "/Reports/Salesorderreport",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Profit & Loss",
				path: "/Reports/profitandloss",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Trial Balance",
				path: "/Reports/trialbalance",
				icon: <IoIcons.IoIosPaper />,
			},
			{
				title: "Balance Sheet",
				path: "/Reports/balancesheet",
				icon: <IoIcons.IoIosPaper />,
			},
		],
	},
	// {
	// 	title: "Filemanager",
	// 	path: "/Filemanager/filemanger",
	// 	icon: <MdIcons.MdSettingsSystemDaydream />,
	// },

];

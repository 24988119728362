import '../Tax-Settings/TaxSettings.css';
import React, { Component, useEffect, useState, useRef } from 'react';

import Paper from '@mui/material/Paper';
import { Row, Col, Table, Modal, Form } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiSave } from "react-icons/hi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory, useParams } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import * as RiIcons from "react-icons/ri";
import copy from "copy-to-clipboard";
import BtnLoader from '../../loader/loader';
import '../Retail-Sale/RetailSale.css'
import closestIndexTo from 'date-fns/closestIndexTo';
import { useReactToPrint } from 'react-to-print';
import emailjs from '@emailjs/browser';
import Barcode from 'react-barcode';



const columns = [
    { id: '1', label: 'SI No.', minWidth: 50, span: false },
    { id: '2', label: 'Tax Name', minWidth: 50, span: false },
    { id: '3', label: 'Tax %', minWidth: 50, span: false },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        span: true
    },
];

const rows = [
    {
        company: 'Lorem Ipsum',

    },
    {
        company: 'Lorem Ipsum',
    },
    {
        company: 'Lorem Ipsum',
    },
];

function Retailsale(props) {
    const componentRef = useRef();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [productmodal, setProductModal] = useState(false)

    const [authmodal, setAuthmodal] = useState(false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [paymenttype, setPaymenttype] = useState("")

    const [paymentrcvd, setPaymentrcvd] = useState(0)

    const [qty, setQty] = useState(1)

    const [billamount, setBillamount] = React.useState(0)
    // const [vatrate, setVatrate] = React.useState(0)
    // const [taxRate, setTaxRate] = React.useState(0.05);
    const [taxamount, setTaxAmount] = React.useState(0);
    const [priceamount, setPriceAmount] = React.useState(0)

    const [counter, setCounter] = useState("")

    const [totalbalance, setTotalbalance] = useState("")
    const [combotable, setCombotable] = React.useState([{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }]);
    const [baseqty, setbaseQty] = React.useState('1');
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [date1, setDate1] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [selectedIndex, setSelectedindex] = React.useState(0)
    const [code, setCode] = useState("");
    const [productlist, setProductlist] = React.useState([]);
    const [productlistitem, setProductlistitem] = React.useState([]);
    const [productnameitem, setProductnameitem] = React.useState([]);
    const [productname, setProductname] = useState("");
    const [customer, setCustomer] = useState(1)
    const [address, setAddress] = useState("")
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modal3, setModal3] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [reportModalZ, setReportModalZ] = useState(false)
    const [cardmodal, setCardmodal] = useState(false)
    const [invoiceno, setInvoiceno] = React.useState('');
    const [errormsg, seterrormsg] = React.useState('');
    const [discountamound, setDiscountamound] = React.useState(0);
    const [discountpercentage, setDiscountpercentage] = React.useState(0);
    const [recall, setRecall] = React.useState(false);
    const [invoicelists, setInvoicelist] = React.useState([]);
    const [userId, setUserid] = React.useState("");
    const [clickedamt, setClickedamt] = React.useState("");
    const [aftersum, setAftersum] = React.useState(0);
    const [posclosingmodal, setPosClosingModal] = React.useState(false);
    const [lengths, setLengths] = React.useState("");
    const [branchName, setBranchName] = React.useState("");

    const [lookupproductlist, setLookupproductlist] = React.useState([]);

    const [search, setSearch] = React.useState("");

    const [discard, setDiscard] = React.useState("");

    const [startShit, setStartshift] = React.useState("");
    const [endShift, setEndshift] = React.useState("");
    const handle = useFullScreenHandle();

    const [grandtotalex, setGrandtotalex] = React.useState(0);
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");

    let history = useHistory();

    const [blocked, setBlocked] = React.useState(0)
    const [blocked2, setBlocked2] = React.useState(0)
    const [blocked3, setBlocked3] = React.useState(0)
    const [blocked4, setBlocked4] = React.useState(0)
    const [card, setCard] = React.useState(0);
    const [refno, setRefno] = React.useState("");
    const [cash, setCash] = React.useState(0);
    const [cashtotal, setCashtotal] = React.useState(0);

    const [bank, setBank] = useState("")

    const [cashamount, setCashamount] = useState("")
    const [bankamount, setBankamount] = useState("")

    const [paymode, setPaymode] = useState("")
    const [round, setRound] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [gross, setGross] = useState(0)
    const [grandTotal, setGrandtotal] = useState(0)
    const [balance, setBalance] = useState(0)
    const [otherCharges, setOthercharge] = useState(0)
    const [numarray, setNumarray] = useState([])

    const [billtypelist, setBilltypelist] = React.useState([]);

    const [billType, setBilltype] = React.useState('');
    const [tableRow, setTableRow] = React.useState([{ key: "", product_batch_id: "", pb_inter_barcode: "", pbexp_date: "", sd_expiry: "", isexpired: "", iseditable: 0, product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", sd_baseqty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: 0, product_taxtype: "", calcprice: "", taxrate: "", taxtype: "", calculated_tax: 0, calculated_price: 0, calculated_totalprice: 0, discount: "", sd_total_amount: 0, sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "" }] }])
    const [addnew, setAddnew] = React.useState(false);
    const [payable, setPayable] = React.useState(0);
    const [discardlist, setDiscardlist] = React.useState([])
    const [comboproductlist, setComboproductlist] = React.useState([]);
    const [popupindex, setPopupindex] = React.useState("");
    const [combopopupid, setCombopopupid] = React.useState("");
    const [combobatchlist, setCombobatchlist] = React.useState([{ key: "", value: [] }]);

    const [cashbutton, setCashbutton] = React.useState(true);
    const [bankbutton, setBankbutton] = React.useState(false);
    const [cashbankbutton, setCashbankbutton] = React.useState(false);

    const [barcodeName, setBarcodename] = React.useState("");

    const [convertunit, setConvertunit] = React.useState(0);

    const [mobile, setMobile] = React.useState("");
    const [editprice, setEditprice] = React.useState("");
    const [total, setTotal] = React.useState(0);
    const [loaderbtn, setLoaderbtn] = useState(false);
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const resultsRef = useRef();
    const inputRef1 = useRef();

    // print

    const [datep, setDatep] = React.useState("")
    const [vouchernop, setVouchernop] = React.useState("")
    const [detailsp, setDetailsp] = React.useState([])
    const [salestotalp, setTotalp] = React.useState(0)
    const [discountp, setDiscountp] = React.useState(0)
    const [vatp, setVatp] = React.useState(0)
    const [grandtotalp, setGrandtotalp] = React.useState(0)
    const [cashp, setCashp] = React.useState(0)
    const [cashpb, setCashpb] = React.useState(0)
    const [cardp, setCardp] = React.useState(0)
    const [cashbillp, setCashbillp] = React.useState(0)
    const [paidp, setPaidp] = React.useState(0)
    const [balancep, setBalancep] = React.useState(0)
    const [mobilep, setMobilep] = React.useState("")
    // const [refresh, setRefresh] = React.useState(false);

    const [indexState, setIndexstate] = React.useState(0)
    const [usertype1, setUsertype1] = React.useState("")

    const [cash500, set500] = useState(0);
    const [cash200, set200] = useState(0);
    const [cash100, set100] = useState(0);
    const [cash50, set50] = useState(0);
    const [cash10, set10] = useState(0);
    const [cash5, set5] = useState(0);
    const [cash1, set1] = useState(0);
    const [coins, setCoins] = useState(0);
    const [totalcl, setTotalcl] = useState(0);
    const [posadmin, setposadmin] = useState("");
    const [pospassword, setpospassword] = useState("");

    const [sysCash, setSyscash] = useState("");
    const [sysCard, setSyscard] = useState("");
    const [sysTotal, setSystotal] = useState(0);
    const [posCash, setPoscash] = useState(0);
    const [posCard, setPoscard] = useState(0);
    const [posTotal, setPosTotal] = useState(0);
    const [diffCash, setDiffcash] = useState("");
    const [diffCard, setDiffcard] = useState("");
    const [diffTotal, setDiffTotal] = useState("");
    const [suspendPop, setSuspendPos] = useState("");
    const [open, setOpen] = useState("");


    const [recalladd, setRecalladd] = useState(false);

    const [xyz, setXyz] = useState(false);

    const discnull = () => {
        setDiscountamound("")
    }
    const discnullcard = () => {
        setCard("")
    }
    const discnull2 = () => {
        setDiscountpercentage("")
    }
    const discnull500 = () => {
        set500("")
    }
    const disnullcard = () => {
        setPoscard("")
    }
    const discnull200 = () => {
        set200("")
    }
    const discnull100 = () => {
        set100("")
    }
    const discnull50 = () => {
        set50("")
    }
    const discnull10 = () => {
        set10("")
    }
    const discnull5 = () => {
        set5("")
    }
    const discnull1 = () => {
        set1("")
    }
    const discnullcoin = () => {
        setCoins("")
    }

    const cashamt = (e) => {
        console.log("check", e.target.value)
        const float = parseFloat(e.target.value)
        setCash(float.toFixed(2))
    }

    useEffect(() => {
        console.log("useEffect", tableRow)
    }, [tableRow])

    useEffect(() => {
        const keyDownHandler = event => {
            //   console.log('User pressed: ', event.key);

            if (event.key === 'F6') {
                event.preventDefault();
                lookupClick()
            }
            if (event.key === 'F7') {

                suspendClick()
            }
            if (event.key === 'F8') {

                recallClick()
            }
            if (event.key === 'F9') {
                reprint()

            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const reprint = () => {
        history.push("/Sales/possummary")
    }
    //   https://posb.galaxyhomescentre.com/public/api/salesPrint

    useEffect(() => {
        var total = (parseInt(cash500) * 500) + (parseInt(cash200) * 200) + (parseInt(cash50) * 50) + (parseInt(cash10) * 10) + (parseInt(cash5) * 5) + (parseInt(cash100) * 100) + (parseInt(cash1) * 1) + (parseFloat(coins))
        console.log("total", total)
        setTotalcl(total)
    }, [cash500, cash200, cash100, cash50, cash10, cash5, cash1, coins])

    const logoutClick = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log("userdata123", userdata)
        setPosClosingModal(true)
        var data = {
            "userid": userdata.user_id,
            "shiftstartdate": userdata.us_date,
            "shiftstarttime": userdata.us_start_time,
            "shiftendtime": userdata.us_end_time,
            "counterid": counter,
        }
        console.log("data", data)
        fetch(global.url + "posDayShowClosing", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("logout responds", responseJson)
                setSyscash(responseJson.data.sales_cash)
                setSyscard(responseJson.data.sales_card)
                // setSystotal(responseJson.data.sales_total)
                setSuspendPos(responseJson.data.suspendcount)
                setOpen(responseJson.data.openingbalance)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const popupsave = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        console.log("userdata123", userdata)
        setPosClosingModal(true)
        var data = {
            "userid": userdata.user_id,
            "companyid": company,
            "shiftstartdate": userdata.us_date,
            "shiftstarttime": userdata.us_start_time,
            "shiftendtime": userdata.us_end_time,
            "possystemcash": sysCash,
            "posmanualcash": posCash,
            "poscashdifference": diffCash,
            "possystemcard": sysCard,
            "posmanualcard": posCard,
            "poscarddifference": diffCard,
            "possystemtotal": sysTotal,
            "posmanualtotal": posTotal,
            "postotaldifference": diffTotal,

        }
        console.log("data popupsave", data)
        fetch(global.url + "posDaySaveClosing", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("logout responds", responseJson)
                setReportModal(true)
                if (responseJson.error == false) {
                    window.print()
                } else {
                    alert(responseJson.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const popupsave2 = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        console.log("userdata123", userdata)
        setPosClosingModal(true)
        var data = {
            "userid": userdata.user_id,
            "companyid": company,
            "branchid": userdata.branch_id,
            "shiftstartdate": userdata.us_date,
            "shiftstarttime": userdata.us_start_time,
            "shiftendtime": userdata.us_end_time,
            "possystemcash": sysCash,
            "posmanualcash": posCash,
            "poscashdifference": diffCash,
            "possystemcard": sysCard,
            "posmanualcard": posCard,
            "poscarddifference": diffCard,
            "possystemtotal": sysTotal,
            "posmanualtotal": posTotal,
            "postotaldifference": diffTotal,
            "counterid": counter,
            "posopeningbalance": open,
            "closingcountersum": totalcl
        }
        console.log("data popupsave", data)
        fetch(global.url + "posDaySaveClosing", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("logout responds", responseJson)
                setReportModal(true)
                if (responseJson.error == false) {

                    logoutclear()
                } else {
                    alert(responseJson.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }

        var date = new Date(datestring)
        var month = pad2(date.getMonth() + 1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year = date.getFullYear();

        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US',
                { timeZone: 'Asia/Kuwait', hour12: true, hour: 'numeric', minute: 'numeric', second: 'numeric' }
            );
        //   console.log("timeString12hr", timeString12hr)

        var formattedDate = day + "/" + month + "/" + year + " " + timeString12hr;
        return formattedDate; //03/02/2021
    }

    useEffect(() => {
        var amt = parseFloat(sysCash) + parseFloat(sysCard)
        setSystotal(amt)
    }, [sysCash, sysCard])

    useEffect(() => {
        var amt = parseFloat(posCash) + parseFloat(posCard)
        setPosTotal(amt)
    }, [posCash, posCard])

    useEffect(() => {
        var amt = parseFloat(sysTotal) - parseFloat(posTotal)
        setDiffTotal(amt)
    }, [sysTotal, posTotal])

    // useEffect(() => {
    //     var amt = parseFloat(sysCash) - parseFloat(posCash)
    //     setDiffcash(amt)
    // }, [sysCash, posCash])

    useEffect(() => {
        var amt = parseFloat(sysCard) - parseFloat(posCard)
        setDiffcard(amt)
    }, [sysCard, posCard])

    const logoutclear = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log("userdata", userdata)
        var data = {
            "userid": userdata.user_id,
        }
        console.log("user_id", data)
        fetch(global.url + "deleteDraftonLogout", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("logout clear", responseJson)
                localStorage.removeItem("isAuthenticated")
                localStorage.removeItem("branch")
                localStorage.removeItem("company")
                localStorage.removeItem("user")
                localStorage.removeItem("pos")

                history.push('/')
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        var total = parseFloat(totalcl) - parseFloat(open)
        setPoscash(total)
    }, [totalcl, open])

    useEffect(() => {
        var diff = parseFloat(sysCash) - parseFloat(posCash)
        setDiffcash(diff)
    }, [sysCash, posCash])

    const posCardChange = (e) => {
        setPoscard(e.target.value)
        var diff = sysCard - e.target.value
        setDiffcard(diff)
    }
    const posTotalChange = (e) => {
        setPosTotal(e.target.value)
        var diff = sysTotal - e.target.value
        setDiffTotal(diff)
    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        // console.log('userdata',userdata )
        setUsertype1(userdata.user_type)
        setBranchName(userdata.branch_name)
    }, [])

    useEffect(() => {
        grantotalClickp()
    }, [salestotalp, discountp])

    const grantotalClickp = () => {
        var total = parseFloat(salestotalp) - parseFloat(discountp)
        setGrandtotalp(total)
    }

    // useEffect(() => {
    //     paidClickp()
    // }, [cashp, cardp])
    // const paidClickp = () => {
    //     var total = parseFloat(cashp) + parseFloat(cardp)
    //     setPaidp(total)
    // }

    useEffect(() => {
        balanceClickp()
    }, [cashp, cashpb])

    const balanceClickp = () => {
        var total = parseFloat(cashp) - parseFloat(cashpb)
        setBalancep(total)
    }

    const getSalesbyid = (id) => {
        var data = {
            "sales_id": id,
        }
        fetch(global.url + "salesPrint", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("print details", responseJson)
                console.log(responseJson.data.sales_total)
                setLoaderbtn(false)
                setDetailsp(responseJson.data.details)
                setTotalp(responseJson.data.sales_total)
                setVouchernop(responseJson.data.sales_invoice)
                setDatep(responseJson.data.sales_date)
                setVatp(responseJson.data.sales_tax)
                setDiscountp(responseJson.data.sales_discount)
                setCashp(responseJson.data.sales_cashreceived)
                setCashpb(responseJson.data.sales_cash)
                setCardp(responseJson.data.sales_card)
                setMobilep(responseJson.data.customer_mobile)
                setCashbillp(responseJson.data.sales_cash)
                // setPaidp(parseFloat(responseJson.data.sales_total))
                setPaidp(parseFloat(responseJson.data.payable))
                setModal3(true)
                handlePrint()
                // window.location.reload()
                // window.print()
                setTimeout(function () {
                    window.location.reload()
                }, 1000);

            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        getInvoiceno(billType)
    }, [billType])


    const cashbuttonClick = () => {

        setClickedamt('')
        setTotalbalance(0)
        setCashbutton(true)
        setBankbutton(false)
        setCashbankbutton(false)
        setCard(0)
    }
    const bankcashbuttonClick = () => {
        setClickedamt('')
        setTotalbalance(0)
        setCard(0)
        setCash(0)
        setCashbutton(false)
        setBankbutton(false)
        setCashbankbutton(true)
    }

    useEffect(() => {
        if (cashbutton == true) {
            var cashamt = parseFloat(billamount) - parseFloat(discountamound)
            setCash(parseFloat(cashamt)?.toFixed(2))
        } else if (bankbutton == true) {
            var cardamt = parseFloat(billamount) - parseFloat(discountamound)
            setCard(cardamt)
        } else {
            var bothamt = parseFloat(billamount) - parseFloat(discountamound) - parseFloat(card)
            setCash(parseFloat(bothamt)?.toFixed(2))
        }

    }, [billamount, discountamound, taxamount, card, cash])

    const bankbuttonClick = () => {
        setCashbutton(false)
        setBankbutton(true)
        setCashbankbutton(false)
        setCard(billamount)
        setCash(0)
        setCard(0)
        setCashtotal(billamount)
    }

    const deletePopupitem = (index) => {
        console.log("delete index", index)
        var input = [...combotable]
        input.splice(index, 1)
        setCombotable(input)
    }

    const addRow = (id) => {
        setAddnew(true)
        setTableRow([...tableRow, { key: "", product_batch_id: "", pb_inter_barcode: "", pbexp_date: "", sd_expiry: "", isexpired: "", iseditable: 0, code: "", productname: "", pricelevel: "", sod_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", calculated_tax: 0, calculated_price: 0, calculated_totalprice: 0, discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setIndexstate(id + 1)
    }

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };

    // const discardChange = (event) => {
    //     setDiscard(event.target.value)
    //     getDetails(event.target.value)

    // }

    useEffect(() => {
        setCash(parseFloat(billamount)?.toFixed(2))
    }, [billamount])

    // useEffect(() => {
    //     var sum = parseFloat(billamount) - parseFloat(discountamound)
    //     setCash(sum)
    // }, [discountamound])





    useEffect(() => {
        var totalbal = parseFloat(clickedamt) - parseFloat(cash)
        setTotalbalance(totalbal)
    }, [clickedamt, cash])

    useEffect(() => {
        var total = parseFloat(cash) + parseFloat(card)
        setCashtotal(total)
    }, [cash, card])

    useEffect(() => {
        var amt = parseFloat(billamount) - parseFloat(discountamound) - (parseFloat(card) + parseFloat(cash))
        setBalance(amt)
    }, [cash, card, billamount, taxamount, discountamound])


    useEffect(() => {
        var tota = parseFloat(billamount) * (parseFloat(discountpercentage) / 100)
        setDiscountamound(tota.toFixed(2))
    }, [discountpercentage, billamount])

    // useEffect(() => {
    //     var totalAmount = 0;
    //     var totaltaxamount = 0
    //     for (var i = 0; i < tableRow.length; i++) {
    //         if (tableRow[i].taxtype == 1) {
    //             const calculatedPrice = parseFloat(tableRow[i].sd_total_amount) / ((parseFloat(tableRow[i].taxrate) / 100) + 1)
    //             const calculatedTax = parseFloat(tableRow[i].sd_total_amount) - parseFloat(calculatedPrice)

    //             totalAmount += parseFloat(calculatedPrice)
    //             totaltaxamount += parseFloat(calculatedTax)
    //         }
    //         else if (tableRow[i].taxtype == 2) {
    //             totalAmount += parseFloat(tableRow[i].sd_total_amount)
    //             const calculatedTax = parseFloat(tableRow[i].sd_total_amount) * (parseFloat(tableRow[i].taxrate) / 100);
    //             totaltaxamount += parseFloat(calculatedTax)
    //         }

    //         else {
    //             console.log("loop total", tableRow[i])
    //             totalAmount += parseFloat(tableRow[i].sd_total_amount)
    //             console.log("single total", totalAmount)         
    //         }
    //     }
    //     console.log("total", totalAmount)
    //     setPriceAmount(totalAmount)
    //     setTaxAmount(totaltaxamount);
    // }, [billamount, taxRate, tableRow]);



    useEffect(() => {
        var payment = parseFloat(billamount) - parseFloat(discountamound)
        setPayable(payment);
        console.log(payment)
    }, [billamount, discountamound, taxamount])

    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "id": 9
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
                setBilltype(responseJson.data[0].billset_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getDetails = (id) => {
        var data = {
            "sales_draft_id": id,
        }

        fetch(global.url + "posDiscardDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("posDiscardDetail", responseJson)
                setTableRow(responseJson.data)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getDiscardlist = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "userid": userdata.user_id,
        }

        fetch(global.url + "posListDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("discardlist", responseJson)
                if (responseJson.error == false) {
                    setDiscardlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        // inputRef.current.focus()
        getBilltype()
        getDiscardlist()
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var username = JSON.parse(localStorage.getItem("user_name"))
        console.log("userdata", userdata)
        setUsername(userdata.user_name)
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setStartshift(userdata.us_start_time)
        setEndshift(userdata.us_end_time)
        setCounter(userdata.counterid)
    }, []);



    useEffect(() => {
        getTotal()

    }, [cashamount, bankamount,]);

    useEffect(() => {
        getTotalamount()

    }, [tableRow]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
            totalAmount += tableRow[i].sd_total_amount
        }
        setTotal(totalAmount)
        setGross(totalAmount)
        var round = Math.round(totalAmount)
        setRound(round)
        setGrandtotal(round)
    }

    const getRoundoff = () => {
        var discount1 = (total / 100) * discount
        var round = Math.round(total) - discount1
        setRound(Math.round(round))
        setGrandtotal(Math.round(round))
        setGross(total)
    }

    const getTotal = () => {
        var total = cashamount + bankamount
        setCashtotal(total)
    }

    const getInvoiceno = () => {
        var data = {
            "id": billType
        }
        console.log('invoice data', data)
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("salesinvoiceno", responseJson.data)
                if (responseJson.error == false) {
                    setInvoiceno(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // useEffect(() => {
    //     if ( hasResults ) {
    //       document.body.addEventListener('keydown', onKeyDown);
    //     } else {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //     }


    //     if ( hasResultsProduct ) {
    //         document.body.addEventListener('keydown', onKeyDown1);
    //     } else {
    //         document.body.removeEventListener('keydown', onKeyDown1);
    //     }


    //     return () => {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //       document.body.removeEventListener('keydown', onKeyDown1);
    //     }
    //   }, [hasResults, hasResultsProduct]);


    //   function onKeyDown(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }
    //   }

    function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;

        const resultsItems = Array.from(resultsRef.current.children)

        const activeResultIndex = resultsItems.findIndex(child => {
            return child.querySelector('a') === document.activeElement;
        });

        if (isUp) {
            console.log('Going up!');
            if (inputIsFocused) {
                resultsItems[resultsItems.length - 1].querySelector('a').focus();
            } else if (resultsItems[activeResultIndex - 1]) {
                resultsItems[activeResultIndex - 1].querySelector('a').focus();
            } else {
                inputRef1.current.focus();
            }
        }

        if (isDown) {
            console.log('Going down!')
            if (inputIsFocused) {
                resultsItems[0].querySelector('a').focus();
            } else if (resultsItems[activeResultIndex + 1]) {
                resultsItems[activeResultIndex + 1].querySelector('a').focus();
            } else {
                inputRef1.current.focus();
            }
        }
    }


    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        textArray1[key].pb_inter_barcode = event.currentTarget.value
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)

    }

    const getSearch = (text, type, key) => {
        console.log('index get sear', type)
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text
            }
        }
        else if (type == "intercode") {
            var data = {
                "type": type,
                "intercode": text
            }
            console.log("dec281")
            fetch(global.url + "posGetSearchWithExpiry", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    console.log(responseJson.data[0].product_taxtype)
                    // setVatrate(responseJson.data[0].product_taxtype)
                    // setProductlist(responseJson.data)
                    // setProductlistitem(responseJson.data[0])
                    listClickrefcode1(
                        key,
                        responseJson.data[0],
                        responseJson.data[0].pbexp_id,
                        responseJson.data[0].pbexp_date,
                        responseJson.data[0].isexpired,
                        responseJson.data[0].pb_iseditprice,
                        responseJson.data[0].pb_isconvertunit,
                        responseJson.data[0].pb_displayconvertunit,
                        responseJson.data[0].products_id,
                        responseJson.data[0].pb_id,
                        responseJson.data[0].pb_inter_barcode,
                        // responseJson.data[0].product_refcode,
                        responseJson.data[0].product_name,
                        responseJson.data[0].products,
                        responseJson.data[0].pei_image,
                        0,
                        responseJson.data[0].iseditable,
                        responseJson.data[0].product_taxtype

                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (type == "plucode") {
            var data = {
                "type": type,
                "plucode": text
            }
            console.log("dec282")
            fetch(global.url + "posGetSearchWithExpiry", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    console.log(responseJson.data[0].product_taxtype)
                    // setVatrate(responseJson.data[0].product_taxtype)
                    //   setProductlist(responseJson.data)
                    // setProductlistitem(responseJson.data[0])
                    listClickrefcode1(
                        key,
                        responseJson.data[0],
                        responseJson.data[0].pbexp_id,
                        responseJson.data[0].pbexp_date,
                        responseJson.data[0].isexpired,
                        responseJson.data[0].pb_iseditprice,
                        responseJson.data[0].pb_isconvertunit,
                        responseJson.data[0].pb_displayconvertunit,
                        responseJson.data[0].products_id,
                        responseJson.data[0].pb_id,
                        responseJson.data[0].pb_inter_barcode,
                        // responseJson.data[0].product_refcode,
                        responseJson.data[0].product_name,
                        responseJson.data[0].products,
                        responseJson.data[0].pei_image,
                        0,
                        responseJson.data[0].iseditable,
                        responseJson.data[0].product_taxtype
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            var data = {
                "type": type,
                "product_name": text
            }
            console.log("dec283")

            fetch(global.url + "posGetSearchWithExpiry", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    console.log(responseJson.data[0].product_taxtype)
                    // setVatrate(responseJson.data[0].product_taxtype)
                    setProductlist(responseJson.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (total / 100) * event
        var result = total - disc
        setGross(result)
        setRound(Math.round(result))
        setGrandtotal(Math.round(result))
    }


    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        console.log("color1", colorsize1)
        console.log("customer percentege", customerpricelevel)
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        // input[index].product_fetchiscombo = iscombo
        input[index].sales_iscombo = iscombo
        input[index].sd_qty = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size
        input[index].addnew = true
        input[index].fetch_products = [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "" }]



        console.log("no customer")

        console.log("no customer no per")
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if (discount) {

            var totalcalc = (calcprice / 100) * discount
        }
        else {

            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sd_total_amount = calcprice - totalcalc




        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", pbexp_date: "", sd_expiry: "", isexpired: "", iseditable: 0, pb_inter_barcode: "", code: "", productname: "", pricelevel: "", sd_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")

        var total1 = total + totalcalc
        console.log("totalcalc", total1)
        setTotal(total1)
    }

    const listClickrefcode = (index, expid, expdate,pb_inter_barcode, isexpired, edit, unit, display, productid, pbid, productrefcode, productname, products, image, iscombo) => {

        console.log("edit 777", products)
        setEditprice(edit)
        var input = [...tableRow]
        input[index].key = index
        input[index].pb_isconvertunit = unit
        input[index].sd_expiry = expid
        input[index].isexpired = isexpired
        input[index].pbexp_date = expdate
        input[index].pb_displayconvertunit = display
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].pb_inter_barcode = pb_inter_barcode
        input[index].inputbarcode = pb_inter_barcode
        input[index].pei_image = image
        input[index].colorsize = products
        input[index].addnew = true
        setDetails(pbid, index, products)

        //   input[index].sd_qty = stock

        input[selectedIndex].product_batch_id = pbid

        //   colorChange(pbid)
        setSelectedindex(index)

        //   setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        //   setCode("")
    }

    const listClickrefcode1 = (index, response, expid, expdate, isexpired, edit, unit, display, productid, pbid, productrefcode, productname, products, image, iscombo, iseditable) => {

        console.log("iseditable", iseditable)
        setEditprice(edit)
        var input = [...tableRow]
        input[index].key = index
        input[index].pb_isconvertunit = unit
        input[index].isexpired = isexpired
        input[index].sd_expiry = expid
        input[index].pbexp_date = expdate
        input[index].pb_displayconvertunit = display
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].iseditable = iseditable
        input[index].productname = productname
        input[index].pei_image = image
        input[index].colorsize = products
        input[index].addnew = true
        input[selectedIndex].product_batch_id = pbid
        //   getProductbybatch1(pbid, index)
        setSelectedindex(index)
        setDetails(pbid, index, response)
    }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].productname = name
        input[index].pricelevel = pricelevel
        input[index].rsd_qty = qty
        input[index].unit = unit
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        // if(discount) {
        //     var totalcalc = (calcprice/100) * discount
        // }
        // else {
        //     var totalcalc = calcprice
        // }

        input[index].sd_total_amount = calcprice
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", pb_inter_barcode: "", pbexp_date: "", sd_expiry: "", isexpired: "", iseditable: 0, barcode: "", code: "", productname: "", pricelevel: "", sd_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0 }])
        setProductname("")
    }

    const deleteRow = (index, price) => {
        console.log("index", index)
        setTotal(total - price)
        var input = [...tableRow]
        input.splice(index, 1)
        console.log("input", input)
        setTableRow(input)
        // setAuthmodal(true)
    }

    const otherChargeschange = (event) => {
        setOthercharge(event)
        var disc = (total / 100) * discount
        var result = (total - disc) + parseInt(event)
        if (result) {
            setGrandtotal(result)
        }

    }

    const cashChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setCash(1)
            setPaymode("cash")
        }
        else {
            setCash(0)
            setPaymode("")
        }
        // do whatever you want with isChecked value
    }

    const bankChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setBank(1)
            setPaymode("bank")
        }
        else {
            setBank(0)
            setPaymode("")
        }
        // do whatever you want with isChecked value
    }

    const onSave = () => {
        setLoaderbtn(true)
        const exampleArray = []
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].product_batch_id == "") {
                // tableRow.splice(i, 1)
            }
            else {
                exampleArray.push(tableRow[i])
                exampleArray[i].sd_baseqty = baseqty
                if (tableRow[i].pb_isconvertunit == 1) {
                    exampleArray[i].sd_qty = tableRow[i].sd_qty * baseqty * 1000
                    exampleArray[i].pb_stock = tableRow[i].pb_stock * 1000
                } else {
                    exampleArray[i].sd_qty = tableRow[i].sd_qty * baseqty
                }
            }
        }
        console.log("tableRow", exampleArray)
        var data = {
            "sales_user_id": userId,
            "sales_bill_type": billType,
            "sales_ref_no": "",
            "sales_salesman": "",
            "sales_invoice": invoiceno,
            "sales_date": date.toISOString().split('T')[0],
            "sales_lpo_no": "",
            "sales_lpo_date": "",
            "sales_customer": customer,
            "sales_del_no": "",
            "sales_order_no": "",
            "sales_address": "",
            "sales_mob_no": mobile,
            "sales_qt_no": "",
            "sales_crdt_limit": "",
            "sales_balance": "",
            "sales_limit_balance": "",
            "sales_total": billamount,
            "sales_tax": taxamount,
            "sales_discount": discountamound,
            "sales_other_charges": "",
            "sales_advance": "",
            "sales_details": exampleArray,
            "sales_branchid": userbranchid,
            "sales_companyid": usercompanyid,
            "sales_id": "",
            "sales_zone": "",
            "sales_reference": "",
            "payable": payable,
            "sales_type": 1,
            "sales_card": card,
            "sales_card_reference": refno,
            "sales_counter": counter,
            "sales_cash": cash,
            "sales_cashtotal": cashtotal,
            "sales_cashreceived": clickedamt,
            "sales_balanceremain": totalbalance
        }
        console.log("data pos save", data)
        fetch(global.url + "updatePos", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response pos", responseJson)
                if (responseJson.error == true) {
                    alert(responseJson.message)
                }
                else {
                    getSalesbyid(responseJson.data.sales_id)

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    //   function calculate(value) {
    //     if(value != undefined) {
    //         let sum = 0;
    //         numArray.push(value)
    //         for (let i = 0; i < numArray.length; i++) {
    //             sum += numArray[i];
    //         }

    //         document.getElementById("clickedBtn").value = sum;
    //     }
    //     else{
    //         let sum = 0;
    //         numArray = []
    //         document.getElementById("clickedBtn").value = sum;
    //     }
    // }
    const calculate = (value) => {
        if (value != undefined) {
            var input = [...numarray]
            input.push(value)
            setNumarray(input)
        }
        else {
            setClickedamt(0)
            setNumarray([])
        }
    }

    const cancelItemclick = (index, price) => {
        console.log("index", index)
        setBillamount(billamount - price)
        var input = [...tableRow]
        input.splice(index, 1)
        setTableRow(input)
    }

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
    };

    // useEffect(() => {
    //     var sum = 0
    //     for (let i = 0; i < numarray.length; i++) {
    //         sum += numarray[i];
    //     }
    //     setClickedamt(sum)
    // }, [numarray]);


    // useEffect(() => {
    //     var totalAmount = 0;
    //     for (var i = 0; i < tableRow.length; i++) {
    //         if (tableRow[i].colorsize.product_taxtype == 1) {
    //             console.log(tableRow[i].colorsize.product_taxrate)
    //             const calculatedPrice = parseFloat(tableRow[i].sd_total_amount) / ((parseFloat(tableRow[i].colorsize.product_taxrate) / 100) + 1)
    //             totalAmount += parseFloat(calculatedPrice)
    //         }
    //         else {
    //             console.log("loop total", tableRow[i])
    //             totalAmount += parseFloat(tableRow[i].sd_total_amount)
    //             console.log("single total", totalAmount)
    //         }
    //     }
    //     console.log("total", totalAmount)
    //     setBillamount(totalAmount)
    // }, [tableRow]);

    useEffect(() => {
        var totalAmount = 0;
        var totalprice = 0;
        var totaltax = 0;

        for (var i = 0; i < tableRow.length; i++) {
            console.log("loop total", tableRow[i])
            // totalAmount += parseFloat(tableRow[i].sd_total_amount)
            // console.log("single total", totalAmount)       

            totalAmount += parseFloat(tableRow[i].sd_total_amount)
            totalprice += parseFloat(tableRow[i].calculated_price)
            totaltax += parseFloat(tableRow[i].calculated_tax)
        }
        console.log("total", totalAmount)
        setTaxAmount(totaltax)
        setPriceAmount(totalprice)
        setBillamount(totalAmount)
    }, [tableRow]);



    useEffect(() => {
        var input = [...tableRow]
        console.log("input", input)
        input[selectedIndex].sd_qty = qty
        input[selectedIndex].sd_total_amount = qty * input[selectedIndex].price
        setTableRow(input)
    }, [qty]);

    useEffect(() => {
        setQty(1)
    }, [selectedIndex]);

    // useEffect(() => {
    //     var result = clickedamt - billamount
    //     setBalance(result)
    //  }, [clickedamt, billamount]);


    // const onApply = () => {
    //     var result = clickedamt - grandTotal
    //     setAftersum(result)
    // }

    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "usertype": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewAllproductbatchwithExpiry", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("item look up", responseJson)
                setLookupproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const suspendClick = () => {
        const exampleArray = []
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].product_batch_id == "") {
                // tableRow.splice(i, 1)
            }
            else {
                exampleArray.push(tableRow[i])
            }
        }
        console.log("tableRow", exampleArray)
        var data = {
            "sales_user_id": userId,
            "sales_bill_type": billType,
            "sales_ref_no": "",
            "sales_salesman": "",
            "sales_invoice": invoiceno,
            "posinvoiceno": invoiceno,
            "sales_date": date.toISOString().split('T')[0],
            "sales_lpo_no": "",
            "sales_lpo_date": "",
            "sales_customer": customer,
            "sales_del_no": "",
            "sales_order_no": "",
            "sales_address": "",
            "sales_mob_no": "",
            "sales_qt_no": "",
            "sales_crdt_limit": "",
            "sales_balance": "",
            "sales_limit_balance": "",
            "sales_total": billamount,
            "sales_discount": discountamound,
            "sales_other_charges": "",
            "sales_advance": "",
            "sales_details": exampleArray,
            "sales_branchid": userbranchid,
            "sales_companyid": usercompanyid,
            "sales_id": "",
            "sales_zone": "",
            "sales_reference": "",
            "payable": payable,
            "sales_type": 1,
            "sales_card": card,
            "sales_card_reference": refno,
            "sales_counter": counter,
            "sales_cash": cash,
            "sales_cashtotal": cashtotal
        }
        console.log("data suspand", data)
        fetch(global.url + "posDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.error == true) {
                    alert(responseJson.message)
                }
                else {
                    alert(responseJson.message)
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onfilter = (text, type) => {
        console.log("type")
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "intercode") {
            var data = {
                "type": 'superadmin',
                "companyid": company,
                "barcode": text,
                "productname": ""
            }
        } else {
            var data = {
                "type": 'superadmin',
                "companyid": company,
                "barcode": "",
                "productname": text
            }
        }

        console.log("console data", data)
        fetch(global.url + "viewAllproductbatchwithExpiry", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response combo", responseJson)
                if (responseJson.status == 'false') {
                    setLookupproductlist(responseJson.data)
                } else {
                    setLookupproductlist([])

                }
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setBlocked(1)
        }
        else {
            setBlocked(0)
        }
    }

    const checkChange2 = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setBlocked2(1)
        }
        else {
            setBlocked2(0)
        }
    }
    const checkChange3 = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setBlocked3(1)
        }
        else {
            setBlocked3(0)
        }
    }
    const checkChange4 = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setBlocked4(1)
        }
        else {
            setBlocked4(0)
        }
    }

    //   const colorChange = (id) => {
    //     var input=[...tableRow]
    //     console.log("selectedIndex", selectedIndex)
    //     input[selectedIndex].product_batch_id = id
    //     setTableRow(input)

    //     getProductbybatch1(id, selectedIndex)
    //   //   setColor(event.target.value)
    // }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        // console.log("event.target.value", event.target.value)
        // console.log("event.target.value", textArray1[index].pb_stock)
        // console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].price * event.target.value
        var taxtype = textArray1[index].taxtype;
        var taxrate = textArray1[index].taxrate;

        if (taxtype == 1) {

            const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);
            // textArray1[index].sod_qty = event.target.value;
            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total
            textArray1[index].sd_total_amount = total
        }
        else if (taxtype == 2) {
            const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
            const price = (parseFloat(totalprice)).toFixed(2)

            // textArray1[index].sod_qty = event.target.value
            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total
            textArray1[index].sd_total_amount = total


        }
        else {
            const Price = (parseFloat(totalprice)).toFixed(2)
            // textArray1[index].sod_qty = event.target.value
            // textArray1[index].calculated_price = Price;
            // textArray1[index].calculated_totalprice = Price;
            textArray1[index].calcprice = Price
            textArray1[index].sd_total_amount = Price
            console.log("set tax");
        }

        textArray1[index].sd_qty = event.target.value
        // textArray1[index].calcprice = totalprice
        // textArray1[index].sd_total_amount = totalprice
        setTableRow(textArray1)
    }

    const priceChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].price = event.target.value
        var totalprice = event.target.value * textArray1[index].sd_qty

        var taxtype = textArray1[index].taxtype;
        var taxrate = textArray1[index].taxrate;



        if (taxtype == 1) {

            const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);

            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total;
            textArray1[index].sd_total_amount = total;
        }
        else if (taxtype == 2) {
            const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
            const price = (parseFloat(totalprice)).toFixed(2)

            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total;
            textArray1[index].sd_total_amount = total;

        }
        else {
            const Price = (parseFloat(totalprice)).toFixed(2)
            // textArray1[index].calculated_price = Price;
            // textArray1[index].calculated_totalprice = Price;
            textArray1[index].calcprice = Price
            textArray1[index].sd_total_amount = Price
            console.log("set tax");
        }








        setTableRow(textArray1)
        // setTimeout(function(){
        //     setEditprice("")
        //     addRow()
        //   }, 2000);
    }

    const getProductbybatch1 = (id, index) => {

        var data = {
            "intercode": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("setdetails", responseJson)
                setDetails(id, index, responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setDetails = (id, index, responseJson) => {
        console.log("index details", responseJson)
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        var stock = responseJson.pb_stock / responseJson.pb_baseqty
        input[index].pb_stock = parseInt(stock)
        setbaseQty(responseJson.pb_baseqty)

        input[index].sd_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].price = responseJson.pb_salesrate
        input[index].taxrate = responseJson.product_taxrate
        input[index].taxtype = responseJson.product_taxtype



        // if(responseJson.pb_salesrate == 0){
        //     input[index].price = null
        // }else{
        //     input[index].price = responseJson.pb_salesrate

        // }

        if (responseJson.iseditable == 1) {
            input[index].sd_total_amount = 0
            input[index].price = 0
            input[index].calcprice = 0
        } else {



            if (responseJson.product_taxtype == 1) {

                const price = (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)).toFixed(2)
                const tax = (parseFloat(responseJson.pb_salesrate) - (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))).toFixed(2);

                input[index].calculated_tax = tax;
                input[index].calculated_price = price;
                const total = (((parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))) + (parseFloat(responseJson.pb_salesrate) - (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)))).toFixed(2);
                // input[index].calculated_totalprice = total;

                input[index].sd_total_amount = total;
                input[index].calcprice = total;


            }
            else if (responseJson.product_taxtype == 2) {
                const tax = (parseFloat(responseJson.pb_salesrate) * (parseFloat(responseJson.product_taxrate) / 100)).toFixed(2);
                const price = (parseFloat(responseJson.pb_salesrate)).toFixed(2)


                input[index].calculated_tax = tax;
                input[index].calculated_price = price;
                const total = ((parseFloat(responseJson.pb_salesrate) * (parseFloat(responseJson.product_taxrate) / 100)) + (parseFloat(responseJson.pb_salesrate))).toFixed(2);
                // input[index].calculated_totalprice = total;
                input[index].sd_total_amount = total;
                input[index].calcprice = total;


            }
            else {
                const Price = (parseFloat(responseJson.pb_purchaseprice)).toFixed(2)
                input[index].sd_total_amount = Price;
                input[index].calcprice = Price;
                console.log("set tax");

            }
            // input[index].sd_total_amount = responseJson.pb_salesrate           
            // input[index].calcprice = responseJson.pb_salesrate
        }

        input[index].discount = 0
        console.log("input", input)
        setTableRow(input)
        setCode("")
        addRow(index)

        // if(responseJson.pb_salesrate == 0){
        // }else{
        // }
        // var total1 = total + responseJson.pb_salesrate
        // console.log("totalcalc", total1)
        // setTotal(total1)


    }

    const cbproductChange = (event, index) => {
        const input = [...combotable]
        input[index].productid = event.target.value
        setCombotable(input)
        getBatchlist(event.target.value, index)
    }

    const getBatchlist = (id, index) => {
        console.log("id", id)
        console.log("id", combopopupid)
        var data = {
            "products_id": id,
            "products_id_master": combopopupid
        }
        console.log("data", data)
        fetch(global.url + "getProductbyIdData", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("product list", responseJson)
                var input = [...combobatchlist]
                input[index].value = responseJson.data
                setCombobatchlist(input)

                var comborow = [...combotable]
                comborow[index].product_name = responseJson.master.product_name
                comborow[index].fetch_quantity = 1
                comborow[index].quantity = responseJson.master.fc_product_quantity
                setCombotable(comborow)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const cbbatchChange = (event, index) => {
        const input = [...combotable]
        input[index].fetch_id = event.target.value
        console.log("bachid", event.target.value)
        setCombotable(input)
        getProductbybatch(event.target.value, index)
    }

    const getProductbybatch = (id, index) => {

        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                var comborow = [...combotable]
                comborow[index].color = responseJson.data.color_name
                comborow[index].size = responseJson.data.size_name
                comborow[index].stock = responseJson.data.pb_stock
                setCombotable(comborow)

            })
            .catch((error) => {
                console.log(error)
            })
    }
    const addComborow = () => {
        setCombotable([...combotable, { key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }])
        setCombobatchlist([...combobatchlist, { key: "", value: [] }])
    }

    const addtoTablerow = () => {
        var counts = [];
        const exampleArray = []

        for (var i = 0; i < combotable.length; i++) {
            exampleArray.push(combotable[i].productid)
        }

        for (var i = 0; i < combotable.length; i++) {
            counts.push({ value: "", count: 0 })
            var input = [...counts]
            input[i].value = combotable[i].productid
            exampleArray.forEach((v) => (v === combotable[i].productid && counts[i].count++));
        }

        for (var i = 0; i < counts.length; i++) {
            for (var j = 0; j < combotable.length; j++) {

                if (counts[i].value == combotable[j].productid) {
                    console.log("if", i)
                    if (counts[i].count > combotable[j].quantity) {
                        console.log("2if")
                        alert("greater than max quantity - " + combotable[j].product_name)
                        return;
                    }
                    if (counts[i].count < combotable[j].quantity) {
                        console.log("2if")
                        alert("Lesser than max quantity - " + combotable[j].product_name)
                        return;
                    }
                }

            }
        }
        console.log("combotable", combotable)
        console.log("exampleArray", exampleArray)

        if (exampleArray.length == 0) {
            alert("No Products added")
        } else {
            var input = [...tableRow]
            console.log("combotable", combotable)
            input[popupindex].fetch_products = combotable
            setTableRow(input)
            console.log("tableRow", tableRow)
            setModal2(false)
            setCombotable([{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }])
        }

    }

    const addProduct = (id, index, comborows, comboid) => {
        console.log("popupid", comboid)
        setModal2(true)
        setCombopopupid(comboid)
        setPopupindex(index)
        var data = {
            "products_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductbyFetchID", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("product list", responseJson)
                setComboproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
        setCombotable(comborows)
    }

    const recallClick = () => {
        setRecall(true)
        getRecalllist()
    }

    const shitfRecall = () => {
        setRecall(true)
        getRecalllist()
    }

    const getRecalllist = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var data = {
            "userid": userdata.user_id,
        }
        console.log("data", data)
        fetch(global.url + "posListDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("recall list", responseJson)
                setInvoicelist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var data = {
            "userid": userdata.user_id,
        }
        console.log("data", data)
        fetch(global.url + "posListDiscard", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("recall list", responseJson)
                setLengths(responseJson.data.length)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])



    const getRecall = (id) => {
        setRecall(false)
        var data = {
            "sales_draft_id": id,
        }
        console.log("data", data)
        fetch(global.url + "posDiscardDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("recall", responseJson)

                if (responseJson.error == false) {
                    setTableRow(responseJson.data.details)
                    setRecalladd(true)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    const deleteRecall = (id) => {
        if (window.confirm('Are you Want to Delete?')) {
            setRecall(false)
            var data = {
                "draftid": id,
            }
            console.log("data", data)
            fetch(global.url + "requestDeleteDraftonLogout", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("recall", responseJson)
                    window.location.reload()
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    // const getbarcodename = (id) => {
    //     console.log("barid", id)

    // }

    const getbarcodename = (id) => {
        // copy(id);
        // alert(`You have copied "${id}"`);
        console.log("indexState", indexState)
        setIndexstate(indexState + 1)

        setProductModal(false)
        var textArray1 = [...tableRow]
        textArray1[indexState].code = id
        textArray1[indexState].pb_inter_barcode = id
        setTextarray([...tableRow, textArray1])
        getSearch(id, "intercode", indexState)
    }

    const indexChange = (index) => {
        console.log("index", index)
        setIndexstate(index)
        setSelectedindex(index)
    }

    useEffect(() => {
        console.log("blocked pro name", blocked2)
    }, [blocked2])

    const xReportClick = () => {
        setXyz(true)
        setReportModal(true)
    }

    const zReportClick = () => {
        setXyz(false)
        setReportModal(true)
    }

    const xRepPrint = () => {
        window.print()
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //   useEffect(() => {
    //     Init()
    //   }, [tableRow])

    // const [port, setPort] = useState("")
    // const [writer, setWritter] = useState("")

    // async function Init()
    // {
    //     port = await navigator.serial.requestPort();
    //     setPort(port)
    //     await port.open({baudRate:9600});
    //     writer = port.writable.getWriter();
    //     setWritter(writer)
    // } 

    // useEffect(() => {
    //     console.log("port", port)
    //     console.log("writer", writer)
    // }, [port, writer])


    return (
        <FullScreen handle={handle}>
            <div className="main-content">

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <div style={{ margin: 20 }}>
                        {/* <div style={{background: '#6d37b9', width: '100%', padding: 5, display: 'flex'}}>
                <p style={{fontSize:20, color: '#fff', marginBottom: 0}}>POS</p>
            </div> */}
                        <div>
                            <Row>
                                <Col xs={12} className="border-color">
                                    <p onClick={handle.enter} style={{ textAlign: 'center', marginTop: 5, height: 15 }}>KHAYRATBALADI ({branchName})</p>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col sm={12}>
                                    <div style={{ display: 'flex' }}>
                                        {/* <Button onClick={() => lookupClick()} className="retail-btn" style={{ backgroundColor: '#00afce', borderRadius: 20, marginTop: 10, width: '100%', marginLeft: 15, marginLeft: 15, fontWeight: 'bold', }} variant="contained">Item Lookup (F6)</Button> */}
                                        {/* <Button onClick={() => cancelItemclick()} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10, width: '100%'}} variant="contained">Cancel Item</Button> */}
                                        {/* <Button onClick={() => history.push("/Products/Products")} style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%',  marginLeft: 15, marginLeft: 15}} variant="contained">Products</Button> */}
                                        <Button onClick={() => suspendClick()} className="retail-btn" style={{ backgroundColor: '#8bb3d5', borderRadius: 20, marginTop: 10, width: '100%', marginLeft: 15, marginLeft: 15, fontWeight: 'bold', }} variant="contained">Hold-({lengths == "" ? 0 : lengths}) (F7)</Button>
                                        <Button onClick={() => recallClick()} className="retail-btn" style={{ backgroundColor: '#8e72e6', borderRadius: 20, marginTop: 10, width: '100%', marginLeft: 15, marginLeft: 15, fontWeight: 'bold', }} variant="contained">Recall (F8)</Button>
                                        {/* <Button onClick={() => returnClick()}  className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Return</Button> */}
                                        {/* <Button className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">Drawer Open</Button> */}

                                        <Button onClick={() => reprint()} className="retail-btn" style={{ backgroundColor: '#d488b1', borderRadius: 20, marginTop: 10, width: '100%', marginLeft: 15, marginLeft: 15, fontWeight: 'bold', }} variant="contained">Reprint (F9)</Button>

                                        <Button onClick={logoutClick} className="retail-btn" style={{ backgroundColor: '#b071c1', borderRadius: 20, marginTop: 10, width: '100%', marginLeft: 15, marginLeft: 15, fontWeight: 'bold', }} variant="contained">Shift Closing</Button>
                                        {/* <Button className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop: 10,width: '100%'}} variant="contained">More ...</Button> */}
                                    </div>

                                </Col>
                            </Row>
                            <Row style={{ marginTop: 20 }}>
                                <Col md={3}>
                                    <FormControl sx={{ width: '100%', marginTop: 2 }}>
                                        <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={billType}
                                            label="Age"
                                            onChange={billtypeChange}
                                            size="small"

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {billtypelist.map((row, index) => {
                                                return (
                                                    <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} className="retail-top">
                                    <FormControl sx={{ width: '100%', marginTop: 2 }}>
                                        <TextField disabled value={invoiceno} id="outlined-basic" variant="outlined" size="small" label="Bill #" />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{ marginTop: 15 }}>
                                    <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                                <Col xs={12} md={3} className="retail-top">
                                    <FormControl sx={{ width: '100%', marginTop: 2 }}>
                                        <TextField disabled value={username} id="outlined-basic" variant="outlined" size="small" label="User" />
                                    </FormControl>
                                </Col>
                                {usertype1 == 0 ? null :
                                    <Col xs={12} md={2} className="retail-top" >
                                        <FormControl sx={{ width: '100%', marginTop: 2 }}>
                                            <TextField disabled value={startShit} id="outlined-basic" variant="outlined" size="small" label="Shift Start" />
                                        </FormControl>
                                    </Col>}
                                {/* {usertype1 == 0 ? null :
                        <Col xs={12} md={3} className="retail-top" >
                                <FormControl sx={{width: '100%', marginTop: 2  }}>
                                    <TextField disabled value={endShift} id="outlined-basic" variant="outlined"  size="small" label="Shift End"/>
                                </FormControl>
                        </Col>} */}
                                {/* <Col xs={12} md={3} className="retail-top">
                                <FormControl sx={{width: '100%',  marginTop: 2  }}>
                                    <TextField type='number' value={mobile} onChange={(e) => setMobile(e.target.value)} id="outlined-basic" variant="outlined"  size="small" label="Mobile"/>
                                </FormControl>
                        </Col> */}
                            </Row>
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        {/* <Col md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <FormControlLabel control={
                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }} checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                                        } label="Combo" />
                                    </FormControl>
                                </Col> */}
                                        <Col md={3}>
                                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                                <FormControlLabel control={
                                                    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }} checked={blocked2 == 0 ? false : true} onChange={e => checkChange2(e)} />
                                                } label="Product Name" />
                                            </FormControl>
                                        </Col>
                                        <Col md={3}>
                                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                                <FormControlLabel control={
                                                    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }} checked={blocked3 == 0 ? false : true} onChange={e => checkChange3(e)} />
                                                } label="PLU Code" />
                                            </FormControl>
                                        </Col>

                                        {/* <Col md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <FormControlLabel control={
                                        <Checkbox cheecked={blocked4 == 0? false : true} onChange={e => checkChange4(e)} />
                                        } label="PLU Name (Veg)" />
                                    </FormControl>
                                </Col> */}
                                    </Row>
                                    <Row style={{ marginTop: 10 }} className="tableheight1">
                                        <Table bordered responsive >
                                            <thead className='headerpos'>
                                                <tr>
                                                    <th style={{ color: '#000' }}>SlNo</th>
                                                    <th style={{ color: '#000' }}>Code</th>
                                                    <th style={{ color: '#000' }}>Barcode</th>
                                                    <th style={{ color: '#000' }}>Product name</th>
                                                    <th style={{ color: '#000' }}>Qty</th>
                                                    <th style={{ color: '#000' }}>Price</th>
                                                    <th style={{ color: '#000' }}>Tax</th>
                                                    <th style={{ color: '#000' }}>Amount</th>
                                                    <th style={{ color: '#000' }}>Total Amount</th>
                                                    <th style={{ color: '#000' }}>Exp Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableRow.map((item, index) => {
                                                    return (
                                                        <tr >
                                                            <td >{index + 1}</td>
                                                            {blocked3 == 1 ?
                                                                <td>
                                                                    <input autoFocus autocomplete="off" style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" value={item.pb_inter_barcode} onFocus={() => indexChange(index)} onChange={(event) => handleOnChange(event, index, "plucode")} />
                                                                </td> :
                                                                <td style={{ position: 'relative' }}>
                                                                    {blocked == 1 ?
                                                                        <input autocomplete="off" style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" value={item.pb_inter_barcode} onFocus={() => indexChange(index)} onChange={(event) => handleOnChange(event, index, "refcode")} /> :
                                                                        <div>
                                                                            {blocked2 == 0 ?
                                                                                <input autoFocus autocomplete="off" style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" value={item.pb_inter_barcode} onFocus={() => indexChange(index)} onChange={(event) => handleOnChange(event, index, "intercode")} /> :
                                                                                <div>
                                                                                    <input autocomplete="off" style={{ width: 150 }} type="text" name="query" value={item.pb_inter_barcode} onFocus={() => indexChange(index)} onChange={(event) => handleOnChange(event, index, "name")} />
                                                                                    {hasResults && selectedIndex == index && (
                                                                                        <div className="autocomplete" style={{ top: 25, width: '118%', left: -17, zIndex: 1000 }}>
                                                                                            <ul ref={resultsRef} className="people scrolling-div">
                                                                                                {productlist.map(item => {
                                                                                                    return (
                                                                                                        item.products.map(result => {
                                                                                                            return (
                                                                                                                <li key={item.url}>
                                                                                                                    <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                                                        index,
                                                                                                                        result.pbexp_id,
                                                                                                                        result.pbexp_date,
                                                                                                                        result.isexpired,
                                                                                                                        result.pb_iseditprice,
                                                                                                                        result.pb_isconvertunit,
                                                                                                                        result.pb_displayconvertunit,
                                                                                                                        result.products_id,
                                                                                                                        result.pb_id,
                                                                                                                        result.pb_inter_barcode,
                                                                                                                        // result.product_refcode,
                                                                                                                        result.product_name,
                                                                                                                        item.products[0],
                                                                                                                        result.pei_image,
                                                                                                                        0
                                                                                                                    )}>
                                                                                                                        {item.product_refcode} - {item.product_name}
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            )
                                                                                                        }
                                                                                                        )
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    }
                                                                </td>}
                                                                
                                                            <td><p>{item.inputbarcode}</p>
                                                            </td>
                                                            <td >
                                                                {item.sales_iscombo == 0 || item.sales_iscombo == null ?
                                                                    <div>
                                                                        <p style={{ width: 180, marginBottom: 'unset' }}>{item.productname}</p>
                                                                        {item.productname == "" ? null :
                                                                            <label style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>stock: {item.pb_isconvertunit == 1 ? item.pb_stock / 1000 : item.pb_stock} {item.pb_displayconvertunit}</label>}
                                                                    </div>
                                                                    :

                                                                    item.sales_iscombo == 1 ?
                                                                        <div style={{ width: 200 }}>
                                                                            {
                                                                                item.addnew == false ? null :
                                                                                    <Button style={{ height: 50 }} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button>
                                                                            }
                                                                            {

                                                                                item.fetch_products.map((result, k) => {
                                                                                    return (
                                                                                        <label style={{ fontSize: 10 }}>
                                                                                            {result.product_name} - {result.color_name} - {result.size_name}
                                                                                        </label>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div> :
                                                                        null
                                                                }
                                                            </td>
                                                            <td >
                                                                {item.sales_iscombo == 0 || item.sales_iscombo == null ?
                                                                    <div>
                                                                        {item.addnew == false ?
                                                                            <input min="1" style={{ width: 75, border: 0, outline: 0 }} disabled autocomplete="off" type="number" name="query" value={item.sd_qty} onChange={(event) => qtyChange(event, index)} /> :
                                                                            <input min="1" style={{ width: 75, border: 0, outline: 0 }} autocomplete="off" type="number" name="query" value={item.sd_qty} onChange={(event) => qtyChange(event, index)} />
                                                                        }

                                                                    </div>:
                                                                    null
                                                                }
                                                            </td>
                                                            {/* {editprice == 1 ?  */}
                                                            <td>
                                                                <input min="1" style={{ width: 75, border: 0, outline: 0 }} disabled={item.iseditable == 1 ? false : true} autocomplete="off" type="number" name="query" value={item.price == "0.00" ? null : item.iseditable == 1 ? item.price : parseFloat(item.price)?.toFixed(2)} onChange={(event) => priceChange(event, index)} />
                                                            </td>
                                                            {/* :<td>{item.price}</td>} */}
                                                            {/* {item.product_fetchiscombo == 0 ?
                                                    <td>{item.calcprice}</td> :
                                                    <td>{item.price}</td>
                                                } */}
                                                            <td>{item.calculated_tax}</td>
                                                            <td>{item.calculated_price}</td>
                                                            {/* <td>{item.calculated_totalprice}</td> */}
                                                            <td>{parseFloat(item.sd_total_amount)?.toFixed(2)}</td>
                                                            <td style={item.isexpired == 1 ? { background: 'red' } : null}><p style={{ width: 100 }}>{item.pb_inter_barcode == "v" || item.pb_inter_barcode == "g" || item.pb_inter_barcode == "V" || item.pb_inter_barcode == "G" ? null : item.pbexp_date}</p></td>
                                                            <td>
                                                                <div>
                                                                    {item.sod_iscancel == 1 ? null : item.sales_isedit == 1 ? null : <Button onClick={() => deleteRow(index, item.amount, item.sod_id)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={20} /></Button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>

                                        </Table>
                                        {/* {recalladd == true ? */}
                                        <Button onClick={() => addRow()} style={{ float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20, width: 150 }} variant="contained">Add New</Button>
                                    </Row>

                                </Col>
                                <Col md={3}>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col xs={12} md={6}>
                                            <Button onClick={() => cashbuttonClick()} style={{ backgroundColor: '#20c12f', color: '#fff', borderRadius: 5, fontWeight: 'bold', marginTop: 10, width: '100%', fontSize: 20 }} variant="contained">CASH</Button>

                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Button onClick={() => bankbuttonClick()} style={{ backgroundColor: '#396dc1', color: '#fff', borderRadius: 5, fontWeight: 'bold', marginTop: 10, width: '100%', fontSize: 20 }} variant="contained">CARD</Button>
                                        </Col>
                                        <Col xs={12}>
                                            <Button onClick={() => bankcashbuttonClick()} style={{ backgroundColor: '#3e92f9', color: '#fff', borderRadius: 5, fontWeight: 'bold', marginTop: 30, width: '100%', fontSize: 20 }} variant="contained">CASH & CARD</Button>
                                        </Col>
                                    </Row>
                                    {cashbutton == true ?
                                        <Row>

                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                                        <TextField value={clickedamt} onChange={(e) => setClickedamt(e.target.value)} id="outlined-basic" label="Rcvng Cash" variant="outlined" size="small" />
                                                    </FormControl>
                                                </Col>
                                                <Col xs={12} md={6}>

                                                    <input min="1" value={parseFloat(totalbalance)?.toFixed(2)} className="pospayable1" disabled autocomplete="off" type="number" name="query" />
                                                </Col>
                                            </Row>
                                            {/* <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                        <TextField disabled onChange={(e) => setCash(e.target.value)} value={parseFloat(cash)?.toFixed(2)} id="outlined-basic" label="Cash" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                        <TextField value={parseFloat(cashtotal)?.toFixed(2)}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col> */}
                                        </Row> :
                                        bankbutton == true ?
                                            <Row>
                                                {/* <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCard(e.target.value)} value={card} id="outlined-basic" label="Card" variant="outlined"  size="small" />
                            </FormControl>
                        </Col> */}
                                                <Col xs={12} md={12}>
                                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                                        <TextField onChange={(e) => setRefno(e.target.value)} disabled value={refno} id="outlined-basic" label="Ref No" variant="outlined" size="small" />
                                                    </FormControl>
                                                </Col>

                                                {/* <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField value={parseFloat(cashtotal)?.toFixed(2)}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>                    */}
                                            </Row>
                                            : <Row>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                                            <TextField value={clickedamt} onChange={(e) => setClickedamt(e.target.value)} id="outlined-basic" label="Rcvng Cash" variant="outlined" size="small" />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={6}>

                                                        <input min="1" value={parseFloat(totalbalance)?.toFixed(2)} className="pospayable1" disabled autocomplete="off" type="number" name="query" />
                                                    </Col>
                                                </Row>
                                                {/* <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCard(e.target.value)} value={card} id="outlined-basic" label="Card" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setRefno(e.target.value)} value={refno}  id="outlined-basic" label="Ref No" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setCash(e.target.value)} value={cash} id="outlined-basic" label="Cash" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField value={parseFloat(cashtotal)?.toFixed(2)}  disabled id="outlined-basic" label="Total" variant="outlined"  size="small" />
                            </FormControl>
                        </Col> */}
                                            </Row>
                                    }
                                    <Row style={{ marginTop: 50 }}>
                                        <Col md={6}>
                                            <p>Bill Amount</p>
                                            <p>Card</p>
                                            <p>Cash</p>
                                            <p>Disc %</p>
                                            <p>VAT </p>
                                            <p>Discount</p>
                                            <p>Diff</p>
                                            <p>Payable</p>
                                            {loaderbtn ?
                                                <BtnLoader /> :
                                                <Button onClick={() => onSave()} id="print-button" style={{ backgroundColor: '#6d37b9' }} variant="contained">Print</Button>}
                                        </Col>
                                        <Col md={6}>
                                            {/* <div className="textbox-padding">
                                                <FormControl sx={{ width: '100%' }} style={{ marginTop: 5 }}>
                                                    <TextField value={parseFloat(priceamount)?.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                                </FormControl>
                                            </div> */}
                                            <div className="textbox-padding">
                                                <FormControl sx={{ width: '100%' }} style={{ marginTop: 5 }}>
                                                    <TextField value={parseFloat(priceamount)?.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                                </FormControl>
                                            </div>
                                            {cashbutton == true ?
                                                <div className="textbox-padding" style={{ marginTop: 15 }}>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <TextField disabled id="outlined-basic" value={parseFloat(card)?.toFixed(2)} variant="outlined" size="small" />
                                                    </FormControl>
                                                </div> :
                                                bankbutton == true ?
                                                    <div className="textbox-padding" style={{ marginTop: 15 }}>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <TextField id="outlined-basic" disabled value={parseFloat(card)?.toFixed(2)} onChange={(e) => setCard(e.target.value)} variant="outlined" size="small" />
                                                        </FormControl>
                                                    </div> :
                                                    <div className="textbox-padding" style={{ marginTop: 15 }}>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <TextField id="outlined-basic" value={card} onFocus={() => discnullcard()} onChange={(e) => setCard(e.target.value)} variant="outlined" size="small" />
                                                        </FormControl>
                                                    </div>}
                                            {bankbutton == true ?
                                                <div className="textbox-padding">
                                                    <FormControl sx={{ width: '100%' }} style={{ marginTop: 15 }}>
                                                        <TextField disabled value={parseFloat(cash).toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                                    </FormControl>
                                                </div>
                                                :
                                                cashbutton == true ?
                                                    <div className="textbox-padding">
                                                        <FormControl sx={{ width: '100%' }} style={{ marginTop: 15 }}>
                                                            <TextField disabled value={parseFloat(cash)?.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                                        </FormControl>
                                                    </div> :
                                                    <div className="textbox-padding">
                                                        <FormControl sx={{ width: '100%' }} style={{ marginTop: 15 }}>
                                                            <TextField value={cash} disabled id="outlined-basic" variant="outlined" size="small" />
                                                        </FormControl>
                                                    </div>}
                                            <div>
                                                <div className="textbox-padding" style={{ display: 'flex' }}>
                                                    <FormControl sx={{ width: '100%' }} style={{ marginTop: 18 }}>
                                                        <TextField id="outlined-basic" onFocus={() => discnull2()} value={discountpercentage} onChange={(e) => setDiscountpercentage(e.target.value)} variant="outlined" size="small" />
                                                    </FormControl>
                                                    <p style={{ marginBottom: 'unset', marginTop: 14, marginLeft: 2 }}>%</p>
                                                </div>
                                                <div className="textbox-padding">
                                                    <FormControl sx={{ width: '100%' }} style={{ marginTop: 15 }}>
                                                        <TextField value={parseFloat(taxamount)?.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                                    </FormControl>
                                                </div>
                                                <div className="textbox-padding">
                                                    <FormControl sx={{ width: '100%' }} style={{ marginTop: 15 }}>
                                                        <TextField type='number' onFocus={() => discnull()} id="outlined-basic" value={discountamound} onChange={(e) => setDiscountamound(e.target.value)} variant="outlined" size="small" />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="textbox-padding" style={{ marginTop: 15 }}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <TextField id="outlined-basic" value={parseFloat(balance)?.toFixed(2)} variant="outlined" size="small" />
                                                </FormControl>
                                            </div>
                                            <div className="textbox-padding">
                                                <input min="1" value={parseFloat(payable)?.toFixed(2)} className="pospayable" disabled autocomplete="off" type="number" name="query" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Paper>

                <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Pay mode
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={2}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <FormControlLabel control={
                                        <Checkbox checked={cash == 0 ? false : true} onChange={e => cashChange(e)} />
                                    } label="Cash" />
                                </FormControl>
                            </Col>
                            <Col xs={2}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <FormControlLabel control={
                                        <Checkbox checked={bank == 0 ? false : true} onChange={e => bankChange(e)} />
                                    } label="Bank" />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            {cash == 1 ?
                                <Col xs={6} style={{ marginTop: 30 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField onChange={(e) => setCashamount(e.target.value)} value={cashamount} id="outlined-basic" variant="outlined" size="small" label="Cash amount" />
                                    </FormControl>
                                </Col> : null
                            }
                            {bank == 1 ?
                                <Col xs={6} style={{ marginTop: 30 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField onChange={(e) => setBankamount(e.target.value)} value={bankamount} id="outlined-basic" variant="outlined" size="small" label="Product code" />
                                    </FormControl>
                                </Col> : null
                            }

                            <Col xs={6} style={{ marginTop: 30 }}>
                                <Button onClick={() => onSave()} id="print-button" className="retail-btn" style={{ backgroundColor: '#6d37b9', borderRadius: 20 }} variant="contained"><HiSave color="White" fontSize={20} style={{ marginRight: 10 }} />Save</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                {/* productmodal */}
                <Modal
                    size="xl"
                    show={productmodal}
                    onHide={() => setProductModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Product List
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} md={3}>
                                <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined" size="small" label="Barcode" />
                            </Col>
                            <Col xs={12} md={3}>
                                <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined" size="small" label="Product Name" />
                            </Col>
                            <Col xs={12} md={3}>
                                <TextField id="outlined-basic" variant="outlined" size="small" label="PLU Name" />
                            </Col>
                            {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12} >
                                <Table striped bordered hover >
                                    <thead>
                                        <tr>
                                            <th>Ref Code</th>
                                            <th>PLU Code</th>
                                            <th>PLU Name</th>
                                            <th>Barcode</th>
                                            <th>Product Name</th>
                                            <th>Exp Date</th>
                                            <th>Exp Stock</th>
                                            <th>Stock</th>
                                            <th>Sales Price</th>
                                        </tr>
                                    </thead>
                                    {/* {productlistitem.length == 0 ? */}
                                    <tbody>
                                        {lookupproductlist?.map((column) => (
                                            <tr>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.product_refcode}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pb_code_formachine}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pb_pluname}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pb_inter_barcode}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.product_name}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pbexp_date}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pbexp_stock}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pb_stock}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={() => getbarcodename(column.pb_inter_barcode)}>{column.pb_salesrate}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                {/* authmodal */}
                <Modal
                    size="md"
                    show={authmodal}
                    onHide={() => setAuthmodal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Authentication
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {/* <Col xs={12} >
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setUsername(e.target.value)} value={username} id="outlined-basic" label="Username" variant="outlined"  size="small" />
                            </FormControl>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField onChange={(e) => setPassword(e.target.value)} value={password} id="outlined-basic" label="Password" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>

                        <Col xs ={12} style={{marginTop:30}}>
                            <Button  style={{float: 'right'}} variant="contained">Submit</Button>
                            <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                        </Col> */}

                        </Row>
                    </Modal.Body>
                </Modal>


                {/* recall */}

                <Modal
                    size="lg"
                    show={recall}
                    onHide={() => setRecall(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Recall
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col xs={12}>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <td style={{ fontSize: 12 }}>Sl No.</td>
                                            <td style={{ fontSize: 12 }}>Invoice</td>
                                            <td style={{ fontSize: 12 }}>Total Products</td>
                                            <td style={{ fontSize: 12 }}>Total Amount</td>
                                            <td style={{ fontSize: 12 }}></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoicelists.map((row, index) => (
                                            <tr>
                                                <td style={{ fontSize: 12 }}>{index + 1}</td>
                                                <td style={{ fontSize: 12 }}>{row.sales_draft_invoice}</td>
                                                <td style={{ fontSize: 12 }}>{row.totalproducts}</td>
                                                <td style={{ fontSize: 12 }}>{row.totalamount}</td>
                                                <td style={{ fontSize: 12 }}>
                                                    <Button onClick={() => getRecall(row.sales_draft_id)} style={{ height: 30, marginRight: 20, float: 'right' }} variant="contained">Recall</Button>
                                                    <Button onClick={() => deleteRecall(row.sales_draft_id)} style={{ height: 30, marginRight: 20, backgroundColor: 'red', float: 'right' }} variant="contained">Delete</Button>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                                {/* <ul>
                        {invoicelists.map((row, index) => {
                            return (
                                <a style={{textDecoration: 'none', listStyle: 'none'}}>
                                    <li className="pay-lists" style={{color: '#000', marginTop: 10}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex'}}>
                                                <p>{index+1}.</p>
                                                <p style={{marginLeft: 10}}>{row.sales_draft_invoice}</p>
                                            </div>
                                            <div style={{display: 'flex'}}>
                                                <Button onClick={() => getRecall(row.sales_draft_id)} style={{height: 30, marginRight: 20,}} variant="contained">Recall</Button>
                                               
                                            </div>
                                        </div>
                                    </li>
                                </a>
                            )}
                            )
                            }
                        </ul> */}
                            </Col>

                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={modal2}
                    onHide={() => setModal2(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Select Combo product
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {combotable.map((item, index) => {
                            return (
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs={12} md={5}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Product</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={item.productid}
                                                label="Age"
                                                onChange={(e) => cbproductChange(e, index)}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {comboproductlist.map((row, index) => {
                                                    return (
                                                        <MenuItem value={row.products_id}>{row.product_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Color and size</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={item.pb_id}
                                                label="Age"
                                                onChange={(e) => cbbatchChange(e, index)}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {combobatchlist[index].value.map((row, index) => {
                                                    return (
                                                        <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <label style={{ fontSize: 10, color: 'red' }}>Max Quantiy = {item.quantity}</label>
                                        <label style={{ fontSize: 10, color: 'red' }}>stock = {item.stock}</label>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a href="javascript:void(0)" onClick={() => deletePopupitem(index)}>
                                            <RiIcons.RiDeleteBin7Fill color="red" fontSize={20} />
                                        </a>
                                    </Col>

                                </Row>
                            )
                        })
                        }
                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12} md={2}>
                                <Button style={{ height: 30 }} variant="contained" onClick={() => addComborow()}>Add</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12} md={2}>
                                <Button style={{ height: 30 }} variant="contained" onClick={() => addtoTablerow()}>Submit</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={modal3}
                    onHide={() => setModal3(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title id="example-modal-sizes-title-lg">
               Select Combo product
                </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <Row >
                            <Col xs={12} style={{ marginTop: 20 }}>
                                <div className="section-to-print" ref={componentRef}>
                                    <div className="invoice-box" style={{ border: 'none' }}>
                                        <div className="invoice-inside">
                                            <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset' }}>KHAYRATBALADI</p>
                                            <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>{branchName} - Doha</p>
                                            <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Tel :30985888</p>

                                            <p style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold', marginBottom: 'unset' }}>{cardp != 0 && cashpb != 0 ? <span>Cash & Card Bill</span> : (cashpb == 0 ? <span>Card Bill</span> : <span>Cash Bill</span>)}</p>
                                            <p style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{formatDate(new Date)}</p>
                                            <Row>
                                                <Col xs={12}>
                                                    <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>المورد</p>
                                                    <p style={{ fontSize: 14, fontWeight: 500 }}>Bill No: {vouchernop}</p>
                                                </Col>
                                                {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Date & Time: {formatDate(new Date)}</p>
                                    <p style={{fontSize: 14, fontWeight: 500, marginTop: 21}}>{vouchernop}</p>
                                </Col> */}
                                                {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>أمين الصندوق</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Cashier: {voucherno}</p>
                                </Col> */}
                                                {/* <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Time: {date}</p>
                                </Col> */}
                                                <Col xs={6}>
                                                    <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>طريقة / مدة الدفع</p>
                                                    <p style={{ fontSize: 14, fontWeight: 500 }}>Customer: Cash Customer</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>المستعمل</p>
                                                    <p style={{ fontSize: 14, fontWeight: 500 }}>User: {username}</p>
                                                </Col>
                                            </Row>
                                            {/* <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div> */}
                                            <div>
                                                <Table >
                                                    <thead>
                                                        <tr>
                                                            <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>عدد</p>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}>SLNo.</p>
                                                            </th>
                                                            {/* <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Product</p></th> */}
                                                            <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>كمية</p>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Qty</p></th>
                                                            <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>السعر</p>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Price</p></th>
                                                            <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>الضريبة</p>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Tax %</p></th>
                                                            <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>كمية</p>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Amount</p></th>
                                                            <th><p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>مجموع</p>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Total </p></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            detailsp.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td style={{ borderBottom: 'none' }}>{index + 1}</td>
                                                                            {/* <td>{item.productname}</td> */}
                                                                            <td style={{ borderBottom: 'none' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <span style={{ fontSize: 13 }}>{item.pb_displayconvertunit}</span></td>
                                                                            <td style={{ borderBottom: 'none' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))).toFixed(2) : (parseFloat(item.price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))).toFixed(2)}</td>
                                                                            <td style={{ borderBottom: 'none' }}>{item.taxrate} %</td>
                                                                            <td style={{ borderBottom: 'none' }}>{item.pb_isconvertunit == 1 ? (parseFloat(item.calculated_price) / (parseFloat(item.sd_qty / 1000) / parseFloat(item.sd_baseqty))).toFixed(2) : (parseFloat(item.price) / (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))).toFixed(2)}</td>
                                                                            <td style={{ borderBottom: 'none' }}>{parseFloat(item.sd_total_amount).toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={4} style={{ fontSize: 13 }}>
                                                                                {item.productname}
                                                                            </td>
                                                                        </tr>
                                                                    </>

                                                                )

                                                            })
                                                        }

                                                        <tr>

                                                            <td colSpan={4}>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>Sub Total / المجموع الفرعي</p>
                                                                {/* <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>VAT % /  ضريبة  </p> */}
                                                                {discountp == 0 ? null :
                                                                    <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>Discount / تخفيض</p>}
                                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10 }}>Grand Total / المبلغ الإجمالي</p>
                                                            </td>

                                                            <td>
                                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>{parseFloat(salestotalp).toFixed(2)}</p>
                                                                {/* <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset' }}>{parseFloat(vatp).toFixed(2)}</p> */}
                                                                {discountp == 0 ? null :
                                                                    <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{discountp}</p>}
                                                                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10 }}>{grandtotalp.toFixed(2)}</p>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4}>
                                                                {cashp == 0 ? null :
                                                                    <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Cash / نقدي</p>}
                                                                {cardp == 0 ? null :
                                                                    <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Card / بطاقة</p>}
                                                            </td>
                                                            <td>
                                                                {cashp == 0 ? null :
                                                                    <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{cashp}</p>}
                                                                {cardp == 0 ? null :
                                                                    <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{cardp}</p>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4}>

                                                                <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Paid / مدفوع</p>

                                                                <p style={{ fontSize: 14, marginBottom: 'unset', marginTop: 10 }}>Balance / الرصيد</p>
                                                            </td>
                                                            <td>

                                                                <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{paidp.toFixed(2)}</p>

                                                                <p style={{ fontSize: 14, marginTop: 10, marginBottom: 'unset' }}>{balancep.toFixed(2)}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div style={{ marginTop: 30 }}>

                                                <div style={{ textAlign: 'center', marginBottom: 'unset' }} className='barr'>
                                                    <Barcode value={vouchernop} />
                                                    {/* <p>*{vouchernop}*</p> */}
                                                </div>
                                                <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Thank You</p>
                                                <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Visit Again!</p>

                                                {/* <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>procurement@alawadi.qa</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* 
                        <div className="invoice-box" style={{marginTop: 60, border: 'none'}}>
                            <div className="invoice-inside">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset'}}>Dekanet Beirut</p>
                                <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>{branchName} - Doha</p>
                                <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Tel :30985888</p>
                               
                                <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', marginBottom: 'unset'}}>{cardp !=0 && cashpb !=0 ? <span>Cash & Card Bill</span> : (cashpb == 0 ? <span>Card Bill</span> : <span>Cash Bill</span>)}</p>
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset'}}>(Duplicate)</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>{formatDate(new Date)}</p>
                                <Row>
                                <Col xs={12}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المورد</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Bill No: {vouchernop}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Customer: Cash Customer</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المستعمل</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>User: {username}</p>
                                </Col>
                                </Row>    
                  
                                <div>
                                    <Table>
                                        <thead>
                                            <tr>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                    
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Price</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Total</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                detailsp.map((item, index) => {
                                                    return(
                                                        <>
                                                            <tr>
                                                                <td style={{borderBottom: 'none'}}>{index+1}</td>
                                                        
                                                                <td style={{borderBottom: 'none'}}>{item.pb_isconvertunit == 1 ?(parseFloat(item.sd_qty/1000) / parseFloat(item.sd_baseqty)) : (parseFloat(item.sd_qty) / parseFloat(item.sd_baseqty))} <span style={{fontSize: 13}}>{item.pb_displayconvertunit}</span></td>
                                                                <td style={{borderBottom: 'none'}}>{ item.pb_isconvertunit == 1 ? (parseFloat(item.price)/(parseFloat(item.sd_qty/1000)/parseFloat(item.sd_baseqty))).toFixed(2) : (parseFloat(item.price)/(parseFloat(item.sd_qty)/parseFloat(item.sd_baseqty))).toFixed(2)}</td>
                                                                <td style={{borderBottom: 'none'}}>{parseFloat(item.sd_total_amount).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={4} style={{fontSize: 13}}>
                                                                    {item.productname}
                                                                </td>
                                                            </tr>
                                                        </>
                                                        
                                                    )
                                               
                                                })
                                            }
                                            
                                            <tr>
                                        
                                                <td colSpan={3}>
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>Sub Total / المجموع الفرعي</p>
                                                    {discountp == 0 ? null : 
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>Discount / تخفيض</p>}
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10}}>Grand Total / المبلغ الإجمالي</p>
                                                </td>
                                                
                                                <td>
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>{parseFloat(salestotalp).toFixed(2)}</p>
                                                    {discountp == 0 ? null :
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{discountp}</p>}
                                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset', marginTop: 10}}>{grandtotalp.toFixed(2)}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    {cashp == 0 ? null :
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Cash / نقدي</p>}
                                                    {cardp == 0 ? null :
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Card / بطاقة</p>}
                                                </td>
                                                <td>
                                                    {cashp == 0 ? null :
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{cashp}</p>}
                                                    {cardp == 0 ? null :
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{cardp}</p>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                         
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Paid / مدفوع</p>
                                                 
                                                    <p style={{fontSize: 14, marginBottom: 'unset', marginTop: 10}}>Balance / الرصيد</p>
                                                </td>
                                                <td>
                                                   
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{paidp.toFixed(2)}</p>
                                                   
                                                    <p style={{fontSize: 14, marginTop: 10, marginBottom: 'unset'}}>{balancep.toFixed(2)}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={{marginTop: 30}}>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Thank You</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                 
                                </div>
                            </div>
                        </div> */}

                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="lg"
                    show={posclosingmodal}
                    onHide={() => setPosClosingModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton >
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <p style={{ fontSize: 18, marginBottom: 'unset', marginTop: 'unset' }}>Shift Closing</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form>
                                <Row>
                                    <Col md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField className="login-textfield" id="outlined-basic" label="Username" variant="outlined" size="small" />
                                            <BsPersonFill color="#999" style={{ position: 'absolute', top: 0, left: 0, marginTop: 10, marginLeft: 10 }} />
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField className="login-textfield" id="outlined-basic" label="Password" variant="outlined" type="password" size="small" />
                                            <BsFillLockFill color="#999" style={{ position: 'absolute', top: 0, left: 0, marginTop: 10, marginLeft: 10 }} />
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <Button onClick={shitfRecall} className="retail-btn" style={{ backgroundColor: '#6d37b9', borderRadius: 5, width: '100%' }} variant="contained">Recall</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} style={{ marginTop: 17 }}>
                                        <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Col>
                                    <Col md={4}>
                                        <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <TextField disabled className="login-textfield" value={username} id="outlined-basic" label="User" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <TextField className="login-textfield" id="outlined-basic" label="Shift" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                {/* <Row>
                            <Col md={4} style={{marginTop: 17  }}>
                            <LocalizationProvider sx={{width: '100%'}} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Start Date"
                                        value={date1}
                                        onChange={(newValue) => {
                                            setDate1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col md={4} style={{marginTop: 17  }}>
                                <LocalizationProvider sx={{width: '100%'}} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="End Date"
                                        value={date2}
                                        onChange={(newValue) => {
                                            setDate2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col md={4} style={{marginTop: 17  }}>
                                <Button onClick={logoutClick} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 5, width: '100%'}} variant="contained">Search</Button>
                            </Col>
                        </Row> */}
                                <Row>
                                    <Col md={4}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" value={open} disabled id="outlined-basic" label="Opening Balance" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <hr style={{ marginTop: 10, marginBottom: 'unset' }}></hr>
                                <Row>
                                    <Col md={4}>
                                        <p style={{ marginTop: 10, marginBottom: 'unset', marginLeft: 90 }}>System</p>
                                    </Col>
                                    <Col md={3}>
                                        <p style={{ marginTop: 10, marginBottom: 'unset', marginLeft: 50 }}>Pos</p>
                                    </Col>
                                    <Col md={3}>
                                        <p style={{ marginTop: 10, marginBottom: 'unset', marginLeft: 30 }}>Diff</p>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={1}>
                                        {/* <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  className="login-textfield" id="outlined-basic" label="Cash" variant="outlined" type="text"  size="small"/>
                                </FormControl> */}
                                        <p style={{ marginTop: 30, marginBottom: 'unset', textAlign: 'center' }}>Cash</p>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(sysCash)?.toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" value={parseFloat(posCash)?.toFixed(2)} disabled id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(diffCash)?.toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={1}>
                                        {/* <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  className="login-textfield" id="outlined-basic" label="Cash" variant="outlined" type="text"  size="small"/>
                                </FormControl> */}
                                        <p style={{ marginTop: 30, marginBottom: 'unset', textAlign: 'center' }}>Card</p>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(sysCard)?.toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" value={posCard} onFocus={() => disnullcard()} onChange={(e) => posCardChange(e)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(diffCard).toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                {/* <Row>
                            <Col md={1}>

                                <p style={{marginTop: 30, marginBottom: 'unset', textAlign: 'center'}}>Other</p>
                            </Col>
                            <Col md={3}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField className="login-textfield" id="outlined-basic" label="Amount" variant="outlined" type="text"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col md={3}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  className="login-textfield" id="outlined-basic" label="Amount" variant="outlined" type="text"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col md={2}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  className="login-textfield" id="outlined-basic" label="Amount" variant="outlined" type="text"  size="small"/>
                                </FormControl>
                            </Col>
                        </Row> */}
                                <Row>
                                    <Col md={1}>
                                        {/* <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  className="login-textfield" id="outlined-basic" label="Cash" variant="outlined" type="text"  size="small"/>
                                </FormControl> */}
                                        <p style={{ marginTop: 20, marginBottom: 'unset', textAlign: 'center' }}>Grand Total</p>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(sysTotal)?.toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(posTotal)?.toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" disabled value={parseFloat(diffTotal)?.toFixed(2)} id="outlined-basic" label="Amount" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={3}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" value={suspendPop} disabled id="outlined-basic" label="Suspend" variant="outlined" type="text" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash500} onChange={(e) => set500(e.target.value)} onFocus={() => discnull500()} className="login-textfield" label="500 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash200} onChange={(e) => set200(e.target.value)} onFocus={() => discnull200()} className="login-textfield" label="200 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash100} onChange={(e) => set100(e.target.value)} onFocus={() => discnull100()} className="login-textfield" label="100 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash50} onChange={(e) => set50(e.target.value)} onFocus={() => discnull50()} className="login-textfield" label="50 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash10} onChange={(e) => set10(e.target.value)} onFocus={() => discnull10()} className="login-textfield" label="10 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash5} onChange={(e) => set5(e.target.value)} onFocus={() => discnull5()} className="login-textfield" label="5 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash1} onChange={(e) => set1(e.target.value)} onFocus={() => discnull1()} className="login-textfield" label="1 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={coins} onChange={(e) => setCoins(e.target.value)} onFocus={() => discnullcoin()} className="login-textfield" label="Coins" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={parseFloat(totalcl)?.toFixed(2)} disabled className="login-textfield" label="Total" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col md={6}>
                                        <Button onClick={xReportClick} className="retail-btn" style={{ backgroundColor: '#92c99f', borderRadius: 5, marginTop: 10, width: '100%' }} variant="contained">X-Report</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button onClick={zReportClick} className="retail-btn" style={{ backgroundColor: '#6d37b9', borderRadius: 5, marginTop: 10, width: '100%' }} variant="contained">Z-Report</Button>
                                    </Col>
                                </Row>
                                {/* <div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                
                                <a 
                                    variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 200, padding: 5, borderRadius: 5, textAlign: 'center'}}>
                                    Logout
                                </a>
                            </div>
                        </div> */}
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="md"
                    show={reportModal}
                    onHide={() => setReportModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title id="example-modal-sizes-title-lg">
               Select Combo product
                </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {xyz == true ?
                                <Button onClick={xRepPrint} className="retail-btn" style={{ backgroundColor: '#6d37b9', borderRadius: 5, marginTop: 10, }} variant="contained">Print</Button> :
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button onClick={popupsave} className="retail-btn" style={{ backgroundColor: '#6d37b9', borderRadius: 5, marginTop: 10, }} variant="contained">Print</Button>
                                    <Button onClick={popupsave2} className="retail-btn" style={{ backgroundColor: '#6d37b9', borderRadius: 5, marginTop: 10, }} variant="contained">Logout</Button>
                                </div>}
                        </div>
                        <Row >
                            <Col xs={12} style={{ marginTop: 20 }}>
                                <div className="section-to-print">
                                    <div className="invoice-box">
                                        <div className="invoice-inside">
                                            <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 'unset' }}>KHAYRATBALADI</p>
                                            <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>{branchName} - Doha</p>
                                            <p style={{ textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset' }}>Tel :30985888</p>
                                            <div style={{ marginTop: 20 }}>
                                                <Row>
                                                    <Col xs={4}>
                                                        <p style={{ fontSize: 12 }}>Username</p>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <p style={{ fontSize: 12 }}>: {username}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={4}>
                                                        <p style={{ fontSize: 12 }}>Date & Time</p>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <p style={{ fontSize: 12 }}>: {formatDate(new Date)}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                <Table bordered>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ fontSize: 12 }}>Mode</td>
                                                            <td style={{ fontSize: 12 }}>System</td>
                                                            <td style={{ fontSize: 12 }}>Pos</td>
                                                            <td style={{ fontSize: 12 }}>Diff</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ fontSize: 12 }}>Cash</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(sysCash)?.toFixed(2)}</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(posCash)?.toFixed(2)}</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(diffCash)?.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: 12 }}>Card</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(sysCard)?.toFixed(2)}</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(posCard)?.toFixed(2)}</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(diffCard)?.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: 12 }}>Grand Total</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(sysTotal)?.toFixed(2)}</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(posTotal)?.toFixed(2)}</td>
                                                            <td style={{ fontSize: 12 }}>{parseFloat(diffTotal)?.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                <Row>
                                                    <Col md={6}>
                                                        <p style={{ fontSize: 12 }}>Cashier Sign  :</p>
                                                    </Col>
                                                    <Col md={6}>

                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: 10 }}>
                                                    <Col md={6}>
                                                        <p style={{ fontSize: 12 }}>Suprevisor  :</p>
                                                    </Col>
                                                    <Col md={6}>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </FullScreen>
    )
}
export default Retailsale;

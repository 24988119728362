
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import { MultiSelect } from "react-multi-select-component";
import Input from '@mui/material/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal } from "react-bootstrap";
import InputAdornment from '@mui/material/InputAdornment';
// import TextField from '@mui/material/TextField';
import { MdAddCircle } from "react-icons/md";





function WizardFormFirstPage(props) {
  console.log("log", props.repList)

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);





  const [userId, setUserid] = React.useState('');
  const [sectionList, setSectionList] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);
  const [imageurl, setImageurl] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [categoryName, setCategoryname] = React.useState("");
  const [categoryId, setCategoryid] = React.useState("");
  const [editmode, setEditmode] = React.useState(false);
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [department, setDepartment] = React.useState("");
  const [section, setSection] = React.useState("");
  const [checked, setChecked] = React.useState(true);
  const [source, setSource] = React.useState([]);
  const [selectedindex, setSelectedindex] = React.useState();
  const [usertype, setUsertype] = useState("")
  const [loader, setLoader] = React.useState(false);
  const [image, setImage] = React.useState();
  const [category, setCategory] = React.useState("");
  const [categoryList, setCategorylist] = React.useState([]);
  const [subcategoryName, setSubcategoryname] = React.useState("");
  const [subcategoryId, setSubcategoryid] = React.useState("");
  const [segmentid, setSegmentid] = React.useState("");
  const [segmentname, setSegmentname] = React.useState("");
  const [segmentdescription, setSegmentdescription] = React.useState("");
  const [segment, setSegment] = React.useState("");
  const [segmentlist, setSegmentlist] = React.useState([]);
  const [itemgroupname, setItemgroupname] = React.useState("");
  const [itemgroupId, setItemgroupid] = React.useState("");
  const [brandId, setBrandid] = React.useState(0);
  const [brandName, setBrandname] = React.useState("");












  const segmentChange = (event) => {
    setSegment(event.target.value)
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  const categoryChange = (event) => {
    setCategory(event.target.value);
  };

  const departmentChange = (event) => {
    setDepartment(event.target.value);
  };

  const sectionChange = (event) => {
    setSection(event.target.value);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  const getCategory = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "true"){
          setCategorylist([])
        }
        else{
          setCategorylist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onAdd6 = () => {
    setModal6(true)
    // setBrandid("")
    // setBrandname("")
    // setBlocked(0)
      getDepartment()
      setItemgroupid("")
      setItemgroupname("")
      setBlocked(0)
  }

  const onAdd4 = () => {
    setModal5(true)
      // getDepartment()
      // setItemgroupid("")
      // setItemgroupname("")
      // setBlocked(0)
      setBrandid("")
        setBrandname("")
        setBlocked(0)
  }

  const onAdd3 = () => {
    setModal4(true)
    setSegmentid("")
    setSegmentname("")
    setSegmentdescription("")
    setBlocked(0)
    getSegment()
    // setSegment()
  }


  const onAdd1 = () => {
    setModal3(true)
    setSegmentid("")
    setSegmentname("")
    setSegmentdescription("")
    setBlocked(0)
  }
  const onSub = () => {
    setModal2(true)
    setEditmode(false)
    setSubcategoryid("")
    setSubcategoryname("")
    setDescription("")
    setBlocked(0)
    setCategory("")
    setImageurl("")
    getCategory()
    getDepartment()
    getSection()
}

  const onAdd = () => {
    setModal(true)
    getDepartment()
    getSection()
    setEditmode(false)
    setCategoryid("")
    setCategoryname("")
    setDescription("")
    setBlocked(0)
    setImageurl("")
  }

  useEffect(() => {
    getData()
  }, []);

  const getDepartment = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewBrand", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.status == "true") {
          setDepartmentList([])
        }
        else {
          setDepartmentList(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSection = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewItemgroup", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("section list", responseJson)
        if (responseJson.status == "true") {
          setSectionList([])
        }
        else {
          setSectionList(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  
  const getSegment = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "dropdownSegment", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
         
          if(responseJson.status == "false") {
            setSegmentlist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)

    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCategory", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false)
        if (responseJson.status == "false") {
          setSource(responseJson.data)

          if (responseJson.data[0].category_grid == 0) {
            setChecked(false)
          }
          else {
            setChecked(true)
          }
          console.log("response", responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onSave1 = () => {
    console.log("brandId", brandId)
    console.log("brandName", brandName)
    console.log("blocked", blocked)
    var data = {
        "brand_id": brandId,
        "brand_name": brandName,
        "brand_blocked": blocked,
        "brand_userid": userId,
        "brand_branchid" : userbranchid,
        "brand_companyid" : usercompanyid
    }
    fetch(global.url + "updateBrand", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.error == false) {
          // getData()
          setModal5(false)
          props.getBrandlist()
          alert(responseJson.message)
        }
        if(responseJson.error == true) {
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }


  const onSave2 = () => {
    var data = {
      "itemgroup_name": itemgroupname,
      "itemgroup_userid": userId,
      "itemgroup_blocked": blocked,
      "itemgroup_id": itemgroupId,
      "itemgroup_branchid" : userbranchid,
      "itemgroup_companyid" : usercompanyid
  }
    fetch(global.url + "updateItemgroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.status == "false") {
          // getData()
          setModal6(false)
          // props.getBrandlist()
          props.getItem()

          alert(responseJson.message)
        }
        if(responseJson.status == "true") {
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onSave = () => {


    const formData = new FormData();
    formData.append('category_id', categoryId);
    formData.append('category_userid', userId);
    formData.append('category_name', categoryName);
    formData.append('category_description', description);
    formData.append('category_blocked', blocked);
    formData.append('category_branchid', userbranchid);
    formData.append('category_companyid', usercompanyid);
    formData.append('category_image', image);

    console.log('category_image', image)

    fetch(global.url + "updateCategory", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responsejson", responseJson);
        if (responseJson.error == false) {
          
          setModal(false)
          alert(responseJson.message)
          props.getCategorylist()
          
        }
        if (responseJson.error == true) {


          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const onSave3 = () => {
    var data = {
      "subseg_id": segmentid,
      "subseg_name": segmentname,
      "subseg_description": segmentdescription,
      "subseg_blocked": blocked,
      "subseg_userid": userId,
      "subseg_branchid": userbranchid,
      "subseg_companyid": usercompanyid,
      "subseg_segment_fk": segment
    }
    console.log("vdsfbgsd", data)
        fetch(global.url + "updateSubsegment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responsejson", responseJson);
            if(responseJson.error == false) {
                // getData()
                setModal4(false)
                props.getSubsegment()
                alert(responseJson.message)
            }
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }


  const onSave4 = () => {
    var data = {
      "segment_id": segmentid,
      "segment_name": segmentname,
      "segment_description": segmentdescription,
      "segment_blocked": blocked,
      "segment_userid": userId,
      "segment_branchid": userbranchid,
      "segment_companyid": usercompanyid
    }
    console.log("vdsfbgsd", data)
        fetch(global.url + "updateSegment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responsejson", responseJson);
            if(responseJson.error == false) {
                // getData()
                setModal3(false)
                props.getSegment()
                alert(responseJson.message)
            }
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }


  const onSave5 = () => {
    console.log("save")
    var data = {
      "subCategory_id": subcategoryId,
      "subCategory_category": category,
      "subCategory_name": subcategoryName,
      "subCategory_description": description,
      "subCategory_blocked": blocked,
      "subCategory_userid": userId,
      "subCategory_branchid" : userbranchid,
      "subCategory_companyid" : usercompanyid
    }
    fetch(global.url + "updateSubcategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.error == false) {
          // getData()
          setModal2(false)
          props.getSubcategorylist()
          alert(responseJson.message)
        }
        if(responseJson.error == true) {
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const taxRates = [
    // { value: 'None', label: 'None' },

    { value: '5', label: '5%' },
    { value: '10', label: '10%' },
    { value: '15', label: '15%' },
  ];


  return (
    <div>
      <Row>
        <Col sm={4} >

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Code type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.code}
                                label="Age"
                                onChange={props.codetypeChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                  <MenuItem value="weighting">Weighting</MenuItem>
                                  <MenuItem value="normal">Normal</MenuItem>
                            </Select>
                    </FormControl> */}
          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="productcode"
              disabled
              onChange={props.updateform}
              value={props.form.productcode} autoComplete="off" id="outlined-basic" label="Product Code" variant="outlined" size="small" />
          </FormControl>


          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="refcode"
              onChange={props.updateform}
              value={props.form.refcode} autoComplete="off" id="outlined-basic" label="Reference code" variant="outlined" size="small" />
          </FormControl>


          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="productname"
              onChange={props.updateform}
              value={props.form.productname} autoComplete="off" id="outlined-basic" label="Product name" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="printname"
              onChange={props.updateform}
              value={props.form.printname} autoComplete="off" id="outlined-basic" label="Print name" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="othername"
              onChange={props.updateform}
              value={props.form.othername} autoComplete="off" id="outlined-basic" label="Local Name" variant="outlined" size="small" />
          </FormControl>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">HSN</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.hsn}
                                label="Age"
                                onChange={props.hsnChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.hsnList.map((item, index) => (
                                  <MenuItem value={item.hsn_id}>{item.hsn_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> */}


                    

                    <div>

<FormControl sx={{ mt: 4, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <InputLabel id="category-select-label"></InputLabel>
  <TextField
    select
    labelId="category-select-label"
    id="category-select"
    value={props.segment}
    label="Segment"
    onChange={props.segmentChange}
    size="small"
    style={{ width: '95%'}}
    // InputProps={{
    //   endAdornment: (
    //     <InputAdornment position="end">
    //       <div 
    //         // variant="contained" 

    //         // size="small"
    //       >
    //        
    //       </div>
    //     </InputAdornment>
    //   ),
    // }}
    fullWidth
  >
    <MenuItem value="">
      <em>None</em>
    </MenuItem>
    {props.segmentlist.map((item) => (
      <MenuItem key={item.segment_id} value={item.segment_id}>
        {item.segment_name}
      </MenuItem>
    ))}
  </TextField>
  <MdAddCircle onClick={onAdd1} />
</FormControl>
</div>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Segment</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.segment}
              label="Age"
              onChange={props.segmentChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.segmentlist.map((item, index) => (
                <MenuItem value={item.segment_id}>{item.segment_id}</MenuItem>
              ))}
            </Select>
          </FormControl> */}


      <div>

            <FormControl sx={{ mt: 4, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InputLabel id="category-select-label"></InputLabel>
              <TextField
                select
                labelId="category-select-label"
                id="category-select"
                value={props.subsegment}
                label="Sub Segment"
                onChange={props.subsegmentChange}
                size="small"
                style={{ width: '95%'}}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <div 
                //         // variant="contained" 

                //         // size="small"
                //       >
                //        
                //       </div>
                //     </InputAdornment>
                //   ),
                // }}
                fullWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.subsegmentlist.map((item) => (
                  <MenuItem key={item.subseg_id}
                   value={item.subseg_id}
                   >
                    {item.subseg_name}
                  </MenuItem>
                ))}
              </TextField>
              <MdAddCircle onClick={onAdd3} />
            </FormControl>
          </div>


{/* 
          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Sub Segment</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.subsegment}
              label="Age"
              onChange={props.subsegmentChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.subsegmentlist.map((item, index) => (
                <MenuItem value={item.subseg_id}>{item.subseg_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {props.editmode == false ?
            <FormControl sx={{ mt: 4 }} component="fieldset">
              <RadioGroup
                defaultValue="credit"
                name="radio-buttons-group"
                onClick={props.radioChange}
              >
                {/* <FormControlLabel value="nonbatch"  control={<Radio checked={props.producttype == 0?  true: false}/>} label="Non Batch product" color = '#999'  /> */}
                <FormControlLabel value="batch" color='#999' control={<Radio checked={props.producttype == 1 ? true : false} />} label="Batch Product" />
              </RadioGroup>
            </FormControl> : null
          }
        </Col>
        <Col sm={4}>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="description"
              onChange={props.updateform}
              value={props.form.description} autoComplete="off" id="outlined-basic" label="Description" variant="outlined" size="small" />
          </FormControl>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="warranty"
                            onChange={props.updateform}
                            value={props.form.warranty} autoComplete="off" id="outlined-basic" label="Warranty" variant="outlined"  size="small"/>
                        </FormControl> */}



<div>

<FormControl sx={{ mt: 4, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <InputLabel id="demo-simple-select-helper-label"></InputLabel>
  <TextField
    select
    labelId="demo-simple-select-helper-label"
    id="demo-simple-select-helper"
    value={props.brand}
    label="Department"
    onChange={props.brandChange}
    size="small"
    style={{ width: '95%'}}
    // InputProps={{
    //   endAdornment: (
    //     <InputAdornment position="end">
    //       <div 
    //         // variant="contained" 

    //         // size="small"
    //       >
    //        
    //       </div>
    //     </InputAdornment>
    //   ),
    // }}
    fullWidth
  >
    <MenuItem value="">
      <em>None</em>
    </MenuItem>
    {props.brandList.map((item) => (
      <MenuItem key={item.brand_id} value={item.brand_id}>
        {item.brand_name}
      </MenuItem>
    ))}
  </TextField>
  <MdAddCircle onClick={onAdd4} />
</FormControl>
</div>
          
          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.brand}
              label="Age"
              onChange={props.brandChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.brandList.map((item, index) => (
                <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}


<div>

<FormControl sx={{ mt: 4, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <InputLabel id="demo-simple-select-helper-label"></InputLabel>
  <TextField
    select
    labelId="demo-simple-select-helper-label"
    id="demo-simple-select-helper"
    value={props.itemGroup}
    label="Section"
    onChange={props.itemgroupChange}
    size="small"
    style={{ width: '95%'}}
    // InputProps={{
    //   endAdornment: (
    //     <InputAdornment position="end">
    //       <div 
    //         // variant="contained" 

    //         // size="small"
    //       >
    //        
    //       </div>
    //     </InputAdornment>
    //   ),
    // }}
    fullWidth
  >
    <MenuItem value="">
      <em>None</em>
    </MenuItem>
    {props.itemgroupList.map((item) => (
      <MenuItem key={item.itemgroup_id} value={item.itemgroup_id}>
        {item.itemgroup_name}
      </MenuItem>
    ))}
  </TextField>
  <MdAddCircle onClick={onAdd6} />
</FormControl>
</div>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.itemGroup}
              label="Age"
              onChange={props.itemgroupChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.itemgroupList.map((item, index) => (
                <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <div>

            <FormControl sx={{ mt: 4, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InputLabel id="category-select-label"></InputLabel>
              <TextField
                select
                labelId="category-select-label"
                id="category-select"
                value={props.category}
                label="Category"
                onChange={props.categoryChange}
                size="small"
                style={{ width: '95%'}}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <div 
                //         // variant="contained" 

                //         // size="small"
                //       >
                //        
                //       </div>
                //     </InputAdornment>
                //   ),
                // }}
                fullWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.categoryList.map((item) => (
                  <MenuItem key={item.category_id} value={item.category_id}>
                    {item.category_name}
                  </MenuItem>
                ))}
              </TextField>
              <MdAddCircle onClick={onAdd} />
            </FormControl>
          </div>


          

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.category}
              label="Age"
              onChange={props.categoryChange}
              size="small"

            >
              
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.categoryList.map((item, index) => (
                <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
              ))}


            </Select>
            
          </FormControl> */}



<div>

<FormControl sx={{ mt: 4, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <InputLabel id="cdemo-simple-select-helper-label"></InputLabel>
  <TextField
    select
    labelId="demo-simple-select-helper-label"
    id="demo-simple-select-helper"
    value={props.subCategory}
    label="Sub Category"
    onChange={props.subcategoryChange}
    size="small"
    style={{ width: '95%'}}
    // InputProps={{
    //   endAdornment: (
    //     <InputAdornment position="end">
    //       <div 
    //         // variant="contained" 

    //         // size="small"
    //       >
    //        
    //       </div>
    //     </InputAdornment>
    //   ),
    // }}
    fullWidth
  >
    <MenuItem value="">
      <em>None</em>
    </MenuItem>
    {props.subcatlist.map((item) => (
            // <MenuItem key={item.subCategory_id} value={item.subCategory_name}>

      <MenuItem key={item.subCategory_id} value={item.subCategory_id}>
        {item.subCategory_name}
      </MenuItem>
    ))}
  </TextField>
  <MdAddCircle onClick={onSub} />
</FormControl>
</div>



          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Sub Categeory</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.subCategory}
              label="Age"
              onChange={props.subcategoryChange}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.subcatlist.map((item, index) => (
                <MenuItem value={item.subCategory_id}>{item.subCategory_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Value Added Tax</InputLabel>
            <Select
              name="vat"
              onChange={props.updateform}
              value={props.form.vat}
              id="outlined-basic"
              size="small"   >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1">Include Tax</MenuItem>
              <MenuItem value="2">Exclude Tax</MenuItem>
            </Select>
          </FormControl> 





          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Value Added Tax</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.vat}
              label="Age"
              onChange={props.vatChange}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.vatList.map((item, index) => (
                <MenuItem value={item.taxmaster_id}>{item.taxmaster_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}


        </Col>
        <Col sm={4}>

          {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Base unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.baseUnit}
                                label="Age"
                                onChange={props.baseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Purchase unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.purchaseUnit}
                                label="Age"
                                onChange={props.purchaseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sale unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.salesUnit}
                                label="Age"
                                onChange={props.salesunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>*/}

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Price level</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.defaultPrice}
              label="Age"
              onChange={props.defaultpriceChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.priceList.map((item, index) => (
                <MenuItem value={item.pricelevel_id}>{item.pricelevel_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="orderlevel"
              onChange={props.updateform}
              value={props.form.orderlevel} autoComplete="off" id="outlined-basic" label="Order Level" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="orderqty"
              onChange={props.updateform}
              value={props.form.orderqty} autoComplete="off" id="outlined-basic" label="Order Quantitiy" variant="outlined" size="small" />
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Zone (Display location)</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.dlocation}
              label="Age"
              onChange={props.dlocationChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.dlocationlist.map((item, index) => (
                <MenuItem value={item.disloc_id}>{item.disloc_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Country of Origin</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.country}
              label="Age"
              onChange={props.countryChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.countrylist.map((item, index) => (
                <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 4, width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Godown</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={props.godown}
              label="Age"
              onChange={props.godownChange}
              size="small"

            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.godownList.map((item, index) => (
                <MenuItem value={item.godown_id}>{item.godown_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

           <FormControl sx={{ mt: 4, width: '100%' }}>
            <TextField name="tax"
              onChange={props.updateform}
              value={props.form.tax} autoComplete="off" id="outlined-basic" label="Tax Rate" variant="outlined" size="small" />
          </FormControl> 

{/* <FormControl sx={{ mt: 4, width: '100%' }}>
  
      <TextField
        select
        name="tax"
        onChange={props.updateform}
        value={props.form.tax}
        autoComplete="off"
        id="outlined-basic"
        label="Tax Rate"
        variant="outlined"
        size="small"
      >
        <MenuItem value="">
                <em>None</em>
              </MenuItem>
        {taxRates.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl> */}

          <FormControl sx={{ width: '100%' }} style={{ marginTop: 20 }}>
            <FormControlLabel control={
              <Checkbox checked={props.blocked == 0 ? false : true} onChange={e => props.checkchange(e)} />
            } label="Blocked" />
          </FormControl>
        </Col>
      </Row>
      {
        props.editmode ?
          <Row style={{ marginTop: 40 }}>
            <Col sm={3}>
              <TextField name="dummysale"
                onChange={props.updateform}
                value={props.form.dummysale} autoComplete="off" id="outlined-basic" label="Bulk Sale Price" variant="outlined" size="small" />
            </Col>
            <Col sm={3}>
              <TextField name="dummypurchase"
                onChange={props.updateform}
                value={props.form.dummypurchase} autoComplete="off" id="outlined-basic" label="Bulk Purchase Price" variant="outlined" size="small" />
            </Col>
          </Row> :
          null
      }
      {/* {
        usertype == "superadmin" ?
          null :
          <button variant="contained" onClick={onAdd}>kk</button>
      } */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Category Creation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} >
              <FormControl sx={{ mt: 3, width: '100%' }}>
                <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={department}
                  label="Age"
                  onChange={departmentChange}
                  size="small"

                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {departmentList.map((item, index) => (
                    <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={12} >
              <FormControl sx={{ mt: 3, width: '100%' }}>
                <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={section}
                  label="Age"
                  onChange={sectionChange}
                  size="small"

                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {sectionList.map((item, index) => (
                    <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={12} >
              <FormControl sx={{ mt: 4, width: '100%' }}>
                <TextField onChange={(e) => setCategoryname(e.target.value)} value={categoryName} id="outlined-basic" label="Category" variant="outlined" size="small" />
              </FormControl>
            </Col>

            <Col xs={12} >
              {
                selectedindex == 0 ?
                  <label>Image Size: (423 * 242)</label> :
                  selectedindex == 1 ?
                    <label>Image Size: (423 * 171)</label> :
                    selectedindex == 2 ?
                      <label>Image Size: (423 * 256)</label> :
                      selectedindex == 3 ?
                        <label>Image Size: (423 * 153)</label> :
                        selectedindex == 4 ?
                          <label>Image Size: (423 * 224)</label> :
                          selectedindex == 5 ?
                            <label>Image Size: (423 * 261)</label> :
                            selectedindex == 6 ?
                              <label>Image Size: (423 * 237)</label> :
                              selectedindex == 7 ?
                                <label>Image Size: (423 * 155)</label> :
                                selectedindex == 8 ?
                                  <label>Image Size: (423 * 155)</label> :
                                  selectedindex == 9 ?
                                    <label>Image Size: (423 * 155)</label> :
                                    selectedindex == 10 ?
                                      <label>Image Size: (423 * 237)</label> :
                                      selectedindex == 11 ?
                                        <label>Image Size: (423 * 155)</label> :
                                        null
              }

              <FormControl sx={{ mt: 4, width: '100%' }}>
                <TextField onChange={(e) => setDescription(e.target.value)} value={description} id="outlined-basic" label="Description" variant="outlined" size="small" />
              </FormControl>
            </Col>
            {/* {
                      editmode && imageurl != "" &&  imageurl != null ? 
                      <Col xs={12} style={{marginTop: 10}}>
                      
                        <img src={global.images + "ecommerce_photos/" + imageurl} style={{width: 60, height: 60}} /> 
                        <Button onClick={deleteImage}  variant="contained">Delete</Button>
                      </Col> : editmode &&  (imageurl == "" || imageurl == null) ?
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                          <Button onClick={addImage}  variant="contained">Add Image</Button>
                      </Col> :
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                      </Col>
                    }
                     */}
            <Col xs={5}>
              <FormControl sx={{ mt: 4, width: '100%' }}>
                <FormControlLabel control={
                  <Checkbox checked={blocked == 0 ? false : true} onChange={e => checkChange(e)} />
                } label="Blocked" />
              </FormControl>
            </Col>
            <Col xs={12} style={{ marginTop: 30 }}>
              <Button onClick={onSave} style={{ float: 'right' }} variant="contained">Submit</Button>
              <Button onClick={() => setModal(false)} style={{ marginRight: 40, float: 'right' }} variant="contained" color="error">Cancel</Button>
            </Col>

          </Row>
        </Modal.Body>
      </Modal>

   


   {/* subcategory */}

   <Modal
              size="lg"
              show={modal2}
              onHide={() => setModal2(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Sub Category Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={department}
                                label="Age"
                                onChange={departmentChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {departmentList.map((item, index) => (
                                  <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={section}
                                label="Age"
                                onChange={sectionChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {sectionList.map((item, index) => (
                                  <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={category}
                              label="Age"
                              onChange={categoryChange}
                              size="small"
                              
                            >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {categoryList.map((item, index) => (
                                <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField  onChange={(e) => setSubcategoryname(e.target.value)} value={subcategoryName} id="outlined-basic" label="Sub Category" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField  onChange={(e) => setDescription(e.target.value)} value={description} id="outlined-basic" label="Description" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>

                    {/* {
                      editmode && imageurl != "" &&  imageurl != null ? 
                      <Col xs={12} style={{marginTop: 10}}>
                      
                        <img src={global.images + "ecommerce_photos/" + imageurl} style={{width: 60, height: 60}} /> 
                        <Button onClick={deleteImage}  variant="contained">Delete</Button>
                      </Col> : editmode &&  (imageurl == "" || imageurl == null) ?
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                          <Button onClick={addImage}  variant="contained">Add Image</Button>
                      </Col> :
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                      </Col>
                    } */}
          
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                                } label="Blocked" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave5} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal2(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>


           {/* segment */}


           
          <Modal
              size="lg"
              show={modal3}
              onHide={() => setModal3(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Segment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setSegmentname(e.target.value)} value={segmentname} id="outlined-basic" label="Segment Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setSegmentdescription(e.target.value)} value={segmentdescription} id="outlined-basic" label="Segment Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button  onClick={onSave4} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button  onClick={() => setModal3(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>


           {/* sub segment */}


           <Modal
              size="lg"
              show={modal4}
              onHide={() => setModal4(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Sub Segment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setSegmentname(e.target.value)} value={segmentname} id="outlined-basic" label="SubSegment Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setSegmentdescription(e.target.value)} value={segmentdescription} id="outlined-basic" label="SubSegment Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Segment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={segment}
                                    label="Age"
                                    onChange={segmentChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {segmentlist.map((item, index) => (
                                    <MenuItem value={item.segment_id}>{item.segment_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>

                    <Col xs ={12} style={{marginTop:30}}>
                      <Button  onClick={onSave3} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button  onClick={() => setModal4(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>


           {/* department */}

           <Modal
              size="lg"
              show={modal6}
              onHide={() => setModal6(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Section Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={department}
                                label="Age"
                                onChange={departmentChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {departmentList.map((item, index) => (
                                  <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                      </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setItemgroupname(e.target.value)} value={itemgroupname} id="outlined-basic" label="Section" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave2} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal6(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>


           {/* Section */}

           <Modal
              size="lg"
              show={modal5}
              onHide={() => setModal5(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Department Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={brandName} onChange={(e) => setBrandname(e.target.value)} id="outlined-basic" label="Department name" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave1} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button  onClick={() => setModal5(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>

    </div>
  );
}

export default WizardFormFirstPage;

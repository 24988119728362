import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./sidebarData";
import { POS } from "./pos";
import SubMenu from "./subMenu";
import './sidebar.css'
import { IconContext } from "react-icons/lib";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Rightsidebar from "./rightsidebar";


const Nav = styled.div`
	background: #92c99f;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 999;
	position: fixed;
	width: 100%
`;

const SidebarWrap = styled.div`
	width: 100%;
`;

const Sidebar = (props) => {
	const [sidebar, setSidebar] = useState(true);
	const [username, setUsername] = useState("")
	const [usertype, setUsertype] = useState("")
	const [pos, setPos] = useState(false)



	useEffect(() => {
		var userdata = JSON.parse(localStorage.getItem("user"))
		var branch = JSON.parse(localStorage.getItem("branch"))
		var pos1 = JSON.parse(localStorage.getItem("pos"))

		setPos(pos1)
		setUsername(userdata.user_name)
		if (userdata.user_type == 1) {
			setUsertype("Sales Executive")
		}
		else if (userdata.user_type == 0) {
			setUsertype("Admin")
		}
		else if (userdata.user_type == 2) {
			setUsertype("Front end User")
		}
		else if (userdata.user_type == 3) {
			setUsertype("Delivery Boy")
		}
		else if (userdata.user_type == 4) {
			setUsertype("HR")
		}
		else if (userdata.user_type == 5) {
			setUsertype("stockmanager")
		}
		else {
			setUsertype("Superadmin")
		}



	}, []);

	const test = localStorage.getItem("ABC")

	const set = test ? "menu-none" : "menu-icon"
	const set1 = test ? "menu-none" : "main-menu"


	const showSidebar = (childData) => {
		props.getData(childData)
	};

	console.log(pos,"pos")

	return (
		<div div className="bar1">
			<div>

				<IconContext.Provider value={{ color: "#fff" }}>
					<Nav   >
						<div className={set} to="#" >

							<FaIcons.FaBars onClick={() => showSidebar(!props.activeData)} />

						</div>
						<Rightsidebar />
					</Nav>
					<div className={set1}>
						<div className="logodiv">
							{/* <img className="header-logo" src={require("../../assets/images/nascript logo-01.png")} style={{width: '100%'}} /> */}
							{/* <img className="header-logo" src={require("../../assets/images/GULF SHORE LOGO PNG.png")} style={{width: '100%'}} /> */}
							{/* <img className="header-logo" src={require("../../assets/images/mjt logo.cpy.png")} style={{width: '60%',height:'60%'}} /> */}
							<div className="sidebar-logo">

							</div>

						</div>
						{/* <div>
							<p style={{fontSize: 30, color: '#fff', fontWeight: 'bold', textAlign: 'center', marginTop: -10}}>ERP</p>
						</div> */}
						<div className="avatardiv">
							<img className="avatarimage" src={require("../../assets/images/unknown.jpeg").default} style={{ width: '100%' }} />
						</div>

						<p style={{ textAlign: 'center', marginBottom: 'unset', color: '#fff', marginTop: 10, fontWeight: 'bold', fontSize: 22 }}>{username}</p>
						<p style={{ textAlign: 'center', color: '#fff', marginBottom: 'unset', fontSize: 15 }}>{usertype}</p>

						<SidebarWrap className="menu-container">
							{
								pos == true ?
									POS.map((item, index) => {
										return <SubMenu item={item} key={index} parent={() => showSidebar(!props.activeData)} />
									}) :
									SidebarData.map((item, index) => {
										return <SubMenu item={item} key={index} parent={() => showSidebar(!props.activeData)} />
									})
							}
						</SidebarWrap>
					</div>
				</IconContext.Provider>
			</div>

		</div>
	);
};

export default Sidebar;

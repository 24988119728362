
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col, Table } from "react-bootstrap";
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function Utilities(pops) {
  const [downloadlink, setDownloadlink] = React.useState("");

  const onExport = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      companyid: company
    }
      fetch(global.url + "excelExportCurrentStock", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("link", responseJson)
        if(responseJson.error == false){
          console.log("link", responseJson.data)
            setDownloadlink(responseJson.data)
        }
                
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            
            <div style={{margin: 40}}>
              <Row style={{marginTop: 20}}>
                    <Col md={12} >
                    <div>
                      <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Product Exel Format Download</p>
                    </div> 
                    <div style={{marginTop: 20}}>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="excelformat"
                            sheet="tablexls"
                            buttonText="Download as XLS"/>
                    </div>
                    <div style={{marginTop: 20}}>
                    <TableContainer sx={{ maxHeight: 440,}}>
                        <Table stickyHeader aria-label="sticky table" id='table-to-xls'>
                            <TableHead className="tableheaderexe headerpos1">
                                <TableRow>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Ref code (Product)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    PLU Name (Vegitable)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Export For Machine (Dept-Prefix)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Export For Machine (Suffix)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Export For Machine (Code)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 150}}>
                                    Product Name
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 150}}>
                                    Local Name
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 150}}>
                                    Print Name
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 110}}>
                                    Description
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    HSN
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Colour
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Size
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    MTN
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    MTSN
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Barcode
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Currency
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Purchse Price
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Landing Cost
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    $
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Purchase Price + Landing Cost $
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Sales Price
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Wholesale (B2B Price)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Online Price
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    GST/Tax
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Department
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Section
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Main Category
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Sub Category
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Segment
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Sub Segment
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Zone (Display Location)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Supplier Code
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Supplier Name
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Supplier Contact
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Supplier Group
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Contry Control
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    UOM (Base Quatity of Product)
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Item Group Code
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Stock Group Code
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Expiry Date
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    IsUnitConvert
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Display Convert Unit
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Branch Control
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Order Level
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Order Qty
                                    </TableCell>
                                    <TableCell style={{fontWeight: 'bold', minWidth: 100}}>
                                    Godown
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            </Table>
                        </TableContainer>
                    </div>
                    </Col>
                 </Row>   
            </div>        
        </Paper> 
      </div>
    )
}
export default Utilities;
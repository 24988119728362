
import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';

const columns = [
    { id: '1', label: 'Name', minWidth: 50,span: false },
    { id: '2', label: 'Image', minWidth: 50,span: false },
    {
      id: 'Edit',
      label: 'Edit Image',
      minWidth: 100,
      span: true
    },
    { id: '3', label: 'Banner/Slider', minWidth: 50,span: false },
    { id: '4', label: 'Destination', minWidth: 50,span: false },
    { id: '6', label: 'Sort', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const rows = [
    {
      company: 'test',
    },

  ];

function Banner(props) {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userId, setUserid] = React.useState('');
  const [companyId, setComapanyid] = React.useState('');
  const [userType, setUsertype] = React.useState('');
  const [branchId, setBranchid] = React.useState('');
  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [categoryList, setCategorylist] = React.useState([]);
  const [category, setCategory] = React.useState('');
  const [subcategoryList, setSubcategorylist] = React.useState([]);
  const [subcategory, setSubcategory] = React.useState('');
  const [bannerList, setBannerlist] = React.useState([]);
  const [banner, setBanner] = React.useState('');
  const [displayList, setDisplaylist] = React.useState([]);
  const [display, setDisplay] = React.useState('');
  const [brandList, setBrandlist] = React.useState([]);
  const [brand, setBrand] = React.useState('');
  const [bannerName, setBannername] = React.useState('');
  const [sort, setSort] = React.useState('');
  const [file, setFile] = React.useState();
  const [bannerId, setBannerid] = React.useState('');
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const categoryChange = (event) => {
    setCategory(event.target.value);
  };
  const subcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };
  const brandChange = (event) => {
    setBrand(event.target.value);
  };
  const bannerChange = (event) => {
    setBanner(event.target.value);
  }
  const displayChange = (event) => {
    setDisplay(event.target.value);
  }
  const batchfile = (e) => {
    console.log("files", e.target.files[0])
    setFile(e.target.files[0])
  }
  const imagefile = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    // console.log('user', userdata)
    setComapanyid(userdata.user_company_id)
    setBranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      'type' : userdata.user_type,
      'companyid' : userdata.user_company_id
    }
    console.log('get data', data)
    fetch(global.url + "viewBslider", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
        if(responseJson.error == false){
          setSource(responseJson.data)
          setLoader(false)
        }  
        else{
          setLoader(false)
        }      
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCategory = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      'type' : userdata.user_type,
      'companyid' : userdata.user_company_id
    }
    fetch(global.url + "viewCategory", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setCategorylist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }
  const getSubategory = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      'type' : userdata.user_type,
      'companyid' : userdata.user_company_id
    }
    fetch(global.url + "viewSubcategory", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        // console.log("respo cat", responseJson)
        if(responseJson.status == "false") {
          setSubcategorylist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }
  const getBrand = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      'type' : userdata.user_type,
      'companyid' : userdata.user_company_id
    }
    fetch(global.url + "viewBrand", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setBrandlist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onAdd = () => {
    setModal(true)
    getCategory()
    getSubategory()
    getBrand()
    setBannerid('')
    setSort('')
    setDisplay('')
    setBrand('')
    setSubcategory('')
    setCategory('')
    setBanner('')
    setBanner('')
  }

  const editClick = (
    banner_id, 
    banner_sort,
    banner_status,
    banner_brand,
    banner_subcat,
    banner_cate,
    banner_choose,
    banner_name
  ) => {
    setModal(true)
    setBannerid(banner_id)
    setSort(banner_sort)
    setDisplay(banner_status)
    setBrand(banner_brand)
    setSubcategory(banner_subcat)
    setCategory(banner_cate)
    setBanner(banner_choose)
    setBannername(banner_name)
    getCategory()
    getSubategory()
    getBrand()
}

  const onSave = () => {
    const formData = new FormData();
    formData.append('banner_slider_userid', userId);
    formData.append('banner_slider_branchid', branchId);
    formData.append('banner_slider_companyid', companyId);
    formData.append('banner_slider_name', bannerName);
    formData.append('banner_slider_image', file);
    formData.append('banner_slider_choose', banner);
    formData.append('banner_slider_category', category);
    formData.append('banner_slider_subcategory', subcategory);
    formData.append('banner_slider_brand', brand);
    formData.append('banner_slider_status', display);
    formData.append('banner_slider_sort', sort);
    formData.append('banner_slider_id', bannerId);

    console.log('banner_slider_choose', banner)
    fetch(global.url + "updateBslider", {
        method: "POST",
        body: formData,
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        // console.log("responsejson123", responseJson);
        if(responseJson.error == false) {
          getData()
          setModal(false)
          alert(responseJson.message)
        }
        if(responseJson.error == true) {
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const deleteClick = (id) =>  {
    var data = {
      "banner_slider_id": id,
    }
    console.log("delete", data)
    fetch(global.url + "deleteBslider", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.message)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const editImage = (
    banner_id
  ) => {
    setModal2(true)
    setBannerid(banner_id)
  }

  const addImageSlider = () => {
    
    const formData = new FormData();
    formData.append('banner_slider_id', bannerId);
    formData.append('banner_slider_image', file);
    console.log('banner_slider_id', bannerId)
    fetch(global.url + "addImageBslider", {
        method: "POST",
        body: formData,
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        // console.log("responsejson123", responseJson);
        if(responseJson.error == false) {
          getData()
          setModal2(false)
          alert(responseJson.message)
        }
        if(responseJson.error == true) {
          alert(responseJson.message)
          setModal2(false)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const deleteImage = (id) => {
    var data = {
      "banner_slider_id": id,
    }
    fetch(global.url + "deleteImageBslider", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.message)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Banner and Slider Management</p>
               <Button onClick={onAdd} variant="contained">Add</Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell>
                                {row.banner_slider_name}
                              </TableCell>
                              <TableCell >
                                {row.banner_slider_image == '' ? <p style={{marginTop: 15}}>No Image</p> :
                                <img src={global.images + global.key + '/ecommerce_photos/' + row.banner_slider_image} style={{width: 50, height: 50}} alt="" />}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editImage(
                                    row.banner_slider_id
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              <TableCell >
                              {row.banner_slider_image == '' ? null :
                                  <a href="javascript:void(0)" onClick={() => deleteImage(
                                    row.banner_slider_id
                                  )}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>}
                              </TableCell>
                              <TableCell >
                              {row.banner_slider_choose}
                              </TableCell>
                              <TableCell >
                              {row.banner_slider_category != 0 && row.banner_slider_category != null ? <p>Category</p>:
                              row.banner_slider_subcategory != 0 && row.banner_slider_subcategory != null ? <p>Sub Category</p>:
                              row.banner_slider_brand != 0 && row.banner_slider_brand != null ? <p>Brand</p> : <p>No Destination</p>}
                              </TableCell>
                              <TableCell >
                                 {row.banner_slider_sort}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editClick(
                                    row.banner_slider_id,
                                    row.banner_slider_sort,
                                    row.banner_slider_status,
                                    row.banner_slider_brand,
                                    row.banner_slider_subcategory,
                                    row.banner_slider_category,
                                    row.banner_slider_choose,
                                    row.banner_slider_name,
                                    )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.banner_slider_id,
                                    )}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add Banner and Slider
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{margin: 20}}>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField onChange={(e) => setBannername(e.target.value)} value={bannerName} id="outlined-basic" label="Banner/Slider Name" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <div style={{marginTop: 15}}>
                                <input type="file" multiple  onChange={(e) => batchfile(e)}  style={{marginTop: 10}}/>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Banner/Slider</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Age"
                                    size="small"
                                    value={banner}
                                    onChange={bannerChange}
                                    >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                      <MenuItem value='smallbanner'>Small Banner(1797 × 714)</MenuItem>
                                      <MenuItem value='bigbanner'>Big Banner(3678 × 870)</MenuItem>
                                      <MenuItem value='slider'>Slider(4098 × 837)</MenuItem>
                                      <MenuItem value='smallposter'>Small Poster(330 x 165)</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Display</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Age"
                                    size="small"
                                    value={display}
                                    onChange={displayChange}
                                    >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                        <MenuItem value='display'>Yes</MenuItem>
                                        <MenuItem value='nodisplay'>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField onChange={(e) => setSort(e.target.value)} value={sort} id="outlined-basic" label="Sort" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Age"
                                    size="small"
                                    value={category}
                                    onChange={categoryChange}
                                    >
                                    <MenuItem value="null">None</MenuItem>
                                    {categoryList.map((item, index) => (
                                      <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Sub Category</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Age"
                                    size="small"
                                    value={subcategory}
                                    onChange={subcategoryChange}
                                    >
                                    <MenuItem value="null">None</MenuItem>
                                    {subcategoryList.map((item, index) => (
                                      <MenuItem value={item.subCategory_id}>{item.subCategory_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Brand</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Age"
                                    size="small"
                                    value={brand}
                                    onChange={brandChange}
                                    >
                                    <MenuItem value="null">None</MenuItem>
                                    {brandList.map((item, index) => (
                                      <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs ={12} style={{marginTop:30}}>
                            <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                            <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                        </Col>
                    </Row>
                </div>
              </Modal.Body>
           </Modal>
           <Modal
              size="lg"
              show={modal2}
              onHide={() => setModal2(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add Banner and Slider Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div>
                    <Row>
                      <Col xs={12} md={6}>
                          <div style={{marginTop: 15}}>
                              <input type="file" multiple  onChange={(e) => imagefile(e)}  style={{marginTop: 10}}/>
                          </div>
                      </Col>
                      <Col xs ={12} style={{marginTop:30}}>
                            <Button onClick={addImageSlider} style={{float: 'right'}} variant="contained">Submit</Button>
                            <Button onClick={() => setModal2(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                        </Col>
                    </Row>
                  </div>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Banner;
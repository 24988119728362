import './App.css';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppNavigator from './router';
import './fonts/BAHNSCHRIFT 10.TTF';

import MasterDashboard from './MasterDashboard'
import MasterLogin from './MasterLogin';

function App() {

  const [userType, setUsertype] = React.useState('')
  const [branchId, setBranchid] = React.useState('')
  const [comapanyId, setCompanyId] = React.useState('')
  const [userId, setUserid] = React.useState('')

  // useEffect(() => {
  //   var userdata = JSON.parse(localStorage.getItem("user"))
  //   console.log("userdata", userdata)
  //   if(userdata != null){
  //     setBranchid(userdata.user_branch_id)
  //     setCompanyId(userdata.user_company_id)
  //     setUserid(userdata.user_id)
  //     if(userdata.user_type == '0'){
  //       setUsertype(userdata.user_type)
  //     }
  //     else if(userdata.user_type == '1'){
  //       setUsertype(userdata.user_type)
  //     }
  //     else{
  //       setUsertype("superadmin")
  //     }
  //     console.log("userType", userType)
  //   }

  // },[userType])

  // global.url = "http://192.168.1.57:8080/api/";
  // global.images = "http://192.168.1.57:8080/";
  // global.excell = "http://192.168.1.57:8080/storage/app/";
  // global.txt = "http://192.168.1.57:8080/storage/app/txtfiles/";

  global.url = "https://posb.exouzia.in/public/api/";
  global.images = "https://posb.exouzia.in/public/";
  global.excell = "https://posb.exouzia.in/public/storage/app/";
  global.txt = "https://posb.exouzia.in/public/storage/app/txtfiles/";

  // global.url = "https://posb.nasiyb.com/public/api/";
  // global.images = "https://posb.nasiyb.com/public/";
  // global.excell = "https://posb.nasiyb.com/public/storage/app/";
  // global.txt = "https://posb.nasiyb.com/public/storage/app/txtfiles/";

// global.url = "https://posb.nasiyb.com/core/public/api/";
  // global.images = "https://posb.nasiyb.com/core/public/";
  // global.excell = "https://posb.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://posb.nasiyb.com/core/public/storage/app/txtfiles/";


  global.key = localStorage.getItem("ApiId")

  // global.url = "https://posb.galaxyhomescentre.com/public/api/";
  // global.images = "https://posb.galaxyhomescentre.com/public/";
  // global.excell = "https://posb.galaxyhomescentre.com/public/storage/app/";
  // global.txt = "https://posb.galaxyhomescentre.com/public/storage/app/txtfiles/";

  // global.url = "https://gulfshore.nasscript.com/core/public/api/";
  // global.images = "https://gulfshore.nasscript.com/core/public/";
  // global.excell = "https://gulfshore.nasscript.com/core/public/storage/app/";
  // global.txt = "https://gulfshore.nasscript.com/core/public/storage/app/txtfiles/";


  // global.url = "https://hotncool.nasiyb.com/core/public/api/";
  // global.images = "https://hotncool.nasiyb.com/core/public/";
  // global.excell = "https://hotncool.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://hotncool.nasiyb.com/core/public/storage/app/txtfiles/";

  // global.url = "https://mokhafat.nasiyb.com/core/public/api/";
  // global.images = "https://mokhafat.nasiyb.com/core/public/";
  // global.excell = "https://mokhafat.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://mokhafat.nasiyb.com/core/public/storage/app/txtfiles/";

  // global.url = "https://baqala.nasiyb.com/core/public/api/";
  // global.images = "https://baqala.nasiyb.com/core/public/";
  // global.excell = "https://baqala.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://baqala.nasiyb.com/core/public/storage/app/txtfiles/";

  // global.url = "https://demobaqala.nasiyb.com/core/public/api/"; 
  // global.images = "https://demobaqala.nasiyb.com/core/public/";
  // global.excell = "https://demobaqala.nasiyb.com/core/public/storage/app/";
  // global.txt = "https://demobaqala.nasiyb.com/core/public/storage/app/txtfiles/";

  // global.url = "http://192.168.1.46:8080/api/";
  // global.images = "http://192.168.1.46:8080/";
  // global.excell = "http://192.168.1.46:8080/storage/app/";
  // global.txt = "http://192.168.1.46:8080/storage/app/txtfiles/";

//..........................................................................//

  global.proname = "Awfiya Digital Print & Media Solutions"
  global.cr = "CR NO:1010799228"
  global.vat = "VAT NO:311419001500003"
  global.loc = "Riyadh-Kingdom of Saudi Arabia"
  global.email = "Email:awfiyaprintjareer@gmail.com"
  global.web = " Web: www.awfiyadigitalprint.com"


  global.arbicname = "اوفياء حلول الوسائط والطباعة الـرقمية"
  global.arbiccr = "1010799228: رقم السجل التجاري  "
  global.arbicvat = "31141900150000 : الرقم الضريبي "
  global.arbicloc =  "الريض المملكة العربية السعودية"
  global.website = "pos.galaxyhomescentre.com"

  //.....................................................................//

  // global.proname = ""
  // global.cr = ""
  // global.vat = ""
  // global.loc = ""
  // global.email = ""
  // global.web = " "


  // global.arbicname = " "
  // global.arbiccr = ""
  // global.arbicvat = ""
  // global.arbicloc = ""
  // global.website = " aljadheer.nasyib.com"
  // global.proname = "Nasshopy"
  // global.website = "www.nasshopy.com"

  global.title = "Awfiya"

  return (
    <div>
      <AppNavigator />
      {/* <MasterLogin />
      <MasterDashboard /> */}
    </div>


  );
}

export default App;

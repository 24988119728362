import '../Tax-Settings/TaxSettings.css';
import React, { Component, useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal, Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { HiSave } from "react-icons/hi";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import './purchase.css'
// import Loader from '../../loader/loader';
import { useHistory, useParams } from "react-router-dom";
import Loader  from '../../loader/loader';


const columns = [
    { id: '1', label: 'SI No.', minWidth: 50, span: false },
    { id: '2', label: 'Tax Name', minWidth: 50, span: false },
    { id: '3', label: 'Tax %', minWidth: 50, span: false },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        span: true
    },
];

const rows = [
    {
        company: 'Lorem Ipsum',

    },
    {
        company: 'Lorem Ipsum',
    },
    {
        company: 'Lorem Ipsum',
    },
];

function Purchase(props) {
    let history = useHistory();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const { id } = useParams()
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [expiry, setExpiry] = React.useState(new Date);

    // const [tableRow, setTableRow] = React.useState([{ key: "", product_batch_id: "", product_id: "", pb_inter_barcode: "", code: "", productname: "", pricelevel: "", sod_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", calculated_tax: "", calculated_price: "", calculated_totalprice: 0, discount: "", taxrate: "", taxtype: "", sod_total_amount: 0, sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", sod_baseqty: "" }] }])

    const [tableRow, setTableRow] = React.useState([{ key: "", product_batch_id: "",  code: "", barcode: '', productname: "", pricelevel: "", pd_qty: "", unit: "", price: 0, expiry: "", calculated_tax: 0, calculated_price: 0, calculated_totalprice: 0, taxrate: "", taxtype: "", salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0,color:"",size:"", colorsize: [], pd_purchase_price: 0, addnew: true }])
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [selectedIndex, setSelectedindex] = React.useState("")
    // const [productname, setProductname] = React.useState("")
    // const [selectedIndex, setSelectedindex] = React.useState("")
    // api values

    const [supplierList, setSupplierList] = React.useState([])
    const [supplier, setSupplier] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');
    // const [loader, setLoader] = React.useState(false);

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(0);
    const [advance, setAdvance] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [price, setPrice] = React.useState(0);
    const [totaltax, setTotaltax] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [productsid, setProductid] = React.useState("");


    const [productlist, setProductlist] = React.useState([]);
    const [purchaseorderlist, setPurchaseorderlist] = React.useState([]);
    

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const [barcode, setBarcode] = useState("")
    const [purchaselpoid, setPurchaselpoid] = useState("")
    const [inputbarcode, setBarcodeInput] = React.useState("")


    const [payable, setPayable] = React.useState(0);
    const [roundoff, setRoundoff] = React.useState(0);
    const [paymentmode, setPaymentmode] = React.useState('bank');

    // const [inputbarcode, setBarcodeInput] = React.useState("")

    const [searchinvoice, setSearchinvoice] = React.useState('');
    const [searchsupplier, setSearchsupplier] = React.useState('');

    const [accountbal, setAccountbal] = React.useState('');
    const [landingCost, setLandingCost] = React.useState("");

    const [taxrate, setTaxrate] = React.useState('');
    const [taxtype, setTaxtype] = React.useState('');

    const [purchaseid, setPurchaseid] = React.useState('')

    const [indexState, setIndexstate] = React.useState(0)

    const [productnameInput,setProductnameInput]=useState('')

    const [customerpricelevel, setCustomerpricelevel] = React.useState("");

    const [productmodal, setProductModal] = useState(false)
    const [lookupproductlist, setLookupproductlist] = React.useState([]);
    const [loader, setLoader] = React.useState(false);


    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;
    const hasResultsBarcode = barcode.length > 0;


    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();


    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
    };

    useEffect(() => {
        getInvoiceno(billType)
    }, [billType])

    const paymodeChange = (event) => {
        setPaymentmode(event.target.value);
        setAccountbal("")
        if (event.target.value == "cash") {
            var company = JSON.parse(localStorage.getItem("company"))
            var data = {
                "companyid": company,
            }
            fetch(global.url + "accountBalance", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.error == false) {
                        setAccountbal(responseJson.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const searchsupplierChange = (event) => {
        setSearchsupplier(event.target.value);
    };

    const colorChange = (event) => {
        var input = [...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        setTableRow(input)

        getProductbybatch1(event.target.value, selectedIndex)
        //   setColor(event.target.value)
    }

    const getProductbybatch1 = (id, index) => {

        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("setdetails", responseJson)
                console.log("setdetails", responseJson.data.product_taxrate)
                console.log("setdetails", responseJson.data.product_taxtype)
                setTaxrate(responseJson.data.product_taxrate)
                setTaxtype(responseJson.data.product_taxtype)
                setDetails(id, index, responseJson.data)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setDetails = (id, index, responseJson) => {
        console.log(tableRow)
        var input = [...tableRow]
        console.log(input)
        input[index].key = index
        input[index].product_batch_id = id

        input[index].inputbarcode = responseJson.pb_inter_barcode

        input[index].pb_stock = responseJson.pb_stock

        input[index].pd_qty = 1
        input[index].unit = responseJson.unit_name
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].expiry = responseJson.pb_expiry_date
        input[index].pd_purchase_price = responseJson.pb_purchaseprice
        // input[index].calcprice = responseJson.pb_purchaseprice 
        input[index].discount = 0
        input[index].salesrate = responseJson.pb_salesrate
        input[index].taxrate = responseJson.product_taxrate
        input[index].taxtype = responseJson.product_taxtype

        if (responseJson.product_taxtype == 1) {

            const price = (parseFloat(responseJson.pb_purchaseprice) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(responseJson.pb_purchaseprice) - (parseFloat(responseJson.pb_purchaseprice) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))).toFixed(2);

            console.log(tax)
            console.log(price)
            input[index].calculated_tax = tax;
            input[index].calculated_price = price;
            const total = (((parseFloat(responseJson.pb_purchaseprice) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))) + (parseFloat(responseJson.pb_purchaseprice) - (parseFloat(responseJson.pb_purchaseprice) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)))).toFixed(2);
            input[index].pd_total_amount = total;
            input[index].calcprice = total;
        }
        else if (responseJson.product_taxtype == 2) {
            const tax = (parseFloat(responseJson.pb_purchaseprice) * (parseFloat(responseJson.product_taxrate) / 100)).toFixed(2);
            const price = (parseFloat(responseJson.pb_purchaseprice)).toFixed(2)


            input[index].calculated_tax = tax;
            input[index].calculated_price = price;
            const total = ((parseFloat(responseJson.pb_purchaseprice) * (parseFloat(responseJson.product_taxrate) / 100)) + (parseFloat(responseJson.pb_purchaseprice))).toFixed(2);
            input[index].pd_total_amount = total;
            input[index].calcprice = total;
            console.log(tax)
            console.log(price)

        }
        else {
            const Price = (parseFloat(responseJson.pb_purchaseprice)).toFixed(2)
            input[index].calcprice = Price;
            input[index].pd_total_amount = Price;
            console.log("set tax");

        }



        // input[index].pd_total_amount = responseJson.pb_purchaseprice
        console.log("input", input)

        setTableRow(input)
        addRow()
        // setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", pd_qty: "", unit: "", price: "", calcprice: "", discount: "", pd_total_amount: 0, pb_stock: "", sales_iscombo: "",pd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        // setCode("")

        // var total1 = total + responseJson.pb_salesrate
        // console.log("totalcalc", total1)
        // setTotal(total1)
    }

    const userChange = (event) => {
        setSalesman(event.target.value);

    };

    const addRow = () => {
        setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: '', productname: "", pricelevel: "", expiry: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", calculated_tax: 0, calculated_price: 0, calculated_totalprice: 0, discount: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
    }

    const supplierChange = (event) => {
        setSupplier(event.target.value);
        getSupplierbyid(event.target.value)
        getSupplierbalance(event.target.value)
    };

    const getSupplierbalance = (id) => {
        var data = {
            "supplier_id": id
        }
        fetch(global.url + "viewSupplierBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    console.log("Supplier Balance", responseJson)
                    setBalance(responseJson.opening_balance)
                    setLimitbalance(responseJson.remain_balance)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (amount / 100) * event
        var result = amount - disc
        var grandtotal1 = result + parseFloat(otherCharges)
        setGrandtotal(grandtotal1)
    }

    const otherChargeschange = (event) => {
        setOthercharge(event)
        var disc = (amount / 100) * discount
        var result = (amount - disc) + parseFloat(event)
        if (result) {
            setGrandtotal(result)
        }
    }

    const getSupplierbyid = (id) => {
        var data = {
            "supplier_id": id
        }
        fetch(global.url + "getSupplierbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("one customer", responseJson)

                setCreditlimit(responseJson.data.supplier_credit_limit)
                setAddress(responseJson.data.supplier_address1)
                setMobile(responseJson.data.supplier_mobile)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const inputHandler = (text, key) => {
        // setSelectedindex(key)
        // setProductname(text)
        // console.log("text", text)
        // const _inputs = [...example];
        // _inputs[key].value = text;
        // _inputs[key].key   = key;
        // console.log("inputs", _inputs)
        // setExample(_inputs)
    }

    // function onKeyDown(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }
    //   }

    //   function onKeyDown1(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef1.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }
    //   }

    useEffect(() => {
        getTotalammount()
        // calculateTotalAndTax()
        // console.log('payable', payable)
    }, [tableRow, discount, roundoff, otherCharges]);


    const getTotalammount = () => {
        var totalAmount = 0;
        var totalPrice = 0;
        var totalTax = 0;


        for (var i = 0; i < tableRow.length; i++) {
            console.log(tableRow[i]);
        
        
            totalTax += parseFloat(tableRow[i].calculated_tax);
            totalPrice += parseFloat(tableRow[i].calculated_price);
            totalAmount += parseFloat(tableRow[i].pd_total_amount);
            console.log("totalamount", tableRow[i].pd_total_amount);
        }
        
    
        setAmount(totalAmount)
        setPrice(parseFloat(totalPrice.toFixed(2)))
        setTotaltax(parseFloat(totalTax.toFixed(2)))

        var disc = (totalAmount / 100) * discount
        console.log("amount edit", totalAmount)
        setGrandtotal(parseFloat(totalAmount) - disc + parseFloat(otherCharges))
        setPayable(parseFloat(totalAmount) - disc + parseFloat(otherCharges))
        console.log('payable', payable)
    }





    // const getTotalammount = () => {
    //     var totalAmount = 0;
    //     var totaltaxamount = 0;
    //     for (var i = 0; i < tableRow.length; i++) {
    //         console.log(tableRow[i])
    //         console.log(tableRow[i].taxtype)
    //         if (tableRow[i].taxtype == 1) {
    //             const calculatedPrice = parseFloat(tableRow[i].pd_total_amount) / ((parseFloat(tableRow[i].taxrate) / 100) + 1)
    //             const calculatedTax = parseFloat(tableRow[i].pd_total_amount) - parseFloat(calculatedPrice)

    //             totalAmount += parseFloat(calculatedPrice)
    //             totaltaxamount += parseFloat(calculatedTax)
    //         }
    //         else if (tableRow[i].taxtype == 2) {
    //             totalAmount += parseFloat(tableRow[i].pd_total_amount)
    //             const calculatedTax = parseFloat(tableRow[i].pd_total_amount) * (parseFloat(tableRow[i].taxrate) / 100);
    //             totaltaxamount += parseFloat(calculatedTax)
    //         }

    //         else {

    //             totalAmount += parseFloat(tableRow[i].pd_total_amount)
    //             console.log("totalamount", tableRow[i].pd_total_amount)
    //         }
    //     }
    //     console.log(totaltaxamount)
    //     console.log(totalAmount)
    //     setAmount(totalAmount)
    //     var disc = (totalAmount / 100) * discount
    //     console.log("amount edit", totalAmount)

    //     setGrandtotal((parseFloat(totalAmount) + parseFloat(totaltaxamount)) - disc + parseFloat(otherCharges))
    //     setPayable((parseFloat(totalAmount) + parseFloat(totaltaxamount)) - disc + parseFloat(otherCharges))
    //     console.log('payable', payable)
    // }




    useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()
    }, []);

    useEffect(() => {
        console.log("id", id)

        getBilltype()
        if (id == "add") {

            // new
            // getInvoiceno()
        }
        else {
            getDetailsbyid(id)
        }
    }, [])

    const getDetailsbyid = (id) => {
        console.log("idsaf", id)
        setLoader(true)
        var data = {
            'purchase_id': id
        }
        console.log("data 345", data)
        fetch(global.url + "ViewPurchaseDetailInAction", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchase123", responseJson)
                if (responseJson.error == false) {
                    setLoader(false)
                    setBilltype(responseJson.data.billtype)
                    setInvoiceno(responseJson.data.invoice)
                    setRefno(responseJson.data.refno)
                    setSalesman(responseJson.data.salesman)
                    setDate(responseJson.data.date)
                    setLpono(responseJson.data.lpono)
                    setSupplier(responseJson.data.supplier)
                    setCreditlimit(responseJson.data.creditlimit)
                    setBalance(responseJson.data.balance)
                    setLimitbalance(responseJson.data.limitbalance)
                    setQuotation(responseJson.data.qtno)
                    setMobile(responseJson.data.mobno)
                    setLimitbalance(responseJson.data.qtno)
                    setAddress(responseJson.data.address)
                    setPurchaseid(responseJson.data.purchase_id)
                    if (responseJson.data.details) {
                        setTableRow(responseJson.data.details)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getSupplier()
    }, []);

    const getInvoiceno = (id) => {
        var data = {
            "id": billType
        }
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchaseinvoiceno", responseJson.data.invoiceno)

                if (responseJson.error == false) {
                    setInvoiceno(responseJson.data)
                    setInvoNu(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)


        var data = {
            "userid": userdata.user_id,
            "companyid": company,
            "branchid": branch,
            "type": userdata.user_type,
        }
        fetch(global.url + "viewuser", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("user response", responseJson)
                setUserlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "id": 6
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
                setBilltype(responseJson.data[0].billset_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSupplier = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewSupplier", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.Error == "false") {
                    setSupplierList(responseJson.data)
                    console.log("response", responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //   useEffect(() => {
    //     var creditbal = creditLimit - balance;
    //     console.log("creditbal", creditbal)
    //     setLimitbalance(creditbal)
    //   }, [creditLimit])

    //   useEffect(() => {
    //     if ( hasResults ) {
    //       document.body.addEventListener('keydown', onKeyDown);
    //     } else {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //     }

    //     if ( hasResultsProduct ) {
    //         document.body.addEventListener('keydown', onKeyDown1);
    //     } else {
    //         document.body.removeEventListener('keydown', onKeyDown1);
    //     }


    //     return () => {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //       document.body.removeEventListener('keydown', onKeyDown1);
    //     }
    //   }, [hasResults, hasResultsProduct]);

    // const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
    //     var input = [...tableRow]
    //     input[index].key = index
    //     input[index].product_batch_id = batchid
    //     input[index].code = code
    //     input[index].productname = name
    //     input[index].pricelevel = pricelevel
    //     input[index].pd_qty = qty
    //     input[index].unit = unit
    //     input[index].price = salesrate
    //     input[index].calcprice = calcprice
    //     input[index].discount = discount



    //     // if(discount) {
    //     //     var totalcalc = (calcprice/100) * discount
    //     // }
    //     // else {
    //     //     var totalcalc = 0
    //     // }



    //     input[index].pd_total_amount = calcprice
    //     setTableRow([...tableRow, input])
    //     setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: '', productname: "", pricelevel: "", expiry: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
    //     setCode("")
    // }


    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        console.log("color1", colorsize1)
        console.log("customer percentege", customerpricelevel)
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        input[index].sales_iscombo = iscombo
        input[index].sod_qty = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size
        input[index].addnew = true
        input[index].fetch_products = [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "" }]



        console.log("no customer")

        console.log("no customer no per")
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if (discount) {

            var totalcalc = (calcprice / 100) * discount
        }
        else {

            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sod_total_amount = calcprice - totalcalc




        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", pb_inter_barcode: "", code: "", productname: "", pricelevel: "", sod_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")
        setBarcode("")
        setProductname("")

        var total1 = total + totalcalc
        console.log("totalcalc", total1)
        setTotal(total1)
    }




    const listClickname = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
       
       setProductid(productid)
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].pb_inter_barcode = pb_inter_barcode

        input[index].inputbarcode = pb_inter_barcode

        // input[index].barcode = pb_inter_barcode
        // getProductbybatch1(pbid, index)
        setSelectedindex(index)
        setTableRow( input)
        setBarcodeInput(barcode);
        // setTableRow([...tableRow, { key: "", product_batch_id: "", calculated_price: "", calculated_tax: "", calculated_totalprice: 0, product_id: "", code: "", barcode: '', expiry: "", colorsize: [], productname: "", pricelevel: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, pb_stock: "", sales_iscombo: "", pd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")
        setProductname("")
    }

    const deleteRow = (index, id) => {
        console.log("index", id)
        // setGrandtotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        // console.log("input", input)
        setTableRow(input)
        deleteFromApi(id)
    }

    const deleteFromApi = (id) => {
        if (window.confirm('Are you Want to delete this ? It will be deleted from your database')) {
            var userdata = JSON.parse(localStorage.getItem("user"))
            var company = JSON.parse(localStorage.getItem("company"))
            const data = {
                "pd_id": id,
                "userid": userdata.user_id,
                "companyid": company
            }
            console.log("data detid", data)
            fetch(global.url + "deletePurchasedetailinEdit", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("del respo", responseJson)
                    if (responseJson.error == true) {
                        alert(responseJson.message)
                    }
                    else {
                        alert(responseJson.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            window.location.reload()
        }
    }


    // const handleOnChange = (event, key, type) => {
    //     var textArray1 = [...tableRow]
    //     textArray1[key].code = event.currentTarget.value;
    //     setTextarray([...tableRow, textArray1])
    //     setSelectedindex(key)
    //     setCode(event.currentTarget.value);
    //     getSearch(event.currentTarget.value, type, key)
    // }


    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        // textArray1[key].pb_inter_barcode = event.currentTarget.value
        setTextarray([...tableRow, textArray1])
        console.log("keysssss", key)
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)
        // getnameSearch(event.currentTarget.value, type, key)
    }

    const handleBarcodechange = (event, key, type) => {
        var textArray1 = [...tableRow]      
        textArray1[key].pb_inter_barcode = event.currentTarget.value;
        textArray1[key].inputbarcode = event.currentTarget.value; 

        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setBarcode(event.currentTarget.value);  
        getBarCodeSearch(event.currentTarget.value, type, key)
    }

        const handleProductchange = (event, key, type) => {
            var textArray1 = [...tableRow]
            textArray1[key].productname = event.currentTarget.value;
            setTextarray([...tableRow, textArray1])
            setSelectedindex(key)
            setProductname(event.currentTarget.value);
            console.log("type", type)
            getnameSearch(event.currentTarget.value, type, key)
        }

    // const handleProductchange = (event, key, type) => {
    //     var textArray1 = [...tableRow]
    //     textArray1[key].productname = event.currentTarget.value;
    //     setTextarray([...tableRow, textArray1])
    //     setSelectedindex(key)
    //     setProductname(event.currentTarget.value);
    //     getSearch(event.currentTarget.value, type)
    // }

    // for calculating quantity * price

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].pd_purchase_price * event.target.value

        var taxtype = textArray1[index].taxtype;
        var taxrate = textArray1[index].taxrate;



        if (taxtype == 1) {

            const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);

            textArray1[index].pd_qty = event.target.value
            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;

            textArray1[index].calcprice = total
            textArray1[index].pd_total_amount = total
        }
        else if (taxtype == 2) {
            const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
            const price = (parseFloat(totalprice)).toFixed(2)
            textArray1[index].pd_qty = event.target.value
            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;

            textArray1[index].calcprice = total
            textArray1[index].pd_total_amount = total


        }
        else {
            const Price = (parseFloat(totalprice)).toFixed(2)
            textArray1[index].pd_qty = event.target.value
            textArray1[index].calcprice = Price
            textArray1[index].pd_total_amount = Price
            // textArray1[index].calculated_price = Price;
            // textArray1[index].calculated_totalprice = Price;
            console.log("set tax");
        }


        textArray1[index].pd_qty = event.target.value


        setTableRow(textArray1)
    }

    const ExpiryChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].expiry = event.target.value
        setTableRow(textArray1)
    }

    const priceChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].pd_purchase_price = event.target.value

        var totalprice = event.target.value * textArray1[index].pd_qty;

        var taxtype = textArray1[index].taxtype;
        var taxrate = textArray1[index].taxrate;



        if (taxtype == 1) {

            const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);


            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total
            textArray1[index].pd_total_amount = total
        }
        else if (taxtype == 2) {
            const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
            const price = (parseFloat(totalprice)).toFixed(2)

            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;

            textArray1[index].calcprice = total
            textArray1[index].pd_total_amount = total


        }
        else {
            const Price = (parseFloat(totalprice)).toFixed(2)
            textArray1[index].calcprice = Price
            textArray1[index].pd_total_amount = Price

        }


        // textArray1[index].calcprice = event.target.value * textArray1[index].pd_qty
        // textArray1[index].pd_total_amount = event.target.value * textArray1[index].pd_qty

        setTableRow(textArray1)
    }

    const getBarCodeSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "type": type,
            "barcode": text,
            "companyid": company,
            "branchid": branch
        }


        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                // setProductlist(responseJson.data)
                listClickbarcode(
                    key,
                    responseJson.data[0].products_id,
                    responseJson.data[0].products[0].pb_inter_barcode,
                    responseJson.data[0].products[0].pb_id,
                    responseJson.data[0].product_refcode,
                    responseJson.data[0].product_name,
                    responseJson.data[0].products,
                    responseJson.data[0].taxrate,
                    responseJson.data[0].taxtype,
                    0
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getnameSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "product_name": text,
                "companyid": company,
                "branchid": branch
            }
            fetch(global.url + "getProductbySearchpc", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson)
                    setProductlist(responseJson.data)
                })
                
                .catch((error) => {
                    console.log(error)
                })
        }
    }








    const getSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "refcode": text,
                "companyid": company,
                "branchid": branch
            }
        }

        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)

                setProductlist(responseJson.data)



                // listClickrefcode(
                //     key,
                //     responseJson.data[0].products_id,
                //     responseJson.data[0].products[0].pb_inter_barcode,
                //     responseJson.data[0].products[0].pb_id,
                //     responseJson.data[0].product_refcode,
                //     responseJson.data[0].product_name,
                //     responseJson.data[0].products,
                //     responseJson.data[0].taxrate,
                //     responseJson.data[0].taxtype,
                //     0
                // )
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onSave = () => {
        const exampleArray = []
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].product_batch_id == "") {
                // tableRow.splice(i, 1)
            }
            else {
                exampleArray.push(tableRow[i])
            }
        }
        // var lpodate1;
        // if(purchaselpoid == ""){
        //     console.log("no id")
        //     lpodate1 = lpodate.toISOString().split('T')[0]
        // }
        // else{
        //     console.log("has id")
        //     lpodate1 = lpodate.split('T')[0]
        // }
        // console.log("tableRow", exampleArray)
        var data = {
            "purchase_id": purchaseid,
            "purchase_user_id": userId,
            "purchase_bill_type": billType,
            "purchase_ref_no": refno,
            "purchase_salesman": salesman,
            "purchase_invoice": invoiceno,
            "purchase_date": date,
            "purchase_lpo_no": lpono,
            // "purchase_lpo_date":  lpodate1,
            "purchase_customer": supplier,
            "purchase_del_no": delno,
            "purchase_order_no": orderno,
            "purchase_address": address,
            "purchase_mob_no": mobile,
            "purchase_qt_no": quotation,
            "purchase_crdt_limit": creditLimit,
            "purchase_balance": balance,
            "purchase_limit_balance": limitbalance,
            "purchase_total": grandtotal,
            "purchase_discount": discount,
            "purchase_other_charges": otherCharges,
            "purchase_advance": advance,
            "purchase_details": exampleArray,
            "purchase_branchid": userbranchid,
            "purchase_companyid": usercompanyid,
            "purchase_lpo_id": purchaselpoid,
            "totaltax": totaltax,
            "totalamount": amount,
            "taxrate": taxrate,
            "taxtype": taxtype,
            "payable": payable,
            "mode": paymentmode,
            "purchase_landingcost": landingCost,
            "purchase_roundof": roundoff,
            "pd_expiry": date2,
            "purchase_price": price,
            "purchase_tax": totaltax
        }
        console.log("purchase data", data)
        fetch(global.url + "updatePurchase", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response purchase", responseJson)
                if (responseJson.error == true) {
                    if (responseJson.data == "") {
                        alert(responseJson.message)
                    } else {
                        alert(responseJson.data)
                    }
                }
                else {
                    alert(responseJson.message)
                    const win = window.open('/purchaseprint/' + responseJson.data, "_blank");
                    win.focus();
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const disountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice / 100) * input[index].discount
        input[index].pd_total_amount = input[index].calcprice - disc
        setTableRow(input)
    }

    const onPopup = () => {
        setModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))


        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        console.log("data", data)
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchase response", responseJson)
                if (responseJson.status == "true") {
                    setPurchaseorderlist([])
                    console.log("true")
                }
                else {
                    console.log("false")
                    setPurchaseorderlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const purchaseOrderclick = (id) => {

        var data = {
            "purchaseorder_id": id,
        }
        console.log("data", data)
        fetch(global.url + "purchaseOrderFill", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setModal(false)
                console.log("Purchase Order response", responseJson)
                const modifiedData = calculateTaxes(responseJson.data.purchaseorder_details);
                setRefno(responseJson.data.purchase_ref_no)
                setSalesman(responseJson.data.purchase_salesman)
                setLpono(responseJson.data.purchase_invoice)
                setCreditlimit(responseJson.data.purchase_crdt_limit)
                setBalance(responseJson.data.purchase_balance)
                setLimitbalance(responseJson.data.purchase_limit_balance)
                setSupplier(responseJson.data.purchase_customer)
                setMobile(responseJson.data.purchase_mob_no)
                setAddress(responseJson.data.purchase_address)
                setQuotation(responseJson.data.purchase_qt_no)
                // setTableRow(responseJson.data.purchaseorder_details)   
                setTableRow(modifiedData);           
                setTableRow((prev) => {return prev.map((item) => ({...item,inputbarcode: item.barcode, }));});        
            })
            .catch((error) => {
                console.log(error)
            })
    }


    function calculateTaxes(purchaseorderDetails) {       
        return purchaseorderDetails.map(item => {
            const qty = item.pd_qty
            if (item.product_taxtype == 1) {
                const price = (parseFloat(item.pd_purchase_price) / ((parseFloat(item.product_taxrate) / 100) + 1)).toFixed(2);
                const tax = (parseFloat(item.pd_purchase_price) - (parseFloat(item.pd_purchase_price) / ((parseFloat(item.product_taxrate) / 100) + 1))).toFixed(2);
    
                item.calculated_tax = tax * qty ;
                item.calculated_price = price * qty;
                const total = (((parseFloat(item.pd_purchase_price) / ((parseFloat(item.product_taxrate) / 100) + 1))) + (parseFloat(item.pd_purchase_price) - (parseFloat(item.pd_purchase_price) / ((parseFloat(item.product_taxrate) / 100) + 1)))).toFixed(2);
                item.pd_total_amount = total * qty ;
                item.calcprice = total * qty;
            } else if (item.product_taxtype == 2) {
                const tax = (parseFloat(item.pd_purchase_price) * (parseFloat(item.product_taxrate) / 100)).toFixed(2);
                const price = (parseFloat(item.pd_purchase_price)).toFixed(2);
    
                item.calculated_tax = tax  * qty;
                item.calculated_price = price  * qty; 
                const total = ((parseFloat(item.pd_purchase_price) * (parseFloat(item.product_taxrate) / 100)) + (parseFloat(item.pd_purchase_price))).toFixed(2);
                item.pd_total_amount = total  * qty;
                item.calcprice = total  * qty;
            } else {
                const Price = (parseFloat(item.pd_purchase_price)).toFixed(2);
                item.calcprice = Price;
                item.pd_total_amount = Price;
            }
            item.taxrate = item.product_taxrate
            item.unit = item.unit
            return item;
        });
    }
    const setOrderDetails = (responseJson , index)=>{
     
        for (var i = 0; i < tableRow.length; i++) {
            console.log(tableRow[i]);
   
        }
    }

    const roundOffchange = (event) => {
        setRoundoff(event)
        var round = amount - event
        var grandtotal1 = round
        setGrandtotal(grandtotal1)
    }



    const listClickbarcode = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].barcode = pb_inter_barcode
        input[index].inputbarcode = pb_inter_barcode
        
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        // setBarcodeInput(pb_inter_barcode)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", pb_inter_barcode: "", code: "", productname: "", pricelevel: "", sod_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", sod_baseqty: "" }] }])
        setCode("")

    }


    // const listClickrefcode = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
    //     var input = [...tableRow]
    //     input[index].key = index
    //     input[index].products_id = productid
    //     input[index].code = productrefcode
    //     input[index].sales_iscombo = iscombo
    //     input[index].productname = productname
    //     input[index].colorsize = products
    //     input[index].barcode = pb_inter_barcode
    //     input[index].inputbarcode = pb_inter_barcode
    //     getProductbybatch1(pbid, index)
    //     setSelectedindex(index)
    //     setTableRow([...tableRow, input])
    //     setTableRow([...tableRow, { key: "", product_batch_id: "", calculated_price: "", calculated_tax: "", calculated_totalprice: 0, product_id: "", code: "", barcode: '', expiry: "", colorsize: [], productname: "", pricelevel: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, pb_stock: "", sales_iscombo: "", pd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
    //     setCode("")
    // }


    const listClickrefcode = (index, productid, productrefcode, productname, products, image, iscombo, barcode, pbid) => {
        console.log(productname, "barcode1")
        console.log("products1234 :", products);
        var input = [...tableRow]
        input[index].key = index
        input[index].product_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].pb_inter_barcode = barcode
        // input[index].inputbarcode = pb_inter_barcode
        input[index].pei_image = image

        // getProductbybatch1(pbid, index)
        setProductnameInput(productname)

        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", pb_inter_barcode: "", code: "", productname: "", pricelevel: "", sod_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", sod_baseqty: "" }] }])
        setBarcode("")
        setCode("")
        

    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "invoice": searchinvoice,
            "supplier": searchsupplier
        }
        console.log("data", data)
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchase response", responseJson)
                if (responseJson.status == "true") {
                    setPurchaseorderlist([])
                    console.log("true")
                }
                else {
                    console.log("false")
                    setPurchaseorderlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })

    }, [searchinvoice, searchsupplier]);

    const upClick = () => {
        setInvoiceno(preCount => preCount + 1)
    }

    const downClick = () => {
        if (invoiceno > 1) {
            setInvoiceno(preCount => preCount - 1)
        }
    }

    // useEffect(() => {
    //     getPurchaseDetails()
    //     console.log('invoiceno', invoiceno)
    // },[invoiceno])

    const purchaseDetails = () => {
        getPurchaseDetails()
    }

    const getPurchaseDetails = () => {
        setLoader(true)
        var data = {
            'invoice_no': invoiceno
        }

        fetch(global.url + "PurchasedetailByInvoiceno", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchase invoice77", responseJson)
                setLoader(false)
                if (responseJson.error == false) {
                    setPurchaseid(responseJson.data.purchase_id)
                    setBilltype(responseJson.data.billtype)
                    setRefno(responseJson.data.refno)
                    setSalesman(responseJson.data.salesman)
                    setDate(responseJson.data.date)
                    setLpono(responseJson.data.lpono)
                    setSupplier(responseJson.data.supplier)
                    setCreditlimit(responseJson.data.creditlimit)
                    setBalance(responseJson.data.balance)
                    setLimitbalance(responseJson.data.limitbalance)
                    setQuotation(responseJson.data.qtno)
                    setMobile(responseJson.data.mobno)
                    setLimitbalance(responseJson.data.qtno)
                    setAddress(responseJson.data.address)
                    console.log(responseJson.data.detail)
                    if (responseJson.data.details) {
                        setTableRow(responseJson.data.details)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            "usertype": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("item look up", responseJson)
                setLookupproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const onfilter = (text, type) => {
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "intercode") {
            var data = {
                "type": 'intercode',
                "intercode": text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.error == false) {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])

                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (type == "superadmin") {
            var data = {
                "type": 'superadmin',
                "productname": text,
                "companyid": company
            }
            console.log("superadmin", data)
            fetch(global.url + "viewAllproductbatch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.status == 'false') {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const getbarcodename = (id) => {
        setIndexstate(indexState + 1)
        console.log("index", indexState)
        setProductModal(false)
        getSearch(id, "refcode", indexState)
        getnameSearch(id, "name", indexState)
        getBarCodeSearch(id, "barcode", indexState)
    }

    const indexChange = (index) => {
        console.log("index", index)
        setIndexstate(index)
        setSelectedindex(index)

    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }

        var date = new Date(datestring)
        var month = pad2(date.getMonth() + 1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year = date.getFullYear();

        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US',
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
        //   console.log("timeString12hr", timeString12hr)

        var formattedDate = day + "/" + month + "/" + year;
        return formattedDate; //03/02/2021
    }

    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ margin: 20 }}>
                    <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Purchase</p>
                    {loader ? <Loader /> :
                        <div>
                            <Row>
                                {/* <Col xs ={12} style={{marginTop:30}}>
                            <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20}} variant="contained"><GoSettings  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Settings</Button>
                            <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20}} variant="contained"><BiRefresh  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Refresh</Button>
                        </Col> */}
                            </Row>
                           <div>
                            { loader ? <Loader /> :
                                <Row style={{ marginTop: 30 }}>
                                    <Col xs={12} md={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={billType}
                                                label="Age"
                                                onChange={billtypeChange}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {billTypelist.map((row, index) => {
                                                    return (
                                                        <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Invoice" value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={supplier}
                                                label="Age"
                                                onChange={supplierChange}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {supplierList.map((row, index) => {
                                                    return (
                                                        <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Crdt Limit:" value={creditLimit} disabled id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Balance" value={balance} disabled id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
}
                           </div>
                            <Row style={{ marginTop: 20 }}>
                                <Col xs={12} md={2}>
                                    <FormControl sx={{ width: '100%' }} onClick={onPopup}>
                                        <TextField label="LPO No." className="cursor-pointer"  autoComplete="off" onChange={(e) => setLpono(e.target.value)} value={lpono} id="outlined-basic" variant="outlined" size="small" />
                                    </FormControl>
                                </Col>
                                <Col md={10}>
                                    <Button className='btn1' onClick={() => history.push("/Products/Products")} style={{  borderRadius: 5, float: 'right' }} variant="contained">Products</Button>
                                    {/* <Button onClick={() => lookupClick()} className="retail-btn" style={{ backgroundColor: '#92c99f', borderRadius: 5, marginRight: 20, float: 'right' }} variant="contained">Item Lookup</Button> */}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 40 }}>
                                <Col xs={12}>
                                    <Table bordered responsive>
                                        <thead>
                                            <tr>
                                                <th>SlNo</th>
                                                <th>Ref Code</th>
                                                <th>Color-Size</th>
                                                <th>Barcode</th>
                                                <th>Product name</th>
                                                <th style={{ width: 0 }}>Order Qty</th>
                                                <th style={{ width: 0 }}>Qty</th>
                                                <th>Unit</th>
                                                <th>Cost Price</th>
                                                <th>Tax</th>
                                                <th>Amount</th>
                                                <th>Total Amount</th>
                                                <th>Sales Price</th>
                                                {/* <th>Expiry</th> */}
                                                <th style={{ width: 0 }}>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableRow?.map((item, index) => {

                                                console.log(item.productname,"power")
                                                                                  


                                                return (
                                                    <tr  style={item.sod_iscancel == 1 ? { background: 'red' } : null}>
                                                        <td >{index + 1}</td>


                                                    
                                                    <td>

                                                        {/* {
                                                            item.addnew == true ?
                                                                <input style={{ width: 100, border: 0, outline: 0 }} autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} /> :
                                                                <input style={{ width: 100, border: 0, outline: 0 }} autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                        } */}

                                                          {item.addnew == true ? (
                                                            <input
                                                                autoComplete="off"
                                                                style={{ width: 115,border:'none' }}
                                                                ref={inputRef}
                                                                type="text"
                                                                name="query"
                                                                value={item.code}
                                                                onChange={(event) =>
                                                                    handleOnChange(event, index, "refcode")
                                                                }
                                                                />
                                                                ) : (
                                                                    <p>{item.code}</p>
                                                                )}
        

                                                          

        



                                                        {hasResults && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ?
                                                                                item.products.map(result => {
                                                                                    console.log(result,"1111");
                                                                                    return (
                                                                                         <li key={item.url}>
                                                                                        <a
                                                                                            href="#"
                                                                                            onClick={() =>
                                                                                                listClickrefcode(
                                                                                                    index,
                                                                                                    item.products_id,
                                                                                                    item.product_refcode,
                                                                                                    item.product_name,
                                                                                                    item.products,
                                                                                                    item.pei_image,
                                                                                                    0
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {item.product_refcode} -{" "}
                                                                                            {item.product_name}
                                                                                        </a>
                                                                                    </li>
                                                                                    )
                                                                                }) :
                                                                                <li key={item.url}>
                                                                                <a
                                                                                    href="#"
                                                                                    onClick={() =>
                                                                                        listClickcode(
                                                                                            index,
                                                                                            item.products_id,
                                                                                            item.product_refcode,
                                                                                            item.product_name,
                                                                                            item.pricelevel_name,
                                                                                            "1",
                                                                                            item.unit_name,
                                                                                            item.product_comboprice,
                                                                                            item.product_comboprice,
                                                                                            0,
                                                                                            item.product_comboprice,
                                                                                            item.pricelevel_percentage,
                                                                                            item.product_combostock,
                                                                                            1,
                                                                                            item.product_inter_barcode,
                                                                                            item.product_comboimage
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {item.product_refcode} -{" "}
                                                                                    {item.product_name}
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>

                                                        {/* <td>


                                                        {item.addnew == true ? (
                                                            <input
                                                                autoComplete="off"
                                                                style={{ width: 115 }}
                                                                ref={inputRef}
                                                                type="text"
                                                                name="query"
                                                                value={item.code}
                                                                onChange={(event) =>
                                                                    handleOnChange(event, index, "refcode")
                                                                }
                                                            // onKeyUp={(event)=>{
                                                            //     if (event.keyCode == "13") {
                                                            //         console.log("Enter key is pressed");
                                                            //         listClickrefcode(index)

                                                            //     }
                                                            //     }
                                                            // }
                                                            />
                                                        ) : (
                                                            <p>{item.code}</p>
                                                        )}

                                                        {hasResults && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul
                                                                    ref={resultsRef}
                                                                    className="people scrolling-div"
                                                                >
                                                                    {productlist.map((item) => {
                                                                        console.log("item :", item);

                                                                        return item.product_fetchiscombo == 0 ? (
                                                                            item.products?.map((result) => {
                                                                                console.log("result :", result);
                                                                                return (
                                                                                    // <li key={result.url}>
                                                                                    //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                    //         index,
                                                                                    //         result.pb_id,
                                                                                    //         result.product_refcode,
                                                                                    //         result.product_name,
                                                                                    //         result.pricelevel_name,
                                                                                    //         "1",
                                                                                    //         result.unit_name,
                                                                                    //         result.pb_salesrate,
                                                                                    //         result.pb_salesrate,
                                                                                    //         0,
                                                                                    //         result.pb_salesrate,
                                                                                    //         result.pricelevel_percentage,
                                                                                    //         result.pb_stock,
                                                                                    //         0,
                                                                                    //         result.color_name,
                                                                                    //         result.size_name,
                                                                                    //         result
                                                                                    //         )}>
                                                                                    //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                                    //     </a>
                                                                                    // </li>
                                                                                    <li key={item.url}>
                                                                                        <a
                                                                                            href="#"
                                                                                            onClick={() =>
                                                                                                listClickrefcode(
                                                                                                    index,
                                                                                                    item.products_id,
                                                                                                    item.product_refcode,
                                                                                                    item.productname,
                                                                                                    item.products,
                                                                                                    item.pei_image,
                                                                                                    0

                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {item.product_refcode} -{" "}
                                                                                            {item.product_name}
                                                                                        </a>
                                                                                    </li>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <li key={item.url}>
                                                                                <a
                                                                                    href="#"
                                                                                    onClick={() =>
                                                                                        listClickcode(
                                                                                            index,
                                                                                            item.products_id,
                                                                                            item.product_refcode,
                                                                                            item.product_name,
                                                                                            item.pricelevel_name,
                                                                                            "1",
                                                                                            item.unit_name,
                                                                                            item.product_comboprice,
                                                                                            item.product_comboprice,
                                                                                            0,
                                                                                            item.product_comboprice,
                                                                                            item.pricelevel_percentage,
                                                                                            item.product_combostock,
                                                                                            1,
                                                                                            item.product_inter_barcode,
                                                                                            item.product_comboimage
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {item.product_refcode} -{" "}
                                                                                    {item.product_name}
                                                                                </a>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                            
                                                            
                                                        </td> */}

                                                        {/* {
                                                        lpono == "" ?
                                                            <td>
                                                                <FormControl sx={{ width: '100%' }}>
                                                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-helper-label"
                                                                        id="demo-simple-select-helper"
                                                                        value={item.product_batch_id}
                                                                        label="Age"
                                                                        onChange={colorChange}
                                                                        size="small"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {item.colorsize.map((row, index) => {
                                                                            return (
                                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </td> 
                                                            : <td>{item.color} - {item.size}</td>
                                                    } */}

                                                        {/* <td>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Select</InputLabel>
                                                            <Select

                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value={item.product_batch_id
                                                                }
                                                                label="Age"
                                                                onChange={colorChange}
                                                                size="small"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {item.colorsize.map((row, index) => {
                                                                    return (
                                                                        <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </td> */}

                                                    
                                                       {item.addnew == true ? (
                                                        <td>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Select</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value={item.product_batch_id}
                                                                label="Age"
                                                                onChange={colorChange}
                                                                size="small"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {item.colorsize.map((row, index) => {
                                                                    return (
                                                                        <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </td>) : <td>{item.color} - {item.size}</td>



                                                    }

                                                    




                                                        {/* <td >
                                                    <TextField autoComplete="off" onChange={(event, value) => this.inputHandler(event.target.value, index)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                                    {this.state.selectedIndex == index && this.state.textLength.length >= 1  ? 
                                                    <div ref={this.myRef} className="develop">sacdscsd</div>: null
                                                    }
                                                    </td> */}
                                                        {/* <td> */}
                                                            {/* <input onFocus={() => indexChange(index)} style={{ width: 100, border: 0, outline: 0 }} autoFocus autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} /> */}

                                                            {/* {hasResults && selectedIndex == index && (
                                                                <div className="autocomplete">
                                                                    <ul ref={resultsRef} className="people scrolling-div">
                                                                        {productlist.map(item => {
                                                                            return (
                                                                                item.product_fetchiscombo == 0 ? 
                                                                                item.products.map(result => {
                                                                                    return (
                                                                                    // <li key={result.url}>
                                                                                    //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                    //         index, 
                                                                                    //         result.pb_id,
                                                                                    //         result.product_refcode,
                                                                                    //         result.product_name,
                                                                                    //         result.pricelevel_name,
                                                                                    //         "1",
                                                                                    //         result.unit_name,
                                                                                    //         result.pb_salesrate,
                                                                                    //         result.pb_salesrate,
                                                                                    //         0,
                                                                                    //         result.pb_salesrate,
                                                                                    //         result.pricelevel_percentage,
                                                                                    //         result.pb_stock,
                                                                                    //         0,
                                                                                    //         result.color_name,
                                                                                    //         result.size_name,
                                                                                    //         result
                                                                                    //         )}>
                                                                                    //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                                    //     </a>
                                                                                    // </li>
                                                                                    <li key={item.url}>
                                                                                        <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                            index, 
                                                                                            item.products_id,
                                                                                            item.product_refcode,
                                                                                            item.product_name,
                                                                                            item.products,
                                                                                            0
                                                                                            )}>
                                                                                        {item.product_refcode} - { item.product_name }
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                                }) : 
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        index, 
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.pricelevel_name,
                                                                                        "1",
                                                                                        item.unit_name,
                                                                                        item.product_comboprice,
                                                                                        item.product_comboprice,
                                                                                        0,
                                                                                        item.product_comboprice,
                                                                                        item.pricelevel_percentage,
                                                                                        item.product_combostock,
                                                                                        1
                                                                                        )}>
                                                                                    {item.product_refcode} - { item.product_name }
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            )} */}
                                                        {/* </td> */}
                                                        {/* <td ><input autocomplete="off" value={item.inputbarcode} style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" onFocus={() => indexChange(index)} */}
                                                            {/* onKeyDown={(event) => { if (event.key === 'Enter') { handleBarcodechange(event, index, "barcode") } }} /> </td> */}

                                                        {/* <td>
                                                            {item.barcode}
                                                        </td> */}

                                                        
                                                    <td>
                                                        {/* {item.sales_iscombo == 0 || item.sales_iscombo == null ? ( */}
                                                            {/* // <div> */}
                                                                {/* // {item.addnew ? ( */}
                                                                    <td ><input autocomplete="off" value={item.inputbarcode} style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" onFocus={() => indexChange(index)}
                                                                        onKeyDown={(event) => { if (event.key === 'Enter') { handleBarcodechange(event, index, "barcode") } }} /> </td>
                                                                {/* // ) : ( */}
                                                                    {/* // <p>{item.pb_inter_barcode}</p> */}
                                                                {/* // )} */}
                                                            {/* // </div> */}
                                                        {/* // ) : null} */}
                                                    </td>

                                                        
                                                    <td>
                                                        {
                                                            <input style={{ width: 150, border: 0, outline: 0 }} autocomplete="off" ref={inputRef} type="text" name="query" value={item.productname} onChange={(event) => handleProductchange(event, index, "name")} />
                                                        }
                                                        {hasResultsProduct && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ?
                                                                                item.products.map(result => {
                                                                                    return (
                                                                                        <li key={item.url}>
                                                                                            <a href="javascript:void(0)" onClick={() => listClickname(
                                                                                                 index,
                                                                                                 item.products_id,
                                                                                                item.product_inter_barcode,

                                                                                                //  result.pb_inter_barcode,
                                                                                                 result.pb_id,
                                                                                                 item.product_refcode,
                                                                                                 item.product_name,
                                                                                                 item.products,
                                                                                                 0
                                                                                                
                                                                                            )}>
                                                                                                {item.product_name} -  {item.product_refcode}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }) :
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        index,
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.pricelevel_name,
                                                                                        "1",
                                                                                        item.unit_name,
                                                                                        item.product_comboprice,
                                                                                        item.product_comboprice,
                                                                                        0,
                                                                                        item.product_comboprice,
                                                                                        item.pricelevel_percentage,
                                                                                        item.product_combostock,
                                                                                        1
                                                                                    )}>
                                                                                        {item.product_refcode} - {item.product_name}
                                                                                    </a>
                                                                                </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {/* <p style={{ width: 300 }}>{item.productname}</p> */}
                                                    </td>

                                     
                                                        <td>{item.pd_qty}</td>
                                                        <td>
                                                            <input style={{ width: 50, border: 0, outline: 0 }} autocomplete="off" type="number" name="query" value={item.pd_qty} onChange={(event) => qtyChange(event, index)} />
                                                        </td>
                                                        <td>{item.unit}</td>
                                                        <td>
                                                            <input style={{ width: 75, border: 0, outline: 0 }} autocomplete="off" type="number" name="query" value={item.pd_purchase_price} onChange={(event) => priceChange(event, index)} />
                                                        </td>
                                                        <td>
                                                            {item.calculated_tax}
                                                        </td>
                                                        <td>
                                                            {item.calculated_price}
                                                        </td>
                                                        {/* <td>
                                                            {item.calculated_totalprice}
                                                        </td> */}
                                                        <td>{parseFloat(item.pd_total_amount).toFixed(2)}</td>
                                                        <td>
                                                            {parseFloat(item.salesrate).toFixed(2)}
                                                        </td>
                                                        {/* <td style={{width: 200}}> */}
                                                        {/* <div style={{width: 200}}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                value={expiry}
                                                                onChange={(newValue, index) => {
                                                                    ExpiryChange(newValue, index);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div> */}
                                                        {/* {item.expiry} */}
                                                        {/* </td> */}
                                                        <td style={{ width: 0 }}><Button onClick={() => deleteRow(index, item.pd_id)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant="contained">< AiFillMinusCircle color="#fc0303" fontSize={30} style={{ marginRight: 10 }} /></Button></td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} style={{ marginTop: 30 }}>
                                    <Button onClick={onSave} style={{ backgroundColor: '#92c99f', borderRadius: 20 }} variant="contained"><HiSave color="White" fontSize={20} style={{ marginRight: 10 }} />Save</Button>
                                    {/* <Button style={{backgroundColor: '#92c99f', marginLeft: 20, borderRadius: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#92c99f', marginLeft: 20, borderRadius: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button> */}
                                    {/* <Button className='btn1' onClick={purchaseDetails} style={{ marginLeft: 20, borderRadius: 20 }} variant="contained"><GiCash color="White" fontSize={20} style={{ marginRight: 10 }} />Find</Button> */}
                                </Col>
                                <Col xs={12} md={6} style={{ marginTop: 30 }}>
                                    <Button onClick={() => addRow()} className='return'
                                    //  style={{ float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20 }}
                                      variant="contained">Add New</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Paymode</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={paymentmode}
                                                    label="Age"
                                                    onChange={paymodeChange}
                                                    size="small"
                                                >
                                                    <MenuItem value="cash">Cash</MenuItem>
                                                    <MenuItem value="bank">Credit</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField label="Tax" disabled value={totaltax.toFixed(2)} onChange={(e) => setPayable(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField label="Amount" disabled value={price.toFixed(2)} onChange={(e) => setPayable(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField label="Total" value={payable.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField label="Discount %" autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined" size="small" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField label="Other Charges" autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} value={otherCharges} id="outlined-basic" variant="outlined" size="small" />
                                            </FormControl>
                                        </Col>
                                        {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Roundoff
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={roundoff} onChange={(e) => roundOffchange(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col> */}
                                        <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField label="Grand Total" disabled onChange={(e) => setGrandtotal(e.target.value)} value={grandtotal.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                            </FormControl>
                                            <p style={{ color: 'red' }}>Account Bal: {accountbal}</p>
                                        </Col>
                                        {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Landing Cost</p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField onChange={(e) => setLandingCost(e.target.value)} value={landingCost} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col> */}
                                    </Row>

                                </Col>

                            </Row>
                        </div>
                    }
                </div>
            </Paper>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Purchase Order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={searchsupplier}
                                    label="Age"
                                    onChange={searchsupplierChange}
                                    size="small"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {supplierList.map((row, index) => {
                                        return (
                                            <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField onChange={(e) => setSearchinvoice(e.target.value)} value={searchinvoice} placeholder='Invoice Number' id="outlined-basic" variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12}>
                            <Table hover responsive bordered className="section-to-print">
                                <thead>
                                    <tr>
                                        <th>Request Invoice</th>
                                        <th>Date</th>
                                        <th>Supplier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseorderlist.map(result => {
                                        return (
                                            <tr style={{ cursor: "pointer" }} onClick={() => purchaseOrderclick(result.purchaseorder_id,
                                                result.purchaseorder_invoice, result.purchaseorder_date)}>
                                                <td>{result.purchaseorder_invoice}</td>
                                                <td>{result.purchaseorder_date}</td>
                                                <td>{result.supplier_name}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {/* <Row>
                    <Col xs={12}>
                        <ul>
                        {purchaseorderlist.map((row, index) => {
                            return (
                                <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => purchaseOrderclick(row.purchaseorder_id,
                                    row.purchaseorder_invoice, row.purchaseorder_date)}>
                                    <li className="pay-lists">{row.purchaseorder_invoice}</li>
                                </a>
                            )}
                            )
                            }
                        </ul>
                    </Col>
                    
                </Row> */}
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={productmodal}
                onHide={() => setProductModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined" size="small" label="Barcode" />
                        </Col>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined" size="small" label="Product Name" />
                        </Col>
                        {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} >
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Ref Code</th>
                                        <th>Barcode</th>
                                        <th>Product Name</th>
                                        <th>Stock</th>
                                        <th>Sales Price</th>
                                    </tr>
                                </thead>
                                {/* {productlistitem.length == 0 ? */}
                                <tbody>
                                    {lookupproductlist?.map((column) => (
                                        <tr>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_refcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }} >{column.pb_inter_barcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_name}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.pb_stock}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.pb_salesrate}</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Purchase;







import '../Tax-Settings/TaxSettings.css';
import React, { Component, useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal, Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import './sales.css'
// import { usePreviousMonthDisabled } from '@mui/lab/internal/pickers/hooks/date-helpers-hooks';
import { useHistory, useParams } from "react-router-dom";


const columns = [
    { id: '1', label: 'SI No.', minWidth: 50, span: false },
    { id: '2', label: 'Tax Name', minWidth: 50, span: false },
    { id: '3', label: 'Tax %', minWidth: 50, span: false },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        span: true
    },
];

const rows = [
    {
        company: 'Lorem Ipsum',

    },
    {
        company: 'Lorem Ipsum',
    },
    {
        company: 'Lorem Ipsum',
    },
];

function Salesreplacement(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [salesid, setSalesid] = useState("")

    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [tableRow, setTableRow] = React.useState([{ addnew: true, key: "", product_batch_id: "", product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", taxrate: "", taxtype: "", calculated_tax: "", calculated_price: "", calculated_total: 0, calculated_totalprice: 0, price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "" }] }])
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [editmode, setEditmode] = React.useState(false);
    // const [productname, setProductname] = React.useState("")
    // const [selectedIndex, setSelectedindex] = React.useState("")


    // api values
    const [diffamount, setDiffamount] = React.useState(0);

    const [amount, setAmount] = React.useState(0);
    const [amounttotal, setAmounttotal] = React.useState(0);
    const [customerList, setCustomerlist] = React.useState([])
    const [customer, setCustomer] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");
    const [customerpricename, setCustomerpricename] = React.useState("");
    const [salesorderid, setSalesorderid] = React.useState("");

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(0);
    const [advance, setAdvance] = React.useState('');

    const [payable, setPayable] = React.useState('');
    const [roundoff, setRoundoff] = React.useState(0);


    const [customername, setCustomername] = React.useState('');
    const [building, setBuilding] = React.useState('');
    const [area, setArea] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [ordertype, setOrdertype] = React.useState('');
    const [street, setStreet] = React.useState('');

    const [dummytotal, setDummytotal] = React.useState(0);
    const [tax, setTax] = React.useState(0);

    const [price, setPrice] = React.useState(0);



    const [deletedAmount, setDeletedamount] = React.useState(0);

    const [totalaftervariation, setTotalaftervariation] = React.useState(0);




    const [errormsg, seterrormsg] = React.useState('');

    const [productlist, setProductlist] = React.useState([]);

    const [colorlist, setColorlist] = React.useState([]);
    const [color, setColor] = React.useState("")

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const { id } = useParams()

    const [reference, setReference] = React.useState("");
    const [zone, setZone] = React.useState("");


    const [deliverycharge, setDeliverycharge] = React.useState(0);

    const [salesorderlist, setSalesorderlist] = React.useState([]);
    const [salesorderno, setSalesorderno] = React.useState("");

    const [searchinvoice, setSearchinvoice] = React.useState("");
    const [searchmobile, setSearchmobile] = React.useState("");

    const [orderpopup, setOrderpopup] = React.useState(false);

    const [variationamount, setVariationamount] = React.useState(0);
    const [grandtotalex, setGrandtotalex] = React.useState(0);

    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();

    let history = useHistory();


    // comboproductadd

    const [combotable, setCombotable] = React.useState([{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }]);
    const [comboproductlist, setComboproductlist] = React.useState([]);
    const [combobatchlist, setCombobatchlist] = React.useState([{ key: "", value: [] }]);
    const [cbproduct, setCbproduct] = React.useState("");
    const [popupindex, setPopupindex] = React.useState("");
    const [combopopupid, setCombopopupid] = React.useState("");

    // const [, setProductlist] = React.useState([]);



    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const billtypeChange = (event) => {
        setOrdertype(event.target.value);
    };

    const cbproductChange = (event, index) => {
        const input = [...combotable]
        input[index].productid = event.target.value
        setCombotable(input)
        getBatchlist(event.target.value, index)
    }

    const cbbatchChange = (event, index) => {
        const input = [...combotable]
        input[index].fetch_id = event.target.value
        console.log("bachid", event.target.value)
        setCombotable(input)
        getProductbybatch(event.target.value, index)
    }

    const colorChange = (event) => {
        var input = [...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        setTableRow(input)

        getProductbybatch1(event.target.value, selectedIndex)
        //   setColor(event.target.value)
    }

    const getProductbybatch = (id, index) => {

        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                var comborow = [...combotable]
                comborow[index].color = responseJson.data.color_name
                comborow[index].size = responseJson.data.size_name
                comborow[index].stock = responseJson.data.pb_stock
                setCombotable(comborow)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getProductbybatch1 = (id, index) => {

        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                setDetails(id, index, responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        console.log(input)
        input[index].key = index
        input[index].product_batch_id = id

        input[index].pb_stock = responseJson.pb_stock
        input[index].sod_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name

        input[index].price = responseJson.pb_salesrate
        // input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0

        input[index].taxrate = responseJson.product_taxrate
        input[index].taxtype = responseJson.product_taxtype

        if (responseJson.product_taxtype == 1) {
            const price = (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(responseJson.pb_salesrate) - (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))).toFixed(2);

            input[index].calculated_tax = tax;
            input[index].calculated_price = price;

            const total = (((parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))) + (parseFloat(responseJson.pb_salesrate) - (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)))).toFixed(2);
            input[index].calcprice = total;
            input[index].sod_total_amount = total;
        }
        else if (responseJson.product_taxtype == 2) {
            const tax = (parseFloat(responseJson.pb_salesrate) * (parseFloat(responseJson.product_taxrate) / 100)).toFixed(2);
            const price = (parseFloat(responseJson.pb_salesrate)).toFixed(2)


            input[index].calculated_tax = tax;
            input[index].calculated_price = price;
            const total = ((parseFloat(responseJson.pb_salesrate) * (parseFloat(responseJson.product_taxrate) / 100)) + (parseFloat(responseJson.pb_salesrate))).toFixed(2);
            input[index].calcprice = total;
            input[index].sod_total_amount = total;
            console.log(tax)
            console.log(price)

        }
        else {
            const Price = (parseFloat(responseJson.pb_salesrate)).toFixed(2)
            // input[index].calculated_price = Price;
            // input[index].calculated_totalprice = Price;
            input[index].calcprice = Price;
            input[index].sod_total_amount = Price;
            console.log("set tax");

        }

        // input[index].sod_total_amount = responseJson.pb_salesrate



        console.log("input", input)
        setTableRow(input)
        // setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        // setCode("")

        // var total1 = total + responseJson.pb_salesrate
        // console.log("totalcalc", total1)
        // setTotal(total1)
    }

    const getBatchlist = (id, index) => {
        console.log("id", id)
        console.log("id", combopopupid)
        var data = {
            "products_id": id,
            "products_id_master": combopopupid
        }
        console.log("data", data)
        fetch(global.url + "getProductbyIdData", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("product list", responseJson)
                var input = [...combobatchlist]
                input[index].value = responseJson.data
                setCombobatchlist(input)

                var comborow = [...combotable]
                comborow[index].product_name = responseJson.master.product_name
                comborow[index].fetch_quantity = 1
                comborow[index].quantity = responseJson.master.fc_product_quantity
                setCombotable(comborow)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const userChange = (event) => {
        setSalesman(event.target.value);
    };

    const addRow = () => {
        setTableRow([...tableRow, { addnew: true, key: "", product_batch_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", calculated_total: 0, discount: "", calculated_price: "", calculated_tax: "", calculated_totalprice: "", sod_total_amount: "", pb_stock: "", sales_iscombo: "", sod_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
    }

    const customerChange = (event) => {
        setCustomer(event.target.value);
        getCustomerbyid(event.target.value)
        getCustomerbalance(event.target.value)
    };

    const getCustomerbalance = (id) => {
        var data = {
            "customer_id": id
        }
        fetch(global.url + "viewCustomerBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    console.log("customer Balance", responseJson)
                    setBalance(responseJson.opening_balance)
                    setLimitbalance(responseJson.remain_balance)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCustomerbyid = (id) => {
        var data = {
            "customer_id": id
        }
        fetch(global.url + "getCustomerbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("one customer", responseJson)

                // setCreditlimit(responseJson.data.customer_credit_limit)
                // setAddress(responseJson.data.customer_address1)
                // setMobile(responseJson.data.customer_mobile)
                // setCustomerpricelevel(responseJson.data.pricelevel_percentage)
                // setCustomerpricename(responseJson.data.pricelevel_name)
                // setTableRow([{key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",fetch_quantity: "", quantity: "",  stock: ""}] }])
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const inputHandler = (text, key) => {
        // setSelectedindex(key)
        // setProductname(text)
        // console.log("text", text)
        // const _inputs = [...example];
        // _inputs[key].value = text;
        // _inputs[key].key   = key;
        // console.log("inputs", _inputs)
        // setExample(_inputs)
    }

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;

        const resultsItems = Array.from(resultsRef.current.children)

        const activeResultIndex = resultsItems.findIndex(child => {
            return child.querySelector('a') === document.activeElement;
        });

        if (isUp) {
            console.log('Going up!');
            if (inputIsFocused) {
                resultsItems[resultsItems.length - 1].querySelector('a').focus();
            } else if (resultsItems[activeResultIndex - 1]) {
                resultsItems[activeResultIndex - 1].querySelector('a').focus();
            } else {
                inputRef.current.focus();
            }
        }

        if (isDown) {
            console.log('Going down!')
            if (inputIsFocused) {
                resultsItems[0].querySelector('a').focus();
            } else if (resultsItems[activeResultIndex + 1]) {
                resultsItems[activeResultIndex + 1].querySelector('a').focus();
            } else {
                inputRef.current.focus();
            }
        }
    }

    function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;

        const resultsItems = Array.from(resultsRef.current.children)

        const activeResultIndex = resultsItems.findIndex(child => {
            return child.querySelector('a') === document.activeElement;
        });

        if (isUp) {
            console.log('Going up!');
            if (inputIsFocused) {
                resultsItems[resultsItems.length - 1].querySelector('a').focus();
            } else if (resultsItems[activeResultIndex - 1]) {
                resultsItems[activeResultIndex - 1].querySelector('a').focus();
            } else {
                inputRef1.current.focus();
            }
        }

        if (isDown) {
            console.log('Going down!')
            if (inputIsFocused) {
                resultsItems[0].querySelector('a').focus();
            } else if (resultsItems[activeResultIndex + 1]) {
                resultsItems[activeResultIndex + 1].querySelector('a').focus();
            } else {
                inputRef1.current.focus();
            }
        }
    }

    useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()

        if (id == "add") {
            getInvoiceno()
            setEditmode(false)
            setSalesid("")
        }
        else {
            getDetailsbyid(id)
            setEditmode(true)
            setSalesid("")
        }
    }, []);

    useEffect(() => {
        getCustomer()

    }, []);

    useEffect(() => {
        getTotalamount()
    }, [tableRow, discount, roundoff, otherCharges, deliverycharge]);

    useEffect(() => {
        setPayable(grandtotal)
    }, [grandtotal]);




    // const getTotalamount = () => {
    //     var totalAmount = 0;
    //     var totalAmount1 = 0;
    //     for (var i = 0; i < tableRow.length; i++) {

    //         totalAmount += parseInt(tableRow[i].sod_total_amount)
    //         if (tableRow[i].sod_id == "" && tableRow[i].product_batch_id != "") {
    //             totalAmount1 += parseInt(tableRow[i].sod_total_amount)
    //         }
    //     }
    //     setAmount(totalAmount)
    //     console.log(totalAmount)
    //     var disc = (totalAmount / 100) * discount
    //     console.log("amount edit", deliverycharge)
    //     setGrandtotal(totalAmount - disc + parseFloat(otherCharges) - parseFloat(roundoff))
    //     setGrandtotalex(totalAmount - disc + parseFloat(otherCharges) - parseFloat(roundoff))
    //     setPayable(totalAmount - disc + parseFloat(otherCharges) - parseFloat(roundoff))
    //     setVariationamount(totalAmount1 - deletedAmount)
    //     setTotalaftervariation(totalAmount1 - deletedAmount + parseInt(deliverycharge))
    //     // var data = (totalAmount+otherCharges) - dummytotal
    //     // setVariationamount(data)
    // }

    const getTotalamount = () => {
        var totalAmount = 0;
        var totalAmount1 = 0;
        var totalprice = 0;
        var totaltax = 0;
        for (var i = 0; i < tableRow.length; i++) {
            console.log("tableRow[i].salesorder_isreplacedone", tableRow[i].salesorder_isreplacedone)
            if (tableRow[i].salesorder_isreplacedone = 1 || tableRow[i].taxtype== 1 ) {
                console.log("this 123")
                console.log(tableRow[i],'set22')
                console.log(tableRow[i].calculated_price)
                const price = (parseFloat(totalprice) / ((parseFloat(tableRow[i].taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(tableRow[i].taxrate) / 100) + 1))).toFixed(2);
                totalAmount += parseFloat(tableRow[i].sod_total_amount)
                totalprice += parseFloat(price)
                totaltax += parseFloat(tax)
                console.log(tableRow[i].calculated_price,'777');
            }
            // else if (tableRow[i].sod_iscancel != 1) {
                else if (tableRow[i].taxtype == 2) {
                console.log("this 124")

                console.log(tableRow[i])
                console.log(tableRow[i].calculated_price)
                totalAmount += parseFloat(tableRow[i].sod_total_amount)
                totalprice += parseFloat(tableRow[i].calculated_price)
                totaltax += parseFloat(tableRow[i].calculated_tax)
                console.log(tableRow[i].sod_total_amount,'set111');

            }
            else if (tableRow[i].sod_iscancel = 1) {
                console.log("this 14")

                console.log(tableRow[i])
                console.log(tableRow[i].calculated_price)
                totalAmount += parseFloat(tableRow[i].sod_total_amount)
                totalprice += parseFloat(tableRow[i].calculated_price)
                totaltax += parseFloat(tableRow[i].calculated_tax)
                console.log(tableRow[i].sod_total_amount,'set121')

            }


            if (tableRow[i].sod_id == "" && tableRow[i].product_batch_id != "") {
                console.log("this state is called");
                console.log("this 4534")
                totalAmount1 += parseFloat(tableRow[i].sod_total_amount)
            }
        }
        setAmount(totalAmount)
        setPrice(totalprice)
        setTax(totaltax)
        var disc = (totalAmount / 100) * discount
        // console.log("otherCharges", parseFloat(otherCharges))
        // console.log("roundoff", parseFloat(roundoff))
        // console.log("totalAmount", totalAmount-disc+parseFloat(otherCharges)-parseFloat(roundoff))
        setGrandtotal(totalAmount - disc + parseFloat(otherCharges) - parseFloat(roundoff))
        setGrandtotalex(totalAmount - disc + parseFloat(otherCharges) - parseFloat(roundoff))
        setPayable(totalAmount - disc + parseFloat(otherCharges) - parseFloat(roundoff))
        setVariationamount(totalAmount1 + diffamount)
        setTotalaftervariation(totalAmount1 + diffamount + parseFloat(deliverycharge))
        // setVariationamount(totalAmount1 - deletedAmount)
        // setTotalaftervariation(totalAmount1 - deletedAmount + parseInt(deliverycharge))
    }


    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (amount + tax / 100) * event
        var result = amount + tax - disc
        var grandtotal1 = result + parseInt(otherCharges)
        setGrandtotal(grandtotal1)

        setPayable(grandtotal1)
    }

    const roundOffchange = (event) => {
        setRoundoff(event)
        var round = amount + tax - event
        var grandtotal1 = round
        setGrandtotal(grandtotal1)

        setPayable(grandtotal1)
    }

    const otherChargeschange = (event) => {
        setOthercharge(event)
        var disc = (amount + tax / 100) * discount
        var result = (amount + tax - disc) + parseInt(event)
        if (result) {
            setGrandtotal(result)

        }

    }

    const getDetailsbyid = (id) => {
        const data = {
            "sales_id": id,
        }
        fetch(global.url + "viewSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("sales head", responseJson)
                if (responseJson.status == "false") {
                    console.log("sale data", responseJson.data)
                    setSalesid(responseJson.data.sales_id)
                    setCustomer(responseJson.data.sales_customer_fk)
                    setSalesman(responseJson.data.sales_salesman_fk)
                    setBilltype(responseJson.data.sales_bill_type)
                    setInvoiceno(responseJson.data.sales_invoice)
                    setMobile(responseJson.data.sales_mob_no)
                    setRefno(responseJson.data.sales_ref_no)
                    setAddress(responseJson.data.sales_address)
                    setLpono(responseJson.data.sales_lpo_no)
                    var invoiceDate = new Date(responseJson.data.sales_date).toISOString();
                    var lpodate1 = new Date(responseJson.data.sales_lpo_date).toISOString();
                    setDate(invoiceDate)
                    setLpodate(lpodate1)
                    setZone(responseJson.data.sales_zone)
                    setReference(responseJson.data.sales_reference)

                    setQuotation(responseJson.data.sales_qt_no)
                    setBalance(responseJson.data.sales_balance)
                    setLimitbalance(responseJson.data.sales_limit_balance)
                    setCreditlimit(responseJson.data.sales_crdt_limit)
                    setTableRow(responseJson.details)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getInvoiceno = () => {
        fetch(global.url + "salesinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("salesinvoiceno", responseJson.data.invoiceno)
                setInvoiceno(responseJson.data.invoiceno)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)


        var data = {
            "companyid": company,
            "branchid": branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("user response", responseJson)
                setUserlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCustomer = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewCustomer", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.Error == "false") {
                    setCustomerlist(responseJson.data)
                    console.log("response", responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //   useEffect(() => {
    //     var creditbal = creditLimit - balance;
    //     console.log("creditbal", creditbal)
    //     setLimitbalance(creditbal)
    //   }, [creditLimit])

    useEffect(() => {
        if (hasResults) {
            document.body.addEventListener('keydown', onKeyDown);
        } else {
            document.body.removeEventListener('keydown', onKeyDown);
        }

        if (hasResultsProduct) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }


        return () => {
            document.body.removeEventListener('keydown', onKeyDown);
            document.body.removeEventListener('keydown', onKeyDown1);
        }
    }, [hasResults, hasResultsProduct]);

    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        console.log("color1", colorsize1)
        console.log("customer percentege", customerpricelevel)
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        input[index].sales_iscombo = iscombo
        input[index].sod_qty = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size

        console.log("no customer")

        console.log("no customer no per")
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if (discount) {

            var totalcalc = (calcprice / 100) * discount
        }
        else {

            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sod_total_amount = calcprice - totalcalc




        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { addnew: true, key: "", product_batch_id: "", product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")

        var total1 = total + totalcalc
        console.log("totalcalc", total1)
        setTotal(total1)
    }

    const listClickrefcode = (index, productid, productrefcode, productname, products, iscombo) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { addnew: true, key: "", product_batch_id: "", product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")
    }

    const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].productname = name
        input[index].pricelevel = pricelevel
        input[index].sod_qty = qty
        input[index].unit = unit
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount

        input[index].sod_total_amount = calcprice
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { addnew: true, key: "", product_batch_id: "", product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sod_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setProductname("")
    }



    const discountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice / 100) * input[index].discount
        input[index].sod_total_amount = input[index].calcprice - disc
        setTableRow(input)
    }


    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        console.log("type", type)
        getSearch(event.currentTarget.value, type)
    }

    // for calculating quantity * price
    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        console.log(textArray1)
        if (event.target.value > textArray1[index].pb_stock) {
            seterrormsg("Can't enter qty greater than stock")
        }
        else {
            seterrormsg("")
            console.log("price", textArray1[index].price)
            var totalprice = textArray1[index].price * event.target.value
            textArray1[index].sod_qty = event.target.value
            var taxtype = textArray1[index].taxtype;
            var taxrate = textArray1[index].taxrate;

            if (taxtype == 1) {
                const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
                const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);


                textArray1[index].sod_qty = event.target.value;
                textArray1[index].calculated_tax = tax;
                textArray1[index].calculated_price = price;
                const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);

                textArray1[index].calcprice = total
                textArray1[index].sod_total_amount = total
            }
            else if (taxtype == 2) {
                const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
                const price = (parseFloat(totalprice)).toFixed(2)

                textArray1[index].sod_qty = event.target.value
                textArray1[index].calculated_tax = tax;
                textArray1[index].calculated_price = price;
                const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);

                textArray1[index].calcprice = total
                textArray1[index].sod_total_amount = total


            }
            else {
                const Price = (parseFloat(totalprice)).toFixed(2)
                textArray1[index].sod_qty = event.target.value


                textArray1[index].calcprice = Price;
                textArray1[index].sod_total_amount = Price;
                console.log("set tax");
            }


            setTableRow(textArray1)
        }
    }
    // const qtyChange = (event, index) => {
    //     var textArray1 = [...tableRow]
    //     if (event.target.value > textArray1[index].pb_stock) {
    //         seterrormsg("Can't enter qty greater than stock")
    //     }
    //     else {
    //         seterrormsg("")
    //         console.log("price", textArray1[index].price)
    //         var totalprice = textArray1[index].price * event.target.value

    //         var taxtype = textArray1[index].taxtype;
    //         var taxrate = textArray1[index].taxrate;


    //         if (taxtype == 1) {

    //             const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
    //             const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);


    //             textArray1[index].sod_qty = event.target.value;
    //             textArray1[index].calculated_tax = tax;
    //             textArray1[index].calculated_price = price;
    //             const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
    //             textArray1[index].calculated_totalprice = total;
    //             textArray1[index].calculated_total = total;
    //         }
    //         else if (taxtype == 2) {
    //             const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
    //             const price = (parseFloat(totalprice)).toFixed(2)

    //             textArray1[index].sod_qty = event.target.value
    //             textArray1[index].calculated_tax = tax;
    //             textArray1[index].calculated_price = price;
    //             const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
    //             textArray1[index].calculated_totalprice = total;
    //             textArray1[index].calculated_total = total;


    //         }
    //         else {
    //             const Price = (parseFloat(totalprice)).toFixed(2)
    //             textArray1[index].sod_qty = event.target.value
    //             textArray1[index].calculated_price = Price;
    //             textArray1[index].calculated_totalprice = Price;
    //             textArray1[index].calculated_total = Price;
    //             console.log("set tax");
    //         }


    //         textArray1[index].sod_qty = event.target.value
    //         textArray1[index].calcprice = totalprice
    //         textArray1[index].calculated_totalprice = totalprice;
    //         setTableRow(textArray1)
    //     }
    // }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "refcode": text,
                "companyid": company,
                "branchid": branch
            }
        }
        console.log("searchdata", data)

        fetch(global.url + "getSearchOnlyStockprd", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                setProductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onSave = () => {
        if (grandtotal > creditLimit && creditLimit != 0) {
            alert("Credit limit greater than Total")
        }
        else {
            var paymentdate;
            var lpodate1;
            if (editmode == true) {
                paymentdate = date.toISOString().split('T')[0]
                lpodate1 = lpodate.toISOString().split('T')[0]
            }
            else {
                paymentdate = date.toISOString().split('T')[0]
                lpodate1 = lpodate.toISOString().split('T')[0]
            }

            const exampleArray = []
            for (var i = 0; i < tableRow.length; i++) {
                if (tableRow[i].product_batch_id != "" && tableRow[i].sod_id == "") {
                    exampleArray.push(tableRow[i])
                }
                else {

                }
            }
            console.log("exampleArray", exampleArray)
            console.log("tableRow", tableRow)
            var data = {
                "salesorder_id": salesorderid,
                "salesorder_user_id": userId,
                "salesorder_branchid": userbranchid,
                "salesorder_companyid": usercompanyid,
                "salesorder_bill_type": billType,
                "salesorder_ref_no": refno,
                "salesorder_salesman": salesman,
                "salesorder_invoice": invoiceno,
                "salesorder_date": "",
                "salesorder_customer": customer,
                "salesorder_crdt_limit": "",
                "salesorder_balance": "",
                "salesorder_cash_customer": "",
                "salesorder_limit_balance": "",
                "salesorder_discount": "",
                "salesorder_other_charges": "",
                "salesorder_advance": "",
                "salesorder_current_bill": "",
                "salesorder_due_date": "",
                "salesorder_zone": zone,
                "salesorder_reference": reference,
                "salesorder_narration": "",
                "salesorder_price": price,
                "salesorder_tax": tax,
                "salesorder_date": date,
                "salesorder_total": totalaftervariation,
                "salesorder_discount": discount,
                "salesorder_other_charges": otherCharges,
                "salesorder_subtotal": amount,
                "salesorder_roundoff": roundoff,

                "customer_name": customername,
                "customer_building": building,
                "customer_street": street,
                "salesorder_zone": zone,
                "customer_mobile": mobile,
                "customer_whatsapp": ordertype,
                "customer_area": area,
                "customer_remarks": remarks,
                "salesorder_details": exampleArray,
                "variation_amount": variationamount,
                "delivery_charge": deliverycharge,
                "sales_ordertype": 1
            }
            console.log("data", data)
            fetch(global.url + "updateSalesorder", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson)
                    if (responseJson.error == true) {
                        alert(responseJson.data)
                    }
                    else {
                        alert(responseJson.message)
                        const win = window.open('/saleorderinvoice/' + responseJson.data, "_blank");
                        win.focus();
                        window.location.reload()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const addProduct = (id, index, comborows, comboid) => {
        console.log("popupid", comboid)
        setModal(true)
        setCombopopupid(comboid)
        setPopupindex(index)
        var data = {
            "products_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductbyFetchID", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("product list", responseJson)
                setComboproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
        setCombotable(comborows)
    }

    const addComborow = () => {
        setCombotable([...combotable, { key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }])
        setCombobatchlist([...combobatchlist, { key: "", value: [] }])
    }

    const addtoTablerow = () => {
        var counts = [];
        const exampleArray = []
        for (var i = 0; i < combotable.length; i++) {
            exampleArray.push(combotable[i].productid)

        }

        for (var i = 0; i < combotable.length; i++) {
            counts.push({ value: "", count: 0 })
            var input = [...counts]
            input[i].value = combotable[i].productid
            exampleArray.forEach((v) => (v === combotable[i].productid && counts[i].count++));
        }

        for (var i = 0; i < counts.length; i++) {
            for (var j = 0; j < combotable.length; j++) {

                if (counts[i].value == combotable[j].productid) {
                    console.log("if", i)
                    if (counts[i].count > combotable[j].quantity) {
                        console.log("2if")
                        alert("greater than max quantity - " + combotable[j].product_name)
                        return;
                    }
                }

            }
        }
        console.log("combotable", combotable)
        console.log("exampleArray", exampleArray)


        var input = [...tableRow]
        console.log("combotable", combotable)
        input[popupindex].fetch_products = combotable
        setTableRow(input)
        console.log("tableRow", tableRow)
        setModal(false)
        setCombotable([{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }])
    }

    const deletePopupitem = (index) => {
        console.log("delete index", index)
        var input = [...combotable]
        input.splice(index, 1)
        setCombotable(input)
    }

    const onPopup = () => {
        setOrderpopup(true)
        // var userdata = JSON.parse(localStorage.getItem("user"))
        // var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid": company,
        }
        console.log("data", data)
        fetch(global.url + "viewSalesforReplace", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("sales order response", responseJson)
                if (responseJson.error == true) {
                    setSalesorderlist([])
                }
                else {
                    setSalesorderlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const salesOrderclick = (id, mode) => {
        console.log("id", id)
        if (mode == "") {
            var data = {
                "companyid": usercompanyid,
                "sales_id": id,
                "type": usertype
            }
            console.log("data1", data)
            fetch(global.url + "viewSaleDetailforReplace", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setOrderpopup(false)
                    console.log("sales order response", responseJson)
                    setSalesid(id)
                    setCustomername(responseJson.data.customer_name)
                    setBuilding(responseJson.data.customer_building)
                    setArea(responseJson.data.customer_area)
                    setRemarks(responseJson.data.customer_remarks)
                    setOrdertype(responseJson.data.customer_whatsapp)
                    setStreet(responseJson.data.customer_street)
                    setZone(responseJson.data.sales_zone)
                    setMobile(responseJson.data.customer_mobile)
                    setLpono(responseJson.data.sales_invoice)
                    setDeletedamount(responseJson.data.sales_return_total)
                    setDiscount(responseJson.data.sales_discount)
                    setRoundoff(responseJson.data.sales_roundof)
                    setOthercharge(responseJson.data.sales_other_charges)

                    if (responseJson.data.sales_details) {
                        setTableRow(responseJson.data.sales_details)

                        var tabledata = responseJson.data.sales_details
                        var totalAmount = 0;
                        for (var i = 0; i < tabledata.length; i++) {
                            totalAmount += parseInt(tabledata[i].sod_total_amount)
                        }
                        setDummytotal(totalAmount)

                    }
                    else {
                        setTableRow([])
                    }

                    setReference(responseJson.data.sales_reference)
                    setSalesman(responseJson.data.sales_salesman)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            var data = {
                "companyid": usercompanyid,
                "sales_id": id,
                "type": usertype
            }
            console.log("data2", data)
            fetch(global.url + "viewSaleDetailforReplace", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setOrderpopup(false)
                    console.log("sales order response", responseJson)
                    setSalesid(id)
                    setCustomername(responseJson.data.customer_name)
                    setBuilding(responseJson.data.customer_building)
                    setArea(responseJson.data.customer_area)
                    setRemarks(responseJson.data.customer_remarks)
                    setOrdertype(responseJson.data.customer_whatsapp)
                    setStreet(responseJson.data.customer_street)
                    setZone(responseJson.data.sales_zone)
                    setMobile(responseJson.data.customer_mobile)
                    setLpono(responseJson.data.sales_invoice)
                    setDeletedamount(responseJson.data.sales_return_total)
                    setDiscount(responseJson.data.sales_discount)
                    setRoundoff(responseJson.data.sales_roundof)
                    setOthercharge(responseJson.data.sales_other_charges)
                    if (responseJson.data.sales_details) {
                        setTableRow(responseJson.data.sales_details)
                        var deleteamt = responseJson.data.replaced_amount - responseJson.data.cancelled_products
                        setDiffamount(deleteamt)

                    }
                    else {
                        setTableRow([])
                        var deleteamt = responseJson.data.replaced_amount - responseJson.data.cancelled_products
                        setDiffamount(deleteamt)
                    }

                    setReference(responseJson.data.sales_reference)
                    setSalesman(responseJson.data.sales_salesman)
                })
                .catch((error) => {
                    console.log(error)
                })
        }


    }

    const deliveryChargechange = (event) => {
        setDeliverycharge(event)
    }

    const deleteRow = (index, price, sodid) => {
        if (sodid == "") {
            console.log("index", index)
            setTotal(total - price)
            var input = [...tableRow]
            input.splice(index, 1)
            console.log("input", input)
            setTableRow(input)
        }
        else {
            var data = {
                "sd_id": sodid,
                "companyid": usercompanyid,
                "user_id": userId,
                "mode": "add"
            }
            console.log("data", data)
            fetch(global.url + "SalesReplaceProducts", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson)
                    salesOrderclick(salesid, "add")

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const getdata2 = (search) => {
        setSearchmobile(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "mobile": search
        }
        fetch(global.url + "viewSalesforReplace", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("user response", responseJson)
                setSalesorderlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getdata3 = (search) => {
        setSearchinvoice(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "invoice": search
        }
        fetch(global.url + "viewSalesforReplace", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("user response", responseJson)
                setSalesorderlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }



    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ margin: 20 }}>
                    <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Sales Replacement</p>
                    <div>
                        <Row>
                            {/* <Col xs ={12} style={{marginTop:30}}>
                            <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20}} variant="contained"><GoSettings  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Settings</Button>
                            <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20}} variant="contained"><BiRefresh  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Refresh</Button>
                        </Col> */}
                        </Row>
                        <Row style={{ marginTop: 30 }}>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={12} md={2}>
                                        <p>Order Type</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        {
                                            global.proname == "Fetch" ?
                                                <FormControl sx={{ width: '100%' }}>
                                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Order Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={ordertype}
                                                        label="Age"
                                                        onChange={billtypeChange}
                                                        size="small"
                                                    >
                                                        <MenuItem value="facebook">Facebook</MenuItem>
                                                        <MenuItem value="whatsap">Whatsap</MenuItem>
                                                        <MenuItem value="Fetch.qatar">Fetch.qatar</MenuItem>
                                                        <MenuItem value="Fetch qatar shopee">Fetch qatar shopee</MenuItem>
                                                        <MenuItem value="Fetch qatar online">Fetch qatar online</MenuItem>
                                                        <MenuItem value="Fashion casual shoe">Fashion casual shoe</MenuItem>
                                                        <MenuItem value="Fetch.qa">Fetch.qa</MenuItem>
                                                    </Select>
                                                </FormControl> :
                                                <FormControl sx={{ width: '100%' }}>
                                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Order Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={ordertype}
                                                        label="Age"
                                                        onChange={billtypeChange}
                                                        size="small"
                                                    >
                                                        <MenuItem value="facebook">Facebook</MenuItem>
                                                        <MenuItem value="whatsap">Whatsap</MenuItem>
                                                    </Select>
                                                </FormControl>
                                        }

                                    </Col>
                                    <Col xs={12} md={2}>
                                        <p>Customer</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled autoComplete="off" onChange={(e) => setCustomername(e.target.value)} value={customername} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    {/* <Col xs={12} md={2}>
                                        <p>Invoice</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField value={invoiceno} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col> */}
                                    <Col xs={12} md={2}>
                                        <p>Date</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Col>
                                </Row>
                                {/* <Row style={{marginTop: 20}}>
                                    <Col xs={12} md={2}>
                                        <p>Customer</p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormControl sx={{width: '100%' }}>
                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Customer</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={customer}
                                                        label="Age"
                                                        onChange={customerChange}
                                                        size="small"
                                                    >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {customerList.map((row, index) => {
                                                        return (
                                                        <MenuItem value={row.customer_id}>{row.customer_name}</MenuItem>
                                                    )})}
                                                    </Select>
                                            </FormControl>
                                            {customerpricename != "" && customerpricename != null  ? 
                                            <p style={{fontSize: 10, marginBottom: 'unset', color: 'red'}}>This customer has pricelevel: {customerpricename}</p>: null    
                                        }
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <a href={'/accounts/customer'} >
                                            <Button variant="contained" >Add</Button>
                                        </a>
                                    </Col>
                                </Row> */}

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs={12} md={2}>
                                        <p>Mob No.</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField onChange={(e) => setMobile(e.target.value)} value={mobile} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <p>Building No.</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField onChange={(e) => setBuilding(e.target.value)} value={building} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs={12} md={2}>
                                        <p>Street.</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField onChange={(e) => setStreet(e.target.value)} value={street} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <p>Sales Invoice No.</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }} onClick={onPopup}>
                                            <TextField className="cursor-pointer" autoComplete="off" onChange={(e) => setLpono(e.target.value)} value={lpono} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                {/* <Row>
                                        <Col xs={12} md={2}>
                                            <p>Salesman</p>
                                        </Col>
                                        <Col xs={12} md={10}>
                                            <FormControl sx={{width: '100%' }}>
                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Salesman</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={salesman}
                                                            label="Age"
                                                            onChange={userChange}
                                                            size="small"
                                                        >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {userList.map((row, index) => {
                                                            return (
                                                            <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                                        )})}
                                                        </Select>
                                                </FormControl>
                                        </Col>
                                    </Row> */}
                                {/* <Row style={{marginTop: 20}}>
                                        <Col xs={12} md={2}>
                                            <p>LPO No.</p>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField onChange={(e) => setLpono(e.target.value)} value={lpono} id="outlined-basic" variant="outlined"  size="small"/>
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <p>LPO Date</p>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={lpodate}
                                                    onChange={(newValue) => {
                                                    setLpodate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Col>
                                        </Row> */}
                                <Row>
                                    <Col xs={12} md={4}>
                                        <p>Delivery Boy</p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={salesman}
                                                label="Age"
                                                onChange={userChange}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {userList.map((row, index) => {
                                                    return (
                                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col xs={12} md={4}>
                                        <p>Zone
                                        </p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField onChange={(e) => setZone(e.target.value)} value={zone} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs={12} md={4}>
                                        <p>Area </p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField value={area} onChange={(e) => setArea(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs={12} md={4}>
                                        <p>Remarks</p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField value={remarks} onChange={(e) => setRemarks(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                </Row>
                                {/* <Row style={{marginTop: 20}}>
                                            <Col xs={12} md={4}>
                                                <p>Limit Balance
                                                </p>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField value={limitbalance} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col>
                                            
                                        </Row>
                                        */}
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs={12} md={4}>
                                        <p>Reference
                                        </p>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField onChange={(e) => setReference(e.target.value)} value={reference} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12}>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>SlNo</th>
                                            <th>Code</th>
                                            <th>color-size</th>
                                            <th>Product name</th>
                                            <th>Qty</th>
                                            <th>Unit</th>
                                            <th>unit Price</th>
                                            <th>Tax</th>
                                            <th>price</th>
                                            <th>Calc Price</th>
                                            <th>Discount(%)</th>
                                            <th>Amount</th>
                                            <th>Close</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRow.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td >{index + 1}</td>
                                                    {/* <td >
                                                            <TextField autoComplete="off" onChange={(event, value) => this.inputHandler(event.target.value, index)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                                            {this.state.selectedIndex == index && this.state.textLength.length >= 1  ? 
                                                            <div ref={this.myRef} className="develop">sacdscsd</div>: null
                                                            }
                                                            </td> */}
                                                    <td>
                                                        {
                                                            item.addnew == false ?
                                                                <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} /> :
                                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                        }

                                                        {hasResults && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ?
                                                                                item.products.map(result => {
                                                                                    return (
                                                                                        // <li key={result.url}>
                                                                                        //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        //         index, 
                                                                                        //         result.pb_id,
                                                                                        //         result.product_refcode,
                                                                                        //         result.product_name,
                                                                                        //         result.pricelevel_name,
                                                                                        //         "1",
                                                                                        //         result.unit_name,
                                                                                        //         result.pb_salesrate,
                                                                                        //         result.pb_salesrate,
                                                                                        //         0,
                                                                                        //         result.pb_salesrate,
                                                                                        //         result.pricelevel_percentage,
                                                                                        //         result.pb_stock,
                                                                                        //         0,
                                                                                        //         result.color_name,
                                                                                        //         result.size_name,
                                                                                        //         result
                                                                                        //         )}>
                                                                                        //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                                        //     </a>
                                                                                        // </li>
                                                                                        <li key={item.url}>
                                                                                            <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                                index,
                                                                                                item.products_id,
                                                                                                item.product_refcode,
                                                                                                item.product_name,
                                                                                                item.products,
                                                                                                0
                                                                                            )}>
                                                                                                {item.product_refcode} - {item.product_name}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }) :
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        index,
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.pricelevel_name,
                                                                                        "1",
                                                                                        item.unit_name,
                                                                                        item.product_comboprice,
                                                                                        item.product_comboprice,
                                                                                        0,
                                                                                        item.product_comboprice,
                                                                                        item.pricelevel_percentage,
                                                                                        item.product_combostock,
                                                                                        1
                                                                                    )}>
                                                                                        {item.product_refcode} - {item.product_name}
                                                                                    </a>
                                                                                </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                    {

                                                        item.addnew == true ?
                                                            <td>
                                                                <FormControl sx={{ width: '100%' }}>
                                                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-helper-label"
                                                                        id="demo-simple-select-helper"
                                                                        value={item.product_batch_id}
                                                                        label="Age"
                                                                        onChange={colorChange}
                                                                        size="small"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {item.colorsize.map((row, index) => {
                                                                            return (
                                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </td> : <td>{item.color} - {item.size}</td>
                                                    }
                                                    <td style={{ fontSize: 10 }}>

                                                        {item.sales_iscombo == 0 || item.sales_iscombo == null ?

                                                            <p> {item.productname} - {item.color} - {item.size} </p> :

                                                            item.sales_iscombo == 1 && item.addnew == true ?
                                                                <div>
                                                                    <Button style={{ height: 30 }} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button>
                                                                    {

                                                                        item.fetch_products.map((result, k) => {
                                                                            return (
                                                                                <label style={{ fontSize: 10 }}>
                                                                                    {result.product_name} - {result.color} - {result.size}
                                                                                </label>
                                                                            )
                                                                        })
                                                                    }

                                                                </div> :

                                                                item.fetch_products.map((result, k) => {
                                                                    return (
                                                                        <label style={{ fontSize: 10 }}>
                                                                            {result.product_name} - {result.color} - {result.size}
                                                                        </label>
                                                                    )
                                                                })
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.sales_iscombo == 0 || item.sales_iscombo == null ?
                                                            <div>
                                                                {item.addnew == false ?
                                                                    <input min="1" disabled autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} /> :
                                                                    <input min="1" autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />
                                                                }
                                                                {
                                                                    <label style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>stock: {item.pb_stock}</label>
                                                                }
                                                            </div> :
                                                            item.sod_qty
                                                        }
                                                    </td>
                                                    <td>{item.unit}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.calculated_tax}</td>
                                                    <td>{item.calculated_price}</td>

                                                    {item.sales_iscombo == 0 ?
                                                        <td>{item.calcprice}</td> :
                                                        <td>{item.price}</td>
                                                    }
                                                    <td>
                                                        {item.addnew == false ?
                                                            <input autocomplete="off" disabled type="number" name="query" value={item.discount} onChange={(event) => discountChange(event, index)} />
                                                            : <input autocomplete="off" type="number" name="query" value={item.discount} onChange={(event) => discountChange(event, index)} />
                                                        }
                                                    </td>
                                                    {/* <td>{item.calculated_totalprice}</td> */}
                                                    <td>{item.sod_total_amount}</td>
                                                    <td>
                                                        {item.sales_isedit == 1 ? null : <Button onClick={() => deleteRow(index, item.amount, item.sod_id)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{ marginRight: 10 }} /></Button>}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </Table>
                                <Button onClick={() => addRow()} style={{ float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20 }} variant="contained">Add New</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
                                {
                                    (-deletedAmount) <= variationamount ?
                                        <Button onClick={onSave} style={{ backgroundColor: '#6d37b9', borderRadius: 20 }} variant="contained"><HiSave color="White" fontSize={20} style={{ marginRight: 10 }} />Save</Button> :
                                        null
                                }

                                <Button style={{ backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20 }} variant="contained"><RiDeleteBinLine color="White" fontSize={20} style={{ marginRight: 10 }} />Delete</Button>
                                <Button style={{ backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20 }} variant="contained"><AiFillPrinter color="White" fontSize={20} style={{ marginRight: 10 }} />Print</Button>
                                <Button style={{ backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20 }} variant="contained"><MdClearAll color="White" fontSize={20} style={{ marginRight: 10 }} />Clear</Button>
                                <Button style={{ backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20 }} variant="contained"><GiCash color="White" fontSize={20} style={{ marginRight: 10 }} />GST</Button>
                                <Button style={{ backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20 }} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{ marginRight: 10 }} />Close</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Payable
                                        </p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled value={payable} onChange={(e) => setPayable(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Total
                                        </p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled value={amounttotal} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Discount %</p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Other Charges
                                        </p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} value={otherCharges} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Roundoff
                                        </p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled value={roundoff} onChange={(e) => roundOffchange(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Grand Total</p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled onChange={(e) => setGrandtotal(e.target.value)} value={grandtotal} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Extra Delivery Charge
                                        </p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField autoComplete="off" type="number" onChange={(e) => deliveryChargechange(e.target.value)} value={deliverycharge} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Variation Amount</p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled onChange={(e) => setVariationamount(e.target.value)} value={variationamount} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <p>Grand Total After variation </p>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField disabled value={totalaftervariation} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                </Row>

                            </Col>

                        </Row>
                    </div>
                </div>
            </Paper>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Combo product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {combotable.map((item, index) => {
                        return (
                            <Row style={{ marginTop: 20 }}>
                                <Col xs={12} md={5}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Product</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.productid}
                                            label="Age"
                                            onChange={(e) => cbproductChange(e, index)}
                                            size="small"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {comboproductlist.map((row, index) => {
                                                return (
                                                    <MenuItem value={row.products_id}>{row.product_name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Color and size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.pb_id}
                                            label="Age"
                                            onChange={(e) => cbbatchChange(e, index)}
                                            size="small"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {combobatchlist[index].value.map((row, index) => {
                                                return (
                                                    <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <label style={{ fontSize: 10, color: 'red' }}>Max Quantiy = {item.quantity}</label>
                                    <label style={{ fontSize: 10, color: 'red' }}>stock = {item.stock}</label>
                                </Col>
                                <Col xs={12} md={2}>
                                    <a href="javascript:void(0)" onClick={() => deletePopupitem(index)}>
                                        <RiIcons.RiDeleteBin7Fill color="red" fontSize={20} />
                                    </a>
                                </Col>

                            </Row>
                        )
                    })
                    }
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} md={2}>
                            <Button style={{ height: 30 }} variant="contained" onClick={() => addComborow()}>Add</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} md={2}>
                            <Button style={{ height: 30 }} variant="contained" onClick={() => addtoTablerow()}>Submit</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={orderpopup}
                onHide={() => setOrderpopup(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Sales Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} lg={8}>
                            <Row>
                                <Col sm={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField onChange={(e) => getdata2(e.target.value)} placeholder="Mobile Number" value={searchmobile} id="outlined-basic" variant="outlined" size="small" />
                                    </FormControl>
                                </Col>
                                <Col sm={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField onChange={(e) => getdata3(e.target.value)} placeholder="Invoice Number" value={searchinvoice} id="outlined-basic" variant="outlined" size="small" />
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12}>
                            <Table hover responsive bordered className="section-to-print">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Invoice</th>
                                        <th>Date</th>
                                        <th>Customer</th>
                                        <th>Mobile</th>
                                        <th>Building</th>
                                        <th>Street</th>
                                        <th>zone</th>
                                        <th>Area</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesorderlist.map(result => {
                                        return (
                                            <tr style={{ cursor: "pointer" }} onClick={() => salesOrderclick(result.sales_id, "")}>
                                                <td>{result.user_name}</td>
                                                <td>{result.sales_invoice}</td>
                                                <td>{result.sales_date}</td>
                                                <td>{result.customer_name}</td>
                                                <td>{result.customer_mobile}</td>
                                                <td>{result.customer_building}</td>
                                                <td>{result.customer_street}</td>
                                                <td>{result.customer_zone}</td>
                                                <td>{result.customer_area}</td>
                                                <td>{result.sales_total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Salesreplacement;
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col,Modal,Button  } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader';
import { ka, nn } from 'date-fns/locale';

function SalesApproval(props) {
    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date1 = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    let history = useHistory();
    // let { id } = useParams();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [modal, setModal] = React.useState(false);
    const [date, setDate] = React.useState(new Date);
    const [input, setInput] = useState(0);
    const [inputa, setInputa] = useState(0);

    const [frominvoice, setFrominvoice] = useState("")
    const [toinvoice, setToinvoice] = useState("")

    const [executiveid, setExecutiveid] = useState("")
    const [executiveList, setExecutivelist] = useState([])
    const [loader, setLoader] = React.useState(false);


    const [mobile, setMobile] = useState("")
    const [invoice, setInvoice] = useState("")

    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])

    const [billDetails, setBilldetails] = useState([])

    const productlist = [{id: 1},{id: 2},{id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}]
    const [blocked, setBlocked] = React.useState(0);
    const [array, setArray] = React.useState([])

    const [frontend, setFrontend] = React.useState(0)
    const [showUnasigned, setShowunasigned] = React.useState(0)

    const [approvalsalesman, setApprovalsalesman] = React.useState("")

    const [checked, setChecked] = useState(false);
    const [fil, setFil] = useState(true);
    const [filt, setFilt] = useState(false)

    const [appro, setAppro] = useState(false)

    const userChange = (event) => {
        setSalesmanid(event.target.value)
        getData(event.target.value)
    }

    const approvalsalesmanChange = (event) => {
        setApprovalsalesman(event.target.value)
    }

    const frontendOrderChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setFrontend(1)
        }
        else{
            setFrontend(0)
        }
    }

   
    const AllChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input1 = [...source]
            for(var i = 0; i<input1.length ; i++) {
                input1[i].status = true
            }
            setArray(input1)
        }
        else{
            var input = [...source]
            for(var i = 0; i<input.length ; i++) {
                input[i].status = false
            }
            setArray(input)
        }
    }

    const unasignChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setShowunasigned(1)
        }
        else{
            setShowunasigned(0)
        }
    }

    const blockChange = (e, id, index) => {
        console.log("value id", id)
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push(id)
            // console.log("input", input)
            setArray(input)

            var input1 = [...source]
            input1[index].status = true
            setSource(input1)
        }

        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            var input1 = [...source]
            input1[index].status = false
            setSource(input1)

            for (let i = 0; i < array.length; i++) {
                if (array[i] === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        // console.log('user',userdata )
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
    
        
        var data = {
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    useEffect(() => {
        getUser()
        getdata1()
      }, [frontend, showUnasigned]);

      useEffect(() => {
            getdata1()
      }, [input]);



      const calculate = (date2) => {
        // console.log("date", year + "-" + month +    "-"  +date1)
       

        // console.log("date2", date2)

        // const today = new Date()
        // const d = new Date("2021-07-24T23:35:01");
        // console.log("result", today.getTime() - d.getTime())
        const today = new Date()
        const d = new Date(date2);

        // console.log("today", today)
        // console.log("d", d)

        let diffInMilliSeconds = Math.abs(today - d) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        // console.log('calculated days', days);

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        // console.log('calculated hours', hours);

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        // console.log('minutes', minutes);

        let difference = '';
        // console.log("days", days)
        if(days > 2) {
            return false
        }
        else{
            return true
        }
        // if (days > 0) {
        //     difference += (days === 1) ? `${days} day, ` : `${days} days `;
        //     // return difference + "ago"
        //     console.log("difference", difference)

        // }
        // else {
        //     difference += (minutes === 0) ? `${minutes} min` : `${minutes} min`;
        //     // return difference + " ago"
        //     console.log("difference", difference)
        // }

      }

      const [sodId,setSodId]=useState("");

      const getdata1 = () => {
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "page" : input*10,
            "limit": 10,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          console.log('datahjasd', data)
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                // console.log("user 123 response", responseJson)
                setLoader(false)
                setSource(responseJson.data)
                console.log(responseJson.data,'ccc1');
                setSodId(responseJson.data.salesorder_id);
                console.log(responseJson.data.salesorder_id,'ccc2');
            
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getdata2 = (search) => {
        setMobile(search)
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "mobile": search,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                // console.log("user response", responseJson)
                setLoader(false)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getdata3 = (search) => {
        setInvoice(search)
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "invoice": search,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                // console.log("user response", responseJson)
                setSource(responseJson.data)
                setLoader(false)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getData = (id) => {
        setMobile("")
        setInvoice("")
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        console.log("id", id)
        var data = {
            "companyid": company,
            "salesmanid": id,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
                setLoader(false)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getAll = () => {
        setFilt(true)
        setMobile("")
        setInvoice("")
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)

        var data = {
            "excelexport" : true,
            "companyid": company,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("excel exp response", responseJson)
                setSource(responseJson.data)
                setLoader(false)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const filter = () => {
        setMobile("")
        setInvoice("")
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
       
        var data = {
            "companyid": company,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "page" : 0,
            "limit": input*10,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
                setLoader(false)
            })
            .catch((error)=>{
                console.log(error)
            })
      }
      const viewBillclick = (id) => {
        setModal(true)
    
        var data = {
            "companyid": usercompanyid,
            "salesorderid": id,
            "type": usertype,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned
        }
        fetch(global.url + "viewSalesOrderDetail", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("report sale detail", responseJson)
          if(responseJson.error == true){
            alert(responseJson.message)
          }
          else{
              if(responseJson.data.sales_details){
                setBilldetails(responseJson.data.sales_details)
              }
              else{
                setBilldetails([])
              }
           
          }
        
        })
      }

      const updatedeliveryboy = () => {
            var data = {
                "salesorder_id": array,
                "deliveryboy": approvalsalesman
            }
            console.log("data", data)
            fetch(global.url + "bulkUpdateSalesman", {
                method: 'POST',
                headers: {
                'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("report sale detail", responseJson)
                if(responseJson.error == false) {
                    alert(responseJson.message)
                    getdata1()
                }
                
            })
      }

      const orderSubmit = () => {
        if(window.confirm('Are you Want to approve this order?')){
          setLoader(true)
        var data = {
            "salesorder_id": array,
        }
        console.log("data", data)
        fetch(global.url + "salesOrderApproval", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("report sale detail", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                getdata1()
                setLoader(false)
            }    
        })
    }else{
        console.log("response")
    }
        
      }

      const onPrint = () => {
            window.print()
      }

      const dateSearch = (text) => {
          setDate(text)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            console.log("date", text.toISOString().split('T')[0])
            var data = {
                "companyid": company,
                "salesorder_date": text.toISOString().split('T')[0],
                "frontendorders": frontend,
                "emptydeliveryboys": showUnasigned,
                "type" : userdata.user_type, 
                "branchid" : branch
            }
            fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const onfilterClick2 = () => {
          setLoader(true)
        setFil(false)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            var data = {
                "companyid": company,
                "invoicedrom": frominvoice,
                "invoiceto": toinvoice,
                "salesmanid": salesmanid,
                "frontendorders": frontend,
                "emptydeliveryboys": showUnasigned,
                "excelexport" : true,
                "type" : userdata.user_type, 
                "branchid" : branch
            }
            console.log('data', data)
            fetch(global.url + "viewSalesOrderBySalesman", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    setLoader(false)
                    console.log("user response", responseJson)
                    setSource(responseJson.data)
                })
                .catch((error)=>{
                    console.log(error)
                })
      }

      const cancelClick = (id, invoice) => {
        if(window.confirm('Are you Want to Cancel this order?')){
            console.log("id", id)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var data = {
            "salesorderid":  id,
            "user_id":  userdata.user_id,
            }
            console.log('data cancel', data)
            fetch(global.url + "salesOrderCancel", {
                method: 'POST',
                headers: {
                'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then((response) => response.json())
            .then((responseJson) => {
            if(responseJson.error == false){
                onfilterClick2()
                setArray([])
                alert(responseJson.message)

            }
                console.log("response", responseJson)
            })
        }else{
           console.log("response")
        }
      }

      const deliveryClick = () => {
        if (window.confirm('Are you sure you want to move the bills from' + frominvoice + "to" + toinvoice)) {
                    var data = {
                        // "salesorderid":  id,
                    }
                    fetch(global.url + "ApplyDeliveredReport", {
                        method: 'POST',
                        headers: {
                            'Content-Type' : 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if(responseJson.error == false){
                            getData()
                            alert(responseJson.message)
                        }
                        console.log("response", responseJson)
                    })
        } else {
        
            console.log('Thing');
        }
    }


    const decrement = () => {
        setInput(prevCount => prevCount - 1);
    }

    const increment = () => {
        setInput(prevCount => prevCount + 1);
    }

    const decrementa = () => {
        setInputa(prevCount => prevCount - 1);
    }

    const incrementa = () => {
        setInputa(prevCount => prevCount + 1);
    }

    const bulkCancellation = () => {
        if(window.confirm('Are you Want to Cancel this orders?')){
            var data = {
               'salesorderid' : array
            }
            console.log("data cancel", data)
            fetch(global.url + "SalesOrderBulkCancel", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("bulk cancel response", responseJson)
                if(responseJson.error == false){
                    onfilterClick2()
                    setChecked(false)
                    setArray([])
                    alert(responseJson.message)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        } else {
            console.log('res')
        }
    }

    // useEffect(() => {
    //     approoved()
    // }, [inputa])

    const approoved = () => {
        setAppro(true)
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "ordertype" : 1,
            "limit" : 10,
            "page" : inputa*10,
            "type" : userdata.user_type, 
            "branchid" : branch
        }
        console.log('data', data)
        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                setLoader(false)
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <Row>
                <Col md={6}>
                    <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                        {
                            usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                            <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Report</p> :
                            <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Approval</p>
                        } 
                    </div>
                </Col>
                <Col md={6}>
                    {/* <Button onClick={() => onPrint()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15, float: 'right' }}>Print</Button> */}
                    <Button className='btn1' onClick={() => bulkCancellation()} variant="primary" style={{padding: 5, fontSize: 15, float: 'right',  border: 'none'}}>Bulk Order Cancellation</Button>
                    <Button className='btn1' onClick={() => orderSubmit()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15, float: 'right',  border: 'none'}}>Order Approve</Button>
                    <Button className='btn1' onClick={approoved} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15, float: 'right',  border: 'none'}}>Approved Only</Button>
                </Col>
            </Row>
            
            <div style={{marginTop: 10}}>
                {
                    usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                    null: 
                    <div>
                        <Row>
                            {/* <Col md={3}>
                                <FormControl style={{marginRight: 10}} sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={approvalsalesman}
                                        label="Age"
                                        onChange={approvalsalesmanChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                                </FormControl>
                            </Col> */}
                            <Col md={6}>
                                {/* <Button onClick={() => updatedeliveryboy()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15}}>Update Delivery Boy</Button> */}
                            </Col>
                        </Row>
                    </div>
                }
            </div>
            <Row>
                <Col sm={12} >
                    <Row>
                        {/* <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                        </Col> */}
                        <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => getdata2(e.target.value)} placeholder="Mobile Number" value={mobile} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => getdata3(e.target.value)} placeholder="Invoice Number" value={invoice} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={2} style={{marginTop:30}}>
                            <LocalizationProvider sx={{width: '100%' }} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={date}
                                    onChange={(newValue) => {
                                    dateSearch(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        </Row>
                        <Row>                        
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setFrominvoice(e.target.value)} value={frominvoice} label='Invoice From' id="outlined-basic" variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>

                      <Col xs={12} md={2} style={{marginTop: 30}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setToinvoice(e.target.value)} value={toinvoice} id="outlined-basic" label='Invoice To' variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>

                      <Col xs={12} sm={2} style={{marginTop: 30}}>
                            <Button className='btn1' onClick={() => onfilterClick2()} variant="primary" style={{  border: 'none'}}>Filter</Button>
                      </Col>
                      
                      {/* <Col xs={12} sm={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox onChange={e => frontendOrderChange(e)} />
                                } label="Website Orders" />
                            </FormControl>
                        </Col>
                        {
                            frontend ? 
                            <Col xs={12} sm={2} style={{marginTop: 30}}>
                                <FormControl sx={{  width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox onChange={e => unasignChange(e)} />
                                    } label="Show unasigned delivery Orders" />
                                </FormControl>
                            </Col> : 
                            null
                        } */}
                        <Col xs={12} sm={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox onChange={e => AllChange(e)} />
                                } label="All" />
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                    <Table responsive id="table-to-xls" bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Invoice</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Building</th>
                                <th>Street</th>
                                <th>zone</th>
                                <th>Area</th>
                                <th>Total</th>
                                <th colSpan="2">Action</th>
                                <th>Bill</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loader ? <Loader /> :
                        source.map((result, index) => {
                            return (
                            <tr style={result.salesorder_isapproval == 1 ? {background: '#12e049'} : (calculate(result.salesorder_date) == true ? null : {background: 'red'})}>
                                <td style={{color:'black'}}>{result.user_name}</td>           
                                <td style={{color:'black'}}>{result.salesorder_invoice}</td> 
                                <td style={{color:'black'}}>{result.salesorder_date} - {result.salesorder_created_at}</td>
                                <td style={{color:'black'}}>{result.customer_name}</td>
                                <td style={{color:'black'}}>{result.customer_mobile}</td>
                                <td style={{color:'black'}}>{result.customer_building}</td>
                                <td style={{color:'black'}}>{result.customer_street}</td>
                                <td style={{color:'black'}}>{result.salesorder_zone}</td>
                                <td style={{color:'black'}}>{result.customer_area}</td>
                                <td style={{color:'black'}}>{result.salesorder_total}</td>
                                
                                {
                                    usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                                    <td>
                                    <Button  onClick={() => cancelClick(result.salesorder_id, result.salesorder_invoice)} variant="primary" style={{padding: 5, fontSize: 10}}>Order Cancel</Button> 
                                    </td>
                                    :
                                    
                                    <td>
                                        {result.salesorder_isapproval == 1 ? <span>Approved</span>  : 

                                       
                                        <td>
                                            <FormControl sx={{  width: '100%' }}>
                                                <FormControlLabel control={
                                                <Checkbox checked={array.includes(result.salesorder_id) || result.status == true ? true : false} onChange={e => blockChange(e, result.salesorder_id, index)} />
                                                } label="" />
                                            </FormControl>
                                             <Button className='clears' onClick={() => cancelClick(result.salesorder_id, result.salesorder_invoice)} variant="primary" style={{padding: 5, fontSize: 10}}>Order Cancel</Button>
                                        </td>
                                        } 
                                    </td>
                                }
                                {
                                    result.sales_return == 1 || result.sales_return == 2 ?
                                    <td className="print_btn">
                                        Replaced Bill
                                    </td> : 
                                    // result.salesorder_isapproval == 1 ?  null :
                                      
                                    result.salesorder_isapproval == 1 ?  <td></td> :
                                    
                                    <td className="print_btn">
                                        <a href={'/transaction/Salesorder/' + result.salesorder_id} >
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                        </a>
                                    </td>
                                 }
                                
                                
                                <td  className="print_btn"><Button className='clears' onClick={() => viewBillclick(result.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button></td>
                                
                            </tr>
                            )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Order Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.code}</td>
                                <td>{item.productname}</td>
                                <td>{item.sd_qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.sd_total_amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
            { appro == true ?
                <div style={{marginTop: 20}}>
                    <Button onClick={incrementa} style={{float: 'right'}} variant="primary">Next</Button>
                    {inputa == 0 ? null :
                    <Button onClick={decrementa} style={{marginRight: 40, float: 'right', borderColor: '#000'}} className='stock-prev' variant="light">Previous</Button>
                    }
                </div>:
                <div>
                    {filt == true ? null :
                    <div style={{marginTop: 20}}>
                        {fil == false ? null :
                        <Button className='clears' onClick={increment} style={{float: 'right',border:'none'}} variant="primary">Next</Button>}
                        {input == 0 ? null :
                        <Button onClick={decrement} style={{marginRight: 40, float: 'right', borderColor: '#000'}} className='stock-prev' variant="light">Previous</Button>
                        }
                        {fil == false ? null :
                        <Button className='clears' onClick={getAll} style={{float: 'right', marginRight: 40, border:'none'}} variant="primary">All</Button>}
                    </div>
                    }
                </div>}
          </Paper> 
      </div>
    )
}
export default SalesApproval;
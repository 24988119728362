
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";

const columns = [
  { id: '1', label: 'Voucher No.', minWidth: 50, span: false },
  { id: '2', label: 'Vourcher Date', minWidth: 50, span: false },
  { id: '3', label: 'Reference No', minWidth: 50, span: false },
  { id: '4', label: 'Reference Date', minWidth: 50, span: false },
  { id: '5', label: 'Remarks', minWidth: 50, span: false },
  { id: '6', label: 'Action', minWidth: 50, span: false },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  {
    company: 'Lorem Ipsum',
  },
  {
    company: 'Lorem Ipsum',
  },
];

function JournalReport(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = React.useState('');

  const [value, setValue] = React.useState(null);
  const [dateFrom, setDatefrom] = React.useState(new Date);
  const [dateTo, setDateTo] = React.useState(new Date);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [age, setAge] = React.useState('');
  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const SelectChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)

    var data = {
      "type": userdata.user_type,
      "fromdate":dateFrom.toISOString().split('T')[0],
      "todate":dateTo.toISOString().split('T')[0],
      "companyid": company,
      "viewtype": 1
    }
    fetch(global.url + "ViewjournalHead", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response journal", responseJson)
        setLoader(false)
        if (responseJson.status == "false") {
          setSource(responseJson.data)
          console.log("response", responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="main-content">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
          <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Journal Report</p>
        </div>
        <div style={{ margin: 20, marginTop: 10 }}>
          <Row>
            <Col xs={12} md={6} lg={3} style={{ marginTop: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  value={dateFrom}
                  onChange={(newValue) => {
                    setDatefrom(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Col>
            <Col xs={12} md={6} lg={3} style={{ marginTop: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  value={dateTo}
                  onChange={(newValue) => {
                    setDateTo(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Col>
            {/* <Col xs={12} md={6} lg={3} style={{ marginTop: 20 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Branch"
                    onChange={SelectChange}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Col> */}
            <Col xs={12} md={6} lg={3} style={{ marginTop: 20 }}>
            <Button variant="primary" onClick={() => getData()}>Filter</Button>
            </Col>
          </Row>
        </div>
        <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
          {loader ? <Loader /> :
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                      colSpan={column.span == true ? 2 : 1}
                    >
                      {column.label}

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {source
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow tabIndex={-1} key={row.code}>
                        <TableCell>
                          {row.journal_voucher_no}
                        </TableCell>
                        <TableCell >
                          {row.journal_date}
                        </TableCell>
                        <TableCell >
                          {row.journal_ref_no}
                        </TableCell>
                        <TableCell >
                          {row.journal_ref_date}
                        </TableCell>
                        <TableCell >
                          {row.journal_remarks}
                        </TableCell>
                        <TableCell >
                          <a href={'/transaction/journalvoucher/' + row.journal_id} >
                            <FaIcons.FaEdit fontSize={20} color="blue" />
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={source.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
export default JournalReport;
import '../Tax-Settings/TaxSettings.css';
import React, { Component, useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal, Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { HiSave } from "react-icons/hi";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";

import Loader from '../../loader/loader';
import { useHistory, useParams } from "react-router-dom";



function SalesSingleReturn(props) {
    let history = useHistory();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const { id } = useParams()
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [expiry, setExpiry] = React.useState(new Date);
    // const [tableRow, setTableRow] = React.useState([{ key: "", product_batch_id: "", product_id: "", pb_inter_barcode: "", code: "", productname: "", pricelevel: "", sod_qty: "", pb_isconvertunit: "", pb_displayconvertunit: "", unit: "", price: "", calcprice: "", calculated_tax: "", calculated_price: "", calculated_totalprice: 0, discount: "", taxrate: "", taxtype: "", sod_total_amount: 0, sod_id: "", color: "", size: "", colorsize: [], addnew: true, fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", sod_baseqty: "" }] }])
    // const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [tableRow, setTableRow] = React.useState([{ key: "", product_batch_id: "", product_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", calculated_tax: "", calculated_price: "", calculated_totalprice: 0, discount: "", taxrate: "", taxtype: "", sd_total_amount: 0, sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "" }] }])
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [selectedIndex, setSelectedindex] = React.useState("")

    const [customername, setCustomername] = React.useState('');
    const [building, setBuilding] = React.useState('');

    const [zone, setZone] = React.useState("");
    const [area, setArea] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [ordertype, setOrdertype] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [ledgerName, setLedgerName] = React.useState("");
    const [reference, setReference] = React.useState("");
    const [salesorderid, setSalesorderid] = React.useState("");
    const [variationamount, setVariationamount] = useState('')
    const [deliverycharge, setDeliverycharge] = useState('')
    const [replacereturn, setReplacereturn] = useState('')
    const [totalaftervariation, setTotalaftervariation] = React.useState(0);
    const [replaceorder, setReplaceorder] = React.useState(0);


    const [supplierList, setSupplierList] = React.useState([])
    const [customerList, setCustomerlist] = React.useState([])
    const [customerpricename, setCustomerpricename] = React.useState("");
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");
    const [supplier, setSupplier] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');
    const [loader, setLoader] = React.useState(false);

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(0);
    const [advance, setAdvance] = React.useState('');
    const [tax, setTax] = React.useState(0);
    const [price, setPrice] = React.useState(0);
    const [amount, setAmount] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);

    const [productlist, setProductlist] = React.useState([]);
    const [purchaseorderlist, setPurchaseorderlist] = React.useState([]);

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const [purchaselpoid, setPurchaselpoid] = useState("")

    const [payable, setPayable] = React.useState(0);
    const [roundoff, setRoundoff] = React.useState(0);
    const [paymentmode, setPaymentmode] = React.useState('bank');


    const [searchinvoice, setSearchinvoice] = React.useState('');
    const [searchsupplier, setSearchsupplier] = React.useState('');

    const [accountbal, setAccountbal] = React.useState('');
    const [landingCost, setLandingCost] = React.useState("");

    // const [purchaseid, setPurchaseid] = React.useState('')
    const [salesid, setSalesid] = React.useState('')
    const [customer, setCustomer] = React.useState("")

    const [indexState, setIndexstate] = React.useState(0)
    const [productmodal, setProductModal] = useState(false)
    const [lookupproductlist, setLookupproductlist] = React.useState([]);

    const [orderpopup, setOrderpopup] = React.useState(false);

    const [salesorderlist, setSalesorderlist] = React.useState([]);

    const [return1, setReturn] = React.useState("");
    const [ordertypelist, setOrdertypelist] = React.useState([]);

    const [currentcash, setCurrentcash] = React.useState("");
    const [ledgerList, setLedgerlist] = React.useState([]);
    const [btnloader, setBtnloader] = React.useState(false);
    const [grandtotalex, setGrandtotalex] = React.useState(0);
    const [searchmobile, setSearchmobile] = React.useState("");
    const [delboyid, setDelboyid] = React.useState("");

    useEffect(() => {
        var total = parseFloat(amount) - parseFloat(discount) + parseFloat(otherCharges)
        setCurrentcash(total);
    }, [amount, grandtotalex])

    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();


    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
    };

    useEffect(() => {
        getInvoiceno(billType)
    }, [billType])

    const paymodeChange = (event) => {
        setPaymentmode(event.target.value);
        setAccountbal("")
        if (event.target.value == "cash") {
            var company = JSON.parse(localStorage.getItem("company"))
            var data = {
                "companyid": company,
            }
            fetch(global.url + "accountBalance", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.error == false) {
                        setAccountbal(responseJson.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const searchsupplierChange = (event) => {
        setSearchsupplier(event.target.valueange);
    };

    const colorChange = (event) => {
        var input = [...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        setTableRow(input)

        getProductbybatch1(event.target.value, selectedIndex)
        //   setColor(event.target.value)
    }

    const getProductbybatch1 = (id, index) => {

        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("setdetails", responseJson)
                setDetails(id, index, responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        console.log(input)
        input[index].key = index
        input[index].product_batch_id = id
        input[index].pb_stock = responseJson.pb_stock
        input[index].sd_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].price = responseJson.pb_salesrate
        // input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0
        input[index].taxrate = responseJson.product_taxrate
        input[index].taxtype = responseJson.product_taxtype

        if (responseJson.product_taxtype == 1) {

            const price = (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(responseJson.pb_salesrate) - (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))).toFixed(2);

            console.log(tax)
            console.log(price)
            input[index].calculated_tax = tax;
            input[index].calculated_price = price;
            const total = (((parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1))) + (parseFloat(responseJson.pb_salesrate) - (parseFloat(responseJson.pb_salesrate) / ((parseFloat(responseJson.product_taxrate) / 100) + 1)))).toFixed(2);

            input[index].calcprice = total;
            input[index].sd_total_amount = total;
        }
        else if (responseJson.product_taxtype == 2) {
            const tax = (parseFloat(responseJson.pb_salesrate) * (parseFloat(responseJson.product_taxrate) / 100)).toFixed(2);
            const price = (parseFloat(responseJson.pb_salesrate)).toFixed(2)


            input[index].calculated_tax = tax;
            input[index].calculated_price = price;
            const total = ((parseFloat(responseJson.pb_salesrate) * (parseFloat(responseJson.product_taxrate) / 100)) + (parseFloat(responseJson.pb_salesrate))).toFixed(2);
            ;
            // input[index].calculated_totalprice = total;
            input[index].calcprice = total;
            input[index].sd_total_amount = total;


        }
        else {
            const Price = (parseFloat(responseJson.pb_salesrate)).toFixed(2)

            input[index].calcprice = Price;
            input[index].sd_total_amount = Price;
            console.log("set tax");

        }


        // input[index].sd_total_amount = responseJson.pb_salesrate 
        setTableRow(input)
        addRow()
    }
    // const setDetails = (id, index, responseJson) => {

    //     var input = [...tableRow]
    //     console.log(input)
    //     input[index].key = index
    //     input[index].product_batch_id = id

    //     input[index].pb_stock = responseJson.pb_stock



    //     input[index].pd_qty = 1
    //     input[index].unit = responseJson.unit_name
    //     input[index].color = responseJson.color_name
    //     input[index].size = responseJson.size_name
    //     input[index].expiry = responseJson.pb_expiry_date
    //     input[index].pd_purchase_price = responseJson.pb_purchaseprice
    //     input[index].calcprice = responseJson.pb_purchaseprice
    //     input[index].pd_total_amount = responseJson.pb_purchaseprice
    //     input[index].discount = 0
    //     // input[index].pd_purchase_price = responseJson.pb_salesrate
    //     // input[index].calcprice = responseJson.pb_salesrate
    //     // input[index].pd_total_amount = responseJson.pb_salesrate 



    //     console.log("input", input)
    //     setTableRow(input)
    //     addRow()
    //     // setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", pd_qty: "", unit: "", price: "", calcprice: "", discount: "", pd_total_amount: 0, pb_stock: "", sales_iscombo: "",pd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
    //     // setCode("")

    //     // var total1 = total + responseJson.pb_salesrate
    //     // console.log("totalcalc", total1)
    //     // setTotal(total1)
    // }

    const userChange = (event) => {
        setSalesman(event.target.value);
    };

    const addRow = () => {
        setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        // setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: '', productname: "", pricelevel: "", expiry: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
    }

    // const supplierChange = (event) => {
    //     setSupplier(event.target.value);
    //     getSupplierbyid(event.target.value)
    //     getSupplierbalance(event.target.value)
    // };

    const getSupplierbalance = (id) => {
        var data = {
            "supplier_id": id
        }
        fetch(global.url + "viewSupplierBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    console.log("Supplier Balance", responseJson)
                    setBalance(responseJson.opening_balance)
                    setLimitbalance(responseJson.remain_balance)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (amount / 100) * event
        var result = amount - disc
        var grandtotal1 = result + parseFloat(otherCharges)
        setGrandtotal(grandtotal1)
    }

    const otherChargeschange = (event) => {
        setOthercharge(event)
        var disc = (amount / 100) * discount
        var result = (amount - disc) + parseFloat(event)
        if (result) {
            setGrandtotal(result)
        }
    }

    const customerChange = (event) => {
        setCustomer(event.target.value);
        getCustomerbyid(event.target.value)
        // getCustomerbalance(event.target.value)
    };

    const getCustomerbalance = (id) => {
        var data = {
            "customer_id": id
        }
        fetch(global.url + "viewCustomerBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    console.log("customer Balance", responseJson)
                    setBalance(responseJson.opening_balance)
                    setLimitbalance(responseJson.remain_balance)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getCustomerbyid = (id) => {
        var data = {
            "customer_id": id
        }
        fetch(global.url + "getCustomerbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("one customer", responseJson)

                setCreditlimit(responseJson.data.customer_credit_limit)
                setAddress(responseJson.data.customer_address1)
                setMobile(responseJson.data.customer_mobile)
                setCustomerpricelevel(responseJson.data.pricelevel_percentage)
                setCustomerpricename(responseJson.data.pricelevel_name)
                setTableRow([{ key: "", product_batch_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "", fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSupplierbyid = (id) => {
        var data = {
            "supplier_id": id
        }
        fetch(global.url + "getSupplierbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("one customer", responseJson)

                setCreditlimit(responseJson.data.supplier_credit_limit)
                setAddress(responseJson.data.supplier_address1)
                setMobile(responseJson.data.supplier_mobile)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const inputHandler = (text, key) => {
        // setSelectedindex(key)
        // setProductname(text)
        // console.log("text", text)
        // const _inputs = [...example];
        // _inputs[key].value = text;
        // _inputs[key].key   = key;
        // console.log("inputs", _inputs)
        // setExample(_inputs)
    }

    // function onKeyDown(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }
    //   }

    //   function onKeyDown1(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef1.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }
    //   }

    useEffect(() => {
        getTotalamount()
        // console.log('payable', payable)
    }, [tableRow, discount, roundoff, otherCharges]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].salesorder_isreplacedone == 1) {
                totalAmount += parseFloat(tableRow[i].sd_total_amount)
            }
            else if (tableRow[i].sod_iscancel != 1) {
                totalAmount += parseFloat(tableRow[i].sd_total_amount)
            }

        }
        setAmount(totalAmount)
        var disc = (totalAmount / 100) * discount
        setGrandtotal(parseFloat(totalAmount) - parseFloat(disc) + parseFloat(otherCharges) - parseFloat(roundoff))
        setGrandtotalex(parseFloat(totalAmount) - parseFloat(disc) + parseFloat(otherCharges) - parseFloat(roundoff))
        setPayable(parseFloat(totalAmount) - parseFloat(disc) + parseFloat(otherCharges) - parseFloat(roundoff))
    }

    useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()
    }, []);

    // useEffect(() => {
    //     getBilltype()
    // }, [])
    useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()

        if (id == "add") {
            getInvoiceno()
            // setEditmode(false)
        }
        else {
            getDetailsbyid(id)
            //    setEditmode(true)
        }
    }, []);

    const getDetailsbyid = (id) => {
        // setLoader(true)
        const data = {
            "sales_id": id,
        }
        fetch(global.url + "viewSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("sales head", responseJson)
                if (responseJson.status == "false") {
                    //  setLoader(false)
                    console.log("sale data", responseJson.data)
                    setSalesid(responseJson.data.sales_id)
                    setCustomer(responseJson.data.sales_customer_fk)
                    setSalesman(responseJson.data.sales_salesman_fk)
                    setBilltype(responseJson.data.sales_bill_type)
                    // setInvoiceno(responseJson.data.salesorder_invoice)
                    setInvoiceno(responseJson.data.sales_invoice)
                    setMobile(responseJson.data.sales_mob_no)
                    setRefno(responseJson.data.sales_ref_no)
                    setAddress(responseJson.data.sales_address)
                    setLpono(responseJson.data.sales_lpo_no)
                    var invoiceDate = new Date(responseJson.data.sales_date).toISOString();
                    var lpodate1 = new Date(responseJson.data.sales_lpo_date).toISOString();
                    setDate(invoiceDate)
                    setLpodate(lpodate1)
                    setQuotation(responseJson.data.sales_qt_no)
                    setBalance(responseJson.data.sales_balance)
                    setLimitbalance(responseJson.data.sales_limit_balance)
                    setCreditlimit(responseJson.data.sales_crdt_limit)
                    setTableRow(responseJson.details)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        // getOrdertype()
    }, [])

    //   const getOrdertype = () => {
    //     var company = JSON.parse(localStorage.getItem("company"))
    //     var data = {
    //         "companyid" : company,
    //     }
    //     fetch(global.url + "showOrderTypelist", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         setOrdertypelist(responseJson.data)
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    //   }


    const ledgerChange = (event) => {
        setLedgerName(event.target.value)
    }
    // const getDetailsbyid = (id) => {
    //     console.log("idsaf", id)
    //     setLoader(true)
    //     var data = {
    //         'purchase_id' : id
    //       }
    //       console.log("data 345", data)
    //       fetch(global.url + "ViewPurchaseDetailInAction", {
    //           method: "POST",
    //           headers: {
    //               'Content-Type': 'application/json',
    //           },
    //           body: JSON.stringify(data),
    //       })
    //       .then((response)=> response.json())
    //       .then((responseJson) => {
    //           console.log("purchase123", responseJson)
    //           if(responseJson.error == false){
    //             setLoader(false)
    //             setBilltype(responseJson.data.billtype)
    //             setInvoiceno(responseJson.data.invoice)
    //             setRefno(responseJson.data.refno)
    //             setSalesman(responseJson.data.salesman)
    //             setDate(responseJson.data.date)
    //             setLpono(responseJson.data.lpono)
    //             setSupplier(responseJson.data.supplier)
    //             setCreditlimit(responseJson.data.creditlimit)
    //             setBalance(responseJson.data.balance)
    //             setLimitbalance(responseJson.data.limitbalance)
    //             setQuotation(responseJson.data.qtno)
    //             setMobile(responseJson.data.mobno)
    //             setLimitbalance(responseJson.data.qtno)
    //             setAddress(responseJson.data.address)
    //             setPurchaseid(responseJson.data.purchase_id)
    //             if(responseJson.data.details){
    //             setTableRow(responseJson.data.details)
    //             }
    //           }
    //       })
    //       .catch((error)=>{
    //           console.log(error)
    //       })
    // }

    useEffect(() => {
        // getSupplier()
        getCustomer()
    }, []);

    const getInvoiceno = (id) => {
        var data = {
            "id": billType
        }
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchaseinvoiceno", responseJson.data.invoiceno)

                if (responseJson.error == false) {
                    setInvoiceno(responseJson.data)
                    setInvoNu(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)


        var data = {
            "userid": userdata.user_id,
            "companyid": company,
            "branchid": branch,
            "type": userdata.user_type,
        }
        fetch(global.url + "viewuser", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("user response", responseJson)
                setUserlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "id": 1
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
                setBilltype(responseJson.data[0].billset_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getCustomer = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewCustomer", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.Error == "false") {
                    setCustomerlist(responseJson.data)
                    console.log("response", responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    // const getSupplier = () => {
    //     var userdata = JSON.parse(localStorage.getItem("user"))
    //     var branch = JSON.parse(localStorage.getItem("branch"))
    //     var company = JSON.parse(localStorage.getItem("company"))
    //     setUsercompanyid(company)
    //     setUserbranchid(branch)
    //     setUsertype(userdata.user_type)
    //     setUserid(userdata.user_id)
    //     var data = {
    //         "type": userdata.user_type,
    //         "companyid": company
    //     }
    //     fetch(global.url + "viewSupplier", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             console.log("response", responseJson)
    //             if (responseJson.Error == "false") {
    //                 setSupplierList(responseJson.data)
    //                 console.log("response", responseJson.data)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    //   useEffect(() => {
    //     var creditbal = creditLimit - balance;
    //     console.log("creditbal", creditbal)
    //     setLimitbalance(creditbal)
    //   }, [creditLimit])

    //   useEffect(() => {
    //     if ( hasResults ) {
    //       document.body.addEventListener('keydown', onKeyDown);
    //     } else {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //     }

    //     if ( hasResultsProduct ) {
    //         document.body.addEventListener('keydown', onKeyDown1);
    //     } else {
    //         document.body.removeEventListener('keydown', onKeyDown1);
    //     }


    //     return () => {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //       document.body.removeEventListener('keydown', onKeyDown1);
    //     }
    //   }, [hasResults, hasResultsProduct]);

    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].productname = name
        input[index].pricelevel = pricelevel
        input[index].pd_qty = qty
        input[index].unit = unit
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        // if(discount) {
        //     var totalcalc = (calcprice/100) * discount
        // }
        // else {
        //     var totalcalc = 0
        // }

        input[index].sd_total_amount = calcprice
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        // setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: '', productname: "", pricelevel: "", expiry: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
        setCode("")
    }

    const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = batchid
        input[index].code = code
        input[index].productname = name
        input[index].pricelevel = pricelevel
        input[index].pd_qty = qty
        input[index].unit = unit
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        // if(discount) {
        //     var totalcalc = (calcprice/100) * discount
        // }
        // else {
        //     var totalcalc = 0
        // }

        input[index].sd_total_amount = calcprice
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])

        // setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: '', productname: "", expiry: "", pricelevel: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
        setProductname("")
    }

    const DeleteRow = (index, id) => {
        var input = [...tableRow]
        input.splice(index, 1)
        setTableRow(input)
        // deletedRow(id)
    }


    const deletedRow = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))

        const data = {
            "pb_id": id,
            "userid": userdata.user_id,
            "companyid": company
        }

        console.log(data, "delete");

        fetch(global.url + "deletePurchasedetailinEdit", {
            headers: {
                'content-Type': 'application/json',
            },

            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("del", responseJson);
                if (responseJson.error == true) {
                    alert(responseJson.error)
                }
                else {
                    alert(responseJson.error)
                }
            })

            .catch((error) => {
                console.log(error);
            })
    }

    const DeleteFromApi = (id) => {
        if (window.confirm('Are you delete this row')) {
            var userdata = JSON.parse(localStorage.getItem("user"))
            var company = JSON.parse(localStorage.getItem("company"))

            const data = {
                "pb_id": id,
                "userid": userdata.user_id,
                "companyid": company
            }

            console.log("data delete", data);
            fetch(global.url + "deletePurchasedetailinEdit", {
                headers: {
                    'content-Type': 'application/json',

                },
                body: JSON.stringify(data)
            })

                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("del res", responseJson);

                    if (responseJson.error == true) {
                        alert(responseJson.message)
                    }

                    else {

                        alert(responseJson.message)
                    }
                })

                .catch((error) => {

                    console.log(error);

                })

        } else {
            window.location.reload()
        }
    }

    const deleteRow = (index, id) => {
        console.log("index", id)
        // setGrandtotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        // console.log("input", input)
        setTableRow(input)
        deleteFromApi(id)
    }

    const deleteFromApi = (id) => {
        if (window.confirm('Are you Want to delete this ? It will be deleted from your database')) {
            var userdata = JSON.parse(localStorage.getItem("user"))
            var company = JSON.parse(localStorage.getItem("company"))
            const data = {
                "pd_id": id,
                "userid": userdata.user_id,
                "companyid": company
            }
            console.log("data detid", data)
            fetch(global.url + "deletePurchasedetailinEdit", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("del respo", responseJson)
                    if (responseJson.error == true) {
                        alert(responseJson.message)
                    }
                    else {
                        alert(responseJson.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            window.location.reload()
        }
    }


    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)
    }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    // for calculating quantity * price

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].pricelevel * event.target.value

        var taxtype = textArray1[index].taxtype;
        var taxrate = textArray1[index].taxrate;



        if (taxtype == 1) {

            const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);

            textArray1[index].sd_qty = event.target.value;
            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
            textArray1[index].sd_total_amount = total;
        }
        else if (taxtype == 2) {
            const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
            const price = (parseFloat(totalprice)).toFixed(2)

            textArray1[index].sd_qty = event.target.value
            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
            textArray1[index].sd_total_amount = total;


        }
        else {
            const Price = (parseFloat(totalprice)).toFixed(2)
            textArray1[index].sd_qty = event.target.value
            textArray1[index].calcprice = Price;
            // textArray1[index].calculated_totalprice = Price;

            textArray1[index].sd_total_amount = Price

            console.log("set tax");
        }
        // textArray1[index].sd_qty = event.target.value
        // textArray1[index].calcprice = totalprice
        // textArray1[index].sd_total_amount = totalprice
        setTableRow(textArray1)
    }

    const ExpiryChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].expiry = event.target.value
        setTableRow(textArray1)
    }

    const priceChange = (event, index) => {
        var textArray1 = [...tableRow]
        // textArray1[index].pd_purchase_price = event.target.value
        textArray1[index].price = event.target.value
        var totalprice = event.target.value * textArray1[index].sd_qty

        var taxtype = textArray1[index].taxtype;
        var taxrate = textArray1[index].taxrate;

        if (taxtype == 1) {

            const price = (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)).toFixed(2)
            const tax = (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))).toFixed(2);


            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = (((parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1))) + (parseFloat(totalprice) - (parseFloat(totalprice) / ((parseFloat(taxrate) / 100) + 1)))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total
            textArray1[index].sd_total_amount = total

        }
        else if (taxtype == 2) {
            const tax = (parseFloat(totalprice) * (parseFloat(taxrate) / 100)).toFixed(2);
            const price = (parseFloat(totalprice)).toFixed(2)

            textArray1[index].calculated_tax = tax;
            textArray1[index].calculated_price = price;
            const total = ((parseFloat(totalprice) * (parseFloat(taxrate) / 100)) + (parseFloat(totalprice))).toFixed(2);
            // textArray1[index].calculated_totalprice = total;
            textArray1[index].calcprice = total
            textArray1[index].sd_total_amount = total


        }
        else {
            const Price = (parseFloat(totalprice)).toFixed(2)

            textArray1[index].calcprice = Price;
            textArray1[index].sd_total_amount = Price;

            console.log("set tax");
        }


        textArray1[index].calcprice = event.target.value * textArray1[index].sd_qty
        textArray1[index].sd_total_amount = event.target.value * textArray1[index].sd_qty

        setTableRow(textArray1)
    }

    const getSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "refcode": text,
                "companyid": company,
                "branchid": branch
            }
        }

        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                listClickrefcode(
                    key,
                    responseJson.data[0].products_id,
                    responseJson.data[0].products[0].pb_inter_barcode,
                    responseJson.data[0].products[0].pb_id,
                    responseJson.data[0].product_refcode,
                    responseJson.data[0].product_name,
                    responseJson.data[0].products,
                    0
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onSave = () => {
        const exampleArray = []
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].product_batch_id == "") {
                // tableRow.splice(i, 1)
            }
            else {
                exampleArray.push(tableRow[i])
            }
        }
        // var lpodate1;
        // if(purchaselpoid == ""){
        //     console.log("no id")
        //     lpodate1 = lpodate.toISOString().split('T')[0]
        // }
        // else{
        //     console.log("has id")
        //     lpodate1 = lpodate.split('T')[0]
        // }
        // console.log("tableRow", exampleArray)
        var data = {
            // "purchase_id": purchaseid,
            // "purchase_user_id": userId,
            // "purchase_bill_type": billType,
            // "purchase_ref_no": refno,
            // "purchase_salesman": salesman,
            // "purchase_invoice": invoiceno,
            // "purchase_date": date,
            // "purchase_lpo_no": lpono,
            // // "purchase_lpo_date":  lpodate1,
            // "purchase_customer": supplier,
            // "purchase_del_no": delno,
            // "purchase_order_no": orderno,
            // "purchase_address": address,
            // "purchase_mob_no": mobile,
            // "purchase_qt_no": quotation,
            // "purchase_crdt_limit": creditLimit,
            // "purchase_balance": balance,
            // "purchase_limit_balance": limitbalance,
            // "purchase_total": grandtotal,
            // "purchase_discount": discount,
            // "purchase_other_charges": otherCharges,
            // "purchase_advance": advance,
            // "purchase_details": exampleArray,
            // "purchase_branchid": userbranchid,
            // "purchase_companyid": usercompanyid,
            // "purchase_lpo_id": purchaselpoid,
            // "payable": payable,
            // "mode": paymentmode,
            // "purchase_landingcost": landingCost,
            // "purchase_roundof": roundoff,
            // "pd_expiry": date2


            "sales_id": salesid,
            "sales_user_id": userId,
            "sales_bill_type": billType,
            "sales_ref_no": refno,
            "sales_salesman": salesman,
            "sales_date": date,
            "sales_lpo_no": lpono,
            // "purchase_lpo_date":  lpodate1,
            "sales_customer": customer,
            "sales_invoice": invoiceno,
            "sales_del_no": delno,
            "sales_order_no": orderno,
            "sales_address": address,
            "sales_mob_no": mobile,
            "sales_qt_no": quotation,
            "sales_crdt_limit": creditLimit,
            "sales_balance": balance,
            "sales_limit_balance": limitbalance,
            "sales_total": grandtotal,
            "sales_discount": discount,
            "sales_other_charges": otherCharges,
            "sales_advance": advance,
            "sales_details": exampleArray,
            "sales_branchid": userbranchid,
            "sales_companyid": usercompanyid,
            "sales_lpo_id": purchaselpoid,
            "payable": payable,
            "mode": paymentmode,
            "sales_landingcost": landingCost,
            "sales_roundof": roundoff,
            "pd_expiry": date2,
            "customer_ledger": ledgerName,
            "customer_name": customername,
            "customer_building": building,
            "customer_street": street,
            "customer_zone": zone,
            "customer_mobile": mobile,
            "customer_whatsapp": ordertype,
            "customer_area": area,
            "customer_remarks": remarks

            // "customer_name": "raju",
        }
        console.log("purchase data", data)
        fetch(global.url + "updateSalessinglereturn", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response purchase", responseJson)
                if (responseJson.error == true) {
                    alert(responseJson.message)
                }
                else {
                    alert(responseJson.message)
                    // const win = window.open('/purchaseprint/' + responseJson.data, "_blank");
                    // win.focus();
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const disountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice / 100) * input[index].discount
        input[index].sd_total_amount = input[index].calcprice - disc
        setTableRow(input)
    }

    const getdata2 = (search) => {
        setSearchmobile(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "mobile": search,
            "ordertype": "approved",
            "type": userdata.user_type,
            "branchid": branch
        }
        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setSalesorderlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getdata3 = (search) => {
        setSearchinvoice(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "invoice": search,
            "ordertype": "approved",
            "type": userdata.user_type,
            "branchid": branch
        }
        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setSalesorderlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getdelfilter = (search) => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "salesmanid": search,
            "ordertype": "approved",
            "type": userdata.user_type,
            "branchid": branch
        }
        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setSalesorderlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    // const onPopup = () => {
    //    setModal(true) 
    //     var userdata = JSON.parse(localStorage.getItem("user"))
    //     var branch = JSON.parse(localStorage.getItem("branch"))
    //     var company = JSON.parse(localStorage.getItem("company"))


    //     var data = {
    //       "type":  userdata.user_type,
    //       "companyid": company
    //     }
    //     console.log("data", data)
    //     fetch(global.url + "viewPurchaseorder", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         console.log("purchase response", responseJson)
    //         if(responseJson.status == "true") {
    //             setPurchaseorderlist([])
    //             console.log("true")
    //         }
    //         else{
    //             console.log("false")
    //             setPurchaseorderlist(responseJson.data)
    //         }

    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    const purchaseOrderclick = (id) => {

        var data = {
            "purchaseorder_id": id,
        }
        console.log("data", data)
        fetch(global.url + "purchaseOrderFill", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setModal(false)
                console.log("Purchase Order response", responseJson)
                setRefno(responseJson.data.purchase_ref_no)
                setSalesman(responseJson.data.purchase_salesman)
                setLpono(responseJson.data.purchase_invoice)
                setCreditlimit(responseJson.data.purchase_crdt_limit)
                setBalance(responseJson.data.purchase_balance)
                setLimitbalance(responseJson.data.purchase_limit_balance)
                // setSupplier(responseJson.data.purchase_customer)
                setMobile(responseJson.data.purchase_mob_no)
                setAddress(responseJson.data.purchase_address)
                setQuotation(responseJson.data.purchase_qt_no)
                setTableRow(responseJson.data.purchaseorder_details)
                setPurchaselpoid(id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const roundOffchange = (event) => {
        setRoundoff(event)
        var round = amount - event
        var grandtotal1 = round
        setGrandtotal(grandtotal1)
    }

    const listClickrefcode = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].barcode = pb_inter_barcode
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])

        // setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", code: "", barcode: '', expiry: "", colorsize: [], productname: "", pricelevel: "", pd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", pd_total_amount: 0, pb_stock: "", sales_iscombo: "", pd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")
    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "invoice": searchinvoice,
            "supplier": searchsupplier
        }
        console.log("data", data)
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchase response", responseJson)
                if (responseJson.status == "true") {
                    setPurchaseorderlist([])
                    console.log("true")
                }
                else {
                    console.log("false")
                    setPurchaseorderlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })

    }, [searchinvoice, searchsupplier]);

    const upClick = () => {
        setInvoiceno(preCount => preCount + 1)
    }

    const downClick = () => {
        if (invoiceno > 1) {
            setInvoiceno(preCount => preCount - 1)
        }
    }

    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            "usertype": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("item look up", responseJson)
                setLookupproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const onfilter = (text, type) => {
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "intercode") {
            var data = {
                "type": 'intercode',
                "intercode": text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.error == false) {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])

                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (type == "superadmin") {
            var data = {
                "type": 'superadmin',
                "productname": text,
                "companyid": company
            }
            console.log("superadmin", data)
            fetch(global.url + "viewAllproductbatch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.status == 'false') {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const delChange = (event) => {
        setDelboyid(event.target.value)
        getdelfilter(event.target.value)
    }

    const getbarcodename = (id) => {
        setIndexstate(indexState + 1)
        console.log("index", indexState)
        setProductModal(false)
        getSearch(id, "refcode", indexState)
    }

    const indexChange = (index) => {
        console.log("index", index)
        setIndexstate(index)
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }

        var date = new Date(datestring)
        var month = pad2(date.getMonth() + 1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year = date.getFullYear();

        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US',
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
        //   console.log("timeString12hr", timeString12hr)

        var formattedDate = day + "/" + month + "/" + year;
        return formattedDate; //03/02/2021
    }

    const salesOrderDetails = () => {
        var data = {
            'invoice_no': invoiceno
        }
        fetch(global.url + "SalesdetailByInvoiceno", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("responseJson find", responseJson)
                if (responseJson.error == false) {
                    setReturn(responseJson.data.sales_isreturn)
                    setDate(responseJson.data.date)
                    setSalesid(responseJson.data.sales_id)
                    setBuilding(responseJson.data.buildingno)
                    setRemarks(responseJson.data.remarks)
                    setOrdertype(responseJson.data.ordertype_id)
                    setLpono(responseJson.data.salesorderno)
                    setStreet(responseJson.data.street)
                    setReference(responseJson.data.reference)
                    setZone(responseJson.data.zone)
                    setMobile(responseJson.data.mobno)
                    setArea(responseJson.data.area)
                    setMobile(responseJson.data.mobno)
                    setCustomername(responseJson.data.customer)
                    setSalesman(responseJson.data.deliveryboy)
                    setDiscount(responseJson.data.discount)
                    setRoundoff(responseJson.data.roundoff)
                    setLedgerName(responseJson.data.ledger)
                    setOthercharge(responseJson.data.othercharges)
                    setTotal(responseJson.data.total)
                    setGrandtotal(responseJson.data.grandtotal)
                    setPayable(responseJson.data.grandtotal)
                    if (responseJson.data.details) {
                        setTableRow(responseJson.data.details)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const salesOrderclick = (id) => {

        var data = {
            "companyid": usercompanyid,
            "salesorderid": id,
            "type": usertype
        }
        fetch(global.url + "viewSalesOrderDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("responseJson get", responseJson)
                setOrderpopup(false)
                setSalesorderid(id)
                setSalesid(responseJson.data.sales_id)
                setCustomername(responseJson.data.customer_name)
                setBuilding(responseJson.data.customer_building)
                setArea(responseJson.data.customer_area)
                setRemarks(responseJson.data.customer_remarks)
                setOrdertype(responseJson.data.sales_bill_type_id)
                setStreet(responseJson.data.customer_street)
                setZone(responseJson.data.sales_zone)
                setMobile(responseJson.data.customer_mobile)
                setLpono(responseJson.data.sales_invoice)
                setOthercharge(responseJson.data.sales_other_charges)
                setLedgerName(responseJson.data.legder)
                setDiscount(responseJson.data.sales_discount)
                if (responseJson.data.sales_roundoff == null) {
                    setRoundoff(0)
                }
                else {
                    setRoundoff(responseJson.data.sales_roundoff)
                }



                setReplaceorder(responseJson.data.salesorder_replacebefore)
                if (responseJson.data.salesorder_replacebefore == 1) {
                    setDeliverycharge(responseJson.data.delivery_charge)
                }
                if (responseJson.data.replacereturn == 1 || responseJson.data.replacereturn == 2) {
                    setReplacereturn(responseJson.data.replacereturn)
                    setVariationamount(responseJson.data.variation_amount)
                    setDeliverycharge(responseJson.data.delivery_charge)
                    const totalamt = parseFloat(responseJson.data.variation_amount) + parseFloat(responseJson.data.delivery_charge)
                    setTotalaftervariation(totalamt)
                    // var data = responseJson.data.variation_amount + responseJson.data.delivery_charge
                    // setTotalamount(data)
                }

                if (responseJson.data.sales_details) {
                    setTableRow(responseJson.data.sales_details)
                }
                else {
                    setTableRow([])
                }

                setReference(responseJson.data.sales_reference)
                setSalesman(responseJson.data.sales_salesman)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onPopup = () => {
        setOrderpopup(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid": company,
            "ordertype": "approved",
            "type": userdata.user_type,
            "branchid": branch
        }
        console.log("data", data)
        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("sales order response", responseJson)
                if (responseJson.error == true) {
                    setSalesorderlist([])
                }
                else {
                    setSalesorderlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ margin: 20 }}>
                    <Row>
                        <Col xs={12} md={2}>
                            <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Sales Return</p>
                        </Col>
                        <Col xs={12} md={2}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField label="Invoice" value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                        {/* <Col md={1}>
                            <div style={{ marginTop: -8 }}>
                                <div>
                                    {invoNum == invoiceno ?
                                        <Button style={{ backgroundColor: '#6d37b9', padding: 0 }} variant="contained"><FaAngleUp color="White" fontSize={15} /></Button> :
                                        <Button onClick={upClick} style={{ backgroundColor: '#6d37b9', padding: 0 }} variant="contained"><FaAngleUp color="White" fontSize={15} /></Button>}
                                </div>
                                <div>
                                    <Button onClick={downClick} style={{ backgroundColor: '#6d37b9', padding: 0 }} variant="contained"><FaAngleDown color="White" fontSize={15} /></Button>
                                </div>
                            </div>
                        </Col> */}
                        <Col md={2}>
                            <Button className='btn1' onClick={salesOrderDetails} style={{ border: 'none', color: '#fff', height: 30 }} variant="outlined">Find</Button>
                        </Col>
                        {/* <Col md={5}>
                        <div style={{float: 'right'}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date"
                                    value={date}
                                    minDate={new Date(begindate)}
                                    onChange={(newValue) => {
                                    setDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div> 
                    </Col> */}
                    </Row>
                    <div>
                        <Row>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Order Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={ordertype}

                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {ordertypelist.map((row, index) => {
                                            return (
                                                <MenuItem value={row.ordertype_id}>{row.ordertype_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Customer" autoComplete="off" onChange={(e) => setCustomername(e.target.value)} value={customername} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Phone" onChange={(e) => setMobile(e.target.value)} value={mobile} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Building No." onChange={(e) => setBuilding(e.target.value)} value={building} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Street" disabled onChange={(e) => setStreet(e.target.value)} value={street} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Zone" disabled onChange={(e) => setZone(e.target.value)} value={zone} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Area" disabled value={area} onChange={(e) => setArea(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Remarks" disabled value={remarks} onChange={(e) => setRemarks(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Reference" disabled onChange={(e) => setReference(e.target.value)} value={reference} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesman}

                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {/* {userList.map((row, index) => {
                                            return (
                                                <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                            )
                                        })} */}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Ledger</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={ledgerName}

                                        label="Age"
                                        onChange={ledgerChange}
                                        size="small"
                                    >

                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>

                                        {ledgerList.map((row, index) => {
                                            return (
                                                <MenuItem value={row.ledger_id}>{row.ledger_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Col>
                            {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }} onClick={onPopup}>
                                <TextField label="Salesorder No." className="cursor-pointer" disabled autoComplete="off" onChange={(e) => setLpono(e.target.value)} value={lpono} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>              */}
                        </Row>

                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12}>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>SlNo</th>
                                            <th>Code</th>
                                            <th>color-size</th>
                                            <th>Product name</th>
                                            {/* <th>Price level</th> */}
                                            <th>Qty</th>
                                            {/* <th>Unit</th>
                                            <th>Price</th> */}
                                            {/* <th>Calc Price</th>
                                                <th>Discount(%)</th> */}
                                            <th>Amount</th>
                                            {/* <th>Display zone</th> */}
                                            {/* <th>Close</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRow.map((item, index) => {
                                            return (
                                                <tr style={item.sod_iscancel == 1 ? { background: 'red' } : null}>
                                                    <td >{index + 1}</td>
                                                    <td>
                                                        {
                                                            item.sales_isedit == 1 ?
                                                                <input style={{ border: 'none',background:'none' }} autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} /> :
                                                                <input style={{ border: 'none',background:'none' }} autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                        }

                                                        {hasResults && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ?
                                                                                item.products.map(result => {
                                                                                    return (
                                                                                        // <li key={result.url}>
                                                                                        //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        //         index, 
                                                                                        //         result.pb_id,
                                                                                        //         result.product_refcode,
                                                                                        //         result.product_name,
                                                                                        //         result.pricelevel_name,
                                                                                        //         "1",
                                                                                        //         result.unit_name,
                                                                                        //         result.pb_salesrate,
                                                                                        //         result.pb_salesrate,
                                                                                        //         0,
                                                                                        //         result.pb_salesrate,
                                                                                        //         result.pricelevel_percentage,
                                                                                        //         result.pb_stock,
                                                                                        //         0,
                                                                                        //         result.color_name,
                                                                                        //         result.size_name,
                                                                                        //         result
                                                                                        //         )}>
                                                                                        //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                                        //     </a>
                                                                                        // </li>
                                                                                        <li key={item.url}>
                                                                                            <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                                index,
                                                                                                item.products_id,
                                                                                                item.product_refcode,
                                                                                                item.product_name,
                                                                                                item.products,
                                                                                                0
                                                                                            )}>
                                                                                                {item.product_refcode} - {item.product_name}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }) :
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        index,
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.pricelevel_name,
                                                                                        "1",
                                                                                        item.unit_name,
                                                                                        item.product_comboprice,
                                                                                        item.product_comboprice,
                                                                                        0,
                                                                                        item.product_comboprice,
                                                                                        item.pricelevel_percentage,
                                                                                        item.product_combostock,
                                                                                        1
                                                                                    )}>
                                                                                        {item.product_refcode} - {item.product_name}
                                                                                    </a>
                                                                                </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                    {
                                                        lpono == "" ?
                                                            <td>
                                                                <FormControl sx={{ width: '100%' }}>
                                                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-helper-label"
                                                                        id="demo-simple-select-helper"
                                                                        value={item.product_batch_id}
                                                                        label="Age"
                                                                        onChange={colorChange}
                                                                        size="small"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {item.colorsize.map((row, index) => {
                                                                            return (
                                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </td> :
                                                            <td>
                                                                {item.fetch_products ? null :
                                                                    <p>{item.color} - {item.size}</p>}
                                                            </td>
                                                    }
                                                    <td style={{ fontSize: 10 }}>

                                                        {item.sales_iscombo == 0 || item.sales_iscombo == null ?

                                                            <p> {item.productname}
                                                                {/* - {item.color} - {item.size}  */}
                                                            </p> :

                                                            item.sales_iscombo == 1 && item.addnew == false ?
                                                                <div>
                                                                    {/* <Button style={{height: 30}} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button> */}
                                                                    {

                                                                        item.fetch_products.map((result, k) => {
                                                                            return (
                                                                                <p style={{ fontSize: 10 }}>
                                                                                    {result.product_name} - {result.color} - {result.size} - (Qty {result.quantity})
                                                                                </p>
                                                                            )
                                                                        })
                                                                    }

                                                                </div> :

                                                                null


                                                        }
                                                    </td>
                                                    {/* <td>{item.price_level} </td> */}
                                                    <td>
                                                        {item.sales_iscombo == 0 || item.sales_iscombo == null ?
                                                            <div>
                                                                {item.sales_isedit == 1 ?


                                                                    <input style={{ outline: 0, border: 'none' }} min="1" disabled autocomplete="off" type="number" name="query" value={item.sd_qty} onChange={(event) => qtyChange(event, index)} /> :
                                                                    <input style={{ outline: 0, border: 'none' }} min="1" autocomplete="off" type="number" name="query" value={item.sd_qty} onChange={(event) => qtyChange(event, index)} />
                                                                }
                                                                {
                                                                    <label style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>stock: {item.pb_stock}</label>
                                                                }
                                                            </div> :
                                                            null
                                                        }
                                                    </td>
                                                    {/* <td>{item.unit}</td>
                                                    <td>{item.price}</td> */}
                                                    {/* <td>{item.calcprice}</td>
                                                        
                                                        <td>
                                                        {item.sales_isedit == 1 ?
                                                        <input autocomplete="off" disabled  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                                        :<input autocomplete="off" disabled  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                                        }
                                                        </td> */}
                                                    <td>{item.sd_total_amount}</td>

                                                    <td>
                                                        {item.sales_isedit == 1 ? null : <Button onClick={() => DeleteRow(index, item.amount)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant='contained'><AiFillMinusCircle color="red" fontSize={30} style={{ marginRight: 10 }} /></Button>}
                                                    </td>
                                                    {/* <td></td> */}
                                                    {/* <td>
                                                            {item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}
                                                        </td> */}

                                                    {/* <td>  <Button onClick={() => DeleteRow(index, item.amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button></td> */}
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </Table>
                                <Button className='return' onClick={() => addRow()}
                                    // style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} 
                                    variant="contained">Add New</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={2} style={{ marginTop: 20, marginBottom: 20 }}>
                                {return1 == 1 ?
                                    <p style={{ color: "red", fontSize: 20, marginTop: 10 }}>Returned</p> :
                                    <div>
                                        {
                                            btnloader ?
                                                <Loader
                                                    type="Oval"
                                                    color="#92c99f"
                                                    height={30}
                                                    width={30}
                                                    style={{ float: 'left', }}
                                                /> :
                                                <Button className='btn1' onClick={onSave} style={{ borderRadius: 20 }} variant="contained">Return</Button>
                                        }
                                    </div>}


                                {/* <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button>
                            <Button onClick={salesOrderDetails} style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><GiCash  color="White" fontSize={20} style={{marginRight: 10}} />Find</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Close</Button> */}
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Total" disabled value={amount.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Discount" disabled autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Delivery Charge" disabled autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} defaultValue={otherCharges} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{ marginTop: 20 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Cash Ledger" disabled onChange={(e) => setGrandtotal(e.target.value)} value={currentcash} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                        </Row>
                        {/* <Row>
                        <Col  xs={12}>
                            <Row>
                                {
                                    replacereturn == 1 ?
                                    <Row>
                                            <Col xs={12} md={1} style={{marginTop: 30}}>
                                                <p>Variation Amount</p>
                                            </Col>
                                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={variationamount} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col> 
                                    
                                            <Col xs={12} md={1} style={{marginTop: 30}}>
                                                <p>Extra Delivery Charge</p>
                                            </Col>
                                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={deliverycharge} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col> 
                                            <Col xs={12} md={1} style={{marginTop: 30}}>
                                                <p>Total After variation</p>
                                            </Col>
                                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={totalaftervariation} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col> 
                                    </Row>:
                                    null
                                }
                                {
                                    replacereturn == 2 ?
                                    <Row>
                                            <Col xs={12} md={1} style={{marginTop: 30}}>
                                                <p>Variation Amount</p>
                                            </Col>
                                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={variationamount} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col> 
                                    
                                            <Col xs={12} md={1} style={{marginTop: 30}}>
                                                <p>Extra Delivery Charge</p>
                                            </Col>
                                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={deliverycharge} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col> 
                                            <Col xs={12} md={1} style={{marginTop: 30}}>
                                                <p>Total After variation</p>
                                            </Col>
                                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={totalaftervariation} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            </Col> 
                                    </Row>:
                                    null
                                }
                                {
                                    replaceorder == 1 ?
                                <Col xs={12} md={2} >
                                    <FormControl sx={{ mt:2,  width: '100%' }}>
                                        <FormControlLabel control={
                                        <Checkbox checked={replaceorder == 0? false : true} disabled />
                                        } label="Replaced Order" />
                                    </FormControl>
                                </Col>: null
                                }
                                {
                                    replaceorder == 1 ?
                                    <Row>
                                        <Col xs={12} md={1} style={{marginTop: 30}}>
                                            <p>Extra Delivery Charge</p>
                                        </Col>
                                        <Col xs={12} md={2} style={{marginTop: 30}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField disabled  value={deliverycharge} id="outlined-basic" variant="outlined"  size="small"/>
                                            </FormControl>
                                        </Col> 
                                    </Row>:
                                    null
                                }
                            </Row>
                            
                        </Col>
                        
                    </Row>   */}
                    </div>
                </div>
            </Paper>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Purchase Order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={searchsupplier}
                                    label="Age"
                                    onChange={searchsupplierChange}
                                    size="small"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {supplierList.map((row, index) => {
                                        return (
                                            <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField onChange={(e) => setSearchinvoice(e.target.value)} value={searchinvoice} placeholder='Invoice Number' id="outlined-basic" variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12}>
                            <Table hover responsive bordered className="section-to-print">
                                <thead>
                                    <tr>
                                        <th>Request Invoice</th>
                                        <th>Date</th>
                                        <th>Supplier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseorderlist.map(result => {
                                        return (
                                            <tr style={{ cursor: "pointer" }} onClick={() => purchaseOrderclick(result.purchaseorder_id,
                                                result.purchaseorder_invoice, result.purchaseorder_date)}>
                                                <td>{result.purchaseorder_invoice}</td>
                                                <td>{result.purchaseorder_date}</td>
                                                <td>{result.supplier_name}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {/* <Row>
                    <Col xs={12}>
                        <ul>
                        {purchaseorderlist.map((row, index) => {
                            return (
                                <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => purchaseOrderclick(row.purchaseorder_id,
                                    row.purchaseorder_invoice, row.purchaseorder_date)}>
                                    <li className="pay-lists">{row.purchaseorder_invoice}</li>
                                </a>
                            )}
                            )
                            }
                        </ul>
                    </Col>
                    
                </Row> */}
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={productmodal}
                onHide={() => setProductModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined" size="small" label="Barcode" />
                        </Col>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined" size="small" label="Product Name" />
                        </Col>
                        {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} >
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Ref Code</th>
                                        <th>Barcode</th>
                                        <th>Product Name</th>
                                        <th>Stock</th>
                                        <th>Sales Price</th>
                                    </tr>
                                </thead>
                                {/* {productlistitem.length == 0 ? */}
                                <tbody>
                                    {lookupproductlist?.map((column) => (
                                        <tr>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_refcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }} >{column.pb_inter_barcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_name}</td>
                                            <td>{column.pb_stock}</td>
                                            <td>{column.pb_salesrate}</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={orderpopup}
                onHide={() => setOrderpopup(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Sales Order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField onChange={(e) => getdata2(e.target.value)} placeholder="Mobile Number" value={searchmobile} id="outlined-basic" variant="outlined" size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField onChange={(e) => getdata3(e.target.value)} placeholder="Invoice Number" value={searchinvoice} id="outlined-basic" variant="outlined" size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={delboyid}
                                            label="Age"
                                            onChange={delChange}
                                            size="small"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {/* {userList.map((row, index) => {
                                                return (
                                                    <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                                )
                                            })} */}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12}>
                            <Table hover responsive bordered className="section-to-print">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Invoice</th>
                                        <th>Date</th>
                                        <th>Customer</th>
                                        <th>Mobile</th>
                                        <th>Building</th>
                                        <th>Street</th>
                                        <th>zone</th>
                                        <th>Area</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesorderlist.map(result => {
                                        return (
                                            <tr style={{ cursor: "pointer" }} onClick={() => salesOrderclick(result.salesorder_id)}>
                                                <td>{result.user_name}</td>
                                                <td>{result.salesorder_invoice}</td>
                                                <td>{result.salesorder_date}</td>
                                                <td>{result.customer_name}</td>
                                                <td>{result.customer_mobile}</td>
                                                <td>{result.customer_building}</td>
                                                <td>{result.customer_street}</td>
                                                <td>{result.customer_zone}</td>
                                                <td>{result.customer_area}</td>
                                                <td>{result.salesorder_total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default SalesSingleReturn;


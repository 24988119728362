import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './login.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [branchlist, setBranchlist] = useState([]);
    const [branch, setBranch] = useState("");
    let history = useHistory();
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const [checked, setChecked] = useState(true);

    const [emailmodal, setEmailmodal] = useState(false);

    const [email, setEmail] = useState("");


    const [finyear, setFineyear] = useState("");
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);
    const [modal, setModal] = useState(false);

    const vertical = "top"
    const horizontal = "center"

    const [posusername, setPosusername] = useState("");
    const [pospassword, setPospassword] = useState("");
    const [counter, setCounter] = useState("");
    const [counterlist, setCounterlist] = useState([]);

    const [cash500, set500] = useState(0);
    const [cash200, set200] = useState(0);
    const [cash100, set100] = useState(0);
    const [cash50, set50] = useState(0);
    const [cash10, set10] = useState(0);
    const [cash5, set5] = useState(0);
    const [cash1, set1] = useState(0);
    const [coins, setCoins] = useState(0);
    const [total, setTotal] = useState(0);

    const [path, setPath] = useState('')

    const onSubmit = () => {
        setLoader(true)
        var data = {
            "user_name": username,
            "user_password": password,
            "branch_id": branch,
            "company_id": id
        }
        fetch(global.url + "login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoader(false)
                if (responseJson.error == true) {
                    // alert(responseJson.message)
                    setOpen(true)
                    setErrormessage(responseJson.message)
                }
                else {
                    localStorage.setItem("isAuthenticated", true);
                    localStorage.setItem("company", id);
                    localStorage.setItem("user", JSON.stringify(responseJson.data))
                    localStorage.setItem("formname", JSON.stringify(responseJson.access));

                    setPath(responseJson.data.user_type)
                    if (responseJson.data.user_type == 0) {
                        history.push('/dashboard')
                    }
                    else if (responseJson.data.user_type == 7) {
                        localStorage.setItem('ABC', true)

                        // localStorage.removeItem("isAuthenticated")
                        history.push('/masterdashboard')

                    }
                    else {
                        history.push('/transaction/Salesorder/add')
                    }
                }
                console.log("login Response", responseJson)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        getCounter()
        const data = {
            "company_id": id
        }
        fetch(global.url + "viewAllBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("branch list response", responseJson)
                setBranchlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    const getCounter = () => {
        const data = {
            "companyid": id
        }
        console.log("data", data)
        fetch(global.url + "dropdownCashCounter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log('getcounter', responseJson)
                if (responseJson.error == false) {
                    setCounterlist(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const branchselect = (event) => {
        console.log("clicked")
        setBranch(event.target.value)
        localStorage.setItem("branch", event.target.value)
    }

    const yearChange = (event) => {
        setFineyear(event.target.value)
    }

    const counterChange = (event) => {
        setCounter(event.target.value)
    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            setChecked(true)
        }
        else {
            setChecked(false)
        }
    }

    const poslogin = () => {
        if (branch == "") {
            alert("Please Select branch")
            return;
        }

        var data = {
            "user_name": posusername,
            "user_password": pospassword,
            "counter": counter,
            "countersum": total
        }
        console.log("pos data", data)
        fetch(global.url + "poslogin", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    localStorage.setItem("isAuthenticated", true);
                    localStorage.setItem("company", id);
                    localStorage.setItem("pos", true);
                    localStorage.setItem("user", JSON.stringify(responseJson.data))
                    localStorage.setItem("formname", JSON.stringify(responseJson.data.access));
                    history.push('/Sales/pos')
                }
                else {
                    alert(responseJson.message)
                }
                console.log("pos login Response", responseJson)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const forgotClick = () => {
        setEmailmodal(true)
        setEmail("")
    }

    const forgotSubmit = () => {
        setLoader(true)
        var data = {
            "emailid": email,
        }
        fetch(global.url + "forgotPassword", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoader(false)

                if (responseJson.error == false) {
                    alert("New Password sent to the Email account")
                    setEmailmodal(false)
                    setEmail("")
                }
                else {
                    alert(responseJson.message)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        var total = (parseInt(cash500) * 500) + (parseInt(cash200) * 200) + (parseInt(cash50) * 50) + (parseInt(cash10) * 10) + (parseInt(cash5) * 5) + (parseInt(cash100) * 100) + (parseInt(cash1) * 1) + (parseInt(coins))
        console.log("total", total)
        setTotal(total)
    }, [cash500, cash200, cash100, cash50, cash10, cash5, cash1, coins])


    return (
        <div className="App">
            <header className="Login-component">
                <Container fluid>
                    <Row >
                        <Col xs={12} style={{ marginTop: 20 }}>
                            <div className="login-box">
                                <div className="box-inside">
                                    <div className="login-title">
                                        {/* <strong>NASSCRIPT
                                        </strong> */}
                                        <strong>{global.title}
                                        </strong>
                                        <h3></h3>
                                    </div>

                                    <div class="frm-title">
                                        Welcome back! Please login to continue
                                    </div>
                                    <Form>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" value={username} onChange={(e) => setUsername(e.target.value)} id="outlined-basic" label="Username" variant="outlined" size="small" />
                                            <BsPersonFill color="#999" style={{ position: 'absolute', top: 0, left: 0, marginTop: 10, marginLeft: 10 }} />
                                        </FormControl>

                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField className="login-textfield" value={password} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="Password" variant="outlined" type="password" size="small" />
                                            <BsFillLockFill color="#999" style={{ position: 'absolute', top: 0, left: 0, marginTop: 10, marginLeft: 10 }} />
                                        </FormControl>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Financial Year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={finyear}
                                                label="Age"
                                                onChange={yearChange}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {/* <MenuItem value={"2021-2022"}>2021-2022</MenuItem> */}
                                                <MenuItem value={"2024-2025"}>2024-2025</MenuItem>

                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Select Branch</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={branch}
                                                label="Age"
                                                onChange={branchselect}
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {branchlist.map((item, index) => (
                                                    <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {/* <Row style={{marginTop: 10}}>
                                        <Col xs={6}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <FormControlLabel control={
                                                <Checkbox checked={checked} onChange={e => checkChange(e)} />
                                                } label="Remember me" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={6} >
                                            <div className="forgotpassword">
                                                <BsFillUnlockFill color="#999" size={12} className="unlockicon"/>
                                                <p className="forgot-text">Forgot password?</p>
                                            </div>
                                        </Col>
                                    </Row> */}
                                        <div >
                                            <div style={{ paddingBottom: 20 }}>
                                                {loader ? <Loader /> :
                                                    <a href="javascript:void(0)" onClick={() => {
                                                        onSubmit()
                                                    }} variant="primary" type="submit" style={{ marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5 }}>
                                                        Login
                                                        <FaArrowCircleRight color="#fff" size={12} className="lefticon" />
                                                    </a>
                                                }
                                            </div>
                                            <a href="javascript:void(0)" onClick={() => forgotClick()} style={{ textDecoration: "unset" }}>Forgot Password</a>
                                            <div>
                                                <a href="javascript:void(0)" onClick={() => setModal(true)}
                                                    variant="primary" type="submit" className="posBtn">
                                                    POS
                                                </a>
                                            </div>
                                        </div>
                                    </Form>
                                    <div class="frm-footer"><span className="nastext">NASSCRIPT</span> © 2021.</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="sm"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton >
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <p style={{ fontSize: 18, marginBottom: 'unset', marginTop: 'unset' }}>Counter Cash Opening</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField className="login-textfield" value={posusername} onChange={(e) => setPosusername(e.target.value)} id="outlined-basic" label="Username" variant="outlined" size="small" />
                                    <BsPersonFill color="#999" style={{ top: 0, left: 0, marginTop: 10, marginLeft: 10 }} />
                                </FormControl>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField value={pospassword} onChange={(e) => setPospassword(e.target.value)} className="login-textfield" id="outlined-basic" label="Password" variant="outlined" type="password" size="small" />
                                    <BsFillLockFill color="#999" style={{ position: 'absolute', top: 0, left: 0, marginTop: 10, marginLeft: 10 }} />
                                </FormControl>
                                <Row>
                                    <Col sm={6}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Select Counter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={counter}
                                                label="Age"
                                                onChange={counterChange}
                                                size="small"

                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {counterlist.map((item, index) => (
                                                    <MenuItem value={item.cc_id}>{item.cc_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Select Branch</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={branch}
                                                label="Age"
                                                onChange={branchselect}
                                                size="small"

                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {branchlist.map((item, index) => (
                                                    <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash500} onChange={(e) => set500(e.target.value)} className="login-textfield" label="500 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash200} onChange={(e) => set200(e.target.value)} className="login-textfield" label="200 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash100} onChange={(e) => set100(e.target.value)} className="login-textfield" label="100 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash50} onChange={(e) => set50(e.target.value)} className="login-textfield" label="50 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash10} onChange={(e) => set10(e.target.value)} className="login-textfield" label="10 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash5} onChange={(e) => set5(e.target.value)} className="login-textfield" label="5 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={cash1} onChange={(e) => set1(e.target.value)} className="login-textfield" label="1 x" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={coins} onChange={(e) => setCoins(e.target.value)} className="login-textfield" label="Coins" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={6}>
                                        <FormControl sx={{ width: '100%', mt: 3 }}>
                                            <TextField value={total} disabled className="login-textfield" label="Total" id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                                        <a href="javascript:void(0)" onClick={() => poslogin()}
                                            variant="primary" type="submit" style={{ marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 200, padding: 5, borderRadius: 5, textAlign: 'center' }}>
                                            Submit
                                        </a>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="sm"
                    show={emailmodal}
                    onHide={() => setEmailmodal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton >
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <p style={{ fontSize: 18, marginBottom: 'unset', marginTop: 'unset' }}>Forgot Password</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined" size="small" />
                            </FormControl>
                            {loader ? <Loader /> :
                                <Button onClick={() => forgotSubmit()} variant="contained" style={{ marginTop: 20 }}>Submit</Button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </header>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Login;

import React, { Component, useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import { Table, Row, Col, Modal, Container } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";

function Damageentry(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")

    const [tableRow, setTableRow] = React.useState([{ key: "", sa_productbatch_fk: "", product_id: "", code: "", pbexp_date: "", pbexp_id: "", barcode: "", narration: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "" }] }])
    const inputRef = useRef();
    const resultsRef = useRef();
    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("");
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [productlist, setProductlist] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState('');
    const [otherCharges, setOthercharge] = React.useState(10);
    const [advance, setAdvance] = React.useState('');

    const [lookupproductlist, setLookupproductlist] = React.useState([]);
    const [productmodal, setProductModal] = useState(false)
    const [indexState, setIndexstate] = React.useState(0)

    const [docno, setDocno] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    // const [advance, setAdvance] = React.useState('');

    const [narration, setNarration] = React.useState('')
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [purchaseLedgid, setPurchaseLedgeid] = React.useState("");
    const [ledgerlist, setLedgerlist] = React.useState([]);
    const [ledgerid, setLedgerid] = React.useState("");
    const [grandtotal, setGrandtotal] = React.useState("0");
    const [barcode, setBarcode] = useState("")

    const hasResults = code.length > 0;
    const hasResultsbarcode = barcode.length > 0;
    const hasResultsProduct = productname.length > 0;

    useEffect(() => {
        getBilltype()
        getSelectedPurchaseac()
        getLedger()
    }, [])

    useEffect(() => {
        getInvoiceno(billType)
    }, [billType])

    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "id": 12
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
                setBilltype(responseJson.data[0].billset_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno()
    };


    const ledgerChange = (event) => {
        setLedgerid(event.target.value);
    };

    const getInvoiceno = () => {
        var data = {
            "id": billType
        }
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchaseinvoiceno", responseJson.data.invoiceno)

                if (responseJson.error == false) {
                    setInvoiceno(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getSelectedPurchaseac = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid": company
        }
        fetch(global.url + "getSelectedPurchaseac", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    console.log("viewLedger", responseJson)
                    setPurchaseLedgeid(responseJson.data[0].ledgerid)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getLedger = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid": company
        }
        fetch(global.url + "viewLedger", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status == 'false') {
                    setLedgerlist(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleBarcodechange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].pb_inter_barcode = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setBarcode(event.currentTarget.value);
        getBarCodeSearch(event.currentTarget.value, type, key)
    }
    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        console.log("type", type)
        getnameSearch(event.currentTarget.value, type, key)
    }
    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)
    }

    const listClickname = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].barcode = pb_inter_barcode
        input[index].inputbarcode = pb_inter_barcode
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", sa_productbatch_fk: "", product_id: "", code: "", pbexp_date: "", pbexp_id: "", barcode: "", narration: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")
        setProductname("")
    }
    const listClickrefcode = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].barcode = pb_inter_barcode
        input[index].inputbarcode = pb_inter_barcode
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", sa_productbatch_fk: "", product_id: "", code: "", pbexp_date: "", pbexp_id: "", barcode: "", narration: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")
    }


    const listClickbarcode = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
        console.log('pbid', pbid)

        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].barcode = pb_inter_barcode
        // input[index].inputbarcode = pb_inter_barcode
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        // setBarcodeInput(pb_inter_barcode)
        input[index].product_batch_id = pbid
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", code: "", barcode: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: 0, discount: "", pqd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [] }])
        setCode("")
    }

    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        console.log("color1", colorsize1)

        var input = [...tableRow]
        input[index].key = index
        input[index].sa_productbatch_fk = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        input[index].sales_iscombo = iscombo
        input[index].sa_quantity = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size

        console.log("no customer")

        console.log("no customer no per")
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if (discount) {

            var totalcalc = (calcprice / 100) * discount
        }
        else {

            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sd_total_amount = calcprice - totalcalc

        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", sa_productbatch_fk: "", product_id: "", code: "", pbexp_date: "", pbexp_id: "", barcode: "", narration: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
        setCode("")

        var total1 = total + totalcalc
        console.log("totalcalc", total1)
        setTotal(total1)
    }

    useEffect(() => {
        getDocno()
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    }, []);

    const getDocno = () => {
        fetch(global.url + "getAdjustid", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("stockdocno", responseJson.data.sa_id)
                setDocno(responseJson.data.sa_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getBarCodeSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "type": type,
            "barcode": text,
            "companyid": company,
            "branchid": branch
        }

        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                // setProductlist(responseJson.data)
                listClickbarcode(
                    key,
                    responseJson.data[0].products_id,
                    responseJson.data[0].products[0].pb_inter_barcode,
                    responseJson.data[0].products[0].pb_id,
                    responseJson.data[0].product_refcode,
                    responseJson.data[0].product_name,
                    responseJson.data[0].products,
                    0
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getnameSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "product_name": text,
                "companyid": company,
                "branchid": branch
            }
            fetch(global.url + "getProductbySearchpc", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson)
                    setProductlist(responseJson.data)
                })

                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const getSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "refcode": text,
                "companyid": company,
                "branchid": branch
            }
        }
        console.log("searchdata", data)

        fetch(global.url + "getProductbySearch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                listClickrefcode(
                    key,
                    responseJson.data[0].products_id,
                    responseJson.data[0].products[0].pb_inter_barcode,
                    responseJson.data[0].products[0].pb_id,
                    responseJson.data[0].product_refcode,
                    responseJson.data[0].product_name,
                    responseJson.data[0].products,
                    0
                )
                getExpProducts(responseJson.data[0].products[0].pb_id, key)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const getExpProducts = (id, index) => {
        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getexpiryByProductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("setexpdate", responseJson)
                setexpdate(index, responseJson.data[0].pbexp_date, responseJson.data[0].pbexp_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setexpdate = (index, date, id) => {
        var input = [...tableRow]
        input[index].pbexp_date = date
        input[index].pbexp_id = id
        setTableRow(input)
        addRow()
    }

    const getProductbybatch1 = (id, index) => {

        var data = {
            "pb_id": id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setDetails(id, index, responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].sa_productbatch_fk = id
        input[index].pb_stock = responseJson.pb_stock
        input[index].sa_quantity = 1
        input[index].unit = responseJson.unit_name
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount =
            input[index].sd_total_amount = responseJson.pb_salesrate
        setTableRow(input)
        addRow()
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].price * event.target.value
        textArray1[index].sa_quantity = event.target.value
        textArray1[index].calcprice = totalprice
        textArray1[index].sd_total_amount = totalprice
        setTableRow(textArray1)
    }

    const narrationChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].narration = event.target.value
        setTableRow(textArray1)
    }

    const addRow = () => {
        setTableRow([...tableRow, { key: "", sa_productbatch_fk: "", code: "", pbexp_date: "", pbexp_id: "", barcode: "", narration: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], fetch_products: [{ key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: "" }] }])
    }

    const deleteRow = (index, price) => {
        console.log("index", index)
        setTotal(total - price)
        var input = [...tableRow]
        input.splice(index, 1)
        console.log("input", input)
        setTableRow(input)
    }

    // const disountChange = (event, index) => {
    //     var input = [...tableRow]
    //     input[index].discount = event.currentTarget.value
    //     var disc = (input[index].calcprice/100) * input[index].discount
    //     input[index].sd_total_amount = input[index].calcprice - disc
    //     setTableRow(input)
    // }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }

        var date = new Date(datestring)
        var month = pad2(date.getMonth() + 1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year = date.getFullYear();

        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US',
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
        //   console.log("timeString12hr", timeString12hr)

        var formattedDate = year + "-" + month + "-" + day;
        return formattedDate; //03/02/2021
    }

    const onSubmit = () => {
        const exampleArray = []
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].sa_productbatch_fk == "" || tableRow[i].sales_isedit == 1) {
                // tableRow.splice(i, 1)
            }
            else {
                exampleArray.push(tableRow[i])
            }
        }
        console.log("tableRow", exampleArray)
        var datenw = formatDate(date)
        var data = {
            "type": "add",
            "companyid": usercompanyid,
            "branchid": userbranchid,
            "userid": userId,
            "narration": narration,
            "bill_type": "",
            "doc_date": datenw,
            "ref_no": refno,
            "doc_no": invoiceno,
            "adjustment_table": exampleArray,
            "ledgerid": ledgerid,
            "purchaseacledgerid": purchaseLedgid,
            "totalamout": grandtotal
        }
        console.log("data", data)
        fetch(global.url + "damageEntry", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.error == true) {
                    alert(responseJson.message)

                }
                else {
                    alert(responseJson.message)
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            "usertype": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("item look up", responseJson)
                setLookupproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const onfilter = (text, type) => {
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "intercode") {
            var data = {
                "type": 'intercode',
                "intercode": text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.error == false) {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])

                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (type == "superadmin") {
            var data = {
                "type": 'superadmin',
                "productname": text,
                "companyid": company
            }
            console.log("superadmin", data)
            fetch(global.url + "viewAllproductbatch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.status == 'false') {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const getbarcodename = (id) => {
        setIndexstate(indexState + 1)
        console.log("index", indexState)
        setProductModal(false)
        getSearch(id, "refcode", indexState)
        getBarCodeSearch(id, "barcode", indexState)
    }

    const indexChange = (index) => {
        console.log("index", index)
        setIndexstate(index)
    }

    useEffect(() => {
        getTotalammount()
    }, [tableRow]);

    const getTotalammount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
            totalAmount += parseFloat(tableRow[i].sd_total_amount)
            console.log("totalamount", tableRow[i].sd_total_amount)
        }
        setGrandtotal(parseFloat(totalAmount))
        console.log('setGrandtotal', grandtotal)
    }

    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Wastege</p>
                </div>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={6} md={2} style={{ marginTop: 30 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Doc Date"
                                            size="small"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Col>
                            {/* <Col sm={6} md={2} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={billType}
                                            label="Age"
                                            onChange={billtypeChange}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {billTypelist.map((row, index) => {
                                            return (
                                            <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                        )})}
                                        </Select>
                            </FormControl>
                        </Col> */}
                            <Col sm={6} md={2} style={{ marginTop: 30 }}>
                                {/* <FormControl sx={{width: '100%' }}>
                                <TextField label="Doc No." value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl> */}


                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Doc No." value={docno} disabled
                                        //  value={invoiceno}
                                        //  onChange={(e) => setInvoiceno(e.target.value)}
                                        id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>


                            </Col>
                            <Col sm={6} md={3} style={{ marginTop: 30 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Ledger</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={ledgerid}
                                        label="Age"
                                        onChange={ledgerChange}
                                        size="small"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {ledgerlist.map((row, index) => {
                                            return (
                                                <MenuItem value={row.ledger_id}>{row.ledger_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col sm={6} md={3} style={{ marginTop: 30 }}>
                                {/* <Button onClick={() => lookupClick()}  className="retail-btn" style={{backgroundColor: '#92c99f', borderRadius: 5, float: 'right'}} variant="contained">Item Lookup</Button>     */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col xs={12}>
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th>SlNo</th>
                                    <th style={{ width: 0 }}>Ref Code</th>
                                    <th>Barcode</th>
                                    <th>Product name</th>
                                    <th>Qty</th>
                                    <th>Unit</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Narration</th>
                                    <th>Exp Date</th>
                                    <th>Close</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRow.map((item, index) => {
                                    return (
                                        <tr>
                                            <td >{index + 1}</td>
                                            <td>
                                                {
                                                    item.sales_isedit == 1 ?
                                                        <input style={{ width: 100, border: 0, outline: 0 }} onFocus={() => indexChange(index)} autoFocus autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} /> :
                                                        <input style={{ width: 100, border: 0, outline: 0 }} onFocus={() => indexChange(index)} autoFocus autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                }
                                            </td>
                                            <td>
                                                <input autocomplete="off" value={item.inputbarcode} style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" onFocus={() => indexChange(index)}
                                                    onKeyDown={(event) => { if (event.key === 'Enter') { handleBarcodechange(event, index, "barcode") } }} />
                                            </td>

                                            {/* {item.barcode}  */}
                                            {/* </td> */}

                                            <td>
                                                {
                                                    <input style={{ width: 150, border: 0, outline: 0 }} autocomplete="off" ref={inputRef} type="text" name="query" value={item.productname} onChange={(event) => handleProductchange(event, index, "name")} />
                                                }
                                                {hasResultsProduct && selectedIndex == index && (
                                                    <div className="autocomplete">
                                                        <ul ref={resultsRef} className="people scrolling-div">
                                                            {productlist.map(item => {
                                                                return (
                                                                    item.product_fetchiscombo == 0 ?
                                                                        item.products.map(result => {
                                                                            return (
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickname(
                                                                                        index,
                                                                                        item.products_id,
                                                                                        result.pb_inter_barcode,
                                                                                        result.pb_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.products,
                                                                                        0

                                                                                    )}>
                                                                                        {item.product_name} -  {item.product_refcode}
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        }) :
                                                                        <li key={item.url}>
                                                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                index,
                                                                                item.products_id,
                                                                                item.product_refcode,
                                                                                item.product_name,
                                                                                item.pricelevel_name,
                                                                                "1",
                                                                                item.unit_name,
                                                                                item.product_comboprice,
                                                                                item.product_comboprice,
                                                                                0,
                                                                                item.product_comboprice,
                                                                                item.pricelevel_percentage,
                                                                                item.product_combostock,
                                                                                1
                                                                            )}>
                                                                                {item.product_refcode} - {item.product_name}
                                                                            </a>
                                                                        </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                            {/* <td style={{fontSize: 10}}>
                                            {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                <p style={{width: 300}}>{item.productname}</p> :
                                                null
                                                }
                                        </td> */}
                                            <td >
                                                {item.sales_iscombo == 0 || item.sales_iscombo == null ?
                                                    <div >
                                                        {item.sales_isedit == 1 ?
                                                            <input style={{ width: 50, border: 0, outline: 0 }} min="1" disabled autocomplete="off" type="number" name="query" value={item.sa_quantity} onChange={(event) => qtyChange(event, index)} /> :
                                                            <input style={{ width: 50, border: 0, outline: 0 }} min="1" autocomplete="off" type="number" name="query" value={item.sa_quantity} onChange={(event) => qtyChange(event, index)} />
                                                        }
                                                        {
                                                            <label style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>stock: {item.pb_stock}</label>
                                                        }
                                                    </div> :
                                                    null
                                                }
                                            </td>
                                            <td>{item.unit}</td>
                                            <td>{item.price}</td>
                                            <td>{item.sd_total_amount}</td>
                                            <td><input style={{ width: 150, border: 0, outline: 0 }} min="1" autocomplete="off" name="query" value={item.narration} onChange={(event) => narrationChange(event, index)} /></td>
                                            <td>{item.pbexp_date}</td>
                                            <td>
                                                {item.sales_isedit == 1 ? null : <Button onClick={() => deleteRow(index, item.amount)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant="contained">< AiFillMinusCircle color="#fc0303" fontSize={30} style={{ marginRight: 10 }} /></Button>}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <div style={{ marginTop: 20 }}>
                    <Row>
                        <Col sm={6} md={3} style={{ marginTop: 30 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField value={grandtotal} disabled id="outlined-basic" variant="outlined" label="Total Amount" size="small" />
                            </FormControl>
                        </Col>
                        <Col sm={6} md={9} style={{ marginTop: 30 }}>
                            <Button onClick={() => addRow()} style={{ float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20 }} variant="contained">Add New</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12}>
                            <TextareaAutosize
                                onChange={(e) => setNarration(e.target.value)}
                                value={narration}
                                minRows={3}
                                placeholder="Narration"
                                style={{ background: 'transparent', width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} >
                            <ul className="list-inline">
                                {/* <li><Button onClick={() => onSubmit("addstock")} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Add Stock</Button></li> */}
                                <li><Button onClick={() => onSubmit("minusstock")} style={{ backgroundColor: '#92c99f', border: '#92c99f' }} variant="outlined"><IconName.BsDownload style={{ marginRight: 10 }} />Submit</Button></li>
                                {/* <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Paper>
            <Modal
                size="lg"
                show={productmodal}
                onHide={() => setProductModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined" size="small" label="Barcode" />
                        </Col>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined" size="small" label="Product Name" />
                        </Col>
                        {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} >
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Ref Code</th>
                                        <th>Barcode</th>
                                        <th>Product Name</th>
                                        <th>Stock</th>
                                        <th>Sales Price</th>
                                    </tr>
                                </thead>
                                {/* {productlistitem.length == 0 ? */}
                                <tbody>
                                    {lookupproductlist?.map((column) => (
                                        <tr>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_refcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }} >{column.pb_inter_barcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_name}</td>
                                            <td>{column.pb_stock}</td>
                                            <td>{column.pb_salesrate}</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Damageentry;
import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Modal , Row, Col } from "react-bootstrap";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

function Purchasereturntaxreport() {

    const [dateFrom, setDatefrom] = React.useState(new Date)
    const [dateTo, setDateTo] = React.useState(new Date)

    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Purchase Return Tax Report  sada</p>
                </div>
                <Row style={{margin: 20}}>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={dateFrom}
                              onChange={(newValue) => {
                                  setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={dateTo}
                              onChange={(newValue) => {
                                  setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>

                  <Col xs={12} md={3} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%'}}>
                            {/* <TextField name="Zone"
                            onChange={(e) => setBatchcode(e.target.value)}
                            autoComplete="off" id="outlined-basic" label="Ref code" variant="outlined"  size="small"/> */}
                        </FormControl>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                    {/* <Button onClick={filter} style={{backgroundColor: '#92c99f', border: '#92c99f'}} variant="contained">Filter</Button> */}
                  </Col>
            </Row>


            </Paper>
        </div>
    )
}
export default Purchasereturntaxreport;
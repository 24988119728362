import '../Tax-Settings/TaxSettings.css';
import React, { Component, useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal, Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
// import { usePreviousMonthDisabled } from '@mui/lab/internal/pickers/hooks/date-helpers-hooks';
import { useHistory, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Purchaserequest(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [purchaseorderid, setPurchaseorderid] = useState("")

    const [combomodal, setCombomodal] = React.useState(false);
    const [combolist, setCombolist] = React.useState([]);
    const [sodidn, setSodidn] = React.useState("");
    const [delindex, setDelindex] = React.useState("");
    const [delproid, setDelproid] = React.useState("")

    
    const [combotable2, setCombotable2] = React.useState([
        {
            key: "",
            productid: "",
            color: "",
            size: "",
            fetch_id: "",
            product_name: "",
            fetch_quantity: "",
            quantity: "",
            stock: "",
        },
    ]);

    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [tableRow, setTableRow] = React.useState([{ key: "", product_batch_id: "",pb_inter_barcode: "", code: "",  productname: "", pricelevel: "", pod_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", purchaseorder_total: 0, colorsize: [], addnew: true, }])
    const [textArray, setTextarray] = React.useState([{ key: "", value: "" }])
    const [selectedIndex, setSelectedindex] = React.useState("")
    // const [productname, setProductname] = React.useState("")
    // const [selectedIndex, setSelectedindex] = React.useState("")
    const { id } = useParams()
    let history = useHistory();


    // api values

    const [supplierList, setSupplierList] = React.useState([])
    const [supplier, setSupplier] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState('0');
    const [advance, setAdvance] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [landingCost, setLandingCost] = React.useState("");

    const [productlist, setProductlist] = React.useState([]);
    const [purchaserequestlist, setPurchaserequestlist] = React.useState([]);
    const [editmode, setEditmode] = React.useState(false);

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const [purchaselpoid, setPurchaselpoid] = useState("")

    const [payable, setPayable] = React.useState(0);
    const [roundoff, setRoundoff] = React.useState('');

    const [searchinvoice, setSearchinvoice] = React.useState('');
    const [searchsupplier, setSearchsupplier] = React.useState('');

    const [purchaserequestno, setPurchaserequestno] = React.useState('');
    const [purchaserequestid, setPurchaserequestid] = React.useState('');

    const [indexState, setIndexstate] = React.useState(0)
    const [productmodal, setProductModal] = useState(false)
    const [lookupproductlist, setLookupproductlist] = React.useState([]);
    const [barcode, setBarcode] = useState("")
    const [select, setSelect] = useState([]);
    const [btnhide, setbtnhide] = useState(false);
    const [findHide, setFindhide] = React.useState(false);



    const [productsid, setProductid] = React.useState("");
    const [inputbarcode, setBarcodeInput] = React.useState("")
    const [productNameInput, setProductNameInput] = useState("");





    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;
    const hasResultsBarcode = barcode.length > 0;

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();


    useEffect(() => {
        getInvoiceno(billType)
        console.log(billType,'billtype values');
    }, [billType])

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        console.log(event.target.value,'event value')
        getInvoiceno()
    };

    const searchsupplierChange = (event) => {
        setSearchsupplier(event.target.value);
    };


    const userChange = (event) => {
        setSalesman(event.target.value);
    };

    const addRow = () => {
        setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: "", productname: "", pricelevel: "", pod_qty: "", unit: "", price: 0, salesrate: 0, calcprice: "", discount: "", purchaseorder_total: 0, colorsize: [], addnew: true, }])
    }

    const supplierChange = (event) => {
        setSupplier(event.target.value);
        getSupplierbyid(event.target.value)
        getSupplierbalance(event.target.value)
    };

    const getSupplierbalance = (id) => {
        var data = {
            "supplier_id": id
        }
        fetch(global.url + "viewSupplierBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    console.log("Supplier Balance", responseJson)
                    setBalance(responseJson.opening_balance)
                    setLimitbalance(responseJson.remain_balance)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (amount / 100) * event
        var result = amount - disc
        var grandtotal1 = result + parseFloat(otherCharges)
        setGrandtotal(grandtotal1)
        setPayable(grandtotal1)
    }
    const deletesingle = (index, price, sodid, sodqty) => {
        setTotal(total - price);
        var input = [...tableRow];
        input.splice(index, 1);
        setTableRow(input);
    };

    const deleteRowcombo = (index, combo, sodid, prodid) => {
        setCombomodal(true);
        setCombolist(combo);
        setSodidn(sodid);
        setDelindex(index);
        setDelproid(prodid);
        setCombotable2(combo);
    };

    const otherChargeschange = (event) => {
        if (event == "") {
            console.log("empty")
            setOthercharge(0)
        }
        else {
            setOthercharge(event)
        }
    }

    const getSupplierbyid = (id) => {
        var data = {
            "supplier_id": id
        }
        fetch(global.url + "getSupplierbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("one customer", responseJson)

                setCreditlimit(responseJson.data.supplier_credit_limit)
                setAddress(responseJson.data.supplier_address1)
                setMobile(responseJson.data.supplier_mobile)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const inputHandler = (text, key) => {
        // setSelectedindex(key)
        // setProductname(text)
        // console.log("text", text)
        // const _inputs = [...example];
        // _inputs[key].value = text;
        // _inputs[key].key   = key;
        // console.log("inputs", _inputs)
        // setExample(_inputs)
    }

    // function onKeyDown(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }
    //   }

    //   function onKeyDown1(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef1.current;

    //     const resultsItems = Array.from(resultsRef.current.children)

    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });

    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }

    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }
    //   }

    useEffect(() => {
        getTotalammount()
    }, [tableRow, discount, roundoff, otherCharges]);

    const getTotalammount = () => {
        var totalAmount = 0;
        console.log("tablerow", tableRow)
        for (var i = 0; i < tableRow.length; i++) {
            totalAmount += parseFloat(tableRow[i].purchaseorder_total)
            console.log("totalamount", tableRow[i].purchaseorder_total)
        }
        console.log("totalamount2", totalAmount)
        setAmount(totalAmount)
        var disc = (totalAmount / 100) * discount
        console.log("amount edit", roundoff)
        setGrandtotal(parseFloat(totalAmount) - disc + parseFloat(otherCharges) - roundoff)
        setPayable(parseFloat(totalAmount) - disc + parseFloat(otherCharges) - roundoff)
    }

    //   useEffect(() => {
    //     inputRef.current.focus();
    //     getBilltype()
    //     getUser()
    //     getInvoiceno()
    //   }, []);

    useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()

        if (id == "add") {
            // getInvoiceno()
            setEditmode(false)
            setPurchaseorderid("")
        }
        else {
            // setLoader(true)
            setPurchaseorderid(id)
            getDetailsbyid(id)
            setEditmode(true)
        }
    }, []);

    useEffect(() => {
        getSupplier()
    }, []);

    const getInvoiceno = (id1) => {
        var data = {
            "id": billType
        }
        console.log('type', data)
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("purchaseinvoiceno", responseJson)
                if (responseJson.error == false) {
                    setInvoiceno(responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)


        var data = {
            "userid": userdata.user_id,
            "companyid": company,
            "branchid": branch,
            "type": userdata.user_type,
        }
        fetch(global.url + "viewuser", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("user response", responseJson)
                setUserlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log(userdata,'userdata')
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "id": 5
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("list response", responseJson)
                setBilltypelist(responseJson.data)
                setBilltype(responseJson.data[0].billset_id)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSupplier = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewSupplier", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.Error == "false") {
                    setSupplierList(responseJson.data)
                    console.log("response", responseJson.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //   useEffect(() => {
    //     var creditbal = creditLimit - balance;
    //     console.log("creditbal", creditbal)
    //     setLimitbalance(creditbal)
    //   }, [creditLimit])

    //   useEffect(() => {
    //     if ( hasResults ) {
    //       document.body.addEventListener('keydown', onKeyDown);
    //     } else {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //     }

    //     if ( hasResultsProduct ) {
    //         document.body.addEventListener('keydown', onKeyDown1);
    //     } else {
    //         document.body.removeEventListener('keydown', onKeyDown1);
    //     }


    //     return () => {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //       document.body.removeEventListener('keydown', onKeyDown1);
    //     }
    //   }, [hasResults, hasResultsProduct]);

    const listClickcode = ( index,
        batchid,
        code,
        name,
        pricelevel,
        qty,
        unit,
        salesrate,
        calcprice,
        discount,
        amount,
        percentage,
        stock,
        iscombo,
        barcode,
        image,
        color,
        size,
        colorsize1) => {
            var input = [...tableRow];
            input[index].key = index;
            input[index].product_batch_id = batchid;
            input[index].code = code;
            input[index].pb_stock = stock;
            input[index].productname = name;
            input[index].sales_iscombo = iscombo;
            input[index].sod_qty = qty;
            input[index].unit = unit;
            input[index].color = color;
            input[index].size = size;
            input[index].addnew = true;
            input[index].product_inter_barcode = barcode;
            input[index].product_comboimage = image
            input[index].fetch_products = [
                {
                    key: "",
                    productid: "",
                    color: "",
                    size: "",
                    fetch_id: "",
                    product_name: "",
                    fetch_quantity: "",
                    quantity: "",
                },
            ];
            input[index].price = salesrate;
            input[index].calcprice = calcprice;
            input[index].discount = discount;
            if (discount) {
                var totalcalc = discount;
            } else {
                var totalcalc = 0;
            }
            input[index].pricelevel = pricelevel;
            input[index].sod_total_amount = salesrate - totalcalc;
            setTableRow([...tableRow, input]);
            setTableRow([
                ...tableRow,
                {
                    key: "",
                    product_batch_id: "",
                    product_id: "",
                    code: "",
                    productname: "",
                    pricelevel: "",
                    sod_qty: "",
                    unit: "",
                    price: "",
                    calcprice: "",
                    discount: "",
                    sod_total_amount: 0,
                    pb_stock: "",
                    sales_iscombo: "",
                    sod_id: "",
                    color: "",
                    size: "",
                    colorsize: [],
                    addnew: true,
                    fetch_products: [
                        {
                            key: "",
                            productid: "",
                            color: "",
                            size: "",
                            fetch_id: "",
                            product_name: "",
                            fetch_quantity: "",
                            quantity: "",
                            stock: "",
                        },
                    ],
                },
            ]);
            setCode("");
            var total1 = total + totalcalc;
            setTotal(total1);
    }

    // const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
    //     var input = [...tableRow]
    //     input[index].key = index
    //     input[index].product_batch_id = batchid
    //     input[index].code = code
    //     input[index].productname = name
    //     input[index].pricelevel = pricelevel
    //     input[index].pod_qty = qty
    //     input[index].unit = unit
    //     input[index].price = salesrate
    //     input[index].calcprice = calcprice
    //     input[index].discount = discount
        
    //     // if(discount) {
    //     //     var totalcalc = (calcprice/100) * discount
    //     // }
    //     // else {
    //     //     var totalcalc = 0
    //     // }

    //     input[index].purchaseorder_total = calcprice
    //     setTableRow([...tableRow, input])
    //     setTableRow([...tableRow, { key: "", product_batch_id: "", code: "", barcode: "", productname: "", pricelevel: "", pod_qty: "", unit: "", price: "", calcprice: "", discount: "", purchaseorder_total: 0, colorsize: [], addnew: true }])
    //     setProductname("")
    // }

    const deleteRow = (index, price, prd_id) => {
        if (prd_id == "") {
            console.log("index", index)
            setTotal(total - price)
            var input = [...tableRow]
            input.splice(index, 1)
            console.log("input", input)
            setTableRow(input)
        }
        else {
            var data = {
                "prd_id": prd_id,
            }
            fetch(global.url + "purchaseRequstDetailDelete", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson)
                    window.location.reload()
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const deleteRow1 = (index, price, prd_id) => {
        if (prd_id == "") {
            console.log("index", index)
            setTotal(total - price)
            var input = [...tableRow]
            input.splice(index, 1)
            console.log("input", input)
            setTableRow(input)
        }
        else {
            var data = {
                "pod_id": prd_id,
            }
            fetch(global.url + "purchaseOrderDetailDelete", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson)
                    window.location.reload()
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const handleBarcodechange = (event, key, type) => {
        console.log(key)
        var textArray1 = [...tableRow]
        console.log(textArray1)
        console.log(textArray1[0].product_batch_id)
        textArray1[key].pb_inter_barcode = event.currentTarget.value;
        textArray1[key].product_batch_id = textArray1[0].product_batch_id
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        setBarcode(event.currentTarget.value);
        getBarCodeSearch(event.currentTarget.value, type, key)
        
    }

    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)
    }


    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        getnameSearch(event.currentTarget.value, type, key)
    }

    // for calculating quantity * price

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].price * event.target.value
        textArray1[index].pod_qty = event.target.value
        textArray1[index].calcprice = totalprice
        textArray1[index].purchaseorder_total = totalprice
        setTableRow(textArray1)
    }

    const getBarCodeSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "type": type,
            "barcode": text,
            "companyid": company,
            "branchid": branch
        }

        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                // setProductlist(responseJson.data)
                listClickbarcode(


                    key,
                    responseJson.data[0].products_id,
                    responseJson.data[0].products[0].pb_inter_barcode,
                    responseJson.data[0].products[0].pb_id,
                    responseJson.data[0].product_refcode,
                    responseJson.data[0].product_name,
                    responseJson.data[0].products,
                    0
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const getnameSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "product_name": text,
                "companyid": company,
                "branchid": branch
            }
        }

        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                setProductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "code") {
            var data = {
                "type": type,
                "product_code": text,
                "companyid": company,
                "branchid": branch
            }
        }
        else {
            var data = {
                "type": type,
                "refcode": text,
                "companyid": company,
                "branchid": branch
            }
        }

        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)

                setProductlist(responseJson.data)
                // listClickrefcode(
                //     key,
                //     responseJson.data[0].products_id,
                //     responseJson.data[0].products[0].pb_inter_barcode,
                //     responseJson.data[0].products[0].pb_id,
                //     responseJson.data[0].product_refcode,
                //     responseJson.data[0].product_name,
                //     responseJson.data[0].products,
                //     0
                // )
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onSave = () => {
        const exampleArray = []
        for (var i = 0; i < tableRow.length; i++) {
            if (tableRow[i].product_batch_id == "") {
                // tableRow.splice(i, 1)
            }
            else {
                console.log(tableRow[i])
                exampleArray.push(tableRow[i])
            }
        }
        var lpodate1;
        if (purchaselpoid == "") {
            console.log("no id")
            lpodate1 = lpodate.toISOString().split('T')[0]
        }
        else {
            console.log("has id")
            lpodate1 = lpodate.split('T')[0]
        }
        console.log("tableRow", exampleArray)
        var data = {
            "purchaseorder_user_id": userId,
            "purchaseorder_bill_type": billType,
            "purchaseorder_ref_no": refno,
            "purchaseorder_salesman": salesman,
            "purchaseorder_invoice": invoiceno,
            "purchaseorder_date": date.toISOString().split('T')[0],
            "purchaseorder_customer": supplier,
            "purchaseorder_crdt_limit": creditLimit,
            "purchaseorder_balance": balance,
            "purchaseorder_limit_balance": limitbalance,
            "purchaseorder_total": grandtotal,
            "purchaseorder_discount": discount,
            "purchaseorder_other_charges": otherCharges,
            "purchaseorder_advance": advance,
            "purchaseorder_details": exampleArray,
            "purchaseorder_branchid": userbranchid,
            "purchaseorder_companyid": usercompanyid,
            "purchaseorder_id": purchaseorderid,
            "purchaseorder_request_id": purchaserequestid,
            "purchaseorder_landingcost": landingCost,
            "purchaseorder_roundof": roundoff,
        }
        console.log("lpo data", data)
        fetch(global.url + "updatePurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson)
                if (responseJson.error == true) {
                    if (responseJson.data == "") {
                        alert(responseJson.message)
                    } else {
                        alert(responseJson.data)
                    }
                }
                else {
                    alert(responseJson.message)
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const disountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice / 100) * input[index].discount
        input[index].purchaseorder_total = input[index].calcprice - disc
        setTableRow(input)
    }

    const onPopup = () => {
        setModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))


        var data = {
            "type": userdata.user_type,
            "companyid": company
        }
        console.log("data", data)
        fetch(global.url + "viewPurchaserequest", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Request response", responseJson)
                if (responseJson.status == "true") {
                    setPurchaserequestlist([])
                    console.log("true")
                }
                else {
                    console.log("false")
                    setPurchaserequestlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const purchaseOrderclick = (id, refno, date) => {
        setModal(false)
        setLpono(refno)
        setPurchaselpoid(id)
        var invoiceDate = new Date(date).toISOString();
        setLpodate(invoiceDate)
    }

    const roundOffchange = (event) => {
        setRoundoff(event)
        var round = amount - event
        var grandtotal1 = round
        setGrandtotal(grandtotal1)
    }

    const listClickbarcode = (index, productid, pb_inter_barcode, pbid, productrefcode, productname, products, iscombo) => {
        console.log('pbid', pbid)

        var input = [...tableRow]
        console.log("selectedIndex",selectedIndex)
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        // input[index].barcode = pb_inter_barcode
        input[index].pb_inter_barcode = pb_inter_barcode
        input[index].inputbarcode = pb_inter_barcode
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        input[index].product_batch_id = pbid
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", code: "", barcode: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: 0, discount: "", pqd_total_amount: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [] }])
        setBarcode("")
    }

    const listClickname = (index, productid, pb_inter_barcode,  productrefcode, productname, products, iscombo) => {
        setProductid(productid)
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].pb_inter_barcode = pb_inter_barcode
        input[index].inputbarcode = pb_inter_barcode
        // input[index].barcode = pb_inter_barcode
        // input[index].inputbarcode = pb_inter_barcode
        // getProductbybatch1(pbid, index)
        setSelectedindex(index)
        // input[selectedIndex].product_batch_id = pbid
        // getProductbybatch1(pbid, index)
        setTableRow(input)
        setBarcodeInput(barcode);
        setSelect(products);
        // setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", code: "", barcode: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", salesrate: 0, calcprice: "", discount: "", purchaseorder_total: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], addnew: true }])
        setProductname("") 
        // setCode("")
    }
    const listClickrefcode = (index, productid,  productrefcode, productname, products, iscombo,barcode) => {
        // var input = [...tableRow]
        // input[index].key = index
        // input[index].products_id = productid
        // input[index].code = productrefcode
        // input[index].sales_iscombo = iscombo
        // input[index].productname = productname
        // input[index].colorsize = products
        // input[index].barcode = pb_inter_barcode
        // input[index].inputbarcode = pb_inter_barcode
        // getProductbybatch1(pbid, index)
        // setSelectedindex(index)
        // input[selectedIndex].product_batch_id = pbid
        // getProductbybatch1(pbid, index)
        // setTableRow([...tableRow, input])
        // setTableRow([...tableRow, { key: "", product_batch_id: "", product_id: "", code: "", barcode: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: "", salesrate: 0, calcprice: "", discount: "", purchaseorder_total: 0, pb_stock: "", sales_iscombo: "", sd_id: "", color: "", size: "", colorsize: [], addnew: true }])
        // setCode("")
        setProductid(productid);
        var input = [...tableRow];
        input[index].key = index;
        input[index].products_id = productid;
        input[index].code = productrefcode;
        input[index].sales_iscombo = iscombo;
        input[index].productname = productname;
        // input[index].pei_image = image;
        input[index].colorsize = products;
        input[index].addnew = true;
        input[index].pb_inter_barcode = barcode;
        setSelectedindex(index);
        setTableRow(input);
        setCode("");
        setProductNameInput(productname);
        setBarcodeInput(barcode);
        setSelect(products);
    }

    const colorChange = (event) => {
        var input = [...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        setTableRow(input)

        getProductbybatch1(event.target.value, selectedIndex)
        //   setColor(event.target.value)
    }

    const getProductbybatch1 = (id, index) => {
        var data = {
            "pb_id": id,
        }
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("setDetails", responseJson)
                setDetails(id, index, responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setDetails = (id, index, responseJson) => {
        console.log(responseJson)

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        input[index].inputbarcode = responseJson.pb_inter_barcode


        input[index].pb_stock = responseJson.pb_stock
        input[index].pod_qty = 1
        input[index].unit = responseJson.unit_name
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].salesrate = responseJson.pb_salesrate
        input[index].price = responseJson.pb_purchaseprice
        input[index].calcprice = responseJson.pb_purchaseprice
        input[index].discount = responseJson.purchaseorder_total

        input[index].purchaseorder_total = responseJson.pb_purchaseprice
        console.log("input", input)
        setTableRow(input)
        addRow()
    }

    const prequestClick = (id) => {
        setPurchaserequestid(id)
        var data = {
            "purchaserequest_id": id,
        }
        console.log("data", data)
        fetch(global.url + "purchaseRequestDataFill", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setModal(false)
                console.log("Purchase request response", responseJson)
                setRefno(responseJson.data.purchaseorder_ref_no)
                setSalesman(responseJson.data.purchaseorder_salesman)
                setCreditlimit(responseJson.data.purchaseorder_crdt_limit)
                setLimitbalance(responseJson.data.purchaseorder_limit_balance)
                setBalance(responseJson.data.purchaseorder_balance)
                setCreditlimit(responseJson.data.purchaseorder_crdt_limit)
                setSupplier(responseJson.data.purchaseorder_customer)
                setPurchaserequestno(responseJson.data.purchaseorder_invoice)
                
                setTableRow(responseJson.data.purchaseorder_details)
                setTableRow((prev) => {return prev.map((item) => ({...item,inputbarcode: item.barcode, }));});
             
            })
            .catch((error) => {
                console.log(error)
            })
    }

    
 

    const getDetailsbyid = (id) => {
        var data = {
            "purchaseorder_id": id,
        }
        console.log("data", data)
        fetch(global.url + "purchaseOrderFill", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setModal(false)
                console.log("Purchase request response", responseJson)
                setRefno(responseJson.data.purchaseorder_ref_no)
                setSalesman(responseJson.data.purchaseorder_salesman)
                setCreditlimit(responseJson.data.purchaseorder_crdt_limit)
                setBalance(responseJson.data.purchaseorder_balance)
                setCreditlimit(responseJson.data.purchaseorder_limit_balance)
                setSupplier(responseJson.data.purchaseorder_customer)
                setPurchaserequestno(responseJson.data.purchaserequest_invoice)
                setPurchaserequestid(responseJson.data.purchaserequest_id)
                setTableRow(responseJson.data.purchaseorder_details)
                setInvoiceno(responseJson.data.purchaseorder_invoice)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const clear = () => {
        history.push('/transaction/Purchaseorder/add')
        window.location.reload()
    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))


        var data = {
            "type": userdata.user_type,
            "companyid": company,
            "invoiceno": searchinvoice,
            "supplier": searchsupplier
        }
        console.log("data", data)
        fetch(global.url + "viewPurchaserequest", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Request response", responseJson)
                if (responseJson.status == "true") {
                    setPurchaserequestlist([])
                    console.log("true")
                }
                else {
                    console.log("false")
                    setPurchaserequestlist(responseJson.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })

    }, [searchinvoice, searchsupplier]);

    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            "usertype": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("item look up", responseJson)
                setLookupproductlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const onfilter = (text, type) => {
        var company = JSON.parse(localStorage.getItem("company"))
        if (type == "intercode") {
            var data = {
                "type": 'intercode',
                "intercode": text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.error == false) {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])

                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (type == "superadmin") {
            var data = {
                "type": 'superadmin',
                "productname": text,
                "companyid": company
            }
            console.log("superadmin", data)
            fetch(global.url + "viewAllproductbatch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response combo", responseJson)
                    if (responseJson.status == 'false') {
                        setLookupproductlist(responseJson.data)
                    } else {
                        setLookupproductlist([])
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const getbarcodename = (id) => {
        setIndexstate(indexState + 1)
        console.log("index", indexState)
        setProductModal(false)
        getSearch(id, "refcode", indexState)
        getnameSearch(id, "name", indexState)
        getBarCodeSearch(id, "barcode", indexState)
    }

    const indexChange = (index) => {
        console.log("index", index)
        setIndexstate(index)
    }

    const checkBoxclick = (e) => {
        let isChecked = e.target.checked;
        if (isChecked == true) {
            // getProducts()
        }
        else {
            setTableRow([])
        }
    }

    return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ margin: 20 }}>
                    <div style={{ margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                        <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Purchase Order</p>
                        <Button className='clears' onClick={clear} variant="contained">Clear</Button>
                    </div>
                    <div>
                        <Row style={{ marginTop: 45 }}>
                            <Col xs={12} md={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={12} md={2}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {billTypelist.map((row, index) => {
                                            return (
                                                <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Invoice" value={invoiceno} disabled id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={supplier}
                                        label="Age"
                                        onChange={supplierChange}
                                        size="small"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {supplierList.map((row, index) => {
                                            return (
                                                <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Crdt Limit:" value={creditLimit} disabled id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            <Col xs={12} md={1}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField label="Balance" value={balance} disabled id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>
                            {/* <Col xs={12} md={2}>
                            <p>Ref No.</p>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField autoComplete="off" onChange={(e) => setRefno(e.target.value)} value={refno} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12} md={2}>
                                <FormControl sx={{ width: '100%' }} style={{ cursor: "pointer" }}>
                                    <TextField label="Req No" value={purchaserequestno}  onClick={() => onPopup()} id="outlined-basic" variant="outlined" size="small" />
                                </FormControl>
                            </Col>

                            {/* <Col xs={12} md={1}>
                            <p>Limit Balance
                            </p>
                        </Col>
                        <Col xs={12} md={2}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField value={limitbalance} disabled id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                            <Col md={10}>
                                <Button className='btn1' onClick={() => history.push("/Products/Products")} style={{ borderRadius: 5, float: 'right' }} variant="contained">Products</Button>
                                {/* <Button onClick={() => lookupClick()} className="retail-btn" style={{ backgroundColor: '#92c99f', borderRadius: 5, float: 'right', marginRight: 20 }} variant="contained">Item Lookup</Button> */}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            {/* <Col md={2}>
                            <FormControl sx={{  width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  onChange={e => checkBoxclick(e)} />
                                } label="Checked Products" />
                            </FormControl>
                        </Col> */}
                            {/* <Col md={3}>
                            <Button  style={{backgroundColor: '#7e7e7e', borderRadius: 5, marginTop: 10}} variant="contained">Get Purchase Order</Button>
                        </Col> */}

                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col xs={12}>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>SlNo</th>
                                            <th>Ref Code</th>
                                            <th>Color-Size</th>
                                            <th>Barcode</th>
                                            <th>Product name</th>
                                            <th style={{ width: 0 }}>Qty</th>
                                            <th>Unit</th>
                                            <th>Cost Price</th>
                                            <th>Amount</th>
                                            <th>Sales Price</th>
                                            <th>Total Amount</th>

                                            <th style={{ width: 0 }}>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRow.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td >{index + 1}</td>
                                                    {/* <td >
                                                    <TextField autoComplete="off" onChange={(event, value) => this.inputHandler(event.target.value, index)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                                    {this.state.selectedIndex == index && this.state.textLength.length >= 1  ? 
                                                    <div ref={this.myRef} className="develop">sacdscsd</div>: null
                                                    }
                                                    </td> */}
                                                    <td>
                                                        {
                                                            item.addnew == false ?
                                                                <input onFocus={() => indexChange(index)} style={{ width: 100, border: 0, outline: 0 }} autoFocus disabled autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} /> :
                                                                <input onFocus={() => indexChange(index)} style={{ width: 100, border: 0, outline: 0 }} autoFocus autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                        }

                                                        {hasResults && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people scrolling-div">
                                                                {productlist.map(item => {
                                                                    return (
                                                                        item.product_fetchiscombo == 0 ? 
                                                                        item.products.map(result => {
                                                                            return (
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                    index, 
                                                                                    item.products_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.products,
                                                                                    0
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                        }) : 
                                                                        <li key={item.url}>
                                                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                index, 
                                                                                item.products_id,
                                                                                item.product_refcode,
                                                                                item.product_name,
                                                                                item.pricelevel_name,
                                                                                "1",
                                                                                item.unit_name,
                                                                                item.product_comboprice,
                                                                                item.product_comboprice,
                                                                                0,
                                                                                item.product_comboprice,
                                                                                item.pricelevel_percentage,
                                                                                item.product_combostock,
                                                                                1
                                                                                )}>
                                                                            {item.product_refcode} - { item.product_name }
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    </td>
                                                    
                                                    {
                                                        item.addnew == true ? (
                                                            <td>

                                                                <FormControl style={{width:'100%'}} >
                                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>

                                                                    <Select
                                                                     labelId="demo-simple-select-helper-label"
                                                                     id="demeo-simple-select-helper"
                                                                     value={item.product_batch_id}
                                                                     label="age"
                                                                     onChange={colorChange}
                                                                     size="small"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {item.colorsize.map((row,index)=>{
                                                                            return (
                                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                            )
                                                                        })}
                                                                        
                                                                    </Select>
                                                                </FormControl>
                                                            </td> 
                                                        ) : <td>{item.color} - {item.size}</td>
                                                    }



                                                    <td>
                                                        <input autocomplete="off" value={item.inputbarcode} style={{ width: 150, border: 0, outline: 0 }} type="text" name="query" onFocus={() => indexChange(index)}
                                                            onKeyDown={(event) => { if (event.key === 'Enter') { handleBarcodechange(event, index, "barcode") } }} />
                                                    </td>


                                                    <td>
                                                        {
                                                            <input style={{ width: 150, border: 0, outline: 0 }} autocomplete="off" ref={inputRef} type="text" name="query" value={item.productname} onChange={(event) => handleProductchange(event, index, "name")} />
                                                        }
                                                        {hasResultsProduct && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ?
                                                                                item.products.map(result => {
                                                                                    return (
                                                                                        <li key={item.url}>
                                                                                            <a href="javascript:void(0)" onClick={() => listClickname(
                                                                                                 index,
                                                                                                 item.products_id,
                                                                                                 item.product_inter_barcode,
                                                                                                //  result.pb_inter_barcode,
                                                                                                //  result.pb_id,
                                                                                                 item.product_refcode,
                                                                                                 item.product_name,
                                                                                                 item.products,
                                                                                                 0
                                                                                                
                                                                                            )}>
                                                                                                {item.product_name} -  {item.product_refcode}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }) :
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        index,
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.pricelevel_name,
                                                                                        "1",
                                                                                        item.unit_name,
                                                                                        item.product_comboprice,
                                                                                        item.product_comboprice,
                                                                                        0,
                                                                                        item.product_comboprice,
                                                                                        item.pricelevel_percentage,
                                                                                        item.product_combostock,
                                                                                        1
                                                                                    )}>
                                                                                        {item.product_refcode} - {item.product_name}
                                                                                    </a>
                                                                                </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {/* <p style={{ width: 300 }}>{item.productname}</p> */}
                                                    </td>


                                                    <td>
                                                        {
                                                            item.addnew == false ?
                                                                <input style={{ width: 50, border: 0, outline: 0 }} disabled autocomplete="off" type="number" name="query" value={item.pod_qty} onChange={(event) => qtyChange(event, index)} /> :
                                                                <input style={{ width: 50, border: 0, outline: 0 }} autocomplete="off" type="number" name="query" value={item.pod_qty} onChange={(event) => qtyChange(event, index)} />
                                                        }
                                                    </td>
                                                    <td>{item.unit}</td>
                                                    <td> {parseFloat(item.price).toFixed(2)}</td>
                                                    <td>{parseFloat(item.calcprice).toFixed(2)}</td>
                                                    <td>
                                                        {parseFloat(item.salesrate).toFixed(2)}
                                                    </td>

                                                    <td>
                                                        {parseFloat(item.purchaseorder_total).toFixed(2)}
                                                    </td>
                                                    {/* <td style={{ width: 0 }}> */}
                                                        {/* {
                                                            editmode ?
                                                                <Button onClick={() => deleteRow1(index, item.amount, item.purchaseorderdetail_id)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant="contained">< AiFillMinusCircle color="#fc0303" fontSize={30} style={{ marginRight: 10 }} /></Button> :
                                                                <Button onClick={() => deleteRow(index, item.amount, item.purchaserequestdetail_id)} style={{ backgroundColor: '#fff', border: 'none', boxShadow: 'none' }} variant="contained">< AiFillMinusCircle color="#fc0303" fontSize={30} style={{ marginRight: 10 }} /></Button>

                                                        } */}

                                                        {/* <Button onClick={() => deleteRow(index, item.amount, item.purchaserequestdetail_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button> */}
                                                    {/* } */}

                                                    {/* </td> */}
                                                       {findHide == true || btnhide == true ? (
                                                        <td>
                                                            {item.sod_iscancel ==
                                                                1 ? null : item.sales_iscombo == 1 ? (
                                                                    <Button
                                                                        onClick={() =>
                                                                            deleteRowcombo(
                                                                                index,
                                                                                item.fetch_products,
                                                                                item.sod_id,
                                                                                item.product_batch_id
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor: "#fff",
                                                                            border: "none",
                                                                            boxShadow: "none",
                                                                        }}
                                                                        variant="contained"
                                                                    >
                                                                        <AiFillMinusCircle
                                                                            color="#6d37b9"
                                                                            fontSize={30}
                                                                            style={{ marginRight: 10 }}
                                                                        />
                                                                    </Button>
                                                                ) : (
                                                                <Button
                                                                    onClick={() =>
                                                                        deleteRow(
                                                                            index,
                                                                            item.amount,
                                                                            item.sod_id,
                                                                            item.sod_qty
                                                                        )
                                                                    }
                                                                    style={{
                                                                        backgroundColor: "#fff",
                                                                        border: "none",
                                                                        boxShadow: "none",
                                                                    }}
                                                                    variant="contained"
                                                                >
                                                                    <AiFillMinusCircle
                                                                        color="#6d37b9"
                                                                        fontSize={30}
                                                                        style={{ marginRight: 10 }}
                                                                    />
                                                                </Button>
                                                            )}
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            {item.sod_iscancel == 1 ? null : (
                                                                <Button
                                                                    onClick={() =>
                                                                        deletesingle(
                                                                            index,
                                                                            item.amount,
                                                                            item.sod_id,
                                                                            item.sod_qty
                                                                        )
                                                                    }
                                                                    style={{
                                                                        backgroundColor: "#fff",
                                                                        border: "none",
                                                                        boxShadow: "none",
                                                                    }}
                                                                    variant="contained"
                                                                >
                                                                    <AiFillMinusCircle
                                                                        color="#FF0000"
                                                                        fontSize={30}
                                                                        style={{ marginRight: 10 }}
                                                                    />
                                                                </Button>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Button className='btn1' onClick={onSave} style={{  borderRadius: 20, marginTop: 30, marginBottom: 20 }} variant="contained"><HiSave color="White" fontSize={20} style={{ marginRight: 10 }} />Save</Button>
                                {/* <Button className='btn1' style={{  marginLeft: 20, borderRadius: 20, marginTop: 30, marginBottom: 20 }} variant="contained"><GiCash color="White" fontSize={20} style={{ marginRight: 10 }} />Find</Button> */}
                                {/* <Button style={{backgroundColor: '#92c99f', marginLeft: 20, borderRadius: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button> */}
                                {/* <Button style={{backgroundColor: '#92c99f', marginLeft: 20, borderRadius: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#92c99f', marginLeft: 20, borderRadius: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button> */}
                                {/* <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><GiCash  color="White" fontSize={20} style={{marginRight: 10}} />GST</Button> */}
                                {/* <Button style={{backgroundColor: '#92c99f', marginLeft: 20, borderRadius: 20}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Close</Button> */}
                            </Col>
                            <Col xs={12} md={6} style={{ marginTop: 30 }}>
                                <Button onClick={() => addRow()} className='return'
                                // style={{ float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20 }} 
                                variant="contained">Add New</Button>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Total" disabled value={amount.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>

                                    <Col xs={12} md={1} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Discount %" autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Other Charges" autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} defaultValue={otherCharges} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Roundoff" value={roundoff} onChange={(e) => roundOffchange(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Grand Total" disabled onChange={(e) => setGrandtotal(e.target.value)} value={grandtotal.toFixed(2)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Landing Cost" onChange={(e) => setLandingCost(e.target.value)} value={landingCost} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{ marginTop: 30 }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField label="Payable" value={payable.toFixed(2)} onChange={(e) => setPayable(e.target.value)} id="outlined-basic" variant="outlined" size="small" />
                                        </FormControl>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    </div>
                </div>
            </Paper>
            <Modal
                size="lg"
                show={productmodal}
                onHide={() => setProductModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined" size="small" label="Barcode" />
                        </Col>
                        <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined" size="small" label="Product Name" />
                        </Col>
                        {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} >
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Ref Code</th>
                                        <th>Barcode</th>
                                        <th>Product Name</th>
                                        <th>Stock</th>
                                        <th>Sales Price</th>
                                    </tr>
                                </thead>
                                {/* {productlistitem.length == 0 ? */}
                                <tbody>
                                    {lookupproductlist?.map((column) => (
                                        <tr>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_refcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }} >{column.pb_inter_barcode}</td>
                                            <td onClick={() => getbarcodename(column.product_refcode)} style={{ cursor: 'pointer' }}>{column.product_name}</td>
                                            <td>{column.pb_stock}</td>
                                            <td>{column.pb_salesrate}</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Select Purchase Request
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel style={{ marginTop: -7 }} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={searchsupplier}
                                    label="Age"
                                    onChange={searchsupplierChange}
                                    size="small"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {supplierList.map((row, index) => {
                                        return (
                                            <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField onChange={(e) => setSearchinvoice(e.target.value)} value={searchinvoice} placeholder='Invoice Number' id="outlined-basic" variant="outlined" size="small" />
                            </FormControl>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12}>
                            <Table hover responsive bordered className="section-to-print">
                                <thead>
                                    <tr>
                                        <th>Request Invoice</th>
                                        <th>Date</th>
                                        <th>Supplier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaserequestlist.map(result => {
                                        return (
                                            <tr style={{ cursor: "pointer" }} onClick={() => prequestClick(result.purchaserequest_id)}>
                                                <td>{result.purchaserequest_invoice}</td>
                                                <td>{result.purchaserequest_date}</td>
                                                <td>{result.supplier_name}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {/* <Row>
                    <Col xs={12}>
                        <ul>
                        {purchaserequestlist.map((row, index) => {
                            return (
                                <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => prequestClick(row.purchaserequest_id)}>
                                    <li className="pay-lists">{row.purchaserequest_invoice} - {row.purchaserequest_date}</li>
                                </a>
                            )}
                            )
                            }
                        </ul>
                    </Col>
                    
                </Row> */}
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Purchaserequest;
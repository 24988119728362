import React, {Component, useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar';
import Footer from '../footer/footer';
import accessimgae from '../../assets/images/access.png'
import { FormName } from 'redux-form';

const DashboardLayout = ({ children, ...rest }) => {
    const [toggle,  setToggle] = useState(false)
    const [access,  setAccess] = useState(true)
    
    function setData(childData) {
        setToggle(childData)
      }

      useEffect(() => {
        const formname = JSON.parse(localStorage.getItem("formname"));
        const user = JSON.parse(localStorage.getItem("user"));
        // console.log("user", user)
        for(var i=0; i< formname.length ; i++) {
          // console.log("formname", formname)
          // console.log("children.props.match.path", children.props.match.path)
          if(formname[i].path == children.props.match.path) {
            console.log("children", children.props.match.path)
            console.log("formname[i].status", formname[i].status)
             if(formname[i].status == true){
                  setAccess(true)
                  // console.log("access true", true)
             }
             else{
                  setAccess(false)
                  // console.log("access false", false)
             }
          }
        }
    }, [children]);

      
  return (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}} className={toggle == false ? "toggle": ""}>
        <Sidebar activeData = {toggle} getData = {setData} />
        {
          access ? 
          <div className='dashboardlayout'>{children}</div>:
          <div style={{marginTop: '90px', marginLeft: '500px'}}>
              <img src={accessimgae} alt='' />
            </div>
        }
        <Footer />
    </div>
  );
};

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated ?  
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout> :
        <Redirect to="/" /> 
      )}
    />
  );
};

export default DashboardLayoutRoute;
import { TextField } from '@mui/material';
import React from 'react'
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
// import { BsPersonFill } from 'react-icons/bs';
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import  { useState, useEffect } from "react";
import Loader from '../src/components/loader/loader';





function MasterLogin() {

  const [loader, setLoader] = useState(false);

  return (
    <div className='App'>

      <header className='Login-component' >
        <Container fluid>
          <Row>
            <Col xs={12} style={{ marginTop: 20 }}>

              <div className='login-box'>
                <div className='box-inside'>
                  <div className='login-title'>
                    <strong>MASTER LOGIN</strong>
                    <h3></h3>

                  </div>
                  <div class="frm-title">
                                    Welcome back! Please login to continue
                                </div>
                  <Form>
                  <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield"
                                        //  value={username} onChange={(e) => setUsername(e.target.value)} 
                                         id="outlined-basic" label="Username" variant="outlined"  size="small"/>
                                        <BsPersonFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/>
                                    </FormControl>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                     <TextField className="login-textfield"
                      // value={password} onChange={(e) => setPassword(e.target.value)}
                       id="outlined-basic" label="Password" variant="outlined" type="password"  size="small"/>
                     <BsFillLockFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/>
                   </FormControl>
                   <div style={{paddingBottom: 20}}>
                                            {loader ? <Loader /> :
                                                <a href="javascript:void(0)"
                                                //  onClick={() => {
                                                        // onSubmit()
                                                    // }} 
                                                     variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5}}>
                                                    Login
                                                    <FaArrowCircleRight color="#fff" size={12} className="lefticon"/>
                                                </a>
                                            }
                                        </div>
                  </Form>
                 

                  <div className='frm-footer'><span>{global.proname}</span></div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </header>


    </div>
  )
}

export default MasterLogin

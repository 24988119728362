import './Inventory.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import { Route, Link } from 'react-router-dom';


import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
// import { usePreviousMonthDisabled } from '@mui/lab/internal/pickers/hooks/date-helpers-hooks';
import { useParams } from 'react-router-dom';


const columns = [
    { id: '1', label: 'Barcode', minWidth: 100,span: false },
    { id: '1', label: 'Product Name', minWidth: 150,span: false },
    { id: '1', label: 'Count Qty', minWidth: 50,span: false },
    { id: '1', label: 'Measure SN', minWidth: 50,span: false },
    { id: '2', label: 'Department', minWidth: 100,span: false },
    { id: '3', label: 'Section', minWidth: 100,span: false },
    { id: '4', label: 'Category', minWidth: 100,span: false },
    { id: '5', label: 'Subcategory', minWidth: 100,span: false },
    { id: '6', label: 'Segment', minWidth: 100,span: false },
    { id: '7', label: 'Sub Segment', minWidth: 100,span: false },
    { id: '7', label: 'UOM', minWidth: 50,span: false },
    { id: '8', label: 'Color', minWidth: 50,span: false },
    { id: '9', label: 'Size', minWidth: 50,span: false },
    { id: '10', label: 'Display Location', minWidth: 100,span: false },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function Inventory(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = React.useState('');
    const [stateName, setStateName] = React.useState("");

    const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [blocked, setBlocked] = React.useState(0)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [date, setDate] = React.useState(new Date);
    const [department, setDepartment] = React.useState("");
    const [section, setSection] = React.useState("");
    const [segment, setSegment] = React.useState("");
    const [subsegment, setSubsegment] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [subcategory, setSubcategory] = React.useState("");
    const [image, setImage] = React.useState("");
    const [dlocation, setDlocation] = React.useState("");
    const [measuresn, setMeasuresn] = React.useState("");
    const [measuredesc, setMeasuredesc] = React.useState("");
    const [uom, setUom] = React.useState("");
    const [sellingprice, setSellingprice] = React.useState("");
    const [intbarcode, setIntbarcode] = React.useState("");
    const [manualbarcode, setManualbarcode] = React.useState("");
    const [bookstock, setBookstock] = React.useState("");
    const [bookstockvalue, setBookstockvalue] = React.useState("");
    const [bookstockqtyvalue, setBookstockqtyvalue] = React.useState("");
    const [countqty, setCountqty] = React.useState("");
    const [countqtyvalue, setCountqtyvalue] = React.useState("");
    const [countqtysalevalue, setCountqtysalevalue] = React.useState("");
    const [diffqty, setDiffqty] = React.useState("");
    const [diffvalue, setDiffvalue] = React.useState("");
    const [user, setUser] = React.useState("");
    const [narration, setNarration] = React.useState("");
    const [purchaseprice, setPurchaseprice] = React.useState("");

    const [downloadlink, setDownloadlink] = React.useState("");

    const [productcode, setProductcode] = React.useState("");
    const [productcode1, setProductcode1] = React.useState("");
    const [productname1, setProductname1] = React.useState("");
    const [batchid, setBtahcid] = React.useState("");
    const [productlist, setProductlist] = React.useState([]);

    const [source, setSource] = React.useState([]);

    const [referalcode, setReferelcode] = React.useState("");

    const [colorlist, setColorlist] = React.useState([]);
    const [sizelist, setSizelist] = React.useState([]);
    const [color, setColor] = React.useState("");
    const [size, setSize] = React.useState("");

    const inputRef = useRef();
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const resultsRef = useRef();
    const resultsRef2 = useRef();

    

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const [barcode, setBarcode] = useState("")
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;
    const hasResults2 = barcode.length > 0;

    const colorChange = (event) => {
      setColor(event.target.value);
      setBtahcid(event.target.value)
      getBatchid(event.target.value)
    };

    const measureChange = (event) => {
        setMeasuresn(event.target.value)
        if(event.target.value == "pc") {
          setMeasuredesc("pieces")
        }
        if(event.target.value == "out"){
          setMeasuredesc("Outer")
        }
        if(event.target.value == "ctn"){
          setMeasuredesc("Cartoons")
        }
        if(event.target.value == "ctn"){
          setMeasuredesc("Promotion")
        }
    };

    const sizeChange = (event) => {
      setSize(event.target.value);
      setBtahcid(event.target.value)
      getBatchid(event.target.value)
    };

    const onExport = () => {
      var data = {
        companyid: usercompanyid
      }
        fetch(global.url + "excelExportStock", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          if(responseJson.error == false){
            console.log("link", responseJson.data)
              setDownloadlink(responseJson.data)
          }
                  
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getBatchid = (id) => {
        

        var data = {
            "pb_id" : id,
        }
        
        fetch(global.url + "getStockbyBatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("response json", responseJson.data)
            setMeasuredesc(responseJson.data.measuredisc)
            setMeasuresn(responseJson.data.measuresn)
            setUom(responseJson.data.uom)
            setSellingprice(responseJson.data.sellingprice)
            setIntbarcode(responseJson.data.intbarcode)
            setManualbarcode(responseJson.data.manualbarcode)
            setBookstock(responseJson.data.bookstock)
            setUser(responseJson.data.username)
            setBookstockvalue(responseJson.data.bookstockvalue)
            setBookstockqtyvalue(responseJson.data.bookqtysalevalue)
            setPurchaseprice(responseJson.data.purchaseprice)
            setReferelcode(responseJson.data.product_refcode)
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
      getData()
      
    }, []);

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
          var data = {
            "companyid" : company,
          }
          
          fetch(global.url + "viewStockbyBatch", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("responseJson viewStockbyBatch", responseJson)
            if(responseJson.error == false) {
              setSource(responseJson.data)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
    }


    useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        if ( hasResults2 ) {
            document.body.addEventListener('keydown', onKeyDown2);
        } else {
            document.body.removeEventListener('keydown', onKeyDown2);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
          document.body.removeEventListener('keydown', onKeyDown2);
        }
      }, [hasResults, hasResultsProduct, hasResults2]);


      function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      function onKeyDown2(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef2.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef2.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef2.current.focus();
          }
        }
      }



      function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }

      const handleOnChange = (event, type) => {
          setCode(event.currentTarget.value);
          getSearch(event.currentTarget.value, type)
      }

      const handlenameOnChange = (event, type) => {
          setProductname(event.currentTarget.value);
          getSearch(event.currentTarget.value, type)
      }

      const handlecodeOnChange = (event, type) => {
            setBarcode(event.currentTarget.value)
            getSearch(event.currentTarget.value, type)
      }

      useEffect(() => {
        if(measuresn == "pc") {
          setUom(1)
        }
        else{
          setUom("")
        }
        
      }, [measuresn]);

      const onSubmit = () => {
        if((measuresn == "out" || measuresn == "ctn") && uom == 1 ){
          alert("uom must be greater than 1")
          return
        }
          var data = {
            "batchid" : batchid,
            "stock" :  countqty,
            "narration" : narration,
            "disloc": dlocation,
            "companyid": usercompanyid,
            "userid": userId,
            "productuom": uom,
            "productuomlist": measuresn,
            "pbs_expiry" : date
          }
          
          fetch(global.url + "stockUpdateProduct", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
             console.log("responseJson", responseJson)
             if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload();
             }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const changeQty = (e) => {
            setCountqty(e)
            // const countqtyvalue = se
            const countqtysalev = e * sellingprice
            const countqtyv =  Math.round(e * purchaseprice).toFixed(2)
            const diffq =  e - bookstock 
            const diffv = diffq * purchaseprice 
            setCountqtysalevalue(countqtysalev)
            setCountqtyvalue(countqtyv)
            setDiffqty(diffq)
            setDiffvalue(diffv)
      }

      const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
            if(type == "code") {
                var data = {
                    "type" : type,
                    "product_code":  text,
                    "companyid" : company,
                    "branchid" : branch
                }
            }
            else if(type == "intercode") {
              var data = {
                  "type" : type,
                  "intercode":  text,
                  "companyid" : company,
                "branchid" : branch
              }
          }
            else {
                var data = {
                    "type" : type,
                    "refcode":  text,
                    "companyid" : company,
                "branchid" : branch
                }
            }
            console.log("searchdata", data)
            
            fetch(global.url + "getSearchStock", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response", responseJson)
                setProductlist(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
        }

      const listClickcode = (id, code, name, category, subcategory, segmentname, subsegment, department, section, products, refcode, batchid) => {
          setDepartment(department)
          setSection(section)
          setSegment(segmentname)
          setSubsegment(subsegment)
          setCategory(category)
          setSubcategory(subcategory)
          setCode("")
          setProductcode("")
          setProductname("")
          setBarcode("")
          setSizelist(products)
          setColorlist(products)
          setProductcode1(code)
          setProductname1(name)
          setReferelcode(refcode)
          setBtahcid(batchid)
          
      }

      const listClickbarcode = (id, code, name, category, subcategory, segmentname, subsegment, department, section, products, refcode, batchid, 
        measuredisc,
        measuresn,
        uom,
        sellingprice,
        intbarcode,
        manualbarcode,
        bookstock,
        username,
        bookstockvalue,
        bookqtysalevalue,
        purchaseprice) => {
        setDepartment(department)
        setSection(section)
        setSegment(segmentname)
        setSubsegment(subsegment)
        setCategory(category)
        setSubcategory(subcategory)
        setCode("")
        setProductcode("")
        setProductname("")
        setBarcode("")
        setSizelist(products)
        setColorlist(products)
        setProductcode1(code)
        setProductname1(name)
        setReferelcode(refcode)
        setBtahcid(batchid)
        setMeasuredesc(measuredisc)
        setMeasuresn(measuresn)
        setUom(uom)
        setSellingprice(sellingprice)
        setIntbarcode(intbarcode)
        setManualbarcode(manualbarcode)
        setBookstock(bookstock)
        setUser(username)
        setBookstockvalue(bookstockvalue)
        setBookstockqtyvalue(bookqtysalevalue)
        setPurchaseprice(purchaseprice)

        console.log("bookstockvalu", bookstockvalue)
        
    }
    

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
               <Row style={{marginTop: 20}}>
                    <Col md={3}>
                        <p style={{fontSize:25,  marginBottom: 10, fontWeight: 'bold'}}>Inventory</p>
                        <p>Bardcode - {intbarcode}</p>
                    </Col>
                    <Col md={3}>
                      <input autocomplete="off" ref={inputRef2} type="text" placeholder="BarCode" name="query" value={barcode} onChange={(event) => handlecodeOnChange(event, "intercode")} />
                        {hasResults2  && (
                            <div className="autocomplete">
                                <ul ref={resultsRef} className="people scrolling-div">
                                    {productlist.map(item => {
                                        return (
                                            item.product_fetchiscombo == 0 ? 
                                            item.products.map(result => {
                                                return (
                                                <li key={result.url}>
                                                    <a href="javascript:void(0)" onClick={() => listClickbarcode(
                                                        item.products_id,
                                                        item.product_code,
                                                        item.product_name,
                                                        item.category_name,
                                                        item.subCategory_name,
                                                        item.segment_name,
                                                        item.subseg_name,
                                                        item.itemgroup_name,
                                                        item.brand_name,
                                                        item.products,
                                                        item.product_refcode,
                                                        item.pb_id,
                                                        item.products[0].measuredisc,
                                                        item.products[0].measuresn,
                                                        item.products[0].uom,
                                                        item.products[0].sellingprice,
                                                        item.products[0].intbarcode,
                                                        item.products[0].manualbarcode,
                                                        item.products[0].bookstock,
                                                        item.products[0].username,
                                                        item.products[0].bookstockvalue,
                                                        item.products[0].bookqtysalevalue,
                                                        item.products[0].purchaseprice,
                                                      
                                                        )}>
                                                    {item.product_code} - { item.product_name }
                                                    </a>
                                                </li>
                                            )
                                            }) : 
                                            <li key={item.url}>
                                                <a href="javascript:void(0)" onClick={() => listClickcode(
                                        
                                                    item.products_id,
                                                    item.product_refcode,
                                                    item.product_name,
                                                    item.pricelevel_name,
                                                    "1",
                                                    item.unit_name,
                                                    item.product_comboprice,
                                                    item.product_comboprice,
                                                    0,
                                                    item.product_comboprice,
                                                    item.pricelevel_percentage,
                                                    item.product_combostock,
                                                    1
                                                    )}>
                                                {item.product_code} - { item.product_name }
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </Col>
                    <Col md={3}>
                      <input autocomplete="off" ref={inputRef} type="text" placeholder="Product Code" name="query" value={code} onChange={(event) => handleOnChange(event, "code")} />
                        {hasResults  && (
                            <div className="autocomplete">
                                <ul ref={resultsRef} className="people scrolling-div">
                                    {productlist.map(item => {
                                        return (
                                            item.product_fetchiscombo == 0 ? 
                                            item.products.map(result => {
                                                return (
                                                <li key={result.url}>
                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                        item.products_id,
                                                        item.product_code,
                                                        item.product_name,
                                                        item.category_name,
                                                        item.subCategory_name,
                                                        item.segment_name,
                                                        item.subseg_name,
                                                        item.itemgroup_name,
                                                        item.brand_name,
                                                        item.products,
                                                        item.product_refcode
                                                        )}>
                                                    {item.product_code} - { item.product_name }
                                                    </a>
                                                </li>
                                            )
                                            }) : 
                                            <li key={item.url}>
                                                <a href="javascript:void(0)" onClick={() => listClickcode(
                                        
                                                    item.products_id,
                                                    item.product_refcode,
                                                    item.product_name,
                                                    item.pricelevel_name,
                                                    "1",
                                                    item.unit_name,
                                                    item.product_comboprice,
                                                    item.product_comboprice,
                                                    0,
                                                    item.product_comboprice,
                                                    item.pricelevel_percentage,
                                                    item.product_combostock,
                                                    1
                                                    )}>
                                                {item.product_code} - { item.product_name }
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </Col>
                    <Col md={3}>
                    <input autocomplete="off" ref={inputRef1} type="text" placeholder="Refcode" name="query" value={productname} onChange={(event) => handlenameOnChange(event, "refcode")} />
                        {hasResultsProduct  && (
                            <div className="autocomplete">
                            <ul ref={resultsRef} className="people scrolling-div">
                                {productlist.map(item => {
                                    return (
                                        item.product_fetchiscombo == 0 ? 
                                        item.products.map(result => {
                                            return (
                                            <li key={result.url}>
                                                <a href="javascript:void(0)" onClick={() => listClickcode(
                                                    item.products_id,
                                                    item.product_code,
                                                    item.product_name,
                                                    item.category_name,
                                                    item.subCategory_name,
                                                    item.segment_name,
                                                    item.subseg_name,
                                                    item.itemgroup_name,
                                                    item.brand_name,
                                                    item.products,
                                                    item.product_refcode
                                                    )}>
                                                {item.product_code} - { item.product_name }
                                                </a>
                                            </li>
                                        )
                                        }) : 
                                        <li key={item.url}>
                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                    
                                                item.products_id,
                                                item.product_refcode,
                                                item.product_name,
                                                item.pricelevel_name,
                                                "1",
                                                item.unit_name,
                                                item.product_comboprice,
                                                item.product_comboprice,
                                                0,
                                                item.product_comboprice,
                                                item.pricelevel_percentage,
                                                item.product_combostock,
                                                1
                                                )}>
                                            {item.product_code} - { item.product_name }
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        )}
                    </Col>
               </Row>
               <Row>
                    <Col sm ={6} md={2} xs={12} style={{marginTop: 25}}>
                        <FormControl sx={{width: '100%' }}>
                                  <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Measure SN</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={measuresn}
                                      label="Age"
                                      onChange={measureChange}
                                      size="small"
                                  >
                                  <MenuItem value="">
                                      <em>None</em>
                                  </MenuItem>
                                      <MenuItem value="pc">pc</MenuItem>
                                      <MenuItem value="out">out</MenuItem>
                                      <MenuItem value="ctn">ctn</MenuItem>
                                      <MenuItem value="pro">pro</MenuItem>
                                  </Select>
                          </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                          <FormControl sx={{  width: '100%' }}>
                            {
                              measuresn == "pc" ? 
                              <TextField label="UOM" autoComplete="off" disabled onChange={(e) => setUom(e.target.value)} value={uom} id="outlined-basic"  variant="outlined"  size="small"/>: 
                              <TextField autoComplete="off"  onChange={(e) => setUom(e.target.value)} value={uom} id="outlined-basic" variant="outlined"  size="small"/>
                            }
                            
                          </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl>
                            <TextField label="Display Location" autoComplete="off" onChange={(e) => setDlocation(e.target.value)} value={dlocation} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Qty" autoComplete="off" onChange={(e) => changeQty(e.target.value)} value={countqty} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                            label="Expiry Date"
                                value={date}
                                onChange={(newValue) => {
                                setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Product Code" autoComplete="off" disabled value={productcode1} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Ref Code" autoComplete="off" disabled value={referalcode} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={4} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Product Name" autoComplete="off" disabled value={productname1} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Department" autoComplete="off" disabled value={department} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Section" autoComplete="off" disabled value={section} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Main Category" autoComplete="off" disabled value={category} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Sub Category" autoComplete="off" disabled value={subcategory} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Segment" autoComplete="off" disabled value={segment} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Sub Segment" autoComplete="off" disabled value={subsegment} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                          <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Size and color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={color}
                                    label="Age"
                                    onChange={colorChange}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {colorlist.map((row, index) => {
                                    return (
                                    <MenuItem value={row.pb_id}>{row.colorname} - {row.sizename}</MenuItem>
                                )})}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Measure Desc" autoComplete="off" disabled value={measuredesc} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Purchase Price" autoComplete="off" disabled value={purchaseprice} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Selling Price" autoComplete="off" disabled value={sellingprice} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Book Stock" autoComplete="off" disabled value={bookstock} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Book Stock Value" autoComplete="off" disabled value={Math.round(bookstockvalue).toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Book QTY Sale value" autoComplete="off" disabled value={bookstockqtyvalue} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Book QTY Sale value" autoComplete="off" disabled value={bookstockqtyvalue} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Count QTY value" autoComplete="off" disabled value={countqtyvalue} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Count QTY Sale Value" autoComplete="off" disabled value={countqtysalevalue} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Diff Qty" autoComplete="off" disabled value={diffqty} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="Diff Value" autoComplete="off" disabled value={diffvalue} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm ={6} md={2} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField label="User" autoComplete="off" disabled value={user} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <TextareaAutosize 
                            minRows={3}
                            placeholder="Narration"
                            onChange={(e) => setNarration(e.target.value)}
                            value={narration}
                            style={{background: 'transparent', width: '100%', marginTop: 20}}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                      <Button className='btn1' onClick={onSubmit} style={{float: 'right', marginTop: 40,  border: 'none'}} variant="contained">Submit</Button>
                    </Col>
               </Row>
              
            </div>
            <div style={{marginTop: 30, marginLeft: 30}}>
              <Row style={{marginTop: 20}}>
                  <Col xs={12} >
                    {
                      downloadlink == "" ? 
                    <Button className='btn1' style={{float: "left",  border: 'none'}} onClick={onExport} variant="contained">Start Excell Export</Button>:
                    <a style={{float: "left"}} href={global.excell + downloadlink}  target="_blank" download>
                        <Button className='btn1'  style={{float: 'left',  border: 'none'}} variant="contained">Download File</Button>
                    </a>
                    }
                      
                  </Col>
              </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  { source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
    
                              <TableCell >
                                  {row.intbarcode}
                              </TableCell>
                              <TableCell >
                                  {row.product_name}
                              </TableCell>
                              <TableCell >
                                  {row.pbs_stock}
                              </TableCell>
                              <TableCell >
                                  {row.measuresn}
                              </TableCell>
                              <TableCell >
                                  {row.department_name}
                              </TableCell>
                              <TableCell >
                                  {row.section_name}
                              </TableCell>
                              <TableCell >
                                  {row.main_category}
                              </TableCell>
                              <TableCell >
                                  {row.subCategory_name}
                              </TableCell>
                              <TableCell >
                                  {row.segmentname}
                              </TableCell>
                              <TableCell >
                                  {row.subsegmentname}
                              </TableCell>
                              <TableCell >
                                  {row.uom}
                              </TableCell>
                              <TableCell >
                                  {row.colorname}
                              </TableCell>
                              <TableCell >
                                  {row.sizename}
                              </TableCell>
                              <TableCell >
                                  {row.pbs_disloc}
                              </TableCell>
                              
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          
      </div>
    )
}
export default Inventory;
import React, { useState, useEffect } from 'react'
import TableCell from '@mui/material/TableCell';
// import { TableContainer } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import * as FaIcons from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Col, Row } from 'react-bootstrap';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { set } from 'date-fns';
import { id } from 'date-fns/locale';
import Tooltip from '@mui/material/Tooltip';
import { Route, useHistory } from 'react-router-dom';
import Loader from '../src/components/loader/loader';
import masterlogo from './assets/images/Awfiya_Logo_bg_remove.png'










function MasterDashboard() {
  const [source, setSource] = useState([])
  const [source1, setSource1] = useState([])

  const [show, setShow] = useState(false);
  const [page, setPage] = React.useState(0);

  const [database, setDatabase] = useState('')
  const [id1, setId1] = useState('')
  const [domain, setDomain] = useState('')
  const [domainName, setDomainName] = useState('')
  const [domainPass, setDomainPass] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [company, setCompany] = useState('')
  const [category, setCategory] = useState('')
  const [directory, setDirectory] = useState('')
  const [login, setLogin] = useState('')
  const [register, setRegister] = useState('')
  // const [source1,setSource1] =useState ('')

  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [modal, setModal] = useState(false)
  const [edit, setEdit] = useState(true)
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);





  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    master1()
  }, [])


  const editClick = (
    company, fg, domain, username, category, directory, password, date1, date2, id1,dname,dpass
  ) => {
    setEdit(true)
    setModal(true)
    setCompany(company)
    setDatabase(fg)
    setDomain(domain)
    setUsername(username)
    setCategory(category)

    setDirectory(directory)
    setPassword(password)
    setDate1(date1)
    setDate2(date2)
    setId1(id1)
    setDomainName(dname)
    setDomainPass(dpass)

    console.log(company, '123');
  }




  const master = () => {

    var data = {

      'db': database,
      'domain': domain,
      'directory': directory,
      'username': username,
      'password': password,
      'company_name': company,
      'category': category,
      'last_login': date1,
      'register_date': date2,
      'db_username' : domainName,
      'db_password' : domainPass,



    }
    if (edit) {
      data['master_updateid'] = id1
    }
    fetch(global.url + "updateMaster", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129'
      },
      body: JSON.stringify(data),

    })
      .then((response) => response.json())
      .then((responseJson) => {

        setSource1(responseJson.data)
        const win = window.open('/masterdashboard/' + responseJson.data, "_blank")

        win.focus();
        window.location.reload()

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const master1 = () => {
    setLoader(true)
    fetch(global.url + "viewMaster", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129'
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false)

        setSource(responseJson.data)

      })
      .catch((error) => {
        console.log(error)
      })
  }
  const onAdd = () => {
    setModal(true)
    setEdit(false)
    setCompany('')
    setDatabase('')
    setDomain('')
    setUsername('')
    setCategory('')
    setDirectory('')
    setPassword('')
    setDate1(null)
    setDate2(null)
    setDomainName('')
    setDomainPass('')

  }

  const logoutclear = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    console.log("userdata", userdata)
    var data = {
      "userid": userdata.user_id,
    }
    console.log("user_id", data)
    fetch(global.url + "deleteDraftonLogout", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false)
        console.log("logout clear", responseJson)
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("branch")
        localStorage.removeItem("company")
        localStorage.removeItem("user")
        localStorage.removeItem("pos")

        history.push('/')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const logout = () => {
    logoutclear()
  }


  return (
    // <>


    <div className="main-content"
    //  style={{ backgroundColor: '#92c99f', borderRadius: '4px' }}
     >
      {/* usertype == 3 ? */}
      {/* <ul className=   "header-list" style={{height:'80px'}}>
                                        <li>
                                            <a href="javascript:void(0)" onClick={logout}>
                                                <Tooltip title="Client Login" arrow>
                                                    <img src={require("./assets/images/icons/logout.svg").default} style={{ width: 50, height: 50 }} />

                                                </Tooltip>
                                            </a>
                                        </li>
                                        </ul> */}


      <div
      // style={{display:'flex',justifyContent:'end'}}
      >

        {/* <ul className='header-list' style={{height:'80px',gap:'25px'}}>
      

       {loader ?  <Loader/> :
       <li>
        <a href="javascript:void(0)" >
          <Tooltip title="Master Logout"  arrow>

                 <img src={masterlogo} style={{width: 40 , height: 90}}/>

          </Tooltip>
        </a>
              
        </li>

        <li>
        <a href="javascript:void(0)" >
          <Tooltip title="Master Logout"  arrow>

                 <img src={require("./assets/images/icons/logout.svg").default} style={{width: 40 , height: 90}}/>

          </Tooltip>
        </a>
              
        </li>
        
         } 



      </ul> */}

        {/* <Row>

          <Col>
            <div className='master-logo'>
              <Tooltip  title="Awfiya Logo" arrow></Tooltip>
              <a href='/masterdashboard'></a>
            </div>
            
          </Col>

          <Col  style={{display:'flex',justifyContent:'end'}}>
          <Tooltip title="Master Logout" onClick={logout} arrow>

            <img  style={{marginTop:'12px',height:'61px',marginRight:'15px'}} src={require("./assets/images/icons/logout.svg").default}/>

          </Tooltip>
          
          </Col>
        </Row> */}

      </div>

      <Paper sx={{ width: '100%', overflow: 'hidden'}}>
        <Row>
          <Col>

            <h2 style={{ padding: '1rem' }}>Master Dashboard</h2>
          </Col>

          <Col> <Button onClick={onAdd} style={{ marginLeft: '400px', marginTop: '20px', backgroundColor: '#92c99f', border: 'none' }}>ADD</Button></Col>
        </Row>

        <div>
          {loader ? <Loader /> :
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">COMPANY NAME</th>
                  <th scope="col">DATABASE</th>
                  <th scope="col">DOMAIN</th>
                  <th scope="col">USERNAME</th>
                  <th scope="col">ENABLE/DISABLE</th>
                  <th scope="col">EDIT</th>

                </tr>
              </thead>
              <tbody>
                {source.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (


                  <tr 
                  tabIndex={-1} key={row.code} 
                  >
                    <td scope="row">{index + 1}</td>
                    <td>{row.company_name}</td>
                    <td>{row.db}</td>
                    <td>{row.domain}</td>
                    <td>{row.username}</td>
                    <td></td>
                    {/* <td>@mdo</td>ss */}
                    <td>
                      {
                      index !==0 &&
                        
                        <FaIcons.FaEdit onHide={() => setEdit(false)} onClick={() =>
                        editClick(
                          row.company_name,
                          row.db,
                          row.domain,
                          row.username,                          
                          row.category,
                          row.directory,
                          row.password,
                          row.register_date,
                          row.last_login,
                          row.id,
                          row.db_password,
                          row.db_username

                        )} fontSize={20} color="#92c99f" />}
                    </td>

                  </tr>
                ))}
              </tbody>

            </table>
          }
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={source.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal show={modal} onHide={() => setModal(false)} >
          <Modal.Header closeButton>
            <Modal.Title>Add Master Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>

                <Col md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Control value={database}
                      type="Database" onChange={(e) => {
                        setDatabase(e.target.value)
                      }} style={{ width: '200px' }}
                      placeholder="Database"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {/* <Form.Label>Example textarea</Form.Label> */}
                    <Form.Control value={domain} onChange={(e) => {
                      setDomain(e.target.value)
                    }} style={{ display: 'flex', width: '200px' }} type='Domain' placeholder="Domain" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control value={directory} onChange={(e) => {
                      setDirectory(e.target.value)
                    }}
                      type="Database" style={{ width: '200px' }}
                      placeholder="Directory"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {/* <Form.Label>Example textarea</Form.Label> */}
                    <Form.Control value={domainName} onChange={(e) => {
                      setDomainName(e.target.value)
                    }} style={{ display: 'flex', width: '200px' }} type='Domain' placeholder="Database Username" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control value={username} onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                      type="Database" style={{ width: '200px' }}
                      placeholder="Username"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {/* <Form.Label>Example textarea</Form.Label> */}
                    <Form.Control value={domainPass} onChange={(e) => {
                      setDomainPass(e.target.value)
                    }} style={{ display: 'flex', width: '200px' }} type='Domain' placeholder="database Password" />
                  </Form.Group>
                </Col>
                 <Col>
                  <Form.Group className="mb-3">
                    <Form.Control value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                      type="password" style={{ width: '200px' }}
                      placeholder="password"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control value={company} onChange={(e) => {
                      setCompany(e.target.value)
                    }}
                      type="Database" style={{ width: '200px' }}
                      placeholder="CompanyName"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control value={category} onChange={(e) => {
                      setCategory(e.target.value)
                    }}
                      type="Database" style={{ width: '200px' }}
                      placeholder="Category"
                    />
                  </Form.Group>
                </Col>
                {/* </Row>
             <Row> */}
                <Col>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Last Login"
                      value={date1}
                      onChange={(newValue) => {
                        setDate1(newValue);
                      }}
                      renderInput={(params) => <TextField style={{ width: '200px' }} {...params} />}
                    />
                  </LocalizationProvider>
                </Col>

                <Col>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Register Date"
                      value={date2}
                      onChange={(newValue) => {
                        setDate2(newValue);
                      }}
                      renderInput={(params) => <TextField style={{ width: '200px' }} {...params} />}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setModal(false)}>
              Cancel
            </Button>
            <Button style={{ backgroundColor: '#92c99f', border: 'none' }} onClick={master}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

      </Paper>
    </div>


  )
}

export default MasterDashboard

import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col,Modal,Button  } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Loader from '../../components/loader/loader';

function Ordercancel(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [modal, setModal] = React.useState(false);
    const [date, setDate] = React.useState(new Date);

    const [frominvoice, setFrominvoice] = useState("")
    const [toinvoice, setToinvoice] = useState("")

    const [executiveid, setExecutiveid] = useState("")
    const [executiveList, setExecutivelist] = useState([])
    const [loader, setLoader] = React.useState(false);

    const [mobile, setMobile] = useState("")
    const [invoice, setInvoice] = useState("")

    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])
    const [input, setInput] = useState(0);
    const [billDetails, setBilldetails] = useState([])

    const productlist = [{id: 1},{id: 2},{id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}]
    const [blocked, setBlocked] = React.useState(0);
    const [array, setArray] = React.useState([])

    const userChange = (event) => {
        setSalesmanid(event.target.value)
        getData(event.target.value)
    }

    const blockChange = (e,id) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push(id)
            console.log("input", input)
            setArray(input)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i] === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log('user',userdata )
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
    
        
        var data = {
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    useEffect(() => {
        getUser()
        getdata1()
      }, [input]);

      const getdata1 = () => {
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "type" :  userdata.user_type,
            "branchid" : branch,
            "page" : input*10,
            "limit": 10
          }
          fetch(global.url + "OrderCancelReport", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
              setLoader(false)
                console.log("response123response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }
      const decrement = () => {
        setInput(prevCount => prevCount - 1);
        
      }
  
     const increment = () => {
        setInput(prevCount => prevCount + 1);
      
      }
  

      const getdata2 = (search) => {
        setMobile(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "mobile": search,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getdata3 = (search) => {
        setInvoice(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "invoice": search,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getData = (id) => {
        setMobile("")
        setInvoice("")
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        console.log("id", id)
        var data = {
            "companyid": company,
            "salesmanid": id,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const viewBillclick = (id) => {
        setModal(true)
    
        var data = {
            "companyid": usercompanyid,
            "salesorderid": id,
            "type": usertype
        }
        fetch(global.url + "viewSalesOrderDetail", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("report sale detail", responseJson)
          if(responseJson.error == true){
            alert(responseJson.message)
          }
          else{
            setBilldetails(responseJson.data.sales_details)
          }
        
        })
      }

      const retrieveClick = (id) => {    
        if(window.confirm('Are you Want to retrieve this orders?')){
        var data = {
            "salesorderid": id,
        }
        fetch(global.url + "salesOrderCancelRetrieve", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("report sale detail", responseJson)
          if(responseJson.error == false){
            alert(responseJson.message)
            // window.location.reload()
            getdata1()
          }
          else{
            alert(responseJson.message)
          }
        
        })}
      }

      const orderSubmit = () => {

        var data = {
            "salesorder_id": array,
        }
        console.log("data", data)
        fetch(global.url + "oval", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("report sale detail", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                getdata1()
            }
            
        })
        
      }

      const onPrint = () => {
            window.print()
      }

      const dateSearch = (text) => {
          setDate(text)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            console.log("date", text.toISOString().split('T')[0])
            var data = {
                "companyid": company,
                "salesorder_date": text.toISOString().split('T')[0],
                "type" : userdata.user_type, 
                "branchid" : branch
            }
            fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const onfilterClick2 = () => {
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            var data = {
                "companyid": company,
                "invoicedrom": frominvoice,
                "invoiceto": toinvoice,
                "salesmanid": salesmanid,
                "type" : userdata.user_type, 
                "branchid" : branch
            }
            fetch(global.url + "viewSalesOrderBySalesman", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    console.log("user response", responseJson)
                    setSource(responseJson.data)
                })
                .catch((error)=>{
                    console.log(error)
                })
      }

      const cancelClick = (id) => {
        console.log("id", id)
        var data = {
          "salesorderid":  id,
        }
        fetch(global.url + "salesOrderCancel", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error == false){
            getData()
            alert(responseJson.message)
          }
            console.log("response", responseJson)
        })
      }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Cancel Order Report</p>
            </div>
            
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                    <Table id="table-to-xls" bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Invoice</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Building</th>
                                <th>Street</th>
                                <th>zone</th>
                                <th>Area</th>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {loader ? <Loader /> :
                        <tbody>
                        {source.map(result => {
                            return (
                            <tr>
                                <td>{result.user_name}</td>
                                <td>{result.salesorder_invoice}</td>
                                <td>{result.salesorder_date}</td>
                                <td>{result.customer_name}</td>
                                <td>{result.customer_mobile}</td>
                                <td>{result.customer_address1}</td>
                                <td>{result.customer_address2}</td>
                                <td>{result.salesorder_zone}</td>
                                <td>{result.customer_address3}</td>
                                <td>{result.salesorder_total}</td>
                                <td  className="print_btn"><Button onClick={() => viewBillclick(result.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button></td>
                                <td  className="print_btn"><Button onClick={() => retrieveClick(result.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Retrieve</Button></td>
                            </tr>
                            )
                            })}
                        </tbody>}
                    </Table>
                </Col>
            </Row>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Order Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Barcode</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>discount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        {loader ? <Loader /> :
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>\
                                <td>{item.pb_inter_barcode}</td>
                                <td>{item.code}</td>
                                <td>{item.productname}</td>
                                <td>{item.sd_qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.discount}</td>
                                <td>{item.sd_total_amount}</td>
                              </tr>
                            ))}
                        </tbody>}
                      </Table>
                </Modal.Body>
            </Modal>
          </Paper> 
          {/* <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div> */}
      </div>
    )
}
export default Ordercancel;
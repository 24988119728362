import React, {Component, useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthLayout = ({ children, ...rest }) => {
      
  return (
    <div>
        <div>{children}</div>
    </div>
  );
};

const AuthLayoutRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated ?  <Redirect to="/dashboard"   /> :
        // master/company
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

export default AuthLayoutRoute;
import React, { Component, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Container, Row, Col, Form } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { BiUser, BiNotepad, BiHomeAlt } from "react-icons/bi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { BsReceiptCutoff, BsDot } from "react-icons/bs";
// import Button from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import { RiCloseFill } from "react-icons/ri";


import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { Link } from 'react-router-dom';
import AllNotificationsComponent from '../Widgets/AllNotificationsComponent';
import Loader from '../loader/loader';
// import NotificationComponent from '../Widgets/NotificationComponent';
// import { Link } from '@mui/material';
//  import { useNavigate } from "react-router-dom"














function Rightsidebar(props) {
    const [toggle, setToggle] = useState(false)
    const location = useLocation();
    const history = useHistory();

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = React.useState("");
    const [show, setShow] = React.useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [branchlist, setBranchlist] = useState([]);
    const [count, setCount] = React.useState();
    const [Notifymessage, setNotifymessage] = React.useState([]);
    const [progress, setProgress] = React.useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [opens, setOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [notification, setNotification] = useState();
    const [loader, setLoader] = React.useState(false);


    // const [dataList, setDataList] = useState([]);


    // const [notifyMessage, setNotifyMessage] = useState([]);

    // const history = useHistory();


    // const navigate=useNavigate();

    // const handleClickOpen = () => {
    //     setOpen(true);
    //   };

    const handleClickOpen = (item) => {
        setSelectedItem(item);
        console.log(item.id);

        setOpen(true);
        const updatedNotifyMessage = Notifymessage.filter(item => item.id !== item);
        setNotifymessage(updatedNotifyMessage);
        setCount(prevCount => (prevCount > 0 ? prevCount - 1 : 0));
        console.log(updatedNotifyMessage)

        var branch = JSON.parse(localStorage.getItem("branch"));
        var company = JSON.parse(localStorage.getItem("company"));
        var data = {
            user_id: 1,
            branch_id: branch,
            company_id: company,
            id: item.id,
        };

        fetch(global.url + "excelUploadDisplayed", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data),
        })
            .then(responseJson => {

                console.log(responseJson);
            })
            .catch(error => {
                console.error(error);
            });
    }
    // const YourMainComponent = () => {
    //    

    const handleClickViewAll = () => {
        // Redirect to the "/allnotifications" route
        history.push('/allnotifications');

    };


    // };

    // const handleClosed = () => {
    //     setOpen(false);
    // };

    const showSidebar = (childData) => {
        setToggle(childData)
    };

    const logout = () => {
        logoutclear()
    }



    useEffect(() => {
        getBranch()
    }, []);

    const getBranch = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        const data = {
            "company_id": company
        }
        fetch(global.url + "viewAllBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("branch list response", responseJson)
                setBranchlist(responseJson.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const logoutclear = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log("userdata", userdata)
        var data = {
            "userid": userdata.user_id,
        }
        console.log("user_id", data)
        fetch(global.url + "deleteDraftonLogout", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("logout clear", responseJson)
                localStorage.removeItem("isAuthenticated")
                localStorage.removeItem("branch")
                localStorage.removeItem("company")
                localStorage.removeItem("user")
                localStorage.removeItem("pos")

                history.push('/')
            })
            .catch((error) => {
                console.log(error)
            })
    }



    useEffect(() => {
        getAll();
    }, []);



    const handleClosed = () => {
        getAll();
        setOpen(false);
    };



    const getAll = () => {
       
         
        // set loader true
        setLoader(true)


        var branch = JSON.parse(localStorage.getItem("branch"));
        var company = JSON.parse(localStorage.getItem("company"));
        var data = {
            user_id: 1,
            branch_id: branch,
            company_id: company,
        };

        fetch(global.url + "excelUploadStatus", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.message === "Success") {
                    // console.log()



                    // set loader false
                    setLoader(false)
                    console.log("Response 124", responseJson);


                // store data into array
                    const dataArray = [];

                    responseJson.data.forEach((item) => {
                        // dataArray.push(item.someProperty);

                        
                        const itemDetails = {
                            description: item.description,
                            datetime: item.datetime,
                        };

                        // push the array in itemDetails
                        dataArray.push(itemDetails)
                    });



            //  reverse the data array code
                    dataArray.reverse();


                    setNotification(dataArray)
                    console.log("Data Array:", dataArray);
                    console.log()
                    console.log("Response", responseJson);

                   



                    // const displayedData = responseJson.data.filter(item => item.is_displayed === "1");

                    // const newcount = displayedData.length;
                    // setCount(newcount);

                    // if (Array.isArray(Notifymessage)) {
                    //     setNotifymessage(displayedData.map((item)=>({
                    //         description:item.description,
                    //         datetime:item.datetime,
                    //     })));
                    // } else {
                    //     console.error('Notifymessage is not an array',responseJson.message);
                    // }
                    // // setNotifyMessage(displayedData);
                    // const maxValues = displayedData.map(item => Number(item.max));
                    // console.log(maxValues);
                    // const totalMax = maxValues.reduce((acc, val) => acc + val, 0);
                    // console.log(totalMax);
                    // const calculatedProgress = totalMax > 0 ? (totalMax / maxValues.length) : 0;
                    // setProgress(calculatedProgress);

                    // setDataList(responseJson.data);

                } else {
                    console.log("err", responseJson.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };



    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)

    }, []);



    // Window.onload = function() {
    //     var hidediv=document.getElementById('div1');
    //     document.onclick=function(div){
    //         if(div.target.id !='div1'){
    //             hidediv.style.display=='none';
    //         }
    //     };
    // };


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);


    };








    return (
        <div style={{ width: '100%' }}>
            <div className={toggle == true ? "rightToggle" : ""} style={{ marginLeft: 'auto' }} to="#">
                <FaIcons.FaBars className="rightsidebarIcon" onClick={() => showSidebar(!toggle)} />
                <div className='bg123' style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div className={toggle == true ? "rightsidbar" : ""}>
                        {
                            usertype == 1 || usertype == 2 || usertype == 4 ?
                                <ul className={toggle == true ? "sidebarlist" : "header-list"}>
                                    {/* <li>
                                    <a href="/transaction/Salesorder/add" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Sales Order" arrow>
                                            <img src={require("../../assets/images/icons/sales-order.svg").default} style={{width: 50, height: 50}} />
                                            
                                        </Tooltip>
                                    </a>
                                </li> */}
                                    {/* <li>
                                    <a href="/Sales/SalesApproval" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Sales Approval" arrow>
                                            <img src={require("../../assets/images/icons/order-report.svg").default} style={{width: 50, height: 50}} />  
                                        </Tooltip>
                                    </a>
                                </li> */}
                                    {/* <li>
                                    <a href="/StockReport/CurrentStockReport" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Stock Report" arrow>
                                            <img src={require("../../assets/images/icons/stock-report.svg").default} style={{width: 50, height: 50}} />
                                            
                                        </Tooltip>
                                    </a>
                                </li> */}
                                    {/* <li>
                                    <a href="/transaction/Salesexecutive" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Sales Executive" arrow>
                                            <img src={require("../../assets/images/icons/salesexecutive.svg").default} style={{width: 60, height: 60}} />
                                            
                                        </Tooltip>
                                    </a>
                                </li> */}
                                    {/* <li>
                                    <a href="/transaction/Salesreplace" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Sales Replacement" arrow>
                                            <img src={require("../../assets/images/icons/Replacement.svg").default} style={{width: 50, height: 50}} />
                                            
                                        </Tooltip>
                                    </a>
                                </li> */}
                                    <li style={{ position: "absolute", top: 0, right: 0 }}>
                                        <a href="javascript:void(0)" onClick={logout}>
                                            <Tooltip title="Client Login" arrow>
                                                <img src={require("../../assets/images/icons/logout.svg").default} style={{ width: 50, height: 50, marginTop: 8 }} />
                                            </Tooltip>
                                        </a>
                                    </li>

                                </ul> :
                                usertype == 3 ?
                                    <ul className={toggle == true ? "sidebarlist" : "header-list"}>
                                        <li>
                                            <a href="/Reports/deliveryboy" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Sales Executive" arrow>
                                                    <img src={require("../../assets/images/icons/deliveryboy.svg").default} style={{ width: 60, height: 60 }} />

                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" onClick={logout}>
                                                <Tooltip title="Client Login" arrow>
                                                    <img src={require("../../assets/images/icons/logout.svg").default} style={{ width: 45, height: 45, marginTop: 1 }} />
                                                </Tooltip>
                                            </a>
                                        </li>

                                    </ul>
                                    : <ul className={toggle == true ? "sidebarlist" : "header-list"}>

                                        <li>
                                            <a href="/dashboard" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Dashboard" arrow>
                                                    <img src={require("../../assets/images/icons/home.svg").default} style={{ width: 50, height: 50 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        {/* <li>
                                        
                                        <a href="/reports/paymentreport" className={'/reports/paymentreport' == location.pathname ? "selected" : ""}>
                                            <Tooltip title="Payment Report" arrow>
                                                <img src={require("../../assets/images/icons/Payments.svg").default} style={{width: 50, height: 50}} />
                                            </Tooltip>
                                        </a>
                                    </li> */}
                                        <li>
                                            <a href="/StockReport/CurrentStockReport" className={'/StockReport/CurrentStockReport' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Stock Report" arrow>
                                                    <img src={require("../../assets/images/icons/stock-report.svg").default} style={{ width: 50, height: 50 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/transaction/Salesorder/add" className={'/transaction/Salesorder/add' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Sales Order" arrow>
                                                    <img src={require("../../assets/images/icons/sales-order.svg").default} style={{ width: 50, height: 50 }} />

                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/Sales/SalesApproval" className={'/Sales/SalesApproval' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Employees" arrow>
                                                    <img src={require("../../assets/images/icons/salesapprovel.svg").default} style={{ width: 50, height: 50 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/transaction/Sales/add" className={'/transaction/Sales/add' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Sales" arrow>
                                                    <img src={require("../../assets/images/icons/sales.svg").default} style={{ width: 44, height: 44, marginTop: 5 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/reports/salesreport" className={'/reports/salesreport' == location.pathname ? "selected" : ""} >
                                                <Tooltip title="Sales Report" arrow>
                                                    <img src={require("../../assets/images/icons/SalesReport.svg").default} style={{ width: 50, height: 50, marginTop: 5 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/transaction/Purchase/add" className={'/transaction/Purchase' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Purchase" arrow>
                                                    <img src={require("../../assets/images/icons/Purchase.svg").default} style={{ width: 42, height: 42, marginTop: 3 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/reports/purchasereport" className={'/reports/purchasereport' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Purchase Report" arrow>
                                                    <img src={require("../../assets/images/icons/PurchaseReport.svg").default} style={{ width: 75, height: 75, marginTop: 3 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/Products/Products" className={'/Products/Products' == location.pathname ? "selected" : ""}>
                                                <Tooltip title="Product" arrow>
                                                    <img src={require("../../assets/images/icons/product.svg").default} style={{ width: 50, height: 50 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        {/* <li>
                                        <a href="/accounts/account" className={'/accounts/account' == location.pathname ? "selected" : ""}>
                                            <Tooltip title="Ledger" arrow>
                                                <img src={require("../../assets/images/icons/ledger.svg").default} style={{width: 50, height: 50}} />
                                            </Tooltip>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/transaction/paymentvoucher/add" className={'/transaction/paymentvoucher/add' == location.pathname ? "selected" : ""}>
                                            <Tooltip title="Payment" arrow>
                                                <img src={require("../../assets/images/icons/Payment.svg").default} style={{width: 50, height: 50}} />
                                            </Tooltip>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/transaction/receiptvoucher/add" className={'/transaction/receiptvoucher/add' == location.pathname ? "selected" : ""}>
                                            <Tooltip title="Receipt" arrow>
                                                <img src={require("../../assets/images/icons/Receipt.svg").default} style={{width: 50, height: 50}} />
                                            </Tooltip>
                                        </a>
                                    </li> */}
                                        {/* <li>
                                        <Tooltip title="Shortcut" arrow>
                                            <img src={require("../../assets/images/icons/ShortKeys.svg").default} style={{width: 50, height: 50, marginTop:4}} />
                                        </Tooltip>
                                    </li> */}

                                        {/* <li onClick={() => setShow(true)}> */}
                                        <li onClick={handleClick}>
                                            <Tooltip title="Notification" arrow>
                                                <Badge badgeContent={count} color="primary">
                                                    <img
                                                        src={require('../../assets/images/icons/Notification.svg').default}
                                                        style={{ width: 55, height: 55, marginTop: 4 }}
                                                        alt="Notification"
                                                    />
                                                </Badge>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" onClick={logout}>
                                                <Tooltip title="Client Login" arrow>
                                                    <img src={require("../../assets/images/icons/logout.svg").default} style={{ width: 40, height: 40, marginTop: 8 }} />
                                                </Tooltip>
                                            </a>
                                        </li>
                                        {/* <Button onClick={getAll}>Notification</Button> */}

                                    </ul>
                        }

                    </div>
                    <div style={{ marginTop: 20, marginRight: 20 }}>
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel id="demo-simple-select-helper-label">Branch</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"

                            >
                                <MenuItem value="0">
                                    Coorparate Company
                                </MenuItem>
                                {branchlist.map((item, index) => (
                                    <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <React.Fragment>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 52,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 20,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: 500, padding: 12 }} onClick={handleClickViewAll}>
                                View All
                            </div>
                        </div>
                        <hr />


                        {/* {Notifymessage.map((row, index) => (
                            <MenuItem onClick={handleClickOpen}   >
                                {row.description}
                            </MenuItem>
                        ))} */}

                        {/* {Notifymessage.map((row, index) => (

                            <MenuItem key={row.id} onClick={() => handleClickOpen(row)}>
                                {row.is_displayed === "0" ? row.description : alert("No data found")}
                            </MenuItem>

                        ))} */}
                    </Menu>

                    <Dialog open={opens} onClose={handleClose}>
                        <DialogTitle>{selectedItem && selectedItem.description}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Datetime: {selectedItem && selectedItem.datetime}
                                <br />
                                Title: {selectedItem && selectedItem.title}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClosed} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* <Dialog open={opens} onClose={handleClosed}>
                        <DialogTitle>Status</DialogTitle>
                        <DialogContent>
                            {Notifymessage.map((row, index) => (
                                <DialogContentText>
                                    {row.title}
                                    {row.datetime}
                                </DialogContentText>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClosed}>Cancel</Button>

                        </DialogActions>
                    </Dialog> */}
                </React.Fragment>



            </div>
            <OutsideClickHandler onOutsideClick={() => {
                setShow(false);
            }}>
            </OutsideClickHandler>

            {/* <Route path="/allnotifications" component={AllNotificationsComponent} /> */}

            <h2 style={{ padding: '2rem' }}>All Notifications</h2>

            <div className='notification-container' style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>






                {/* <ul>
            {notification.map((item, index) => (
              <li key={index}>
                <p>Description: {item.description}</p>
                <p>Datetime: {item.datetime}</p>
              </li>
            ))}
          </ul>
                
                 */}
                <ul className='notification-list' style={{ listStyle: 'none', padding: '0' }}>



                {/* mapping method and loading spinner */}

                    {loader ? <Loader /> :
                    notification && notification.map(item => (

                        <li className='notification-item' style={{ padding: '1rem', marginLeft: '10px', marginBottom: '5px', border: '1px solid #92c99f', borderRadius: '5px', backgroundColor: '#f9f9f9', boxShadow: 'black' }}>
                            <p className='description' style={{ fontWeight: 'bold' }}> {item.description}</p>

                            <p className='datetime' style={{ color: '#888' }}> {item.datetime}</p>

                        </li>





                    ))}

                </ul>

            </div>
        </div>

    );
}



export default Rightsidebar;


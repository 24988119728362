import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal, Row, Col, Table, ProgressBar } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import WizardFormFirstPage from './FirstPage';
import Productecommerce from './productecommerce';
import WizardFormSecondPage from './productBatch';
import WizardFormThirdPage from './productUnit';
import Defaultprice from './defaultprice';
import Branches from './branhces';
import Others from './others';
import "./product.css";
import Loader from '../../loader/loader';
import Loader2 from "react-loader-spinner";
import BtnLoader from '../../loader/btnLoader';
import Checkbox from '@mui/material/Checkbox';
import Barcode from 'react-barcode';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import wrongFile from '../../../assets/images/wrongfile.jpg';
import correctFile from '../../../assets/images/correctfile.jpg';
// import bulk from '../../../asset
import bulkFile from '../../../assets/gg/bulkuploadformat.xlsx'


const columns = [
  { id: '1', label: 'Ref code', minWidth: 100, span: false },
  { id: '3', label: 'Product Name', minWidth: 100, span: false },
  { id: '2', label: 'Local Name', minWidth: 100, span: false },
  { id: '1', label: 'Print Name', minWidth: 100, span: false },
  { id: '4', label: 'Description', minWidth: 100, span: false },
  { id: '5', label: 'HSN', minWidth: 100, span: false },
  { id: '6', label: 'Colour', minWidth: 100, span: false },
  { id: '7', label: 'Size', minWidth: 100, span: false },
  { id: '7', label: 'MTN', minWidth: 100, span: false },
  { id: '8', label: 'MTSN', minWidth: 100, span: false },
  { id: '9', label: 'Barcode', minWidth: 100, span: false },
  { id: '9', label: 'Currency', minWidth: 100, span: false },
  { id: '10', label: 'Purchse Price', minWidth: 100, span: false },
  { id: '11', label: 'Landing Cost', minWidth: 100, span: false },
  { id: '12', label: '$', minWidth: 100, span: false },
  { id: '1', label: 'Purchase Price + Landing Cost $', minWidth: 100, span: false },
  { id: '3', label: 'Sales Price', minWidth: 100, span: false },
  { id: '2', label: 'Wholesale (B2B Price)', minWidth: 100, span: false },
  { id: '1', label: 'Online Price', minWidth: 100, span: false },
  { id: '4', label: 'GST/Tax', minWidth: 100, span: false },
  { id: '5', label: 'Department', minWidth: 100, span: false },
  { id: '6', label: 'Section', minWidth: 100, span: false },
  { id: '7', label: 'Main Category', minWidth: 100, span: false },
  { id: '7', label: 'Sub Category', minWidth: 100, span: false },
  { id: '8', label: 'Segment', minWidth: 100, span: false },
  { id: '9', label: 'Sub Segment', minWidth: 100, span: false },
  { id: '9', label: 'Zone (Display Location)', minWidth: 100, span: false },
  { id: '10', label: 'Supplier Code', minWidth: 100, span: false },
  { id: '11', label: 'Supplier Name', minWidth: 100, span: false },
  { id: '12', label: 'Supplier Contact', minWidth: 100, span: false },
  { id: '9', label: 'Supplier Group', minWidth: 100, span: false },
  { id: '9', label: 'Contry Control', minWidth: 100, span: false },
  { id: '10', label: 'UOM (Base Quatity of Product)', minWidth: 100, span: false },
  { id: '11', label: 'Item Group Code', minWidth: 100, span: false },
  { id: '12', label: 'Stock Group Code', minWidth: 100, span: false },
  { id: '1', label: 'Expiry Date', minWidth: 100, span: false },
  { id: '3', label: 'IsUnitConvert', minWidth: 100, span: false },
  { id: '2', label: 'Display Convert Unit', minWidth: 100, span: false },
  { id: '1', label: 'Branch Control', minWidth: 100, span: false },
  { id: '4', label: 'Order Level', minWidth: 100, span: false },
  { id: '5', label: 'Order Qty', minWidth: 100, span: false },
  { id: '6', label: 'Godown', minWidth: 100, span: false },
  { id: '12', label: 'Hide', minWidth: 100, span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

const productcolumn = [
  { id: '1', label: 'Code', minWidth: 50, span: false },
  { id: '3', label: "", minWidth: 50, span: false },
  { id: '4', label: 'Product name', minWidth: 50, span: false },
  { id: '7', label: 'Othername', minWidth: 50, span: false },
  { id: '5', label: 'Brand', minWidth: 50, span: false },
  { id: '8', label: 'Category', minWidth: 50, span: false },
  { id: '10', label: 'Sub category', minWidth: 50, span: false },
  { id: '11', label: 'Image Status', minWidth: 50, span: false },
  // { id: '11', label: 'Base unit', minWidth: 50,span: false },
  // { id: '12', label: 'Purchase unit', minWidth: 50,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

const rows = [
  {
    company: 'Lorem Ipsum',

  },
  {
    company: 'Lorem Ipsum',
  },
  {
    company: 'Lorem Ipsum',
  },
];

const dates = [
  {
    company: 'Lorem Ipsum'
  },
  {
    company: 'Lorem Ipsum'
  }
]

function Customer(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [btnload, setBtnload] = React.useState(false);
  const [btnload2, setBtnload2] = React.useState(false);
  const [toggle, setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [age, setAge] = React.useState('');
  const [value, setValue] = React.useState('1');
  const [bulkloader, setBulkloader] = React.useState(false);
  const [barmodal, setBarmodal] = React.useState(false);
  const [expmodal, setExpmodal] = React.useState(false);
  const [expmodalview, setExpmodalview] = React.useState(false);
  const [tableloader, setTableloader] = React.useState(false);

  const [array1, setArray1] = React.useState([])

  const [blocked, setBlocked] = useState(0)
  const [blocked2, setBlocked2] = useState(0)
  const [blocked3, setBlocked3] = useState(0)
  const [array, setArray] = React.useState([])
  const [refcode, setRefcode] = React.useState("")
  const [checkboxarray, setCheckboxarray] = React.useState([])
  const [trigger, setTrigger] = React.useState(false)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [bulkfileFormat, setBulkfileFormat] = useState(null);
  const [form, setForm] = useState({
    productcode: "",
    productname: "",
    printname: "",
    othername: "",
    description: "",
    refcode: "",
    warranty: "",
    orderlevel: "",
    orderqty: "",
    dummypurchase: "",
    dummysale: "",
    vat: "",
    tax: ""
  })
  const [count, setCount] = useState(1)
  const [bulkmodal, setBulkmodal] = React.useState(false);

  const [barcodename, setBarcodename] = React.useState('barcode');
  const [barcodeprice, setBarcodeprice] = React.useState('');
  const [barcodenameog, setBarcodenameog] = React.useState('');

  const [source, setSource] = React.useState([]);
  const [userId, setUserid] = React.useState('');
  const [productid, setProductid] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [batchbtnloader, setBatchbtnloader] = React.useState(false);
  const [unitbtnloader, setUnitbtnloader] = React.useState(false);
  const [batchlistloader, setBatchlistloader] = React.useState(false);
  const [unitlistloader, setUnitlistloader] = React.useState(false);

  const [bulkimagefile, setBulkimagefile] = React.useState();

  const [colorcode, setColorcode] = React.useState("");
  const [input, setInput] = useState(0);
  const [downloadlink, setDownloadlink] = React.useState("");

  const [purchasemodal, setPurchasemodal] = React.useState(false);

  const [searchsource, setSearchsource] = React.useState([]);

  const [hsnList, setHsnlist] = React.useState([]);

  const [godown, setGodown] = React.useState('');
  const [godownList, setGodownlist] = React.useState([]);

  const [codetype, setCodetype] = React.useState('');

  const [color, setColor] = React.useState("");
  const [size, setSize] = React.useState("");
  const [sizelist, setSizelist] = React.useState([]);
  const [colorlist, setColorlist] = React.useState([]);
  const [taxList, setTaxlist] = React.useState([]);
  const [itemGroup, setItemgroup] = React.useState('');
  const [itemgroupList, setItemgrouplist] = React.useState([]);

  const [brand, setBrand] = React.useState('');
  const [brandList, setBrandlist] = React.useState([]);

  const [branches, setBranches] = React.useState([]);

  const [category, setCategory] = React.useState('');
  const [categoryList, setCategorylist] = React.useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');

  // const [ vat , setVat] = React.useState('');
  // const [vatList,setVatlist]= React.useState([]);

  const [subCategory, setSubcategory] = React.useState('');
  const [subcatlist, setSubcatlist] = React.useState([]);

  const [baseUnit, setBaseunit] = React.useState('');
  const [purchaseUnit, setPurchaseunit] = React.useState('');
  const [salesUnit, setSalesunit] = React.useState('');
  const [defaultPrice, setDefaultprice] = React.useState('');

  const [saveLoader, setSaveloader] = React.useState(false)

  const [pothersid, setPotherid] = React.useState('');

  const [productSave, setProductsave] = React.useState(false);

  const [priceList, setPricelist] = React.useState([]);

  const [unitList, setUnitlist] = React.useState([]);

  const [selected, setSelected] = React.useState([]);
  const [branchlist, setBranchlist] = React.useState([]);

  const [multipleproduct, setMultipleproduct] = React.useState([{ pe_color: "", pe_size: "", pe_purchaseprice: "", pe_salesrate: "", pe_mrp: "", pe_stock: "", pe_image: [] }]);
  const [expiryDate, setExpirydate] = React.useState(new Date);
  const [producttype, setProducttype] = React.useState(0);
  const [file, setFile] = React.useState();
  const [bulkfile, setBulkfile] = React.useState();
  const [listtype, setListtype] = React.useState(0);

  const [segment, setSegment] = React.useState("");
  const [subsegment, setSubsegment] = React.useState("");


  const [dlocation, setDlocation] = React.useState("");
  const [subsegmentlist, setSubsegmentlist] = React.useState([]);
  const [segmentlist, setSegmentlist] = React.useState([]);

  const [dlocationlist, setDlocationlist] = React.useState([]);
  const [countrylist, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState("");


  //Defaultprice
  const [dproductcolor, setDproductcolor] = React.useState("");
  const [dproductsize, setDproductsize] = React.useState("");
  const [dcost, setDcost] = React.useState("");
  const [dproductpurchaseprice, setDpurchaseprice] = React.useState("");
  const [dsalesrate, setDsalesrate] = React.useState("");
  const [dunit, setDunit] = React.useState("");
  const [dopeningqty, setDopeningqty] = React.useState("");
  const [dexpirydate, setDexpirydate] = React.useState(new Date);
  const [defaulttablerow, setDefaulttablerow] = React.useState([]);
  const [viewshow, setViewshow] = React.useState(true);





  // ecommerse
  const [ecommerceproduct, setEcommerceproduct] = React.useState([]);
  const [productcolor, setProductcolor] = React.useState("");
  const [productsize, setProductsize] = React.useState("");
  const [productpurchaseprice, setProductpurchaseprice] = React.useState("");
  const [productsalesprice, setProductsalesprice] = React.useState("");
  const [productmrp, setProductmrp] = React.useState("");
  const [productstock, setProductstock] = React.useState("");
  const [productimage, setProductimage] = React.useState([]);
  const [productecommerceid, setProductecommerceid] = React.useState("");
  const [viewimage, setViewimage] = React.useState([]);
  const [editproductimage, setEditproductimage] = React.useState();
  const [ecommerceproductloader, setEcommerceproductloader] = React.useState(false);


  // batchtab

  const [tableRow, setTableRow] = React.useState([]);
  const [purchaseprice, setPurchase] = React.useState('');
  const [mrp, setMrp] = React.useState('');
  const [salesrate, setSales] = React.useState('');
  const [unit, setUnit] = React.useState('');
  const [batch, setBatch] = React.useState('');
  const [stock, setStock] = React.useState(0);
  const [batchcolor, setBatchcolor] = React.useState("");
  const [batchsize, setBatchsize] = React.useState("");
  const [batchexpirydate, setBatchexpirydate] = React.useState(new Date);
  const [batchid, setBatchid] = React.useState("");
  const [fil, setFil] = useState(true);
  const [filt, setFilt] = useState(false)

  const [uom, setUom] = React.useState(1);
  const [stockgroup, setStockgroup] = React.useState("");
  const [b2b, setB2b] = React.useState("");
  const [onlineprice, setOnlineprice] = React.useState("");
  const [intbarcode, setIntbarcode] = React.useState("");
  const [manbarcode, setManbarcode] = React.useState("");
  const [qrcode, setQrcode] = React.useState("");
  const [hsn, setHsn] = React.useState("");
  const [tax, setTax] = React.useState('');
  const [enddate, setEnddate] = React.useState(new Date);
  const [convertunit, setConvertunit] = React.useState("");
  const [currencyqr, setCurrenctqr] = React.useState('');
  const [currencydoller, setCurrenctdoller] = React.useState('');
  const [plc, setPlc] = React.useState('');

  // multiunittab

  const [multiUnitrow, setMultiunitrow] = React.useState([]);
  const [multibaseunit, setMultibaseunit] = React.useState('');
  const [cnvunit, setCnvunit] = React.useState('');
  const [baseqty, setBaseqty] = React.useState('');
  const [cnvratio, setCnvratio] = React.useState('');
  const [cnvqty, setCnvqty] = React.useState('');

  // product others

  const [supplierList, setSupplierlist] = React.useState([]);
  const [supplierCode, setSuppliercode] = React.useState('');
  const [supplierName, setSuppliername] = React.useState('');
  const [contactNumber, setContactnumber] = React.useState('');
  const [mainsupplierlist, setMainsupplierlist] = React.useState([]);
  const [mainsupplier, setMainsupplier] = React.useState("");
  const [gst, setGst] = React.useState('');
  const [expid, setExpid] = React.useState('');
  const [expdate, setExpdate] = React.useState(new Date);
  const [expdatename, setExpdatename] = React.useState([]);
  const [preview, setPreview] = React.useState(false);
  const [bulkpreview, setBulkpreview] = React.useState([]);
  const [bulkdupli, setBulkdupi] = React.useState(false);
  const [progresshide, setProgressHide] = React.useState(false);
  const [progress, setProgress] = React.useState(1);

  const [vegmodal, setVegmodal] = React.useState(false);
  const [grocerymodal, setGrocerymodal] = React.useState(false);
  const [barcodemodal, setBarcodemodal] = React.useState(false);
  const [barcodepedit, setBarcodepedit] = React.useState("");
  const [barcodelist, setBarcodelist] = React.useState([]);
  const [veglist, setVeglist] = React.useState([]);
  const [fromedit, setFromedit] = React.useState("");
  const [toedit, setToedit] = React.useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);

  // const [productStatus, setProductStatus] = useState(false);
  const [productStatus, setProductStatus] = useState(0);
  const [productId, setProductId] = useState(1);

  const expClick = (id) => {
    setExpmodal(true)
    setExpid(id)
  }

  useEffect(() => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    setUserbranchid(userdata.user_branch_id)
  }, [])

  useEffect(() => {
    for (var i = 0; i < bulkpreview.length; i++) {
      if (bulkpreview[i].d_duplicate == 1 || bulkpreview[i].d_dup_barcode == 1 || bulkpreview[i].d_dup_department_master == 1 || bulkpreview[i].d_dup_section_master == 1 || bulkpreview[i].d_dup_maincategory_master == 1 || bulkpreview[i].d_dup_subcategory_master == 1 ||
        bulkpreview[i].d_dup_segment_master == 1 || bulkpreview[i].d_dup_subsegment_master == 1 || bulkpreview[i].d_dup_displayloc_master == 1 || bulkpreview[i].d_dup_supcontact_master == 1 || bulkpreview[i].d_dup_supgroup_master == 1 || bulkpreview[i].d_dup_country_master == 1 ||
        bulkpreview[i].d_dup_itemgroup_master == 1 || bulkpreview[i].d_dup_godown_master == 1 || bulkpreview[i].d_dup_supref_master == 1 || bulkpreview[i].d_pluname == "" || bulkpreview[i].d_dup_machine == 1 || bulkpreview[i].d_dup_expirydate == 1) {
        setBulkdupi(true)
      } else {
        setBulkdupi(false)
      }
    }
  }, [bulkpreview])

  // const expViewClick = (id) => {
  //   setExpmodalview(true)
  //   var company = JSON.parse(localStorage.getItem("company"))
  //   var data = {
  //     "pb_id" : id,
  //     "companyid" : company
  //   }

  //   fetch(global.url + "viewExpiry", {
  //     method: "POST",
  //     headers: {
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  // })
  // .then((response)=> response.json())
  // .then((responseJson)=>{

  //     if(responseJson.error == false){
  //       setExpdatename(responseJson.data)
  //     } 

  // })
  // .catch((error)=>{
  //     console.log(error)
  // })

  // }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = bulkFile;
    link.download = 'bulkuploadformat.xlsx';
    link.click();

  }

  const expsubmitClick = () => {
    var data = {
      "pb_id": expid,
      "pb_date": expdate
    }
    fetch(global.url + "addExpiry", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("add expiry", responseJson) 
        if (responseJson.error == false) {
          alert(responseJson.message)
        }
        setExpmodal(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // useEffect(() => {
  //   console.log("global.txt  + downloadlink", global.txt  + downloadlink)
  // }, [downloadlink])

  const addBatch = (type) => {
    if (!productid || !salesrate || !mrp || !stock || !userId || !purchaseprice) {
      alert('detailes required');
      return
    }
    // setBatchbtnloader(true)
    // console.log("form.file", file[0])
    // var data = {
    //   "pb_productid_fk":  productid,
    //   "pb_userid":  userId,
    //   "pb_batch":  batch,
    //   "pb_unit":  unit,
    //   "pb_purchaseprice":  purchaseprice,
    //   "pb_salesrate":  salesrate,
    //   "pb_mrp":  mrp,
    //   "pb_stock": stock,
    //   "pb_color_fk": batchcolor,
    //   "pb_size_fk": batchsize,
    //   "pb_image": file,
    //   "pb_expiry_date":  batchexpirydate.toISOString().split('T')[0]
    // }

    function isIsoDate(str) {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
      var d = new Date(str);
      console.log(str, "str55")
      return d.toISOString() === str;

    }

    // console.log(isIsoDate(batchexpirydate))
    // console.log(isIsoDate('2018-11-10T11:22:33+00:00'));

    if (isIsoDate(batchexpirydate) == true) {

      const formData = new FormData();
      console.log(formData)


      formData.append('pb_productid_fk', productid);
      formData.append('pb_id', batchid);
      formData.append('pb_userid', userId);

      if (type == "single") {
        formData.append('pb_unit', baseUnit);
        formData.append('pb_batch', form.productname);
      }
      else {
        formData.append('pb_unit', unit);
        formData.append('pb_batch', batch);
      }

      formData.append('pb_purchaseprice', purchaseprice);

      formData.append('pb_expiry_date', batchexpirydate.toISOString().split('T')[0]);
      formData.append('pb_salesrate', salesrate);
      formData.append('pb_mrp', mrp);
      formData.append('pb_stock', stock);
      formData.append('pb_color_fk', batchcolor);
      formData.append('pb_size_fk', batchsize);
      formData.append('branchid', userbranchid);
      formData.append('companyid', usercompanyid);
      formData.append('pb_startdate', batchexpirydate.toISOString().split('T')[0]);
      formData.append('pb_uom', uom);
      formData.append('pb_baseqty', uom);
      formData.append('pb_stockgroup', stockgroup);
      formData.append('pb_b2bprice', b2b);
      formData.append('pb_onlineprice', onlineprice);
      formData.append('pb_inter_barcode', intbarcode);
      formData.append('pb_manual_barcode', manbarcode);
      formData.append('pb_qrcode', qrcode);
      formData.append('pb_hsn', hsn);
      formData.append('pb_tax', tax);
      formData.append('pb_currencyqr', currencyqr);
      formData.append('pb_currencydollar', currencydoller);
      formData.append('pb_purchase_landingcost', plc);
      formData.append('pb_endate', enddate);
      formData.append('branchid', userbranchid);
      formData.append('companyid', usercompanyid);
      formData.append('pb_iseditprice', blocked2);
      formData.append('pb_isconvertunit', blocked3);
      formData.append('pb_displayconvertunit', convertunit);

      const files1 = file;
      console.log("files", files1)
      if (files1 != null) {
        for (let i = 0; i < files1.length; i++) {
          console.log("one file", files1[i])
          formData.append(`pb_image[${i}]`, files1[i])
        }
      }

      // console.log("pb_productid_fk", productid)
      // console.log("pb_id", batchid)
      // console.log("pb_userid", userId)
      // console.log("pb_unit", unit)
      // console.log("pb_batch", batch)
      // console.log("pb_purchaseprice", purchaseprice)
      // console.log("pb_salesrate", salesrate)
      // console.log("pb_mrp", mrp)
      // console.log("pb_stock", stock)
      // console.log("pb_color_fk", batchcolor)
      // console.log("pb_size_fk", batchsize)
      // console.log("branchid", userbranchid)
      // console.log("companyid", usercompanyid)
      // console.log("pb_startdate", batchexpirydate)
      // console.log("pb_uom", uom)
      // console.log("pb_baseqty", uom)
      // console.log("pb_stockgroup", stockgroup)
      // console.log("pb_b2bprice", b2b)
      // console.log("pb_onlineprice", onlineprice)
      // console.log("pb_inter_barcode", intbarcode)
      // console.log("pb_manual_barcode", manbarcode)
      // console.log("pb_qrcode", qrcode)
      // console.log("pb_endate", enddate)
      // console.log("pb_iseditpirce", blocked2)

      fetch(global.url + "updateProductbatch", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson)
          if (responseJson.status == "false") {
            setBatchbtnloader(false)
            getBatchlist(productid)
            setBatch("")
            setUnit("")
            setPurchase("")
            setSales("")
            setMrp("")
            setStock("")
            setBatchsize("")
            setUom("")
            setStockgroup("")
            setIntbarcode("")
            setManbarcode("")
            setB2b("")
            setStock("")
            setOnlineprice("")
            setEnddate(new Date)
            setQrcode("")
            setHsn("")
            setTax("")
            setCurrenctqr("")
            setCurrenctdoller("")
            setPlc("")
            setBatchcolor("")
            setBatchexpirydate(new Date)
            setFile(null)
            setViewshow(false)
            setBlocked2(0)
            setBlocked3(0)
            setConvertunit("")
            console.log("response", responseJson.data)
            alert(responseJson.message)
          }
          else {
            alert(responseJson.message)
            setBatchbtnloader(false)
          }
        })
        .catch((error) => {
          alert("Something went wrong")
          setBatchbtnloader(false)
          console.log(error)
        })
    }
    else {
      // console.log(isIsoDate('2018-11-10T11:22:33+00:00'));
      // console.log("pb_productid_fk", productid)
      // console.log("pb_userid", userId)
      // console.log("pb_batch", batch)
      // console.log("pb_unit", unit)
      // console.log("pb_purchaseprice", purchaseprice)
      // console.log("pb_salesrate", salesrate)
      // console.log("pb_mrp", mrp)
      // console.log("pb_stock", stock)
      // console.log("pb_color_fk", batchcolor)
      // console.log("pb_size_fk", batchsize)
      // console.log("pb_image", file)
      // console.log("pb_expiry_date2", batchexpirydate)

      const formData = new FormData();
      formData.append('pb_productid_fk', productid);
      formData.append('pb_id', batchid);
      formData.append('pb_userid', userId);

      if (type == "single") {
        formData.append('pb_unit', baseUnit);
        formData.append('pb_batch', form.productname);
      }
      else {
        formData.append('pb_unit', unit);
        formData.append('pb_batch', batch);
      }

      formData.append('pb_purchaseprice', purchaseprice);
      formData.append('pb_salesrate', salesrate);
      formData.append('pb_mrp', mrp);
      formData.append('pb_stock', stock);
      formData.append('pb_color_fk', batchcolor);
      formData.append('pb_size_fk', batchsize);
      formData.append('branchid', userbranchid);
      formData.append('companyid', usercompanyid);
      formData.append('pb_expiry_date', batchexpirydate.toISOString().split('T')[0]); //.toISOString().split('T')[0]
      formData.append('pb_baseqty', uom);
      formData.append('pb_uom', uom);
      formData.append('pb_stockgroup', stockgroup);
      formData.append('pb_b2bprice', b2b);
      formData.append('pb_onlineprice', onlineprice);
      formData.append('pb_inter_barcode', intbarcode);
      formData.append('pb_manual_barcode', manbarcode);
      formData.append('pb_qrcode', qrcode);
      formData.append('pb_hsn', hsn);
      formData.append('pb_tax', tax);
      formData.append('pb_currencyqr', currencyqr);
      formData.append('pb_currencydollar', currencydoller);
      formData.append('pb_purchase_landingcost', plc);
      formData.append('pb_endate', enddate);
      formData.append('pb_iseditprice', blocked2);
      formData.append('pb_isconvertunit', blocked3);
      formData.append('pb_displayconvertunit', convertunit);
      formData.append('branchid', userbranchid);
      formData.append('companyid', usercompanyid);

      const files1 = file;
      console.log("files", files1)
      if (files1 != null) {
        for (let i = 0; i < files1.length; i++) {
          console.log("one file", files1[i])
          formData.append(`pb_image[${i}]`, files1[i])
        }

        for (var pair of formData.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }
      }
      console.log("pb_iseditpirce", blocked2)
      console.log('data2', uom)

      fetch(global.url + "updateProductbatch", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson)
          if (responseJson.status == "false") {
            setBatchbtnloader(false)
            getBatchlist(productid)
            setBatch("")
            setUnit("")
            setPurchase("")
            setSales("")
            setMrp("")
            setStock("")
            setBatchsize("")
            setBatchcolor("")
            setUom("")
            setStockgroup("")
            setIntbarcode("")
            setManbarcode("")
            setB2b("")
            setStock("")
            setOnlineprice("")
            setEnddate(new Date)
            setQrcode("")
            setHsn("")
            setTax("")
            setCurrenctdoller("")
            setPlc("")
            setCurrenctqr("")
            setBatchexpirydate(new Date)
            setFile(null)
            setViewshow(false)
            setBlocked2(0)
            setBlocked3(0)
            setConvertunit("")
            console.log("response", responseJson.data)
            alert(responseJson.message)
          }
          else {
            alert(responseJson.message)
            setBatchbtnloader(false)
          }
        })
        .catch((error) => {
          alert("Something went wrong")
          setBatchbtnloader(false)
          console.log(error)
        })
    }
  }

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
  }

  const addProductecommerse = () => {

    const formData = new FormData();
    formData.append('pe_productid_fk', productid);
    formData.append('pe_id', productecommerceid);
    formData.append('pe_color', productcolor);
    formData.append('pe_size', productsize);
    formData.append('pe_purchaseprice', productpurchaseprice);
    formData.append('pe_salesrate', productsalesprice);
    formData.append('pe_mrp', productmrp);
    formData.append('pe_stock', productstock);
    formData.append('pe_userid', userId);
    // formData.append('pe_image', JSON.stringify(productimage));

    const files1 = productimage;
    console.log("files", files1)
    for (let i = 0; i < files1.length; i++) {
      console.log("one file", files1[i])
      formData.append(`pe_image[${i}]`, files1[i])
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    fetch(global.url + "editProductecommerce", {
      method: "POST",

      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("product add responseJson", responseJson)
        if (responseJson.status == "false") {
          alert(responseJson.message)
          getProductecomerse(productid)
          setProductecommerceid("")
          setProductcolor("")
          setProductsize("")
          setProductpurchaseprice("")
          setProductsalesprice("")
          setProductmrp("")
          setProductstock("")
          // setViewimage(pe_details)
          productImageview("")
          setViewimage([])
          setViewshow(false)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changestock = (e) => {
    if (e < 0) {
      setStock(0)
    }
    else {
      setStock(e)
    }
  }

  const radioChange = (event) => {
    console.log(event.target.value, "event.target.value")
    if (event.target.value == "nonbatch") {

      setProducttype(0)
    }
    else {
      setProducttype(1)
    }
  }

  const selectImage = () => {

  }

  const barcodClick = (name, price, nameog) => {
    setBarmodal(true)
    setBarcodename(name)
    setBarcodeprice(price)
    setBarcodenameog(nameog)
  }

  const addSupplier = () => {
    // console.log("form.productid", productid)
    var data = {
      "psupplier_product_fk": productid,
      "psupplier_supplier_fk": mainsupplier
      // "psupplier_code":  supplierCode,
      // "psupplier_name":  supplierName,
      // "psupplier_contactno":  contactNumber,
      // "psupplier_gstin":  gst,
    }
    // console.log("data", data)
    fetch(global.url + "addProductsupplier", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("add sup response", responseJson)
        if (responseJson.status == "false") {
          getSupplierlist(productid)
          setSuppliercode("")
          setSuppliername("")
          setContactnumber("")
          setMainsupplier("")
          setGst("")
          // console.log("response", responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fileSelectedHandler = (e) => {
    setProductimage(e.target.files)
  }

  const dfileSelectedHandler = (e) => {
    // setDproductimage(e.target.files)
  }

  // const changeBulkfile = (e) => {
  //   setBulkfile(e.target.files[0])
  // }

  const changeBulkfile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bulkfile = e.target.result.split(',')[1];
        console.log("the base64 string is :", bulkfile);
        setBulkfile(bulkfile)
      };
      reader.readAsDataURL(file);
    }
  };

  // const changeBulkfile =(e) => {
  //   setBulkfile(e.target.files[0])
  // }

  const fileSingleselectedHandler = (e) => {
    setEditproductimage(e.target.files)
  }

  const batchfile = (e) => {
    console.log("files", e.target.files)
    setFile(e.target.files)
  }


  const addMultiunit = () => {
    setUnitbtnloader(true)
    console.log("form.productid", productid)
    var data = {
      "pu_userid": userId,
      "pu_baseunit": multibaseunit,
      "pu_cnvqty": cnvqty,
      "pu_cnvratio": cnvratio,
      "pu_baseqty": baseqty,
      "pu_cnvunit": cnvunit,
      "pu_productid_fk": productid,
    }
    console.log("data", data)
    fetch(global.url + "updateProductunit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        if (responseJson.status == "false") {
          getMultiunitlist(productid)
          setCnvunit("")
          setBaseqty("")
          setCnvratio("")
          setBaseunit("")
          setCnvqty("")
          console.log("response", responseJson.data)
          setUnitbtnloader(false)
        }
        else {
          setUnitbtnloader(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    getData(listtype)
    getBranches()
    getMainsupplier()

  }, []);

  useEffect(() => {
    getData(listtype)
  }, [listtype])

  const getMainsupplier = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    // setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewSupplier", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response supplier", responseJson)

        if (responseJson.Error == "false") {

          setMainsupplierlist(responseJson.data)
          console.log("response", responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getData(listtype)
  }, []);

  useEffect(() => {
    calculateCnvqty1()
  }, [baseqty, cnvratio]);

  const getSubcategorylist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewSubcategory", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        setLoader(false)
        if (responseJson.status == "false") {
          setSubcatlist(responseJson.data)
          setSubcategory(responseJson.data[0].subCategory_id)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getProductcode = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "userid": userdata.user_id
    }
    fetch(global.url + "getProductcode", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        const newValues = { ...form }
        newValues['productcode'] = responseJson.data.productcode
        newValues['productname'] = ""
        newValues['printname'] = ""
        newValues['othername'] = ""
        newValues['description'] = ""
        newValues['refcode'] = ""
        newValues['warranty'] = ""
        newValues['dummysale'] = ""
        newValues['dummypurchase'] = ""
        newValues['vat'] = ""
        newValues['tax'] = ""
        setForm(newValues)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // const getProductcode = () => {
  //   var userdata = JSON.parse(localStorage.getItem("user"))
  //     var data = {
  //       "companyid": usercompanyid,
  //       "codetype": codetype
  //     }
  //     fetch(global.url + "productCodeStart", {
  //         method: "POST",
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(data),
  //       })
  //       .then((response)=> response.json())
  //       .then((responseJson)=>{
  //         console.log("response", responseJson)
  //         const newValues = { ...form }
  //         newValues['productcode']= responseJson.data.code
  //         newValues['productname']= ""
  //         newValues['printname']= ""
  //         newValues['othername']= ""
  //         newValues['description']= ""
  //         newValues['refcode']= ""
  //         newValues['warranty']= ""
  //         setForm(newValues)
  //       })
  //       .catch((error)=>{
  //           console.log(error)
  //       })
  // }


  const getColorlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewColor", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        setLoader(false)
        if (responseJson.status == "false") {
          setColorlist(responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSizelist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewSize", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        setLoader(false)
        if (responseJson.status == "false") {
          setSizelist(responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getBranches = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "company_id": company,
    }
    fetch(global.url + "multipleBranch", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("branch", responseJson)
        if (responseJson.error == false) {
          setBranchlist(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getHsnlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewHSN", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        setLoader(false)
        if (responseJson.status == "false") {
          setHsnlist(responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }



  const getBrandlist = () => {

    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))

    var data = {
      "type": userdata.user_type,
      "companyid": company,
      "id": 1
    }
    fetch(global.url + "viewBrand", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("brand response", responseJson)
        if (responseJson.status == "false") {
          setBrandlist(responseJson.data)
          setBrand(responseJson.data[0].brand_id)
          
          console.log("response", responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getItem = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type,
      "id": 1
    }
    fetch(global.url + "viewItemgroup", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("brand response", responseJson)
        if (responseJson.status == "false") {
          setItemgrouplist(responseJson.data)
          setItemgroup(responseJson.data[0].itemgroup_id)
          
          console.log("response", responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUnitlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewUnit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false)
        if (responseJson.status == "false") {
          setUnitlist(responseJson.data)
          console.log("response", responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }


  const getPricelist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type,
      "id": 1
    }
    fetch(global.url + "viewPricelevel", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        if (responseJson.status == "false") {
          setPricelist(responseJson.data)
          
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCategorylist = () => {

    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type": userdata.user_type,
      "companyid": company,
      "id": 1
    }
    fetch(global.url + "viewCategory", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false)
        if (responseJson.status == "false") {
          setCategorylist(responseJson.data)
          setCategory(responseJson.data[0].category_id)
                   console.log("response", responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  // const getCategorylist = () => {

  // }

  useEffect(() => {
    getData(listtype)
  }, [input]);


  // const handleButtonClick = (productId , productStatus) => {
  //   setProductStatus(!productStatus  );

  //   const proStatus = productStatus  == 0 ? 1 :0

  //   var data = {
  //       product_isactive: proStatus,
  //       products_id: productId

  //   }

  //   const response = fetch (global.url + "" ,{
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'APPKEY': 'erp6129'
  //     },
  //     body: JSON.stringify(data),
  //   })

  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     console.log("123", responseJson)


  //     if (responseJson.status == "false") {
  //       // setSource(responseJson.data)
  //       getData();
  //       console.log("response", responseJson.data)
  //     }

  //   })

  //   .catch((error) => {
  //     console.log(error)
  //   })

  // };

  // const handleButtonClick = async (productId, productStatus) => {
  //   try {
  //     // if (productId == null || productStatus == null) {
  //     //   console.error('Invalid parameters. productId and productStatus are required.');
  //     //   return;
  //     // }

  //     const proStatus = productStatus === 0 ? 1 : 0;
  //    console.log(proStatus,'0123');

  //     const data = {
  //       product_isactive: proStatus,
  //       products_id: productId
  //     };

  //     const response = await fetch(global.url + "", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'APPKEY': 'erp6129'
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     const responseJson = await response.json();

  //     console.log("Response:", responseJson);

  //     if (response.ok) {
  //       // setSource(responseJson.data);
        
  //       console.log(`${productId}`);
  //       getData();
  //     } else {
  //       console.log("Request was successful.");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };




  const getData = (type) => {
    if (type == 0) {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)

      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "page": input * 10,
        "limit": 10,
        // "branchid" :branch
      }

      fetch(global.url + "viewAllproductbatchunhide", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'APPKEY': 'erp6129'
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("viewAllproductbatch response", responseJson)
          setLoader(false)
          if (responseJson.status == "false") {
            setSource(responseJson.data)
            console.log("response", responseJson.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      console.log("type", 1)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(userdata.user_branch_id)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "page": input * 10,
        "limit": 10,
        // "branchid" : userdata.user_branch_id
      }
      fetch(global.url + "viewProducts", {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson)
          setLoader(false)
          if (responseJson.status == "false") {
            setSource(responseJson.data)
            console.log("response", responseJson.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }


  const listtypeChange = (event) => {
    setListtype(event.target.value);
  };

  const countryChange = (event) => {
    setCountry(event.target.value)
  }

  const itemgroupChange = (event) => {
    setItemgroup(event.target.value);
  };

  const brandChange = (event) => {
    setBrand(event.target.value);
  };

  const codetypeChange = (event) => {
    setCodetype(event.target.value);
  };

  const batchcolorChange = (event) => {
    setBatchcolor(event.target.value)
  }

  const segmentChange = (event) => {
    setSegment(event.target.value)
    getSubsegment(event.target.value)
  }

  const subsegmentChange = (event) => {
    setSubsegment(event.target.value)
  }

  const batchsizeChange = (event) => {
    setBatchsize(event.target.value)
  }

  const categoryChange = (event) => {
    setCategory(event.target.value);
  };

  const subcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };

  const baseunitChange = (event) => {
    setBaseunit(event.target.value);
  };

  const mainsupplierChange = (event) => {
    setMainsupplier(event.target.value)
  }

  const godownChange = (event) => {
    setGodown(event.target.value);
  };

  const productcolorChange = (event) => {
    setProductcolor(event.target.value);
  };

  const productsizechange = (event) => {
    setProductsize(event.target.value);
  };

  const colorChange = (event, index) => {
    const input = [...multipleproduct]
    input[index].pe_color = event.target.value

    setMultipleproduct(input)
  };

  const changeMultiplepurchaseprice = (event, index) => {
    const input = [...multipleproduct]
    input[index].pe_purchaseprice = event.target.value
    setMultipleproduct(input)
  }

  const changeMultiplesalesrate = (event, index) => {
    const input = [...multipleproduct]
    input[index].pe_salesrate = event.target.value
    setMultipleproduct(input)
  }

  const changeMultiplemrp = (event, index) => {
    const input = [...multipleproduct]
    input[index].pe_mrp = event.target.value
    setMultipleproduct(input)
  }

  const expiryChange = (event, index) => {
    const input = [...multipleproduct]

    input[index].pe_expirydate = event
    setMultipleproduct(input)
    console.log("event", multipleproduct)
  }

  const changeMultiplestock = (event, index) => {
    const input = [...multipleproduct]
    input[index].pe_stock = event.target.value
    setMultipleproduct(input)
  }

  const sizeChange = (event, index) => {
    const input = [...multipleproduct]
    input[index].pe_size = event.target.value
    setMultipleproduct(input)
  };

  const purchaseunitChange = (event) => {
    setPurchaseunit(event.target.value);
  };

  const unitChange = (event) => {
    setUnit(event.target.value);
  };


  const salesunitChange = (event) => {
    setSalesunit(event.target.value);
  };

  const defaultpriceChange = (event) => {
    setDefaultprice(event.target.value);
  };



  const multibaseunitChange = (event) => {
    setMultibaseunit(event.target.value);
  };

  const cnvunitChange = (event) => {
    setCnvunit(event.target.value);
  };

  const dlocationChange = (event) => {
    setDlocation(event.target.value);
  };

  const dproductcolorChange = (event) => {
    setDproductcolor(event.target.value);
  };

  const dproductsizechange = (event) => {
    setDproductsize(event.target.value);
  };

  const dunitchange = (event) => {
    setDunit(event.target.value);
  };





  const onNext = () => {

    setSaveloader(true)

    const exampleArray = []
    for (var i = 0; i < multipleproduct.length; i++) {
      if (multipleproduct[i].pe_color == "") {
        // tableRow.splice(i, 1)
      }
      else {
        exampleArray.push(multipleproduct[i])
      }
    }
    var data = {
      "product_userid": userId,
      "product_code": form.productcode,
      "products_id": productid,
      "product_name": form.productname,
      "product_printname": form.printname,
      "product_othername": form.othername,
      "product_refcode": form.refcode,
      "product_type": producttype,
      "product_hsn": "",
      "product_tax": "",
      "product_taxrate": form.tax,
      "product_taxtype": form.vat,
      "product_description": form.description,
      "product_warranty": form.warranty,
      "product_itemgroup": itemGroup,
      "product_brand": brand,
      "product_category": category,
      "product_subcategory": subCategory,
      "product_baseunit": baseUnit,
      "product_saleunit": salesUnit,
      "product_purchaseunit": purchaseUnit,
      "product_defaultprice": defaultPrice,
      "product_branches": selected,
      "multipleprice_table": exampleArray,
      "product_branchid": userbranchid,
      "product_companyid": usercompanyid,
      "product_godown": godown,
      "product_segment": segment,
      "product_subsegment": subsegment,
      "product_displaylocation": dlocation,
      "product_orderlevel": form.orderlevel,
      "product_orderquantity": form.orderqty,
      "product_isblocked": blocked,
      "product_countryorigin": country,
      "codetype": codetype,
      "bulk_edit_purchaserate": form.dummypurchase,
      "bulk_edit_salesrate": form.dummysale
    }
    console.log("Product data", data)

    fetch(global.url + "updateProducts", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responsejson save", responseJson);
        if (responseJson.error == false) {
          setProductid(responseJson.data)
          getBatchlist(responseJson.data)
          getMultiunitlist(responseJson.data)
          getSupplierlist(responseJson.data)
          alert(responseJson.message)
          setSaveloader(false)
          setViewshow(true)
        }
        if (responseJson.status == "true") {
          alert(responseJson.message)
          setProductsave(false)
          setSaveloader(false)
        }
        if (responseJson.status == "false") {
          alert(responseJson.message)
          getBatchlist(productid)
          setSaveloader(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editClick = (
    productid,
    productcode,
    productname,
    printname,
    othername,
    description,
    tax,
    vat,
    itemgroup,
    brand,
    category,
    subcategory,
    baseunit,
    purchaseunit,
    salesunit,
    defaultprice,
    branches,
    producttype,
    refcode,
    warranty,
    segment,
    subsegment,
    orderlevel,
    orderqty,

    displayloc,
    godown,
    country,
    blocked
  ) => {
    console.log("productid editclick", productid)
    getSelectedbranch(productid)
    getSupplierlist(productid)
    getHsnlist()
    getItem()
    getBrandlist()
    getCategorylist()
    getUnitlist()
    getPricelist()
    getSubcategorylist()
    getColorlist()
    getSizelist()
    getGodownlist()
    getSegment()
    getDlocation()
    getCountry()

    setModal(true)
    setEditmode(true)
    setProductid(productid)
    console.log(tax)
    const newValues = { ...form }
    newValues['productcode'] = productcode
    newValues['productname'] = productname
    newValues['printname'] = printname
    newValues['othername'] = othername
    newValues['description'] = description
    newValues['refcode'] = refcode
    newValues['warranty'] = warranty
    newValues['orderlevel'] = orderlevel
    newValues['orderqty'] = orderqty
    newValues['tax'] = tax
    newValues['vat'] = vat
    setForm(newValues)
    setItemgroup(itemgroup)
    setBrand(brand)
    setCategory(category)
    setSubcategory(subcategory)
    setBaseunit(baseunit)
    setSegment(segment)
    getSubsegment(segment)
    setSubsegment(subsegment)
    setDlocation(displayloc)
    setGodown(godown)
    setCountry(country)
    setPurchaseunit(purchaseunit)
    setSalesunit(salesunit)
    setDefaultprice(defaultprice)
    setProducttype(producttype)
    getBatchlist(productid)
    getMultiunitlist(productid)
    getProductecomerse(productid)
    setBlocked(blocked)

    setExpirydate(new Date)
    setPotherid("")
    setProductsave(false)
    setViewshow(false)

  }

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("respo country", responseJson)
        if (responseJson.status == "false") {
          setCountryList(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getDlocation = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    const data = {
      "companyid": company,
      "type": userdata.user_type,
      "id": 1
    }
    fetch(global.url + "dropdownDisplayloc", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("dropdownDisplayloc", responseJson)

        if (responseJson.status == "false") {
          setDlocationlist(responseJson.data)
          setDlocation(responseJson.data[0].disloc_id)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSegment = (id) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    const data = {
      "companyid": company,
      "type": userdata.user_type,
            "segmentid": id

    }
    fetch(global.url + "dropdownSegment", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)

        if (responseJson.status == "false") {
          setSegmentlist(responseJson.data)
          setSegment(responseJson.data[0].segment_id)
          
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getTaxlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type": userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewTaxmaster", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == "false") {
          console.log("response", responseJson.data)
          setTaxlist(responseJson.data)
          // setVatlist(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSubsegment = (id) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    const data = {
      "companyid": company,
      "type": userdata.user_type,
      // "segmentid": id
      "id":1
    }
    fetch(global.url + "viewSubsegment", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response dropsubseg", responseJson)

        if (responseJson.status == "false") {
          console.log("response dropsubseg", responseJson)

          setSubsegmentlist(responseJson.data)
          setSubsegment(responseJson.data[0].subseg_id)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getProductecomerse = (id) => {
    setEcommerceproductloader(true)
    var data = {
      "pe_product_id": id,
    }
    fetch(global.url + "viewProductEcommerce", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("productecommerse", responseJson)
        setEcommerceproductloader(false)
        if (responseJson.error == false) {
          setEcommerceproduct(responseJson.data)
        }
        else {
          setEcommerceproduct([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getGodownlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type": userdata.user_type,
      "companyid": company,
      "id": 1
    }
    fetch(global.url + "viewGodown", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("godown list", responseJson)
        if (responseJson.status == "false") {
          setGodownlist(responseJson.data)
          setGodown(responseJson.data[0].godown_id)
          // setGodownlist([])
        }
        else {
          // setGodownlist(responseJson.data)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }


  const getSelectedbranch = (id) => {
    var data = {
      "id": id,
      "type": "product"
    }
    fetch(global.url + "multipleBranchSelect", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          setSelected(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onAdd = () => {
    getHsnlist()
    getItem()
    getBrandlist()
    getCategorylist()
    getUnitlist()
    getPricelist()
    getTaxlist()
    getSubcategorylist()
    getColorlist()
    getSizelist()
    getGodownlist()
    getSegment()
    getDlocation()
    getCountry()
    getSubsegment()

    setModal(true)
    setEditmode(false)
    setProductid("")
    const newValues = { ...form }
    newValues['productcode'] = ""
    newValues['productname'] = ""
    newValues['printname'] = ""
    newValues['othername'] = ""
    newValues['description'] = ""
    newValues['refcode'] = ""
    newValues['warranty'] = ""
    newValues['orderlevel'] = ""
    newValues['orderqty'] = ""
    newValues['vat'] = ""
    newValues['tax'] = ""
    setForm(newValues)
    setItemgroup("")
    setBrand("")
    setCategory("")
    setSubcategory("")
    setBaseunit("")
    setPurchaseunit("")
    setSalesunit("")
    setDefaultprice("")
    setSegment("")
    setSubsegment("")
    setCountry("")
    setDlocation("")
    setBlocked(0)
    setProducttype(1)
    setSelected([])
    getProductcode()
    setExpirydate(new Date)
    setProductsave(false)
  }



  const getBatchlist = (id) => {
    setBatchlistloader(true)
    console.log("productid", id)
    var data = {
      "productid": id
    }
    fetch(global.url + "viewProductbatch", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("batch response", responseJson)
        // setLoader(false)
        if (responseJson.error == false) {
          setTableRow(responseJson.data)
          setDefaulttablerow(responseJson.data[0])
          console.log("if batch response", responseJson.data)
          setBatchlistloader(false)
        }
        else {
          console.log("else batch response", responseJson)
          setTableRow([])
          setDefaulttablerow([])
          setBatchlistloader(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSupplierlist = (id) => {
    console.log("productid", id)
    var data = {
      "productid": id
    }
    fetch(global.url + "viewMainproductsupplier", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("bil response", responseJson)
        if (responseJson.status == "false") {
          setSupplierlist(responseJson.data)
          console.log("response", responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getMultiunitlist = (id) => {
    setUnitlistloader(true)
    var data = {
      "productid": id
    }
    fetch(global.url + "viewProductunit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("unitlisttab response", responseJson)
        // setLoader(false)
        if (responseJson.status == "false") {
          setMultiunitrow(responseJson.data)
          console.log("response", responseJson.data)
          setUnitlistloader(false)
        }
        else {
          setMultiunitrow([])
          setUnitlistloader(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const calculateCnvqty = (event, type) => {
    console.log(event, type)
    if (type == "base") {
      setBaseqty(event)
    }
    else {
      setCnvratio(event)
    }
  }

  const calculateCnvqty1 = () => {
    var result = baseqty * cnvratio;
    console.log("ratio", result)
    setCnvqty(result)
  }



  const onhide = () => {
    setEditmode(false)
    setModal(false)
    setTableRow([])
    getData(listtype)
    clearBatch()
  }

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const deleteClick = (id) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "products_id": id,
      "product_userid": userdata.user_id,
    }
    fetch(global.url + "deleteProducts", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.data)
        getData(listtype)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteBatch = (id) => {
    var data = {
      "pb_id": id,
    }
    fetch(global.url + "deleteProductbatch", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.data)
        getBatchlist(productid)
        clearBatch()

      })
      .catch((error) => {
        console.log(error)
      })
  }



  const editbatch = (id, pb_batch, pb_unit, pb_color_fk, pb_size_fk, pb_image, pb_expiry_date, pb_purchaseprice, pb_salesrate, pb_mrp, pb_stock, pb_stockgroup, pb_baseqty, pb_b2bprice, pb_onlineprice, pb_inter_barcode, pb_manual_barcode, pb_qrcode, pb_hsn, pb_tax, pb_currencyqr, pb_currencydollar, pb_purchase_landingcost, pb_startdate, pb_endate, pb_iseditprice, pb_isconvertunit, pb_displayconvertunit) => {
    console.log("this function is being called.")
    console.log(pb_startdate, "start", pb_expiry_date, "expire")

    setBatchid(id)
    setBatch(pb_batch)
    setUnit(pb_unit)
    setBatchcolor(pb_color_fk)
    setBatchsize(pb_size_fk)
    setFile(pb_image)
    var expire = new Date(pb_startdate).toISOString();
    console.log(expire, "expire data")
    setBatchexpirydate(pb_expiry_date)
    setPurchase(pb_purchaseprice)
    setSales(pb_salesrate)
    setMrp(pb_mrp)
    setStock(pb_stock)
    productImageview(id)
    setViewshow(true)
    setStockgroup(pb_stockgroup)
    setUom(pb_baseqty)
    setB2b(pb_b2bprice)
    setOnlineprice(pb_onlineprice)
    setIntbarcode(pb_inter_barcode)
    setManbarcode(pb_manual_barcode)
    setQrcode(pb_qrcode)
    setHsn(pb_hsn)
    setTax(pb_tax)
    setCurrenctqr(pb_currencyqr)
    setCurrenctdoller(pb_currencydollar)
    setPlc(pb_purchase_landingcost)
    setBlocked2(pb_iseditprice)
    setBlocked3(pb_isconvertunit)
    setConvertunit(pb_displayconvertunit)
    var enddata = new Date(pb_endate).toString();
    console.log(enddata, "end date")
    setEnddate(enddata)
    console.log('pb_isconvertunit', pb_isconvertunit)
  }

  const editProductecommerse = (id, pe_color, pe_size, pe_purchaseprice, pe_salesrate, pe_mrp, pe_stock, pe_details) => {
    setProductecommerceid(id)
    setProductcolor(pe_color)
    setProductsize(pe_size)
    setProductpurchaseprice(pe_purchaseprice)
    setProductsalesprice(pe_salesrate)
    setProductmrp(pe_mrp)
    setProductstock(pe_stock)
    // setViewimage(pe_details)
    productImageview(id)

  }

  const clearProductecommerce = () => {
    setProductecommerceid("")
    setProductcolor("")
    setProductsize("")
    setProductpurchaseprice("")
    setProductsalesprice("")
    setProductmrp("")
    setProductstock("")
    setViewimage([])
  }

  const clearBatch = () => {
    setBatchid("")
    setBatch("")
    setUnit("")
    setBatchcolor("")
    setBatchsize("")
    setFile(null)
    setExpirydate(new Date)
    setPurchase("")
    setSales("")
    setMrp("")
    setUom("")
    setStockgroup("")
    setIntbarcode("")
    setConvertunit("")
    setManbarcode("")
    setB2b("")
    setStock("")
    setOnlineprice("")
    setEnddate(new Date)
    setQrcode("")
    setTax("")
    setCurrenctqr("")
    setCurrenctdoller("")
    setPlc("")
    setHsn("")
    setViewimage([])
    setViewshow(false)
  }

  const deleteMultiunit = (id) => {
    var data = {
      "pu_id": id,
    }
    fetch(global.url + "deleteProductunit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.data)
        getMultiunitlist(productid)

      })
      .catch((error) => {
        console.log(error)
      })
  }


  const deleteSupplier = (id) => {
    var data = {
      "psupplier_id": id,
      "user_id": userId,
    }
    fetch(global.url + "deleteProductsupplier", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(responseJson.data)
        getSupplierlist(productid)

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const productImagedelete = (id) => {
    var data = {
      "pei_id": id,
    }
    fetch(global.url + "deleteEcommerceImage", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("delete image response", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          productImageview(batchid)
          getBatchlist(productid)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteEcommerceproduct = (id) => {
    var data = {
      "pe_id": id
    }
    fetch(global.url + "deleteProductecommerce", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("Delete ecommerce product", responseJson)
        if (responseJson.status == "false") {
          alert(responseJson.data)
          productImageview(batchid)
          getProductecomerse(productid)
        }
        else {

        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const uploadimage = () => {
    setLoader(true)
    const formData = new FormData();
    formData.append('pei_image_fk', batchid);
    const files1 = editproductimage;
    console.log("files", files1)
    if (files1 != null) {
      for (let i = 0; i < files1.length; i++) {
        console.log("one file", files1[i])
        formData.append(`pe_image[${i}]`, files1[i])
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
    }

    console.log('formData', formData)
    fetch(global.url + "addEcommerceImage", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("upload image response", responseJson)
        setLoader(false)
        if (responseJson.error == false) {
          alert(responseJson.message)
          productImageview(batchid)
          getBatchlist(productid)
        } else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const productImageview = (id) => {
    console.log("productid", id)
    var data = {
      "pe_id": id,
    }
    fetch(global.url + "viewEcommerceImage", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("view product image response", responseJson)
        if (responseJson.status == "false") {
          setViewimage(responseJson.data)
        }
        else {
          setViewimage([])
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addMultiple = () => {
    setMultipleproduct([...multipleproduct, { pe_color: "", pe_size: "", pe_purchaseprice: "", pe_salesrate: "", pe_mrp: "", pe_stock: "", pe_image: [] }])
    console.log("multipleproduct", multipleproduct)
  }

  // const onBulksubmit = () => {
  //   setBulkloader(true)
  //   const formData = new FormData();
  //   formData.append('upload_file', bulkfile);
  //   formData.append('user_id', userId);
  //   formData.append('branch_id', userbranchid);
  //   formData.append('company_id', usercompanyid);
  //   console.log("branch_id", userbranchid)
  //   console.log("company_id", usercompanyid)
  //   // fetch(global.url + "dummyUploading", {
  //   //   method: "POST",
  //   //   body: formData,
  //   // })
  //   fetch(global.url + "excelUploadProduct", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson.error == false) {
  //         // setBulkloader(false)
  //         // setBulkmodal(false)
  //         // viewduplicationOndummyUploads()
  //         // setPreview(true)
  //         setBulkloader(false)
  //         setProgressHide(true)
  //         fucn()
  //       }
  //       else {
  //         setBulkloader(false)
  //         alert(responseJson.message)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  const onBulksubmit = () => {
    setBulkloader(true)
    const formData = new FormData();
    formData.append('upload_file', bulkfile);
    formData.append('user_id', userId);
    formData.append('branch_id', userbranchid);
    formData.append('company_id', usercompanyid);
    fetch(global.url + "excelUploadProduct", {
      method: "POST",
      headers: {
        'APPKEY': 'erp6129'
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          setBulkloader(false)
          setProgressHide(true)
          if (responseJson.data.is_displayed === 0) {
            // setShow(true);
            setNotificationMessage(responseJson.data.description);
          }
          fucn()
          setBulkmodal(false)
        }
        else {
          setBulkloader(false)
          alert(responseJson.message)
          setBulkmodal(false)
        }
      })


      .catch((error) => {
        console.log(error)
      })
  }

  const viewduplicationOndummyUploads = () => {
    var data = {
      branch_id: userbranchid,
      company_id: usercompanyid
    }
    fetch(global.url + "viewduplicationOndummyUploads", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('dummy', responseJson)
        if (responseJson.error == false) {
          setBulkpreview(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }

  const deleteDuplicate = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "company_id": company,
      "branch_id": branch
    }
    fetch(global.url + "deletedummyUploads", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("delete duplicate product", responseJson)
        // if(responseJson.error == false){
        //   alert(responseJson.message)
        //   setPreview(false)
        // }
        // else{
        //   alert(responseJson.message)
        // }
        onBulksubmit()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onBulkUploadSubmit = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    setBulkloader(true)
    setTableloader(true)
    const formData = new FormData();
    formData.append('upload_file', bulkfile);
    formData.append('user_id', userdata.user_id);
    formData.append('branch_id', userbranchid);
    formData.append('company_id', usercompanyid);
    console.log('upload_file', bulkfile)
    fetch(global.url + "excelUploadBatch", {
      method: "POST",

      body: formData,

    })
      .then((response) => response.json())
      .then((responseJson) => {
        setTableloader(false)
        if (responseJson.error == false) {
          setBulkloader(false)
          alert(responseJson.message)
          setPreview(false)
          getData(listtype)
        }
        else {
          setBulkloader(false)
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const multipleblockChange = (e, id) => {
    let isChecked = e.target.checked;
    console.log(isChecked)
    if (isChecked == true) {
      var input = [...array]
      input.push(id)
      console.log("input", input)
      setArray(input)
    }
    else {

      var testArr = [1, 2, 3, 4, 5];
      const val = 3; // Value to remove

      for (let i = 0; i < array.length; i++) {
        if (array[i] === id) {
          const removedElements = array.splice(i, 1);
          console.log(removedElements); // Outputs [3]
          i--; // Since the indexes of elements following this index get updated after removal
        }
      }
    }
    console.log("array", array)
  }


  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     // Check if the file extension is ".xlsx"
  //     const fileName = file.name.toLowerCase();
  //     if (fileName.endsWith('.xlsx')) {
  //       const reader = new FileReader();

  //       reader.onload =(e)  => {
  //         const bulkfile = e.target.result.split(',')[1];
  //         console.log("the base64 string is:", bulkfile);
  //         setBulkfile(bulkfile)
  //         setBulkfileFormat('xlsx');
  //       };

  //       reader.readAsDataURL(file);
  //     } else {
  //       // alert("Only supported XLSX format";
  //       // event.target.value = null;
  //       setBulkfileFormat('other');
  //     }
  //   } else {
  //     setBulkfileFormat(null);
  //   }
  // };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert('Please select files first');
      return;
    }
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    try {
      const response = await fetch(global.url + "BulkUpdateImage", {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        alert('Files uploaded successfully');
      } else {
        alert('Failed to upload the files');
      }
    } catch (error) {
      console.error('Error while uploading the files:', error);
      alert('Error occurred while uploading the files');
    }
  };

  const bulkimageupload = (e) => {
    setBulkimagefile(e.target.files)
  }


  const bulkimageuploadserver = (mode1) => {

    // var data = {
    //   "batchids": array,
    //   "images": bulkimagefile,
    // }
    const formData = new FormData();
    if (bulkimagefile != null) {
      for (let i = 0; i < bulkimagefile.length; i++) {
        console.log("one file", bulkimagefile[i])
        formData.append(`images[${i}]`, bulkimagefile[i])
      }

      for (let i = 0; i < array.length; i++) {
        console.log("one file", array[i])
        formData.append(`batchids[${i}]`, array[i])
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
    }
    // formData.append('batchids', array);
    console.log("data", array)
    formData.append("mode", mode1)
    fetch(global.url + "BulkUpdateImage", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          alert("success")
          getBatchlist(productid)
          setArray([])
        }
        else {
          setArray([])
          alert(responseJson.message)
        }
        console.log("response", responseJson)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const colorcodeSave = () => {
    var data = {
      "batch_id": array,
      "color_code": colorcode
    }
    fetch(global.url + "ProductColorcode", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          getBatchlist(productid)
          setColorcode('')
        }
        else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const searchProduct = (ref, name, bar, zone) => {
    setFilt(true)
    if (listtype == 0) {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(userdata.user_branch_id)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)

      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "branchid": userdata.user_branch_id,
        "referralcode": ref,
        "productname": name,
        "barcode": bar,
        "displaylocation": zone,
        "excelexport": true
      }
      console.log("productname", data)
      console.log("barcode", data)
      fetch(global.url + "viewAllproductbatchunhide", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("viewAllproductbatch", responseJson)
          setLoader(false)
          if (responseJson.status == "false") {
            setSource(responseJson.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(userdata.user_branch_id)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "referral_code": ref,
        "productname": name,

        // "branchid" : userdata.user_branch_id
      }
      console.log("data", data)
      fetch(global.url + "viewProducts", {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(" viewProducts response", responseJson)
          setLoader(false)
          if (responseJson.status == "false") {
            setSource(responseJson.data)
            console.log("response", responseJson.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const filtr1 = () => {
    setBtnload(true)
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      "usertype": userdata.user_type,
      "companyid": company,
      "branchid": userdata.user_branch_id,
      "referralcode": "",
      "productname": "",
      "barcode": "",
      "displaylocation": "",
      "codefrom": 1,
      "codeto": 7000,
      "excelexport": true
    }
    console.log("productname", data)
    fetch(global.url + "viewAllproductbatchunhide", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("sjdec28", responseJson)
        setLoader(false)
        setBtnload(false)
        if (responseJson.status == "false") {
          setSource(responseJson.data)
        } else {
          setSource([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const filtr2 = () => {
    setBtnload2(true)
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      "usertype": userdata.user_type,
      "companyid": company,
      "branchid": userdata.user_branch_id,
      "referralcode": "",
      "productname": "",
      "barcode": "",
      "displaylocation": "",
      "codefrom": 7000,
      "codeto": 10000,
      "excelexport": true
    }
    console.log("productname", data)
    fetch(global.url + "viewAllproductbatchunhide", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("sjdec282", responseJson)
        setLoader(false)
        setBtnload2(false)
        if (responseJson.status == "false") {
          setSource(responseJson.data)
        } else {
          setSource([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const searchProduct1 = (barcode, name, ref) => {
    setRefcode(ref)
    if (ref != "" || barcode != "" || name != "") {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(userdata.user_branch_id)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)

      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "referralcode": ref,
        "excelexport": true,
        "barcode": barcode,
        "productname": name
      }
      fetch(global.url + "viewAllproductbatch", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == "false") {
            setSearchsource(responseJson.data)
            console.log("purchase details resonse", responseJson.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else {
      setSearchsource([])
    }
  }

  const clearAll = () => {
    fetch(global.url + "clearAllProducts", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(" viewProducts 123ft", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          getCheckeddisplayproducts()
          setPurchasemodal(false)
        } else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const checkBoxclick = (e, id) => {

    let isChecked = e.target.checked;
    console.log(isChecked)
    setTableloader(true)
    var data = {
      "pb_id": id,
      "type": isChecked ? "checked" : "unchecked",
    }
    fetch(global.url + "statusCheckedProducts", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson)
        setTrigger(false)
        searchProduct1(refcode)
        getCheckeddisplayproducts()
        setTableloader(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getCheckeddisplayproducts()
  }, [trigger])

  const decrement = () => {
    setInput(prevCount => prevCount - 1);
  }

  const increment = () => {
    console.log("increment")
    setInput(prevCount => prevCount + 1);
  }

  const getCheckeddisplayproducts = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    var data = {
      "companyid": company,
    }
    fetch(global.url + "displayCheckedProducts", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setCheckboxarray(responseJson.data)
        searchProduct1(refcode)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAll = () => {
    setFilt(true)
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(userdata.user_branch_id)
    setUsertype(userdata.user_type)

    var data = {
      "excelexport": true,
      "companyid": company,
      // "branchid" : userdata.user_branch_id
    }
    fetch(global.url + "viewAllproductbatchunhide", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("excel exp response", responseJson)
        setSource(responseJson.data)
        setLoader(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const onQtychange = (e, index) => {
    var input = [...checkboxarray]
    input[index].qty = e
    setCheckboxarray(input)
  }

  const onPurchasechange = (e, index) => {
    var input = [...checkboxarray]
    input[index].pb_purchaseprice = e
    setCheckboxarray(input)
  }

  const insertPurchaseRequest = () => {
    var data = {
      "purchasedetails": checkboxarray,
    }
    fetch(global.url + "insertPurchaseRequest", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          setPurchasemodal(false)
        }
        else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const blockChange = (e, id, index) => {
    // console.log("value id", check)
    let isChecked = e.target.checked;
    console.log(isChecked)
    if (isChecked == true) {
      var input = [...array1]
      input.push(id)
      // console.log("input", input)
      setArray1(input)

      var input1 = [...source]
      input1[index].pb_isblocked = true
      setSource(input1)
    }

    else {

      var input1 = [...source]
      input1[index].pb_isblocked = false
      setSource(input1)
      var input = [...array1]
      input.push(id)
      setArray1(input)
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] === id) {
          const removedElements = array1.splice(i, 1);
          console.log(removedElements); // Outputs [3]
          i--; // Since the indexes of elements following this index get updated after removal
        }
      }
    }
    console.log("array", array1)
  }

  const hideClick = () => {
    var data = {
      "pb_id": array1,
      "status": 1
    }
    console.log("data hide", data)
    fetch(global.url + "hideProductBatch", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
        }
        else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const unhideClick = () => {
    var data = {
      "pb_id": array1,
      "status": 0
      
    }
    console.log("data unhide", data)
    fetch(global.url + "hideProductBatch", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          console.log(responseJson.message,'ver123');
        }
        else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // const showClick = () => {
  //   var data = {
  //     "pb_id": array1,
  //     "status": 0
  //   }
  //   console.log("data show", data)
  //   fetch(global.url + "hideProductBatch", {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log("responseJson", responseJson)
  //       if (responseJson.error == false) {
  //         alert(responseJson.message)
  //       }
  //       else {
  //         alert(responseJson.message)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  const printClick = () => {
    window.print()
  }

  const checkpriceChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setBlocked2(1)
    }
    else {
      setBlocked2(0)
    }
  }

  const checkunitChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setBlocked3(1)
    }
    else {
      setBlocked3(0)
    }
  }

  const exportAllMachine = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "companyid": company
    }
    fetch(global.url + "exportForMachineTXT", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("link", responseJson)
        setDownloadlink(responseJson.data)

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onHidePreview = () => {
    setPreview(false)
    window.location.reload()
  }

  const fucn = () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 50);
    return () => {
      clearInterval(timer);
    };
  }

  useEffect(() => {
    if (progress == 100) {
      setProgressHide(false)
      setBulkmodal(false)
      viewduplicationOndummyUploads()
      setPreview(true)
    }
  }, [progress])

  const saveBarcodePedit = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "companyid": company,
      "barcode": barcodepedit,
      "branchid": userdata.user_branch_id
    }
    fetch(global.url + "addbarcodeEditable", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("addbarcodeEditable", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          setBarcodepedit("")
          viewBarcodelist()
        } else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    viewBarcodelist()
    viewVeglist()
  }, [])

  const saveVegPedit = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "companyid": company,
      "branchid": userdata.user_branch_id,
      "from": fromedit,
      "to": toedit
    }
    fetch(global.url + "zerostockenable", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("zerostockenable", responseJson)
        if (responseJson.error == false) {
          alert(responseJson.message)
          viewVeglist()
          setVegmodal(false)
        } else {
          alert(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const viewBarcodelist = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "companyid": company,
      "branchid": userdata.user_branch_id
    }
    fetch(global.url + "viewbarcodeEditable", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("viewbarcodeEditable", responseJson)
        if (responseJson.error == false) {
          setBarcodelist(responseJson.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const viewVeglist = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "companyid": company,
      // "branchid" : userdata.user_branch_id
    }
    fetch(global.url + "viewzerostock", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("viewzerostock", responseJson)
        if (responseJson.error == false) {
          setFromedit(responseJson.data.from)
          setToedit(responseJson.data.to)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="main-content">
      {modal == false ?
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <div style={{ margin: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Product Details</p>
              <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20, justifyContent:'end',alignItems:'flex-end',marginLeft:'600px' }}>
                {/* <Button className='btn1'  onClick={handleDownload}>Download Bulkupload format</Button> */}
              </div>
              <div style={{ display: 'flex' }}>
                {/* <Button onClick={() => setBarcodemodal(true)} variant="contained" style={{ backgroundColor: '#92c99f', marginRight: 20 }} >Price Edit-Barcode</Button> */}
                {/* <Button onClick={() => setVegmodal(true)} variant="contained" style={{ backgroundColor: '#92c99f', marginRight: 20 }} >Veg & Fruits</Button> */}
                {/* <Button onClick={() => setGrocerymodal(true)} variant="contained" style={{backgroundColor: '#92c99f', marginRight: 20}} >Grocery</Button> */}
                {/* {
                  btnload ?
                    <Loader2
                      type="Oval"
                      color="#38B0DE"
                      height={30}
                      width={30}
                      style={{ marginRight: 50 }}
                    /> :
                    <Button className='btn1' onClick={filtr1} variant="contained" style={{ marginLeft: 20 }} >1-7000 Product</Button>}
                {
                  btnload2 ?
                    <Loader2
                      type="Oval"
                      color="#38B0DE"
                      height={30}
                      width={30}
                      style={{ marginLeft: 50 }}
                    /> :
                    <Button className='btn1' onClick={filtr2} variant="contained" style={{ marginLeft: 20 }}>7000-10000 Vegitable</Button>} */}
              </div>
            </div>

            <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20 }}>

            <div>
                {
                  usertype == "superadmin" ?
                    null :
                    <div>
                      <Button className='btn1' variant="contained" style={{ marginLeft: 20, float: 'right',marginRight:'10px' }} onClick={onAdd}>Add New Products</Button>
                    </div>
                }
              </div>

              <div>
                <Button className='btn1' onClick={getAll} style={{ marginLeft: 20, marginRight:'18px' }}>View All Products</Button>
              </div>

              <div>
                <Button className='btn1' variant="contained" onClick={() => setPurchasemodal(true)}>Added To Checklist</Button>
              </div>
              {/* <div>
                <Button className='btn1' onClick={getAll} style={{ marginLeft: 20, }}>View All Products</Button>
              </div> */}
              <div>
                <Button className='btn1' variant="contained" style={{ marginLeft: 20 }} onClick={hideClick}>Hide</Button>


              </div>
              <div>
              <Button className='btn1' variant="contained" style={{ marginLeft: 20 }} onClick={unhideClick}>Unhide</Button>
                {/* <Button className='btn1' variant="contained" style={{ marginLeft: 20 }} onClick={showClick}>Show</Button> */}


              </div>
              <div style={{marginLeft: 20}}>
                {/* {
                  downloadlink == "" ?
                    <Button className='btn1' onClick={exportAllMachine} variant="contained" style={{ marginLeft: 20 }}>Export For Machine <span style={{ fontSize: 9, marginTop: 2 }}>(Prefix+Code+Dept+Weight)</span></Button> :
                    <a download href={global.txt + downloadlink} target="_blank" style={{ textDecoration: 'none' }}>
                      <Button className='btn1' variant="contained" style={{ marginLeft: 20 }}>Download</Button>
                    </a>
                } */}

<Button className='btn1'  onClick={handleDownload}>Bulkupload Excel Format</Button>

              </div>
              <div>
                {
                  usertype == "superadmin" ?
                    null :
                    <div>
                      <Button className='btn1' style={{ marginLeft: 20 }} variant="contained" onClick={() => setBulkmodal(true)}>Bulk Upload</Button>
                    </div>
                }
              </div>

              {/* <div>
                {
                  usertype == "superadmin" ?
                    null :
                    <div>
                      <Button className='btn1' variant="contained" style={{ marginLeft: 20, float: 'right' }} onClick={onAdd}>Add</Button>
                    </div>
                }
              </div> */}
              <div style={{ marginLeft: 20 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Download as XLS" />
              </div>

            </div>
            <div style={{ marginTop: 30 }}>
              <Row>
                <Col md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField name="Zone"
                      onChange={(e) => searchProduct('', '', e.target.value, '')}
                      autoComplete="off" id="outlined-basic" label="Barcode" variant="outlined" size="small" />
                  </FormControl>
                </Col>
                <Col md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField name="Zone"
                      onChange={(e) => searchProduct('', e.target.value, '', '')}
                      autoComplete="off" id="outlined-basic" label="Product Name" variant="outlined" size="small" />
                  </FormControl>
                </Col>
                <Col md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField name="Zone"
                      onChange={(e) => searchProduct(e.target.value, '', '', '')}
                      autoComplete="off" id="outlined-basic" label="Referal code" variant="outlined" size="small" />
                  </FormControl>
                </Col>
                <Col md={3}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">List type</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={listtype}
                      label="Age"
                      onChange={listtypeChange}
                      size="small"
                    >
                      <MenuItem value={"0"}>Batchwise</MenuItem>
                      <MenuItem value={"1"}>Product master wise</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              {/* <FormControl sx={{ width: '15%' }}>
                      <TextField name="Zone"
                      onChange={(e) => searchProduct('', '', '', e.target.value)}
                      autoComplete="off" id="outlined-basic" label="Zone" variant="outlined"  size="small"/>
                  </FormControl> */}

              <div>


              </div>
            </div>


            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
              {loader ? <Loader /> :
                <Table stickyHeader aria-label="sticky table" id='table-to-xls'>
                  <TableHead className="tableheaderexe headerpos1">
                    {/* <TableRow>
                    {
                    listtype == 0 ?
                    columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    )) : 
                    productcolumn.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))
                  }
                  </TableRow> */}
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Ref code (Product)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100, color: 'red' }}>
                        PLU Name (Vegitable)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100, color: 'red' }}>
                        Export For Machine (Dept-Prefix)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100, color: 'red' }}>
                        Export For Machine (Suffix)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100, color: 'red' }}>
                        Export For Machine (Code)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 150 }}>
                        Product Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 150 }}>
                        Local Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 150 }}>
                        Print Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 110 }}>
                        Description
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        HSN
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Colour
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Size
                      </TableCell>
                      {/* <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        MTN
                      </TableCell> */}
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        CTN
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        MTSN
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Barcode
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Currency
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Purchse Price
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Landing Cost
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        $
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Purchase Price + Landing Cost $
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Sales Price
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Wholesale (B2B Price)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Online Price
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        GST/Tax
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Department
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Section
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Main Category
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Sub Category
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Segment
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Sub Segment
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Zone (Display Location)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Supplier Code
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Supplier Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Supplier Contact
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Supplier Group
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Contry Control
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        UOM (Base Quatity of Product)
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Item Group Code
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Stock Group Code
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Expiry Date
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        IsUnitConvert
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Display Convert Unit
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Branch Control
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Order Level
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Order Qty
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', minWidth: 100 }}>
                        Godown
                      </TableCell>
                      <TableCell className='prostable2' style={{ fontWeight: 'bold', minWidth: 100, background: "white" }}>
                        Hide
                      </TableCell>
                      <TableCell className='prostable' style={{ fontWeight: 'bold', minWidth: 100, background: "white" }}>
                        Edit
                      </TableCell>
                      {/* <TableCell className='prostable' style={{ fontWeight: 'bold', minWidth: 100, background: "white" }}>
                        Disable
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {source.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={row.code}>
                          <TableCell >
                            {row.product_refcode}
                          </TableCell>
                          <TableCell >
                            {row.pb_pluname}
                          </TableCell>
                          <TableCell >
                            {row.pb_prefix_formachine}
                          </TableCell>
                          <TableCell >
                            {row.pb_suffix_formachine}
                          </TableCell>
                          <TableCell >
                            {row.pb_code_formachine}
                          </TableCell>
                          <TableCell >
                            {row.product_name}
                          </TableCell>
                          <TableCell >
                            {row.product_othername}
                          </TableCell>
                          <TableCell >
                            {row.product_printname}
                          </TableCell>
                          <TableCell >
                            {row.product_description}
                          </TableCell>
                          <TableCell >
                            {row.pb_hsn}
                          </TableCell>
                          <TableCell >
                            {row.color_name}
                          </TableCell>
                          <TableCell >
                            {row.size_name}
                          </TableCell>
                          <TableCell >
                            {row.unit_name}
                          </TableCell>
                          <TableCell >
                            {row.unit_code}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => barcodClick(row.pb_inter_barcode, row.pb_salesrate, row.product_name)}>
                              {row.pb_inter_barcode}
                            </a>
                          </TableCell>
                          <TableCell >
                            {row.pb_currencyqr}
                          </TableCell>
                          <TableCell >
                            {row.pb_purchaseprice}
                          </TableCell>
                          <TableCell >
                            {row.pb_mrp}
                          </TableCell>
                          <TableCell >
                            {row.pb_currencydollar}
                          </TableCell>
                          <TableCell >
                            {row.pb_purchase_landingcost}
                          </TableCell>
                          <TableCell >
                            {row.pb_salesrate}
                          </TableCell>
                          <TableCell >
                            {row.pb_b2bprice}
                          </TableCell>
                          <TableCell >
                            {row.pb_onlineprice}
                          </TableCell>
                          <TableCell >
                            {row.pb_tax}
                          </TableCell>
                          <TableCell >
                            {row.dept_name}
                          </TableCell>
                          <TableCell >
                            {row.section_name}
                          </TableCell>
                          <TableCell >
                            {row.category_name}
                          </TableCell>
                          <TableCell >
                            {row.subCategory_name}
                          </TableCell>
                          <TableCell >
                            {row.segment_name}
                          </TableCell>
                          <TableCell >
                            {row.subsegment_name}
                          </TableCell>
                          <TableCell >
                            {row.Zone}
                          </TableCell>
                          <TableCell >
                            {row.supplier_code}
                          </TableCell>
                          <TableCell >
                            {row.supplier_name}
                          </TableCell>
                          <TableCell >
                            {row.supplier_contactno}
                          </TableCell>
                          <TableCell >
                            {row.supplier_group}
                          </TableCell>
                          <TableCell >
                            {row.country_name}
                          </TableCell>
                          <TableCell >
                            {row.pb_baseqty}
                          </TableCell>
                          <TableCell >
                            {row.itemgroup_name}
                          </TableCell>
                          <TableCell >
                            {row.pb_stockgroup}
                          </TableCell>
                          <TableCell >
                            {row.pb_expiry_date}
                          </TableCell>
                          <TableCell >
                            {row.pb_isconvertunit}
                          </TableCell>
                          <TableCell >
                            {row.pb_displayconvertunit}
                          </TableCell>
                          <TableCell >
                            {row.branches}
                          </TableCell>
                          <TableCell >
                            {row.product_orderlevel}
                          </TableCell>
                          <TableCell >
                            {row.product_orderquantity}
                          </TableCell>
                          <TableCell >
                            {row.godown_name}
                          </TableCell>
                          {listtype == 0 ?
                            <TableCell className='prostable2' style={{ background: "white" }}>
                              <FormControl sx={{ width: '100%' }}>
                                <FormControlLabel control={
                                  <Checkbox checked={row.pb_isblocked == 1 ? true : false }   onChange={e => blockChange(e, row.pb_id, index)} />
                                } label="" />
                              </FormControl>
                            </TableCell> : null
                          }
                          <TableCell className='prostable' style={{ background: "white" }}>
                            {
                              usertype == "superadmin" ?
                                null :
                                <a href="javascript:void(0)" onClick={() =>
                                  editClick(
                                    row.products_id,
                                    row.product_code,
                                    row.product_name,
                                    row.product_printname,
                                    row.product_othername,
                                    row.product_description,
                                    row.product_taxrate,
                                    row.product_taxtype,
                                    row.product_itemgroup,
                                    row.product_brand,
                                    row.product_category,
                                    row.product_subcategory,
                                    row.product_baseunit,
                                    row.product_purchaseunit,
                                    row.product_saleunit,
                                    row.product_defaultprice,
                                    row.product_branches,
                                    row.product_type,
                                    row.product_refcode,
                                    row.product_warranty,
                                    row.product_segment,
                                    row.product_subsegment,
                                    row.product_orderlevel,
                                    row.product_orderquantity,
                                    row.product_displaylocation,
                                    row.product_godown,
                                    row.product_countryorigin,
                                    row.product_tax,
                                    row.product_blocked
                                  )}>
                                  <FaIcons.FaEdit fontSize={20} color="blue" />
                                </a>
                            }
                          </TableCell>

                          {/* <TableCell > */}
                            {/* <button onClick={() => handleButtonClick( row.pb_id, index)}  >
                              {productStatus ? 'Disable Product' : 'Enable Product'}
                              
                            </button> */}
                            {/* <button onClick={handleButtonClick}>
                                  {productStatus ? 'Disable Product' : 'Enable Product'}
                          </button> */}
                            {/* <button onClick={() => {
        handleButtonClick( productStatus);
        
        setProductStatus(productStatus === 0 ? 1 : 0);
      }}>
        {productStatus ? 'Disable Product' : 'Enable Product'}
      </button> */}
                          {/* </TableCell> */}

                          {listtype == 0 ?
                            null :
                            // <TableCell >
                            //     <a href="javascript:void(0)" onClick={() => deleteClick(row.products_id)}>
                            //       <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                            //     </a>
                            // </TableCell>
                            null
                          }
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </TableContainer>

            <TablePagination
                            rowsPerPageOptions={[1,5,10, 25,50, 100]}
                            component="div"
                            count={source.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

            {/* {filt == true ? null :
              <div style={{ marginTop: 20 }}>
                <Button onClick={increment} style={{ float: 'right' }} variant="contained">Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{ marginRight: 40, float: 'right', borderColor: '#000' }} className='stock-prev' variant="light">Previous</Button>
                }
              </div>
            } */}
          </div>
        </Paper>
        :
        <Row style={{ background: '#fff' }}>
          <Col xs={12}>
            <div style={{ margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
              <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 'bold' }}>Product Master</p>
              <Button variant="contained" onClick={onhide}>Back</Button>
            </div>
            <WizardFormFirstPage
              form={form}
              updateform={updateForm}
              checkchange={checkChange}
              blocked={blocked}
              itemGroup={itemGroup}
              itemgroupList={itemgroupList}
              brand={brand}
              brandList={brandList}
              category={category}
              categoryList={categoryList}
              subCategory={subCategory}
              subcatlist={subcatlist}
              baseUnit={baseUnit}
              purchaseUnit={purchaseUnit}
              salesUnit={salesUnit}
              defaultPrice={defaultPrice}
              priceList={priceList}
              unitList={unitList}
              godownList={godownList}
              godown={godown}
              godownChange={godownChange}
              itemgroupChange={itemgroupChange}
              brandChange={brandChange}
              categoryChange={categoryChange}
              subcategoryChange={subcategoryChange}
              purchaseunitChange={purchaseunitChange}
              salesunitChange={salesunitChange}
              defaultpriceChange={defaultpriceChange}
              baseunitChange={baseunitChange}

              codetype={codetype}
              codetypeChange={codetypeChange}

              producttype={producttype}
              radioChange={radioChange}
              editmode={editMode}

              selected={selected}
              setSelected={setSelected}
              branchlist={branchlist}
              selectImage={selectImage}

              color={color}
              colorlist={colorlist}
              colorChange={colorChange}

              size={size}
              sizelist={sizelist}
              sizeChange={sizeChange}
              addMultiple={addMultiple}
              changeMultiplepurchaseprice={changeMultiplepurchaseprice}
              changeMultiplesalesrate={changeMultiplesalesrate}
              changeMultiplemrp={changeMultiplemrp}
              changeMultiplestock={changeMultiplestock}
              multipleproduct={multipleproduct}

              expirydate={expiryDate}
              expiryChange={expiryChange}
              fileSelectedHandler={fileSelectedHandler}
              productid={productid}

              segment={segment}
              segmentChange={segmentChange}
              segmentlist={segmentlist}

              subsegment={subsegment}
              subsegmentChange={subsegmentChange}
              subsegmentlist={subsegmentlist}

              // vat={vat}
              // vatChange={vatChange}
              // vatList={vatList}

              dlocation={dlocation}
              dlocationChange={dlocationChange}
              dlocationlist={dlocationlist}

              country={country}
              countrylist={countrylist}
              countryChange={countryChange}


              ecommerceproduct={ecommerceproduct}
              productcolor={productcolor}
              productcolorChange={productcolorChange}
              productsize={productsize}
              productsizechange={productsizechange}
              productpurchaseprice={productpurchaseprice}
              setProductpurchaseprice={setProductpurchaseprice}
              productsalesprice={productsalesprice}
              setProductsalesprice={setProductsalesprice}
              productmrp={productmrp}
              setProductmrp={setProductmrp}
              productstock={productstock}
              setProductstock={setProductstock}
              addProductecommerse={addProductecommerse}
              editProductecommerse={editProductecommerse}
              productecommerceid={productecommerceid}
              clearProductecommerce={clearProductecommerce}
              loader={loader}
              viewimage={viewimage}
              productImagedelete={productImagedelete}
              uploadimage={uploadimage}
              fileSingleselectedHandler={fileSingleselectedHandler}
              getCategorylist={getCategorylist}
              getSubcategorylist={getSubcategorylist}
              getSegment={getSegment}
              getSubsegment={getSubsegment}
              getBrandlist={getBrandlist}
              getItem={getItem}
              
              
              


            />
            {/* <Row style={{ marginTop: 40 }}>
              <Col xs={12} >
          
                <div>
                  <h2>Multiple File Upload</h2>
                  <input type="file" multiple onChange={handleFileChange} />
                  <button onClick={handleUpload}>Upload</button>
                </div>

                <label>Image (640 × 508)</label>
                <input type="file" multiple onChange={(e) => bulkimageupload(e)} />
                <Button onClick={() => bulkimageuploadserver("")} style={{ float: 'left', marginBottom: 20, marginRight: 30, backgroundColor: '#92c99f', border: 'none' }} variant="contained">Bulk Upload Image</Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} >
                <Button onClick={() => bulkimageuploadserver("deleteimage")} style={{ backgroundColor: '#92c99f', border: 'none' }} variant="contained">Bulk Delete Image</Button>
              </Col>
            </Row> */}
            <Row style={{ marginTop: 20 }}>
              <Col xs={12} >
                {saveLoader ?
                  <div style={{ float: 'right' }}>
                    <BtnLoader />
                  </div>
                  :

                  <Button className='btn1' onClick={onNext} style={{ float: 'right', marginBottom: 20, border: 'none' }} variant="contained">Product Details Save</Button>
                }
              </Col>
            </Row>
            {/* <Row>
                    <Col xs={3}>
                        <FormControl sx={{  width: '100%' }}>
                            <TextField name="othername"
                            onChange={(e) => setColorcode(e.target.value)}
                            value={colorcode} autoComplete="off" id="outlined-basic" label="Color Code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={2}>
                        <Button onClick={() => colorcodeSave()} style={{float: 'right', marginBottom: 20}}  variant="contained">Color Code Save</Button>
                    </Col>
                </Row> */}
            {producttype == 1 && productid != "" ?
              <Row style={{ marginTop: 20 }}>
                {/* <WizardFormThirdPage 
                    multibaseunit = {multibaseunit} 
                    multibaseunitChange = {multibaseunitChange} 
                    // calculateCnvqty= {calculateCnvqty}
                    cnvunit = {cnvunit} 
                    cnvunitChange ={cnvunitChange}
                    baseqty ={baseqty}
                    cnvratio= {cnvratio}
                    unitList= {unitList}
                    setBaseqty={setBaseqty}
                    setCnvratio={setCnvratio}
                    cnvqty = {cnvqty}
                    addMultiunit = {addMultiunit}
                    deleteMultiunit = {deleteMultiunit}
                    multiUnitrow = {multiUnitrow}
                    unitbtnloader ={unitbtnloader}
                    unitlistloader = {unitlistloader}
              /> */}
                <WizardFormSecondPage
                  multipleblockChange={multipleblockChange}
                  batch={batch}
                  unit={unit}
                  purchaseprice={purchaseprice}
                  salesrate={salesrate}
                  mrp={mrp}
                  setMrp={setMrp}
                  setBatch={setBatch}
                  unitList={unitList}
                  unitChange={unitChange}
                  setPurchase={setPurchase}
                  setSales={setSales}
                  stock={stock}
                  setStock={setStock}
                  changestock={changestock}
                  addBatch={addBatch}
                  deleteBatch={deleteBatch}
                  tableRow={tableRow}
                  producttype={producttype}
                  batchbtnloader={batchbtnloader}
                  batchlistloader={batchlistloader}
                  colorlist={colorlist}
                  sizelist={sizelist}
                  batchcolor={batchcolor}
                  batchsize={batchsize}
                  batchcolorChange={batchcolorChange}
                  batchsizeChange={batchsizeChange}
                  batchexpirydate={batchexpirydate}
                  setBatchexpirydate={setBatchexpirydate}
                  batchfile={batchfile}
                  editbatch={editbatch}
                  file={file}
                  batchid={batchid}
                  clearBatch={clearBatch}

                  checkpriceChange={checkpriceChange}
                  checkunitChange={checkunitChange}
                  blocked2={blocked2}
                  setBlocked2={setBlocked2}
                  blocked3={blocked3}
                  setBlocked3={setBlocked3}
                  convertunit={convertunit}
                  setConvertunit={setConvertunit}
                  setUom={setUom}
                  setStockgroup={setStockgroup}
                  uom={uom}
                  stockgroup={stockgroup}
                  setB2b={setB2b}
                  b2b={b2b}
                  onlineprice={onlineprice}
                  setOnlineprice={setOnlineprice}
                  setIntbarcode={setIntbarcode}
                  intbarcode={intbarcode}
                  setManbarcode={setManbarcode}
                  manbarcode={manbarcode}
                  setQrcode={setQrcode}
                  setHsn={setHsn}
                  setTax={setTax}
                  setCurrenctqr={setCurrenctqr}
                  setCurrenctdoller={setCurrenctdoller}
                  setPlc={setPlc}
                  hsn={hsn}
                  tax={tax}
                  currencyqr={currencyqr}
                  currencydoller={currencydoller}
                  plc={plc}
                  qrcode={qrcode}
                  enddate={enddate}
                  setEnddate={setEnddate}

                  loader={loader}
                  viewimage={viewimage}
                  productImagedelete={productImagedelete}
                  uploadimage={uploadimage}
                  fileSingleselectedHandler={fileSingleselectedHandler}

                />
                <Others
                  supplierList={supplierList}
                  supplierCode={supplierCode}
                  unitList={unitList}
                  supplierName={supplierName}
                  contactNumber={contactNumber}
                  mainsupplierlist={mainsupplierlist}
                  mainsupplierChange={mainsupplierChange}
                  mainsupplier={mainsupplier}
                  gst={gst}
                  addSupplier={addSupplier}
                  setSuppliercode={setSuppliercode}
                  setSuppliername={setSuppliername}
                  setContactnumber={setContactnumber}
                  setGst={setGst}
                  deleteSupplier={deleteSupplier}
                  multiUnitrow={multiUnitrow}
                />
              </Row> : producttype == 0 && productid != "" ?
                <div>
                  <Others
                    supplierList={supplierList}
                    supplierCode={supplierCode}
                    unitList={unitList}
                    supplierName={supplierName}
                    contactNumber={contactNumber}

                    mainsupplierlist={mainsupplierlist}
                    mainsupplierChange={mainsupplierChange}
                    mainsupplier={mainsupplier}

                    gst={gst}
                    addSupplier={addSupplier}
                    setSuppliercode={setSuppliercode}
                    setSuppliername={setSuppliername}
                    setContactnumber={setContactnumber}
                    setGst={setGst}
                    deleteSupplier={deleteSupplier}
                    multiUnitrow={multiUnitrow}
                  />
                  <Defaultprice
                    batch={batch}
                    unit={unit}
                    purchaseprice={purchaseprice}
                    salesrate={salesrate}
                    mrp={mrp}
                    setMrp={setMrp}
                    setBatch={setBatch}
                    unitList={unitList}
                    unitChange={unitChange}
                    setPurchase={setPurchase}
                    setSales={setSales}
                    stock={stock}
                    setStock={setStock}
                    addBatch={addBatch}
                    deleteBatch={deleteBatch}
                    tableRow={tableRow}
                    producttype={producttype}
                    batchbtnloader={batchbtnloader}
                    batchlistloader={batchlistloader}
                    colorlist={colorlist}
                    sizelist={sizelist}
                    batchcolor={batchcolor}
                    batchsize={batchsize}
                    batchcolorChange={batchcolorChange}
                    batchsizeChange={batchsizeChange}
                    batchexpirydate={batchexpirydate}
                    setBatchexpirydate={setBatchexpirydate}
                    batchfile={batchfile}
                    editbatch={editbatch}
                    file={file}
                    batchid={batchid}
                    clearBatch={clearBatch}
                    editmode={editMode}
                    viewshow={viewshow}
                    loader={loader}
                    viewimage={viewimage}
                    productImagedelete={productImagedelete}
                    uploadimage={uploadimage}
                    fileSingleselectedHandler={fileSingleselectedHandler}
                    defaulttablerow={defaulttablerow}
                  />
                </div>
                : null
            }
            {productid != "" ?
              <div>
              </div>
              // <Others 
              //         supplierList = {supplierList} 
              //         supplierCode = {supplierCode} 
              //         unitList = {unitList}
              //         supplierName = {supplierName} 
              //         contactNumber ={contactNumber}
              //         gst ={gst}
              //         addSupplier= {addSupplier}
              //         setSuppliercode= {setSuppliercode}
              //         setSuppliername={setSuppliername}
              //         setContactnumber={setContactnumber}
              //         setGst = {setGst}
              //         addSupplier = {addSupplier}
              //         deleteSupplier = {deleteSupplier}
              //         multiUnitrow = {multiUnitrow}
              //   />
              : null}
          </Col>
        </Row>
      }
      <Modal
        size="lg"
        show={bulkmodal}
        onHide={() => setBulkmodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Bulk upload
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} >

              <label style={{ width: '100%' }}>Select File</label>
              <input type="file" onChange={(e) => changeBulkfile(e)} style={{ marginTop: 10 }} />
            </Col>
            <Col xs={12} >
              <p style={{ fontSize: 16, color: 'red' }}>Do not add already existed product</p>
            </Col>
            <div>
              {progresshide == true ?
                <Row>
                  <Col md={4}>
                    <ProgressBar now={progress} label={`${progress}%`} />
                  </Col>
                </Row> : null}
            </div>
            <Col xs={12} style={{ marginTop: 30 }}>
              <div>
                {bulkloader ?
                  <Loader2
                    type="Oval"
                    color="#38B0DE"
                    height={30}
                    width={30}
                    style={{ float: 'right' }} /> :
                  <Button onClick={() => onBulksubmit()} style={{ float: 'right' }} variant="contained">Submit</Button>
                }
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* <Modal
        size="xl"
        show={preview}
        onHide={onHidePreview}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {tableloader ? <Loader /> :
                <Table responsive id="table-to-xls" bordered className="section-to-print">
                  <thead>
                    <tr>
                      <th>Ref Code</th>
                      <th>PLU Name</th>
                      <th>Prefix</th>
                      <th>Suffix</th>
                      <th>Code</th>
                      <th>Barcode</th>
                      <th>Product Name</th>
                      <th>Expiry Date</th>
                      <th>Department</th>
                      <th>Section</th>
                      <th>Main Category</th>
                      <th>Sub Category</th>
                      <th>Segment</th>
                      <th>Sub Segment</th>
                      <th>Zone(Display Location)</th>
                      <th>Supplier Code</th>
                      {/* <th>Supplier Name</th>  
                      <th>Supplier Contact</th>
                      <th>Supplier Group</th>
                      <th>Country Control</th>
                      <th>Item Group Code</th>
                      <th>Godown</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bulkpreview.map(item => {
                      return (
                        <tr >
                          <td style={item.d_duplicate == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_refcode}
                          </td>
                          <td style={item.d_pluname == "" ? { backgroundColor: 'red' } : null}>
                            {item.d_pluname}
                          </td>
                          <td style={item.d_dup_machine == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_prefix_formachine}
                          </td>
                          <td style={item.d_dup_machine == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_suffix_formachine}
                          </td>
                          <td style={item.d_dup_machine == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_code_formachine}
                          </td>
                          <td style={item.d_dup_barcode == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_barcode}
                          </td>
                          <td>
                            {item.d_productname}
                          </td>
                          <td style={item.d_dup_expirydate == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_expirydate}
                          </td>
                          <td style={item.d_dup_department_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_department_master}
                          </td>
                          <td style={item.d_dup_section_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_section_master}
                          </td>
                          <td style={item.d_dup_maincategory_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_maincategory_master}
                          </td>
                          <td style={item.d_dup_subcategory_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_subcategory_master}
                          </td>
                          <td style={item.d_dup_segment_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_segment_master}
                          </td>
                          <td style={item.d_dup_subsegment_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_subsegment_master}
                          </td>
                          <td style={item.d_dup_displayloc_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_displayloc_master}
                          </td>
                          <td style={item.d_dup_supref_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_supref_master}
                          </td>
                          <td style={item.d_dup_supcontact_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_supcontact_master}
                          </td>
                          <td style={item.d_dup_supgroup_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_supgroup_master}
                          </td>
                          <td style={item.d_dup_country_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_country_master}
                          </td>

                          <td style={item.d_dup_itemgroup_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_itemgroup_master}
                          </td>

                          <td style={item.d_dup_godown_master == 1 ? { backgroundColor: 'red' } : null}>
                            {item.d_godown_master}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              }
            </Col>
            {bulkdupli == true ? null :
              <Col xs={12}>
                 {bulkloader ? 
                      <BtnLoader />:
                      <Button onClick={() => deleteDuplicate()} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Delete Duplicate</Button>
                    }

                </Col>:
              <Col xs={12}>
                {bulkloader ?
                  <Loader2
                    type="Oval"
                    color="#38B0DE"
                    height={30}
                    width={30}
                    style={{ float: 'right' }} /> :
                  <Button onClick={() => onBulkUploadSubmit()} style={{ float: 'right', marginTop: 20 }} variant="contained">Upload</Button>
                }

              </Col>}
          </Row>
        </Modal.Body>
      </Modal> */}

      <Modal
        size="xl"
        show={purchasemodal}
        onHide={() => setPurchasemodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Purchase Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Button style={{ backgroundColor: '#7e7e7e' }} onClick={printClick} variant="contained">Print</Button>
            </Col>
            <Col sm={6}>
              <a onClick={clearAll}>
                <p style={{ fontSize: 17, color: 'red', textAlign: 'right', cursor: 'pointer' }}>Clear all</p>
              </a>
            </Col>
            <Table responsive id="table-to-xls" bordered className="section-to-print">
              <thead>
                <tr>
                  <th>Ref code</th>
                  <th>Name</th>
                  {/* <th>Color</th>
                    <th>Size</th> */}
                  <th>Qty</th>
                  <th>Purchase price</th>
                </tr>
              </thead>
              <tbody>
                {checkboxarray.map((result, index) => {
                  return (
                    <tr>
                      <td>
                        {result.product_refcode}
                      </td>
                      <td>
                        {result.product_name}
                      </td>
                      {/* <td>
                                     {result.color_name}
                                </td>
                                <td>
                                     {result.size_name}
                                </td> */}
                      <td>
                        <FormControl >
                          <TextField autoComplete="off" type="number" value={result.qty} onChange={(e) => onQtychange(e.target.value, index)} id="outlined-basic" label="QTY" variant="outlined" size="small" />
                        </FormControl>
                      </td>
                      <td>

                        <FormControl >
                          <TextField autoComplete="off" type="number" value={result.pb_purchaseprice} onChange={(e) => onPurchasechange(e.target.value, index)} id="outlined-basic" label="Purchase price" variant="outlined" size="small" />
                        </FormControl>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

          </Row>
          <Row>
            <Col sm={3}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  onChange={(e) => searchProduct1(e.target.value, "", "")}
                  autoComplete="off" id="outlined-basic" label="Barcode" variant="outlined" size="small" />
              </FormControl>
            </Col>
            <Col sm={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  onChange={(e) => searchProduct1("", e.target.value, "")}
                  autoComplete="off" id="outlined-basic" label="Product Name" variant="outlined" size="small" />
              </FormControl>
            </Col>
            <Col sm={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField name="Ref code"
                  onChange={(e) => searchProduct1("", "", e.target.value)}
                  autoComplete="off" id="outlined-basic" label="Referal code" variant="outlined" size="small" />
              </FormControl>
            </Col>
            <Col sm={2}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-helper-label">Supplier</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Age"
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col sm={1}>
              <Button variant="contained" style={{ float: 'right' }} onClick={() => insertPurchaseRequest()}>Submit</Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            {tableloader ? <Loader /> :
              <Table responsive id="table-to-xls" bordered className="section-to-print">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Ref Code</th>
                    <th>Barcode</th>
                    <th>Product Name</th>
                    {/* <th>Color</th>
                                <th>Size</th> */}
                    <th>Stock</th>
                    <th>Purchase Price</th>
                    <th>Sales price</th>
                  </tr>
                </thead>
                <tbody>
                  {searchsource.map(result => {
                    return (
                      <tr>
                        <td>
                          <FormControl sx={{ width: '100%' }}>
                            <FormControlLabel control={
                              <Checkbox checked={result.pb_ispurchaseorder == 1 ? true : false} onChange={e => checkBoxclick(e, result.pb_id)} />
                            } label="" />
                          </FormControl>
                        </td>
                        <td>
                          {result.product_refcode}
                        </td>
                        <td>
                          {result.pb_inter_barcode}
                        </td>
                        <td>
                          {result.product_name}
                        </td>
                        {/* <td>
                                  {result.color_name}
                                </td>
                                <td>
                                  {result.size_name}
                                </td> */}
                        <td>
                          {result.pb_stock}
                        </td>
                        <td>
                          {result.pb_purchaseprice}

                        </td>
                        <td>
                          {result.pb_salesrate}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            }

          </Row>
        </Modal.Body>
      </Modal>
      <Modal className='rent'
        size="lg"
        show={barmodal}
        onHide={() => setBarmodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton  className='nem'>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
                Purchase Detail
                </Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{alignItems:'center',display:'flex',justifyContent:'center'}}>
          <div style={{ marginTop: 10 }} >
            <div style={{ width: 176, height: 135 }} className="section-to-print">
              <div>
                {/* <p style={{ fontSize: 14, marginBottom: 'unset', textAlign: 'center' }}>Dekanet Beirut</p> */}
                <p style={{ fontSize: 12, marginBottom: 'unset', textAlign: 'center' }}>{global.proname}</p>

                <p style={{ fontSize: 10, marginBottom: 'unset', textAlign: 'center' }}>{barcodenameog}</p>
                <p style={{ fontSize: 12, marginBottom: 'unset', textAlign: 'center' }}>QAR {barcodeprice}</p>
              </div>
              <div className="barcodeimg" style={{ position: 'relative', }}>
                <Barcode value={barcodename} />
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button className='mmm' variant="contained" onClick={printClick}>Print</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={expmodal}
        onHide={() => setExpmodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
                Purchase Detail
                </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: 10 }} >
            <Row>
              <Col xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={expdate}
                    onChange={(newValue) => {
                      setExpdate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <div style={{ marginTop: 20 }}>
              <Button variant="contained" onClick={expsubmitClick}>Add</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={expmodalview}
        onHide={() => setExpmodalview(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Expiry Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: 10 }} >
            <Row>
              <Row>
                <Col xs={12}>
                  <ul>
                    {expdatename.map((row, index) => {
                      return (
                        <li className="pay-lists" style={{ color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer' }}>{row.pbexp_date}</li>
                      )
                    })
                    }
                  </ul>
                </Col>
              </Row>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={vegmodal}
        onHide={() => setVegmodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Vegitables and Fruits (Ref Code - Price Editable)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: 10 }} >
            <Row>
              <Col md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField name="Zone"
                    autoComplete="off" value={fromedit} onChange={(e) => setFromedit(e.target.value)} id="outlined-basic" label="From" variant="outlined" size="small" />
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField name="Zone"
                    autoComplete="off" value={toedit} onChange={(e) => setToedit(e.target.value)} id="outlined-basic" label="To" variant="outlined" size="small" />
                </FormControl>
              </Col>
            </Row>
            <Button onClick={saveVegPedit} variant="contained" style={{ marginTop: 20 }} >Submit</Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
              size="lg"
              show={grocerymodal}
              onHide={() => setGrocerymodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Grocery (Ref Code - Price Editable)
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div style={{marginTop:10}} >
                      <Row>
                          <Col md={4}>
                            <FormControl sx={{ width: '100%' }}>
                              <TextField name="Zone"
                                autoComplete="off" id="outlined-basic" label="From" variant="outlined"  size="small"/>
                            </FormControl>
                          </Col>
                          <Col md={4}>
                            <FormControl sx={{ width: '100%' }}>
                              <TextField name="Zone"
                                autoComplete="off" id="outlined-basic" label="To" variant="outlined"  size="small"/>
                            </FormControl>
                          </Col>
                      </Row>
                      <Button variant="contained" style={{marginTop: 20}} >Submit</Button>
                    </div>
              </Modal.Body>
           </Modal> */}
      <Modal
        size="lg"
        show={barcodemodal}
        onHide={() => setBarcodemodal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Barcode (Price Editable)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: 10 }} >
            <Row>
              <Col md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField name="Barcode"
                    autoComplete="off" value={barcodepedit} onChange={(e) => setBarcodepedit(e.target.value)} id="outlined-basic" label="Barcode" variant="outlined" size="small" />
                </FormControl>
              </Col>
            </Row>
            <Button onClick={saveBarcodePedit} variant="contained" style={{ marginTop: 20 }} >Submit</Button>
            <div style={{ marginTop: 20 }}>
              <Table responsive id="table-to-xls" bordered className="section-to-print">
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th>Barcode</th>
                  </tr>
                </thead>
                <tbody>
                  {barcodelist.map((item, index) => {
                    return (
                      <tr >
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          {item.barcode}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}
export default Customer;
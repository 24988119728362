
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const columns = [
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Voucher No.', minWidth: 50,span: false },
    { id: '2', label: 'Barcode', minWidth: 50,span: false },
    { id: '3', label: 'Customer', minWidth: 50,span: false },
    { id: '4', label: 'Product', minWidth: 50,span: false },
    { id: '5', label: 'Quantity', minWidth: 50,span: false },
    { id: '7', label: 'CP', minWidth: 50,span: false },
    { id: '8', label: 'Total CP	', minWidth: 50,span: false },
    { id: '8', label: 'SP', minWidth: 50,span: false },
    { id: '8', label: 'Total SP	', minWidth: 50,span: false },
    { id: '8', label: 'Profit', minWidth: 50,span: false },
    { id: '8', label: 'Profit Total	', minWidth: 50,span: false },
    { id: '8', label: 'Profit %', minWidth: 50,span: false },
  ];
  
function Productwise(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Productwise Report</p>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                      {/* <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                        setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col> */}
                        <Col xs={12} md={3} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Search" autoComplete='off' id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={9} style={{marginTop: 20}}>
                          <div style={{float: 'right'}}>
                              {/* <Button style={{backgroundColor: '#92c99f', border: '#92c99f'}} variant="primary" >Filter</Button> */}
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="table-to-xls"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="Download as XLS"/>
                          </div>
                        </Col>
                </Row>
            </div>

            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
            {loader ? <Loader /> :
            
              <Table stickyHeader aria-label="sticky table" id="table-to-xls">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <>
                            <TableRow  tabIndex={-1} >
                                <TableCell style={{fontWeight: 'bold'}}>
                             
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                           
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>

                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                        
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                  
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                               
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                 
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                              
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                   
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                              
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                              
                                </TableCell> 
                                <TableCell style={{fontWeight: 'bold'}}>
                              
                                </TableCell> 
                            </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>

            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper> 
      </div>
    )
}
export default Productwise;
import React, {Component, useEffect, useState, useRef} from 'react';
import './InvoiceBill.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

function InvoiceBill(){
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState("")
    const [othercharges, setOthercharges] = React.useState("")
    const [customer, setCustomer] = React.useState("")
    const [building, setBuilding] = React.useState("")
    
    console.log("id", id)
    useEffect(() => {
        var data = {
            "purchase_id" : id,
        }
        
        fetch(global.url + "purchasePrint", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("print details", responseJson)
            setDetails(responseJson.data.details)
            setTotal(responseJson.data.total)
            setVoucherno(responseJson.data.purchase_invoice)
            setDate(responseJson.data.purchase_date)
            setOthercharges(responseJson.data.purchase_other_charges)
            setCustomer(responseJson.data.supplier_name)
            setBuilding(responseJson.data.supplier_address1)
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);

    const print = () => {
        window.print()
    }

    return(
        <div>
            <Container fluid >
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        
                        <div className="invoice-box1 printing">
                            <div className="invoice-inside" style={{marginLeft:'-60px'}}>
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>{global.proname}</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Purchase Invoice</p>
                                <Row>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>عميل</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Supplier: {customer}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>رقم القسيمة</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Voucher No: {voucherno}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Building: {building}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Date: {date}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Other Reference(s): Cash</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col>
                                </Row>    
                                <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                <div>
                                    <Table  bordered>
                                        <thead>
                                            <tr>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                                            <th ><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الرمز الشريطي</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Barcode</p>
                                            </th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Product</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Price</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Total</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                details.map((item, index) => {
                                                    return(
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.pb_inter_barcode}</td>
                                                            <td>{item.productname}</td>
                                                            <td>{item.qty}</td>
                                                            {item.price == null ? <td>{item.price}</td>:
                                                            <td>QAR {item.price}</td>}
                                                            <td>QAR {item.pqd_total_amount}</td>
                                                        </tr>
                                                    )
                                               
                                                })
                                            }
                                            <tr>
                                                <td colSpan={4}></td>
                                                <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                <p style={{fontSize: 14, fontWeight: 'bold'}}>Other Charges</p></th>
                                                <td>QAR {othercharges}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td colSpan={4}></td>
                                                <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                <p style={{fontSize: 14, fontWeight: 'bold'}}>Sub Total</p></th>
                                                <td>QAR {salestotal}</td>
                                            </tr>
                                            <tr>
                                            <td colSpan={4}></td>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Total</p></th>
                                            <td>QAR {salestotal}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={{marginTop: 30}} className="invoicebill">
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>{global.proname}</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Doha, Qatar</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Button onClick={print} variant="primary">Print</Button>
        </div>
    );
}


export default InvoiceBill;
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import Loader from "react-loader-spinner";

function loader() {
  return (
        <Loader
            type="ThreeDots"
            color="#92c99f"
            height={80}
            width={80}
            style={{display: 'flex', justifyContent: 'center'}}
        />
  );
}

export default loader;
